import map from 'lodash/map';
import size from 'lodash/size';
import min from 'lodash/min';
import max from 'lodash/max';
import range from 'lodash/range';
// import { intl } from '../config/intl';

const common = {
  paper_bgcolor: 'white',
  plot_bgcolor: 'white',
  font: {
    color: 'black',
    family: 'Roboto, sans-serif',
    size: 12
  },
  // eslint-disable-next-line id-length
  margin: { t: 30, autoexpand: true }
};

const marker = { color: 'rgb(94,248,241)' };

// eslint-disable-next-line max-statements
function bestFitLine(values_x, values_y) {
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  const values_length = size(values_x);
  if (values_length <= 0 || values_length != size(values_y)) return [[], []];
  let x = 0;
  let y = 0;
  for (let index = 0; index < values_length; index += 1) {
    x = values_x[index];
    y = values_y[index];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
  }
  const mval = ((values_length * sum_xy) - (sum_x * sum_y)) / ((values_length * sum_xx) - (sum_x * sum_x));
  const bval = (sum_y / values_length) - ((mval * sum_x) / values_length);
  const result_values_x = [];
  const result_values_y = [];
  for (let index = 0; index < values_length; index += 1) {
    x = values_x[index];
    y = (x * mval) + bval;
    result_values_x.push(x);
    result_values_y.push(y);
  }
  return [result_values_x, result_values_y];
}

export function createPredErrorData(values) {
  const { y_true: x, y_predicted: y } = values || {};
  const bestFitData = bestFitLine(x, y);
  return {
    data: [
      {
        x,
        y,
        mode: 'markers',
        marker,
        showLegend: false,
        name: 'Error', //intl.formatMessage({ id: 'project.metrics.prediction_error.error' })
      },
      // Identity line
      {
        x: [min(x), max(x)],
        y: [min(y), max(y)],
        line: { dash: 'dash', color: 'rgb(252, 118, 90)', width: 3 },
        name: 'Identity', //intl.formatMessage({ id: 'project.metrics.prediction_error.identity' }),
        mode: 'lines',
        showLegend: true
      },
      // Best fit
      {
        x: bestFitData[0],
        y: bestFitData[1],
        line: { dash: 'dash', color: 'rgb(252, 220, 91)', width: 3 },
        name: 'Best Fit', //intl.formatMessage({ id: 'project.metrics.prediction_error.best_fit' }),
        mode: 'lines',
        showLegend: true
      }
    ],
    layout: {
      ...common,
      xaxis: {
        title: 'True', //intl.formatMessage({ id: 'project.metrics.prediction_error.true_value' }),
        autorange: true
      },
      yaxis: {
        title: 'Predicted', //intl.formatMessage({ id: 'project.metrics.prediction_error.predicted' }),
        type: 'linear',
        autorange: true
      }
    }
  };
}

export function createPredCurveData(values) {
  const { y_predicted, y_true } = values || {};
  const x = range(size(y_predicted));
  return {
    data: [
      {
        x,
        y: y_true,
        line: { dash: 'solid', color: 'rgb(252, 118, 90)', width: 3 },
        name: 'True', //intl.formatMessage({ id: 'project.metrics.prediction_curve.true_value' }),
        mode: 'lines',
        showLegend: true
      },
      {
        x,
        y: y_predicted,
        line: { dash: 'dash', color: 'rgb(252, 220, 91)', width: 3 },
        name: 'Predicted', //intl.formatMessage({ id: 'project.metrics.prediction_curve.predicted' }),
        mode: 'lines',
        showLegend: true
      }
    ],
    layout: {
      ...common,
      yaxis: { anchor: 'x', autorange: true },
      xaxis: {type: 'linear', autorange: true }
    }
  };
}

export function createResidualsData(values) {
  const { y_predicted: x, y_true } = values || {};
  const y = y_true || [];
  return {
    data: [
      {
        x,
        y: map(x, (value, index) => value - y[index]),
        mode: 'markers',
        showlegend: false,
        type: 'scatter',
        marker
      },
      {
        x: [min(x), max(x)],
        y: [0, 0],
        mode: 'lines',
        line: { color: 'gray' },
        type: 'scatter'
      }
    ],
    layout: {
      ...common,
      yaxis: {
        title: 'Residuals', //intl.formatMessage({ id: 'project.metrics.residuals.residuals' }),
        anchor: 'x',
        autorange: true
      },
      xaxis: {
        title: 'Predicted Value', //intl.formatMessage({ id: 'project.metrics.residuals.predicted_value' }),
        type: 'linear',
        autorange: true
      }
    }
  };
}
