import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { Container, Form } from 'react-bootstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../../redux/actions/index';
import './styles.scss';


const renderField = (field) => {
  const {
    input,
    label,
    placeholder,
    type,
    name,
    meta: { touched, error, warning },
  } = field;
  return (
    <Form.Group key={`${name}-${type}`}>
      <Form.Label style={{ color: "#6e6e6e" }}>{label}</Form.Label>
      <input
        type={type}
        placeholder={placeholder}
        {...input}
        className="form-control"
      />
      {touched
        && ((error && <p className="help is-danger">{error}</p>)
          || (warning && <p className="help is-danger">{warning}</p>))}
    </Form.Group>
  );
}

const renderSelectField = (field) => {
  const { input, label, placeholder, options, isMulti, name, meta: { touched, error, warning } } = field;
  return (
    <Form.Group controlId={name}>
      <Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
      <Select
        {...input}
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
          },
        })}
      />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </Form.Group>
  );
};


const notifySuccess = (msg) => toast.success(msg);

const notifyError = (msg) => toast.error(msg);

const dispositionOpt = [
  { value: 'D', label: 'Positive' },
  { value: 'G', label: 'Negative' }
];

const AddHealthForm = (props) => {
  const {
    getHealthStatusData,
    addHealthStatusData,
    handleSubmit,
    onResetForm,
  } = props;
  const [isLoading, setIsLoading] = useState(false)


  const handleHealthStatus = (values) => {
    setIsLoading(true)
    const data = {
      event_id: values.event_id,
      first_name: values.first_name,
      last_name: values.last_name,
      email_address: values.email_address,
      mobile_phone: values.mobile_phone,
      disposition: values.disposition.value
    }

    addHealthStatusData(data, () => {
      notifySuccess('Added Health Data Successfully.');
      getHealthStatusData();
      onResetForm();
      setIsLoading(false);
    });
  }


  return (
    <div className="from-div">
      <h2>Add Health Data</h2>
      <Form onSubmit={handleSubmit(handleHealthStatus)}>
        <Field
          name="event_id"
          type="text"
          placeholder="Enter Event ID"
          component={renderField}
          label="EventID"
        />
        <Field
          name="first_name"
          type="text"
          placeholder="Enter first name"
          component={renderField}
          label="First Name"
        />
        <Field
          name="last_name"
          type="text"
          placeholder="Enter last name"
          component={renderField}
          label="Last Name"
        />
        <Field
          name="email_address"
          type="text"
          placeholder="Enter email address"
          component={renderField}
          label="Email Address"
        />
        <Field
          name="mobile_phone"
          type="text"
          placeholder="Enter mobile number"
          component={renderField}
          label="Mobile Number"
        />
        <Field
          name="disposition"
          type="text"
          isMulti={false}
          placeholder="Please select disposition"
          component={renderSelectField}
          options={dispositionOpt}
          label="Disposition"
        />
        <button type="submit" className="upload-btn exp-btn">
          {isLoading ? 'Waiting on Health Access...' : 'Add'}
        </button>
      </Form>
    </div>
  )
}

const validate = (values) => {
  const errors = {};
  if (!values.event_id) {
    errors.event_id = 'Field is required';
  }
  if (!values.first_name) {
    errors.first_name = 'Field is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Field is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Field is required';
  }
  if (!values.email_address) {
    errors.email_address = 'Email address field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_address)) {
    errors.email_address = 'Invalid email address';
  }
  if (!values.mobile_phone) {
    errors.mobile_phone = 'Field is required';
  }
  if (!values.disposition) {
    errors.disposition = 'Field is required';
  }
  return errors;
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getHealthStatusData: () => dispatch(actions.getHealthStatusData()),
  addHealthStatusData: (params, callback) => dispatch(actions.addPWHealthStatusData(params, callback)),
  onResetForm: () => dispatch(reset('AddHealthForm')),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'AddHealthForm',
    validate
  })(AddHealthForm)
);