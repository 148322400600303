// IMPORT PACKAGE REFERENCES

import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';

// IMPORT REDUCERS
import { AuthReducer } from './AuthReducer';
import { CompanyReducer } from './CompanyReducer';
import { ResellerReducer } from './ResellerReducer';
import { MSReducer } from './MSReducer';
import {AdminSettingsReducer} from "./AdminSettingsReducer";
import { AugerReducer } from './AugerReducer';
import { S2reportReducer } from './S2reportReducer';
import { RWReducer } from './RWReducer';
import { PWReducer } from './PWReducer';

// EXPORT APP REDUCER

export const AppReducer = combineReducers({
  auth: AuthReducer,
  form: FormReducer,
  company: CompanyReducer,
  reseller: ResellerReducer,
  ms: MSReducer,
  adminSettings: AdminSettingsReducer,
  auger: AugerReducer,
  s2data: S2reportReducer,
  rw: RWReducer,
  pw: PWReducer,
});
