import axiosAuth from '../axiosInstance';

export const PW_GET_USER_HEALTH_STATUS = 'PW_GET_USER_HEALTH_STATUS';
export const PW_ADD_USER_HEALTH_STATUS = 'PW_ADD_USER_HEALTH_STATUS';
export const UPLOAD_CSV_HEALTH_DATA = 'UPLOAD_CSV_HEALTH_DATA';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';
export const FORM_START = 'FORM_START';
export const FORM_COMPLETE = 'FORM_COMPLETE';


export const formStart = () => ({
	type: FORM_START
});

export const formComplete = () => ({
	type: FORM_COMPLETE
});

export const fetchStart = () => ({
	type: FETCH_START
});

export const fetchFail = (errorMsg) => ({
	type: FETCH_FAIL,
	errorMsg
});

export const addHealthStatus = (status) => ({
	type: PW_ADD_USER_HEALTH_STATUS,
	status
});

export const getHealthStatus = (status) => ({
	type: PW_GET_USER_HEALTH_STATUS,
	status
});

export const csvData = (data) => ({
	type: UPLOAD_CSV_HEALTH_DATA,
	data: data
});



export const addPWHealthStatusData = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post('/user-health-status/add', params)
		.then((res) => {
			dispatch(addHealthStatus(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const getHealthStatusData = (callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.get(`/user-health-status`)
		.then((res) => {
			dispatch(getHealthStatus(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};


export const uploadCsvFile = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post(`/user-health-status/csv/upload`, params)
		.then((res) => {
			dispatch(csvData(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};


