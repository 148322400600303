import React, { Component } from 'react';
import './support-cases.scss';
import DropDown from '../../../components/Admin/DropDown';
import Table from '../../../components/Admin/Table';


const SeverityRender = ({severity}) => {
    return(
        <div className={severity}>{severity}</div>
    )
}
const providerOptions = [
    {label: 'Provider 1', value: 'provider1'},
    {label: 'Provider 2', value: 'provider 2'},
    {label: 'Provider 3', value: 'provider3'}
];

const severity = {
    columns: [
        {title: 'Company', key: 'company'},
        {title: 'Subject', key: 'subject'},
        {title: 'Created', key: 'created'},
        {title: 'Severity', key: 'severity'},
        {title: 'Status', key: 'status'}
    ],
    rows: [
        {
            company: 'Amanino',
            subject: 'BlackSwan data sync issues',
            created: '2019-06-22T14:04',
            severity: <SeverityRender severity='high'/>,
            status: 'Resolved',
        },
        {
            company: 'Amanino',
            subject: 'BlackSwan data sync issues',
            created: '2019-06-22T14:04',
            severity: <SeverityRender severity='mid'/>,
            status: 'Needs addressing',
        },
        {
            company: '?',
            subject: 'BlackSwan data sync issues',
            created: '2019-06-22T14:04',
            severity: <SeverityRender severity='low'/>,
            status: '?',
        },
    ]
}

export default class SupportCases extends Component {

      render() {
        return (
            <section>
                <div className="container-fluid">
                <div className="row">
                    <div className="support-content">
                        <h5>Support Cases</h5>
                        <div className="table-content-box">
                        <div className="support-account">
                          <div>
                            <h5>Accounts</h5>
                              <span>
                                <p>Filter: &nbsp;</p>
                                <DropDown options={providerOptions} placeholder="Provider"/>
                            </span>
                          </div>
                            <button className="custom-btn">create new</button>
                        </div>
                    <Table columns={severity.columns} rows={severity.rows}/>
                        </div>
                 </div>
                </div>
                </div>
            </section>
        )
    }
}
