import { Provider } from 'react-redux';
import Container from './Container';
import { createAppStore } from './redux/stores/AppStore';
import 'react-toastify/dist/ReactToastify.min.css';
import { hot } from 'react-hot-loader/root';

const App = () => {
	// console.log(process.env.NODE_ENV, 'process.env.NODE_ENV');
	console.log(process.env.API_URL, 'API_URL');
	return (
		<div id="root" className="App main-html">
			<Provider store={createAppStore()}>
				<Container />
			</Provider>
		</div>
	);
};

export default hot(App);
