import React from 'react';
import styled from 'styled-components';

import { StyledSpinner } from './styled';

const spinner = () => (
	<StyledSpinner>
		<div className="loader">Loading ...</div>
	</StyledSpinner>
);

export default spinner;
