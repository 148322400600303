import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import loadable from 'react-loadable';
import loading from '../../../../components/Loading/Loading';

const Plot = loadable({ loading,
  loader: () => import('./plot')
});

export default class Chart extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    layout: PropTypes.object,
    config: PropTypes.object
  };

  static defaultProps = {
    config: {
      showLink: false,
      displayModeBar: false,
      responsive: true
    }
  };

  render() {
    const { data, layout, config } = this.props;
    return (
      <Plot
        style={{ width: '100%' }}
        data={data}
        layout={layout}
        config={config}
      />
    );
  }
}
