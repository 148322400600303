import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import logo from '../../../static/images/Trovo-stacked-white.png'
import AnimatedBGFE from '../Charts/AnimatedBGFE'

const Container = styled.div`
  width: 60vw;
  margin: 0 auto
  border-radius: 10px;
  background-color: rgba(39,46,52, 0.9);
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 15%;
  left: 0px;
  right: 0px;
  justifyContent: center;

`
//background-size: 30vw 100%, contain;
const ImageContainer = styled.div`
  display: flex;
  padding-top: 75px;
  flex-flow: column;
  width: 275px;
  max-width: 40%;
  min-width: 275px;
  min-height: 100%;
  flex: 1;

`


const StyleImg = styled.img`
  display: block;
  margin: auto;
  width: 260px;
  height: auto;
`

const FormContainer = styled.div`
  width: 60%;
  height: 100%;
  padding: 20px 10px;
  background-color: transparent;
  flex: 1;
`

//style={{backgroundImage: `url(${background})`}} 
//<span style={{color: "white", marginTop: "10px", padding: "10%", paddingTop: "25%", fontSize: "16pt"}}>COMPETING IN THE NEW DATA ECONOMY</span> 
class FormBg extends React.Component{

  constructor(props){
  	super(props);
  }

  render(){
  	return(
      <div>
      <AnimatedBGFE />
      <Container>
        <ImageContainer> 
          <div >
            <StyleImg src={logo} />
          </div>         
        </ImageContainer>
        <FormContainer>
      	  {this.props.children}
      	</FormContainer>
      </Container>
      </div>
    )
  }


}

export default FormBg;

