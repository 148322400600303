import forEach from 'lodash/forEach';
import slice from 'lodash/slice';
import size from 'lodash/size';
import map from 'lodash/map';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';

const common = {
  paper_bgcolor: 'white',
  plot_bgcolor: 'white',
  font: {
    color: 'black',
    family: 'Roboto, sans-serif',
    size: 12
  },
  // eslint-disable-next-line id-length
  margin: { t: 30, autoexpand: true }
};

const colors = [
  'rgb(94,248,241)', 'rgb(150, 255, 133)', 'rgb(112, 31, 137)', 'rgb(205, 86, 90)', 'rgb(60, 115, 212)',
  'rgb(210, 127, 61)', 'rgb(221, 221, 219)', 'rgb(160, 145, 97)', 'rgb(52, 206, 111)', 'rgb(106, 156, 245)'
];

export const truncateLabel = (label) => {
  const len = size(label);
  return len <= 20 ? label : `${label.substring(0, 9)}...${label.substring(len - 9, len)}`;
};


export function createFeatureImportanceData(values) {
  const x = [];
  const y = [];
  const marker_color = [];
  let colorIdx = 0;
  const { features: feature_names, scores: feature_scores } = values || {};
  forEach(feature_names, (val) => {
    x.push(truncateLabel(val));
    marker_color.push(colors[colorIdx]);
    if ((colorIdx += 1) >= colors.length) colorIdx = 0;
  });
  forEach(feature_scores, (val) => y.push(val));
  return {
    data: [
      { x, y, type: 'bar', marker: { color: marker_color }, hoverinfo: 'y' }
    ],
    layout: { ...common,
      xaxis: {
        type: 'category',
        automargin: true
      },
      yaxis: {
        range: [-1, 1],
        automargin: true
      }
    }
  };
}


export function experimentData(values) {
  return {
    data: values,
    layout: { ...common,
      xaxis: { type: 'category', title: 'Regex Rules Category', autorange: true},
      yaxis: {title: 'Cloud Experiment Graph', autorange: true},
      barmode: 'relative',
      title: 'PII Models'
    }
  };
}