import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
// TODO: Create custom styled bootstrap modules
import { Col, Container, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';

import Button from '../../components/Forms/Button';
import FormBg from '../../components/Layouts/FormBg';
import './form.css';
// import Spinner from '../../components/Spinner/Spinner';

import * as actions from '../../redux/actions/index';

const StyledField = styled.input`
	&:-internal-autofill-selected,
	&:required,
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 50px #3e3e3e inset;
		-webkit-text-fill-color: #cdcdcd;
	}

	&::placeholder {
		color: #cdcdcd;
	}

	background-color: #3e3e3e;
	border: none;
	border-bottom: 1px solid #cdcdcd;
	color: #cdcdcd;
	width: 100%;
`;
const renderField = (field) => {
	const { input, label, placeholder, type, meta: { touched, error, warning } } = field;
	return (
		<Form.Group key={`${name}-${type}`}>
			<Form.Label htmlFor={label} style={{ color: '#6e6e6e' }}>
				{label}
			</Form.Label>
			<div>
				<StyledField
					{...input}
					type={type}
					placeholder={placeholder}
					style={{ background: '#6e6e6e' }}
					className="field-item"
				/>
			</div>
			{touched &&
				((error && <p className="help is-danger">{error}</p>) ||
					(warning && <p className="help is-danger">{warning}</p>))}
		</Form.Group>
	);
};

const Login = (props) => {
	useEffect(() => {
		props.onClearErrMsg();
	}, []);

	const submitHandle = (values) => {
		props.onSignIn(values, () => {
			props.history.push('/');
		});
	};

	const { handleSubmit, loadingSubmit, errorMsg } = props;
	let errorMessage = null;
	if (errorMsg) {
		errorMessage = <p className="is-danger">{errorMsg}</p>;
	}
	let authRedirect = null;
	if (props.isAuthenticated) {
		authRedirect = <Redirect to="/" />;
	}
	return (
			<FormBg>
				<Container style={{ color: "white" }} >
					<h2 style={{ marginLeft: '0px'}}>Login</h2>
					<Row className="">
						<Col>
							{authRedirect}
							{errorMessage}
							<Form onSubmit={handleSubmit((values) => submitHandle(values))}>
								<Field
									name="email"
									type="email"
									placeholder="Enter email"
									label="Email address"
									component={renderField}
								/>
								<Field
									name="password"
									type="password"
									placeholder="Enter password"
									label="Password"
									component={renderField}
								/>
								<Button
									style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}
									type="submit"
									block
								>
									{loadingSubmit ? ' LOADING...' : 'Login'}
								</Button>
							</Form>
						</Col>
					</Row>
					<Row style={{ marginTop: '20%' }}>
						<Col>
							<Link style={{ color: '#68afa9' }} to={'/forgot-password'}>
								Forgot Password?
							</Link>
						</Col>
						<Col>
							<p style={{ fontSize: '11pt' }}>
								Don't have an account?{' '}
								<Link to="/register" style={{ color: '#68afa9', fontWeight: 'bold' }}>
									REGISTER
								</Link>
							</p>
						</Col>
					</Row>
				</Container>
			</FormBg>
	);
};

const validate = (values) => {
	const errors = {};
	if (!values.email) {
		errors.email = 'Email address field is required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}
	if (!values.password) {
		errors.password = 'Password field is required';
	} else if (values.password.length < 6) {
		errors.password = 'Password should be longer than 6 letters!';
	}
	return errors;
};

const mapStateToProps = (state) => ({
	loading: state.auth.loading,
	loadingSubmit: state.auth.submit,
	errorMsg: state.auth.errorMsg,
	isAuthenticated: state.auth.idToken !== null
});

const mapDispatchToProps = (dispatch) => ({
	onSignIn: (email, password) => dispatch(actions.signIn(email, password)),
	onClearErrMsg: () => dispatch(actions.clearAllErrMsg())
});

export default connect(mapStateToProps, mapDispatchToProps)(
	reduxForm({
		form: 'loginForm',
		validate
	})(Login)
);
