import axios from 'axios';
import size from 'lodash/size';
import axiosAuth from '../axiosInstance';

export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';
export const FETCH_COMPLETE = 'FETCH_COMPLETE';
export const FETCH_AUGER_ORGANIZATIONS = 'FETCH_AUGER_ORGANIZATIONS';
export const FETCH_AUGER_PROJECTS = 'FETCH_AUGER_PROJECTS';
export const START_AUGER_PROJECT = 'START_AUGER_PROJECT';
export const STOP_AUGER_PROJECT = 'STOP_AUGER_PROJECT';
export const ADD_AUGER_PROJECT = 'ADD_AUGER_PROJECT';
export const FETCH_AUGER_PROJECT_DATASETS = 'FETCH_AUGER_PROJECT_DATASETS';
export const FETCH_AUGER_PROJECT_FILES = 'FETCH_AUGER_PROJECTS_FILES';
export const ADD_AUGER_PROJECT_FILE = 'ADD_AUGER_PROJECT_FILE';
export const FETCH_AUGER_EXPERIMENTS = 'FETCH_AUGER_EXPERIMENTS';
export const ADD_AUGER_EXPERIMENTS = 'ADD_AUGER_EXPERIMENTS';
export const GET_AUGER_EXPERIMENTS_SESSION = 'GET_AUGER_EXPERIMENTS_SESSION';
export const RUN_AUGER_EXPERIMENTS_SESSION = 'RUN_AUGER_EXPERIMENTS_SESSION';
export const FETCH_AUGER_EXPERIMENTS_ID = 'FETCH_AUGER_EXPERIMENTS_ID';
export const DELETE_AUGER_EXPERIMENTS_ID = 'DELETE_AUGER_EXPERIMENTS_ID';
export const CHECK_AUGER_API_STATUS = 'CHECK_AUGER_API_STATUS';
export const LOG_AUGER_API = 'LOG_AUGER_API';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const ADD_NEW_AUGER_PROJECT = 'ADD_NEW_AUGER_PROJECT';
export const GET_TRIAL_METRICS = 'GET_TRIAL_METRICS';
export const GET_AUGER_TRIALS = 'GET_AUGER_TRIALS';
export const DEPLOY_TRIALS = 'DEPLOY_TRIALS';
export const DEPLOY_EXPERIMENTS_SESSION_TRIALS =
	'DEPLOY_EXPERIMENTS_SESSION_TRIALS';
export const GET_JITTERBIT_DATASOURCE = 'GET_JITTERBIT_DATASOURCE';

const TOKEN =
	'329767c0bb9b8258c432f2ca3712d6cdc2193c09cabb991108cb020f6ecc586b';
const AUGER_API_ENDPOINT = 'https://app.auger.ai/api/v1';
const ORGGANIZATION_ID = '632';

export const fetchStart = () => ({
	type: FETCH_START,
});

export const fetchFail = errorMsg => ({
	type: FETCH_FAIL,
	errorMsg,
});
export const fetchComplete = msg => ({
	type: FETCH_COMPLETE,
	msg,
});

export const augerOrganizations = organizations => ({
	type: FETCH_AUGER_ORGANIZATIONS,
	organizations,
});

export const augerProjects = projects => ({
	type: FETCH_AUGER_PROJECTS,
	projects,
});

export const addProject = project => ({
	type: ADD_AUGER_PROJECT,
	project,
});

export const addNewProject = project => ({
	type: ADD_NEW_AUGER_PROJECT,
	project,
});

export const startProject = project => ({
	type: ADD_AUGER_PROJECT,
	project,
});

export const projectsFile = projectsFile => ({
	type: FETCH_AUGER_PROJECTS,
	projectsFile,
});

export const addProjectsFile = projectsFile => ({
	type: ADD_AUGER_PROJECT_FILE,
	transaction: projectsFile,
});

export const fetchExperiments = experiments => ({
	type: FETCH_AUGER_EXPERIMENTS,
	experiments,
});

export const fetchExperimentsID = experiments => ({
	type: FETCH_AUGER_EXPERIMENTS_ID,
	experiments,
});

export const addExperiments = experiment => ({
	type: ADD_AUGER_EXPERIMENTS,
	experiment,
});

export const deleteExperiments = experiment => ({
	type: DELETE_AUGER_EXPERIMENTS_ID,
	experiment,
});

export const projectDatasets = datasets => ({
	type: FETCH_AUGER_PROJECT_DATASETS,
	datasets,
});

export const logAugerApi = logs => ({
	type: LOG_AUGER_API,
	logs,
});

export const experimentSessions = expSessions => ({
	type: RUN_AUGER_EXPERIMENTS_SESSION,
	expSessions,
});

export const getExpSessions = expSessions => ({
	type: GET_AUGER_EXPERIMENTS_SESSION,
	expSessions,
});

export const addTransaction = transaction => ({
	type: ADD_TRANSACTION,
	transaction,
});

export const getTrialMetrics = trialMetrics => ({
	type: GET_TRIAL_METRICS,
	trialMetrics,
});

export const getTrials = trials => ({
	type: GET_AUGER_TRIALS,
	trials,
});

export const getJitterBitData = jitterBit => ({
	type: GET_JITTERBIT_DATASOURCE,
	jitterBitOptions: jitterBit,
});

export const deployTrials = data => ({
	type: DEPLOY_TRIALS,
	deployTrialData: data,
});

export const deployModelData = data => ({
	type: DEPLOY_EXPERIMENTS_SESSION_TRIALS,
	deployModelData: data,
});

export const fetchOrganizations = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/organizations?token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(augerOrganizations(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response.data, 'fetchOrganizations');
				dispatch(fetchFail(err.message));
			});
	};
};

export const addAugerProject = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		console.log(params, 'params');
		const url = `${AUGER_API_ENDPOINT}/projects?name=${params.name}&organization_id=${ORGGANIZATION_ID}&token=${TOKEN}`;
		await axios
			.post(url)
			.then(resData => {
				dispatch(addNewProject(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response, 'addAugerProject');
				dispatch(fetchFail(err.message));
			});
	};
};

export const fetchAugerProjects = company_id => {
	return async dispatch => {
		dispatch(fetchStart());
		await axiosAuth
			.get(`/auger/?company__id=${company_id}`)
			.then(async resData => {
				const projects = [];
				if (size(resData.data.results) > 0) {
					const url = `${AUGER_API_ENDPOINT}/projects?organization_id=${ORGGANIZATION_ID}&token=${TOKEN}`;
					await axios
						.get(url)
						.then(res => {
							resData.data.results.forEach(r => {
								const project = res.data.data.filter(
									d => d.id === r.project_id
								);
								projects.push(project[0]);
							});
							dispatch(augerProjects(projects));
						})
						.catch(err => {
							console.log(err.response, 'fetchAugerProjects');
							dispatch(fetchFail(err.message));
						});
				} else {
					dispatch(augerProjects(projects));
				}
			})
			.catch(err => {
				console.log(err, 'fetchAugerProjects');
				dispatch(fetchFail(err.message));
			});
	};
};

export const fetchAllAugerProjects = () => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/projects?organization_id=${ORGGANIZATION_ID}&token=${TOKEN}`;
		await axios
			.get(url)
			.then(res => {
				dispatch(augerProjects(res.data.data));
			})
			.catch(err => {
				console.log(err.response, 'fetchAugerProjects');
				dispatch(fetchFail(err.message));
			});
	};
};

export const startAugerProject = (project_id, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/projects/${project_id}/deploy?worker_type_id=1&workers_count=2&cluster_autoterminate_minutes=10&token=${TOKEN}`;
		await axios
			.patch(url)
			.then(() => {
				callback();
			})
			.catch(err => {
				console.log(err.response.data, 'fetchAugerProjects');
				dispatch(fetchFail(err.message));
			});
	};
};

export const stopAugerProject = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/projects/${params.project_id}/undeploy?token=${TOKEN}`;
		await axios
			.patch(url)
			.then(resData => {
				dispatch(addProject(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response.data, 'fetchAugerProjects');
				dispatch(fetchFail(err.message));
			});
	};
};

export const fetchAugerExperiments = project_id => {
	return async dispatch => {
		dispatch(fetchStart());
		dispatch(logAugerApi({ message: 'fetchAugerExperiments' }));
		const url = `${AUGER_API_ENDPOINT}/experiments?project_id=${project_id}&token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(fetchExperiments(resData.data.data));
				// callback();
			})
			.catch(err => {
				console.log(err.response, 'fetchAugerExperiments');
				dispatch(fetchFail(err.message));
			});
	};
};

export const addAugerExperiments = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/experiments?name=${params.name}&project_id=${params.project_id}&project_file_id=${params.project_file_id}&is_auto_create_session=true&token=${TOKEN}`;
		await axios
			.post(url)
			.then(resData => {
				dispatch(addTransaction(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response, 'addAugerExperiments');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getAugerExperimentID = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/experiments?project_id=${params.project_id}&name=${params.name}&project_file_id=${params.project_file_id}&token=${TOKEN}`;
		await axios
			.post(url)
			.then(resData => {
				dispatch(addExperiments(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err, 'getAugerExperimentID');
				dispatch(fetchFail(err.message));
			});
	};
};

export const delAugerExperiments = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/experiments?project_id=${params.project_id}&name=${params.name}&project_file_id=${params.project_file_id}&token=${TOKEN}`;
		await axios
			.post(url)
			.then(resData => {
				dispatch(addExperiments(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err, 'delAugerExperiments');
				dispatch(fetchFail(err.message));
			});
	};
};

export const addAugerExperimentSessions = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/experiment_sessions?token=${TOKEN}`;
		await axios
			.post(url, params)
			.then(resData => {
				dispatch(addTransaction(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response, 'addAugerExperimentSessions');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getAugerExperimentSessions = (exp_session_id, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/experiment_sessions/${exp_session_id}?token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(getExpSessions(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response, 'getAugerExperimentSessions');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getLeaderBoardTrials = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/experiment_sessions/${params.experiment_session_id}/trials?token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(addTransaction(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response, 'getLeaderBoardTrials');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getAugerTrials = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/trials?experiment_session_id=${params.experiment_session_id}&limit=1&token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(addTransaction(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response, 'getAugerTrials');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getAugerTrialMetrics = (experiment_session_id, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/trials?experiment_session_id=${experiment_session_id}&limit=1&token=${TOKEN}`;
		await axios
			.get(url)
			.then(async resData => {
				if (resData.data.data.length > 0) {
					dispatch(getTrials(resData.data.data));
					const trial_id = resData.data.data[0].id;
					const urlTM = `${AUGER_API_ENDPOINT}/trial_metrics?trial_id=${trial_id}&limit=100&token=${TOKEN}`;
					await axios
						.get(urlTM)
						.then(res => {
							const metrics = res.data.data.filter(
								d => d.metrics_status === 'success'
							);
							dispatch(getTrialMetrics(metrics));
							callback();
						})
						.catch(err => {
							console.log(err.response, 'getAugerTrialMetrics');
							dispatch(fetchFail(err.message));
						});
				} else {
					console.log('getAugerTrialMetrics NO data');
				}
			})
			.catch(err => {
				console.log(err.response, 'getAugerTrials');
				dispatch(fetchFail(err.message));
			});
	};
};

export const fetchAugerProjectDataSets = project_id => {
	return async dispatch => {
		dispatch(fetchStart());
		dispatch(logAugerApi({ message: 'fetchAugerProjectDataSets' }));
		const url = `${AUGER_API_ENDPOINT}/project_files?&project_id=${project_id}&limit=100&token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(projectDatasets(resData.data.data));
				// callback();
			})
			.catch(err => {
				console.log(err.response, 'fetchAugerProjectDataSets');
				dispatch(fetchFail(err.message));
			});
	};
};

export const addAugerProjectFile = (params, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/project_files?file_name=${params.fileName}&name=${params.name}&project_id=${params.project_id}&url=${params.filePath}&token=${TOKEN}`;
		await axios
			.post(url)
			.then(resData => {
				dispatch(addProjectsFile(resData.data.data));
				callback();
			})
			.catch(err => {
				console.log(err.response.data, 'addAugerProjectFile');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getJitterBitDataOptions = () => {
	return async dispatch => {
		dispatch(fetchStart());
		await axiosAuth
			.get('/jitterbits-datasource/?limit=500')
			.then(resData => {
				dispatch(getJitterBitData(resData.data.results));
			})
			.catch(err => {
				console.log(err, 'getJitterBitDataOptions');
				dispatch(fetchFail(err.message));
			});
	};
};

export const deployTrialsAuger = (experiment_session_id, callback) => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/trials?experiment_session_id=${experiment_session_id}&limit=1&token=${TOKEN}`;
		await axios
			.get(url)
			.then(async resData => {
				if (resData.data.data.length > 0) {
					const trial_id = resData.data.data[0].id;
					const urlTM = `${AUGER_API_ENDPOINT}/pipelines?trial_id=${trial_id}&token=${TOKEN}`;
					await axios
						.get(urlTM)
						.then(res => {
							dispatch(deployTrials(res.data.data));
							callback();
						})
						.catch(err => {
							console.log(err.response, 'deployTrialsAuger');
							dispatch(fetchFail(err.message));
						});
				} else {
					console.log('deployTrialsAuger NO data');
				}
			})
			.catch(err => {
				console.log(err.response, 'deployTrialsAuger');
				dispatch(fetchFail(err.message));
			});
	};
};

export const addAugerProjectOnTed = params => {
	return async dispatch => {
		dispatch(fetchStart());
		const reData = {
			scan_date: params.scan_date,
			company: params.company_id,
			microservice: params.microservice,
			project_id: params.project_id,
		}
		await axiosAuth
			.post('/add-auger-project/', reData)
			.then((resData) => {
				dispatch(fetchComplete('Done'));
				// const reportId = resData.data.id
				// const data = {
				// 	project_id: params.project_id,
				// 	company: params.company_id,
				// 	report: reportId
				// }
				// await axiosAuth
				// .post('/auger/', data)
				// .then(resData => {
				// 	dispatch(fetchComplete('Done'));
				// })
				// .catch(err => {
				// 	console.log(err, 'addAugerProjectOnTed');
				// 	dispatch(fetchFail(err.message));
				// });
			})
			.catch(err => {
				console.log(err, 'addAugerProjectOnTed');
				dispatch(fetchFail(err.message));
			});
	};
};

export const getDeploymentsModelData = project_id => {
	return async dispatch => {
		dispatch(fetchStart());
		const url = `${AUGER_API_ENDPOINT}/trials?project_id=${project_id}&has_saved_models=true&limit=100&token=${TOKEN}`;
		await axios
			.get(url)
			.then(resData => {
				dispatch(deployModelData(resData.data.data));
			})
			.catch(err => {
				console.log(err.response.data, 'getDeploymentsModelData');
				dispatch(fetchFail(err.message));
			});
	};
};
