import React, { Component, Fragment } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Switch from '../../../components/Admin/Switch';
import moment from 'moment';

import './calendar.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment);

const events = [
	{
		id: 0,
		title: 'All Day Event very long title',
		allDay: true,
		start: new Date(2015, 3, 0),
		end: new Date(2015, 3, 1)
	},
	{
		id: 1,
		title: 'Long Event',
		start: new Date(2015, 3, 7),
		end: new Date(2015, 3, 10)
	},

	{
		id: 2,
		title: 'DTS STARTS',
		start: new Date(2016, 2, 13, 0, 0, 0),
		end: new Date(2016, 2, 20, 0, 0, 0)
	},

	{
		id: 3,
		title: 'DTS ENDS',
		start: new Date(2016, 10, 6, 0, 0, 0),
		end: new Date(2016, 10, 13, 0, 0, 0)
	},

	{
		id: 4,
		title: 'Some Event',
		start: new Date(2015, 3, 9, 0, 0, 0),
		end: new Date(2015, 3, 10, 0, 0, 0)
	},
	{
		id: 5,
		title: 'Conference',
		start: new Date(2015, 3, 11),
		end: new Date(2015, 3, 13),
		desc: 'Big conference for important people'
	},
	{
		id: 6,
		title: 'Meeting',
		start: new Date(2015, 3, 12, 10, 30, 0, 0),
		end: new Date(2015, 3, 12, 12, 30, 0, 0),
		desc: 'Pre-meeting meeting, to prepare for the meeting'
	},
	{
		id: 7,
		title: 'Lunch',
		start: new Date(2015, 3, 12, 12, 0, 0, 0),
		end: new Date(2015, 3, 12, 13, 0, 0, 0),
		desc: 'Power lunch'
	},
	{
		id: 8,
		title: 'Meeting',
		start: new Date(2015, 3, 12, 14, 0, 0, 0),
		end: new Date(2015, 3, 12, 15, 0, 0, 0)
	},
	{
		id: 9,
		title: 'Happy Hour',
		start: new Date(2015, 3, 12, 17, 0, 0, 0),
		end: new Date(2015, 3, 12, 17, 30, 0, 0),
		desc: 'Most important meal of the day'
	},
	{
		id: 10,
		title: 'Dinner',
		start: new Date(2015, 3, 12, 20, 0, 0, 0),
		end: new Date(2015, 3, 12, 21, 0, 0, 0)
	},
	{
		id: 11,
		title: 'Birthday Party',
		start: new Date(2015, 3, 13, 7, 0, 0),
		end: new Date(2015, 3, 13, 10, 30, 0)
	},
	{
		id: 12,
		title: 'Late Night Event',
		start: new Date(2015, 3, 17, 19, 30, 0),
		end: new Date(2015, 3, 18, 2, 0, 0)
	},
	{
		id: 12.5,
		title: 'Late Same Night Event',
		start: new Date(2015, 3, 17, 19, 30, 0),
		end: new Date(2015, 3, 17, 23, 30, 0)
	},
	{
		id: 13,
		title: 'Multi-day Event',
		start: new Date(2015, 3, 20, 19, 30, 0),
		end: new Date(2015, 3, 22, 2, 0, 0)
	},
	{
		id: 14,
		title: 'Today',
		start: new Date(new Date().setHours(new Date().getHours() - 3)),
		end: new Date(new Date().setHours(new Date().getHours() + 3))
	},
	{
		id: 15,
		title: 'Point in Time Event',
		start: new Date(),
		end: new Date()
	}
];

export default class CalendarComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			isChecked: false
		};
	}
	handleChange = (value) => {
		this.setState({ isChecked: value });
	};

	render() {
		const { isChecked } = this.state;
		return (
			<Fragment>
				<div className="container-fluid">
					<div className="main-calendar">
						<div className="rbc-calendar-header">
							<h6>View:</h6>
							<div className="rbc-content-switch">
								<h5>Payments:</h5>
								<ul>
									<li>
										<p>Overdue</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch
												isChecked={isChecked}
												handleChange={this.handleChange}
											/>&nbsp;&nbsp; off
										</span>
									</li>
									<li>
										<p>On time</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch
												isChecked={isChecked}
												handleChange={this.handleChange}
											/>&nbsp;&nbsp; off
										</span>
									</li>
									<li>
										<p>Future</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch isChecked={isChecked} handleChange={this.handleChange} />&nbsp;
											&nbsp; off
										</span>
									</li>
								</ul>
							</div>
							<div className="rbc-content-switch">
								<h5>Scans:</h5>
								<ul>
									<li>
										<p>BlackSwan</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch
												isChecked={isChecked}
												handleChange={this.handleChange}
											/>&nbsp;&nbsp; off
										</span>
									</li>
									<li>
										<p>Nmap</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch
												isChecked={isChecked}
												handleChange={this.handleChange}
											/>&nbsp;&nbsp; off
										</span>
									</li>
									<li>
										<p>SecurityScorecard</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch
												isChecked={isChecked}
												handleChange={this.handleChange}
											/>&nbsp;&nbsp; off
										</span>
									</li>
									<li>
										<p>?</p>
										<span>
											on &nbsp;&nbsp;{' '}
											<Switch
												isChecked={isChecked}
												handleChange={this.handleChange}
											/>&nbsp;&nbsp; off
										</span>
									</li>
								</ul>
							</div>
						</div>
						<Calendar localizer={localizer} events={events} startAccessor="start" endAccessor="end" />
					</div>
				</div>
			</Fragment>
		);
	}
}
