import React from 'react';
import size from 'lodash/size';
import { connect } from 'react-redux';
import ls from 'local-storage';
import Moment from 'react-moment';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import MainContent from '../../components/Layouts/MainContent';

import loadable from 'react-loadable';
import loading from '../../components/Loading/Loading';

import CsvDownload from 'react-json-to-csv';
import { currencyFormatter } from '../../common/utils/currencyFormatter';
import { deriveUnitCost, colorClassCVSS } from '../../common/utils/deriveUnitCost';
import { colors } from '../../global/colors';
import { OverviewWidget } from './styled';
import RangeSelector from '../../components/Charts/ChartUI/RangeSelector';
import { getReportsForDSA, getMSCompScanList, getRemediationReport, getCyberScans } from '../../utilities/s2APIHelpers';
import { nmapParseTab } from '../../utilities/dataMutators';
import * as actions from '../../redux/actions/index';

const ScansWidget = loadable({ loading,
  loader: () => import('../../components/DashboardGRE/ScansWidget')
});
const ValueWidget = loadable({ loading,
  loader: () => import('../../components/DashboardGRE/ValueWidget')
});
const SensitivityWidget = loadable({ loading,
  loader: () => import('../../components/DashboardGRE/SensitivityWidget')
});
const RedundancyWidget = loadable({ loading,
  loader: () => import('../../components/DashboardGRE/RedundancyWidget')
});
const HistoryBarChart = loadable({ loading,
  loader: () => import('../../components/Charts/HistoryBarChart')
});

// import ScansWidget from '../../components/DashboardGRE/ScansWidget';
// import ValueWidget from '../../components/DashboardGRE/ValueWidget';
// import SensitivityWidget from '../../components/DashboardGRE/SensitivityWidget';
// import RedundancyWidget from '../../components/DashboardGRE/RedundancyWidget';
// import HistoryBarChart from '../../components/Charts/HistoryBarChart';

import './dashboardnew.scss';

const ContainerWrapper = styled(Container)`
  background: #2b343a;
  margin-left: 15px;
`;

const LeftSection = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #1b2024;
  margin-top: 30px;
  line-height: 52px;
  padding: 0 15px;
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 5px;
  }
`;

const DynamicRow1 = styled(Row)`
  @media (min-width: 768px) and (max-width: 992px) {
    display: none;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 10px;
  }
`;

const DynamicRow2 = styled(Row)`
  display: none;
  @media (min-width: 768px) and (max-width: 992px) {
    display: flex;
    flex-flow: row nowrap;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  line-height: 45px;
  flex: 0.5;
`;

const TimeRange = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
  h5 {
    margin-bottom: 0;
    align-self: center;
    font-size: 16px;
    font-weight: 600;
  }
  p {
    margin-bottom: 0;
    align-self: center;
    padding: 0 25px;
    font-size: 14px;
    @media (max-width: 991px) {
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 0 10px;
    }
  }
`;

const DataWrapper = styled.div`background: #272e34;`;

const ChartWrapper = styled.div`
  background: #272e34;
  margin-top: 30px;
  padding: 30px 30px 0;
  min-height: 100%;
  overflow: hidden;
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  color: #fff;
  font-size: 14px;
  p {
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
  }
`;
const SourceTitle = styled.div`
  color: #fff;
  font-size: 15px;
  padding-bottom: 10px;
`;
const SourceChartWrapper = styled.div`
  background: #272e34;
  padding: 10px 20px;
`;
const BoxWrapper = styled.div`
  background: #272e34;
  height: 100%;
`;
const CardWrapper = styled.div`
    background: #272e34;
    height: 100%;
    padding: 15px;
    h5 {
       color: #fff;
       font-size: 15px;
       padding-bottom: 10px;
       border-bottom: 1px solid #3b4951;
    }
    p {
        color: #fff;
        font-size: 14px;
        margin-top: 10px;
      }
      button {
        width: 100%;
        background: ${colors.orange};
        border-radius: 5px;
        font-size: 13px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 20px;        
      label {
      color: #fff;
      font-size: 15px;
      }
      input {
        background: #424f57;
        border: none;
        padding: 6px 10px;
        width: 100%;
        line-height: 24px;
        &::placeholder {
          color: #fff;     
          font-size: 12px; 
        }
      }
`;

const BottomRow = styled(Row)`
  @media print {
    flex-flow: row nowrap;
  }
`;

const tooltipTableSens = () => {
  return `<table>
            <thead>
              <tr><th>Sensitivity Score</th><th>Examples</th></tr>
            </thead>
            <tbody>
              <tr><td>0</td><td>N/A</td></tr>
              <tr><td>1</td><td>US Zip code, Gender</td></tr>
              <tr><td>2</td><td>Phone #</td></tr>
              <tr><td>3</td><td>First /or Last Name</td></tr>
              <tr><td>4</td><td>Email, Birthday</td></tr>
              <tr><td>5</td><td>SSN #, Credit Card #</td></tr>
            </tbody>
          </table>`
}

const widgetDescripts = {
  "redund-widget": `Total Average Redundancy is measured by averaged redundancy scoring across multiple 
                    datastore per scan analysis, for example, if the same data element is found 7 distinct 
                    times, then the Data Surface of that element is determined to have 7 times the risk 
                    exposure.`,
  "sens-widget": `<div><p>Sensitivity is measured by weighted score collected by autonomous sensitivity 
                  classification from 0 to 5, where 0 is determined to be nonsensitive, non-applicable 
                  data, eg: NULL values or internal string identifiers, and 5 is highly sensitive data, 
                  eg: social security and credit card numbers. Note that false positives are possible 
                  and are preferred over false negatives to ensure coverage during any following 
                  remediation phases.</p><div class="table-bordered">${tooltipTableSens()}</div></div> `,
  "value-widget": `Gross Risk Exposure is value of your data risk by redundancy times sensitivity 
                   adjusted by historical breach costs per record.`,
  "gre-kpi": `Determined by the following formula and risk scan factors: <br />
              GRE = <a href="/data-surface">Redundancy * Sensitivity</a> * <a href="/settings">
              Sector Adjustment</a>`
}

const maxGREFromS2 = (data) => {
  return Math.max.apply( Math, data.map((d) => d.data_stores.scanStats ? parseFloat(d.data_stores.scanStats.gre.result) : 0 ) )
}

class DashboardNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanId: 0,
      scanDataStats: '',
      currentScanData: {},
      cvss: 'N/A'
    };
  }

  //setter for selected s2 scan
  async fetchUrl(scanId = null, scans) {
    if (scans.length > 0 ) {
        console.log(scanId, "scanId")
        const current = scanId ? scans.filter((d) => d.id == scanId)[0] : scans[scans.length - 1];
        console.log(current, "current")
        const scanStats = current.data_stores.scanStats; // await getReportsForDSA(this.props.user.company.id, current.id, this.props.unitCost);
        this.setState({
          scanId: current.id,
          scanDataStats: scanStats,
          currentScanData: current,
          loading: false
        });
    }
  }

  //access and render data specific to an s2 scan. Handler for dots near date range.
  handleChangeData(id) {
    const selectedId = Number.parseInt(id); 
    ls.set('scanId', selectedId);
    this.fetchUrl(selectedId, this.props.s2data);
  }

  handleDownload(e) {
    e.preventDefault();
    window.print();
  }

  async componentDidMount() {
    const { user } = this.props;
    await this.props.onFetchS2Data({company_id: user.company.id});
    const cyberReports = await getCyberScans(user.company.id);
    const remediationData = await getRemediationReport(user.company.id);
    let cvss = 'NA';
    if (cyberReports.length > 0) {
      const json = JSON.parse(cyberReports[cyberReports.length - 2].nmapdata);
      const data = nmapParseTab(json);
      cvss = Number(
        data.reduce((acc, host) => {
          return parseFloat(host.cvssScore) + acc;
        }, 0) / data.length
      ).toFixed(2);
    }

    this.setState({
      remediationData,
      cvss
    });

    if (size(this.props.s2data) > 0 ) {
      const s2data = this.props.s2data;
      this.fetchUrl(
        null,
        s2data.sort((a, b) => {
          return a.id - b.id;
        })
      );
    }
  }

  render() {
    const { s2data, s2loading, unitCost, user } = this.props;   
    const { scanId, currentScanData, scanDataStats } = this.state;
    const cvss = colorClassCVSS(this.state.cvss);
    const scanGRE = scanDataStats && scanDataStats.gre ? (parseFloat(scanDataStats.gre.result )* parseFloat(unitCost) ) : 0
    const NARE = size(user.insurances) > 0 ?
                   currencyFormatter( (scanGRE * cvss.factor) -
                   user.insurances.reduce((a,v) =>{ return a + parseFloat(v.amount) }, 0)  )
                   : currencyFormatter(scanGRE * cvss.factor );
    const storeCount = scanDataStats && scanDataStats.stores ? scanDataStats.stores.number : 0;
    const dataCells = scanDataStats && scanDataStats.stores ? scanDataStats.stores.rows : 0;
    const totalRedundancy = scanDataStats && scanDataStats.redundancy ? scanDataStats.redundancy.result : 0;
    const avgSensitivity = scanDataStats && scanDataStats.sensitivity ? scanDataStats.sensitivity.result : 0;
    const insightsText = ` This scan resulted in finding ${ currencyFormatter(scanGRE) } of risk exposure. We scanned 
                           ${ storeCount } data stores with ${ dataCells }  total data cells. Your 
                           organization's average redundancy is ${ totalRedundancy }% and your total 
                           average sensitivity is ${ avgSensitivity }. Next steps are modeling high-risk
                           remediation targets.`;
    const cyberText = `<p>Determined by aggregated and individual <a href="https://www.first.org/cvss/calculator/3.1" target="_blank" >
                       CVSS calculation</a> and vulnerability factors, including Attack Vector, Complexity, Privileges, 
                       Interaction, Scope, Confidentiality, Integrity, and Availability.</p>`;
    const NAREText = `Determined by the following formula and risk scan factors: <br />
                        NARE = (GRE * <a href="/detection-scan">Cyber Score</a>) - <a href="/settings">Cyber Coverage</a>`;

		return (
			<MainContent title="Dashboard">
				<ContainerWrapper fluid style={{ maxWidth: 1350, marginTop: '40px' }}>
					<BottomRow>
						<Col data-tip={widgetDescripts["gre-kpi"]} lg={3} style={{flex: 1 }}>
							<OverviewWidget bgColor="#3e6d7e" bgColorHeader="#2f5a6a">
								<h4>Gross Risk Exposure</h4>
								<h2>{ currencyFormatter(scanGRE) }</h2>
							</OverviewWidget>
						</Col>
						<Col lg={3} style={{ flex: 1 }}>
							<OverviewWidget bgColor="#373e44" bgColorHeader="#5b979b" data-tip={cyberText}>
								<h4>Cyber Score</h4>
								<div id="cyber-detect" style={{ color: cvss.text, backgroundColor: cvss.bg }}>
									<p>{` ${this.state.cvss} ${cvss.level}`}</p>
								</div>
							</OverviewWidget>
						</Col>
						<Col lg={3} style={{ flex: 1 }}>
							<OverviewWidget bgColor="#759fc8" bgColorHeader="#356fa8" data-tip={NAREText}>
								<h4>Net Adj Risk Exposure</h4>
								<h2>{NARE}</h2>
							</OverviewWidget>
						</Col>
						<Col lg={3} style={{ flex: 1 }}>
							<OverviewWidget bgColor="#373e44" bgColorHeader="#d15914" data-tip={insightsText}>
								<h4>Remediation Insights</h4>
								{currentScanData ? (
									<div style={{ textAlign: 'center' }}>
										<CsvDownload
											disabled={this.state.remediationData == undefined}
											data={this.state.remediationData}
											filename={`${this.props.user
												.company.name} - TED Remediation Insights - ${currentScanData
												? moment(currentScanData.scan_date).format('YYYY-MM-DD')
												: 'N/A'}.csv`}
											className={'cs-button'}
										>
											{this.state.remediationData == undefined ? (
												'No Report Available'
											) : (
												'Download Reports'
											)}{' '}
											<FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faDownload} />
										</CsvDownload>
									</div>
								) : (
									<p> You do not currently have any available scans. </p>
								)}
							</OverviewWidget>
						</Col>
					</BottomRow>
				</ContainerWrapper>
				<ContainerWrapper fluid style={{ maxWidth: 1350 }}>
					<LeftSection>
						<div>
							<Row>
								<Col md={6}>
									<Header>
										<TimeRange>
											<h5>TIME RANGE</h5>
											<p>
												<Moment format=" MMM DD, YYYY ">
													{s2data.length > 0 ? s2data[0].scan_date : new Date()}
												</Moment>

												{` - `}

												<Moment format=" MMM DD, YYYY ">
													{currentScanData ? currentScanData.scan_date : new Date()}
												</Moment>
											</p>
											<p>
												<FontAwesomeIcon icon={faCalendar} />
											</p>
										</TimeRange>
										<RadioGroup>
											<RangeSelector
												currentScan={scanId}
												scanList={s2data}
												changeScan={(val) => this.handleChangeData(val)}
											/>
										</RadioGroup>
									</Header>
									<Row>
										<Col lg={6} md={12}>
											<DataWrapper className="widget" id="scan-widget">
												<ScansWidget
													stores={storeCount}
													totalBytes={
														scanDataStats && scanDataStats.stores ? (
															scanDataStats.stores.elements
														) : (
															0
														)
													}
													uniqueElements={dataCells}
													currentScanData={currentScanData}
													linkPath="/data-surface"
												/>
											</DataWrapper>
										</Col>
										<Col lg={6} md={12}>
											<DataWrapper className="widget" data-tip={widgetDescripts['redund-widget']}>
												<RedundancyWidget
													redundancy={totalRedundancy}
													list={
														scanDataStats && scanDataStats.redundancy ? (
															scanDataStats.redundancy.top
														) : (
															[]
														)
													}
													linkPath="/data-surface"
												/>
											</DataWrapper>
										</Col>
									</Row>
									<DynamicRow1>
										<Col lg={6} md={12}>
											<DataWrapper className="widget" data-tip={widgetDescripts['value-widget']}>
												<ValueWidget
													value={scanGRE}
													list={
														scanDataStats && scanDataStats.gre ? scanDataStats.gre.top : []
													}
													linkPath="/data-surface"
                          unitCost={unitCost}
                          maxVal={s2data ? maxGREFromS2(s2data) : 0}
												/>
											</DataWrapper>
										</Col>
										<Col lg={6} md={12}>
											<DataWrapper className="widget" data-tip={widgetDescripts['sens-widget']}>
												<SensitivityWidget
													sensitivity={
														scanDataStats && scanDataStats.sensitivity ? (
															scanDataStats.sensitivity.result
														) : (
															0
														)
													}
													list={
														scanDataStats && scanDataStats.sensitivity ? (
															scanDataStats.sensitivity.top.sort((a,b) =>{return parseFloat(a.sensitivityScore) > parseFloat(b.sensitivityScore) })
														) : (
															[]
														)
													}
													linkPath="/data-surface"
												/>
											</DataWrapper>
										</Col>
									</DynamicRow1>
								</Col>
								<Col md={6} style={{ overflow: 'hidden' }}>
									<ChartWrapper>
										<ContentText>
											Risk Exposure Trend
											<p>
												<Moment subtract={{ days: 7 }} format=" MMM DD, YYYY ">
													{currentScanData ? currentScanData.scan_date : new Date()}
												</Moment>

												{` - `}

												<Moment format=" MMM DD, YYYY ">
													{currentScanData ? currentScanData.scan_date : new Date()}
												</Moment>
											</p>
										</ContentText>
										{!s2loading ? (
											<HistoryBarChart
												style={{ width: '100%' }}
												currentScan={currentScanData}
												scanId={scanId}
												scanList={s2data}
                        unitCost={unitCost}
											/>
										) : (
											'loading'
										)}
									</ChartWrapper>
								</Col>
							</Row>
							<DynamicRow2>
								<Col lg={2} md={6}>
									<DataWrapper className="widget" data-tip={widgetDescripts['value-widget']}>
										<ValueWidget
											value={scanGRE}
											list={scanDataStats && scanDataStats.gre ? scanDataStats.gre.top : []}
											linkPath="/data-surface"
                      unitCost={unitCost}
                      maxVal={maxGREFromS2(s2data)}
										/>
									</DataWrapper>
								</Col>
								<Col lg={2} md={6}>
									<DataWrapper className="widget" data-tip={widgetDescripts['sens-widget']}>
										<SensitivityWidget
											sensitivity={avgSensitivity}
											list={
												scanDataStats && scanDataStats.sensitivity ? (
													scanDataStats.sensitivity.top
												) : (
													[]
												)
											}
											linkPath="/data-surface"
										/>
									</DataWrapper>
								</Col>
							</DynamicRow2>
						</div>
					</LeftSection>
					<br />
				</ContainerWrapper>
			</MainContent>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
	unitCost: deriveUnitCost(state.auth.user.adjustments),
	s2data: state.s2data.data,
	s2loading: state.s2data.loading 
});

const mapDispatchToProps = (dispatch) => ({
	onFetchS2Data: (fields) => dispatch(actions.fetchS2Data(fields)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNew);


