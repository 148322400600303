import React from 'react'
import BootstrapButton from 'react-bootstrap/Button'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

import {colors} from '../../global/colors'

const StyledButton = styled(BootstrapButton)`
  background: #68afa9;
  color: #fff;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${({size})=> size == 'sm' ? '.6rem' : 'auto'};
  border-radius: 3px;
  &:hover {
    background: ${colors.orange};
    text-decoration: none;
    outline: none;
  }
  .disabled, :disabled {
    opacity: .4;
    background: #444;
  }
`

const StyledNavLink = styled(NavLink)`
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: none;
    outline: none;
  }
`

const Button = ({
  children,
  to,
  ...rest
}) => {
  const getContent = () => {
    if(to) {
      return (
        <StyledNavLink to={to}>
          <StyledButton {...rest}>
            {children}
          </StyledButton>
        </StyledNavLink>
      )
    }
    return (
      <StyledButton {...rest}>
        {children}
      </StyledButton>
    )
  }
  return getContent()
}

export default Button