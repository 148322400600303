import size from 'lodash/size';
import split from 'lodash/split';
import map from 'lodash/map';
import replace from 'lodash/replace';
import toString from 'lodash/toString';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import last from 'lodash/last';
import DateTime from 'luxon/src/datetime';
import axios, { CancelToken } from 'axios';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {
  createFeatureImportanceData, createLearningCurveData, createRocData,
  createConfusionMatrixData, createClassifReportData, createClassBalanceData
} from './classification-charts-data';
import { createPredCurveData, createPredErrorData, createResidualsData } from './regression-charts-data';

const MAX_SVG_PDF_SIZE = 4194304; // 4Mb
const TREE_GRAPH = 'explainers_graph';

const dtOptions = { zone: 'utc' };
const getUtcFromISO = (str) => {
  const dt = DateTime.fromISO(str, dtOptions);
  if (dt.isValid) return dt;
  return DateTime.fromSQL(str, dtOptions);
};
const getCurrentSeconds = () => Math.round(DateTime.utc().toSeconds());
const getSecondsFromISO = (str) => Math.round(getUtcFromISO(str).toSeconds());

const normalizeAlgoritmName = (algorithm_name) => last(split(algorithm_name, '.'));

const hasClusterTaskSucceeded = (status) => status === 'success';

const hasSignedTreeGraphUrl = ({ metrics, metrics_status, metrics_statuses }, png = false) => {
  const {
    path_to_tree_graph, signed_path_to_tree_graph, signed_path_to_tree_graph_expires_at,
    signed_path_to_tree_graph_png, signed_path_to_tree_graph_png_expires_at
  } = metrics || {};
  return (
    // has calculated Tree Graph
    Boolean(path_to_tree_graph) &&
    hasClusterTaskSucceeded((metrics_statuses || {})[TREE_GRAPH] || metrics_status) &&
    // has valid signed URL for it
    Boolean(png ? signed_path_to_tree_graph_png : signed_path_to_tree_graph) &&
    getSecondsFromISO(
      png ? signed_path_to_tree_graph_png_expires_at : signed_path_to_tree_graph_expires_at
    ) > getCurrentSeconds()
  );
};

let cachedPdfMake = null;
let cachedVfsFonts = null;

let cachedPngPath = null;
let cachedPng = null;

let cachedSvgPath = null;
let cachedSvg = null;
let svgSource = null;

const cancelSvgDownload = () => {
  if (svgSource) {
    svgSource.cancel();
    svgSource = null;
  }
};

const preprocessPlotlySvg = (svg) =>
  replace(toString(svg), /,\s*(sans-|)serif/g, '');

const getSvgChartPromise = (data) => {
  if (!data) return null;
  return new Promise((resolve, reject) => {
    const plotDiv = document.createElement('div');
    Plotly.newPlot(plotDiv, data).then(
      (gd) => Plotly.toImage(gd, { format: 'svg', width: 495, height: 300 }).then(
        (url) => resolve(
          preprocessPlotlySvg(
            decodeURIComponent(
              replace(url, /^data:image.*,/, '')
            )
          )
        )
      ).catch((error) => reject(error))
    ).catch((error) => reject(error));
  });
};

const pdfSection = ({ id, texts, svg, image }) => {
  if (!texts || (!svg && !image)) return [];
  const { text_before, text_after } = texts;
  return [
    { text: id, style: 'title', headlineLevel: 2 },
    ...map(split(text_before, '\n'), (text) => ({ text, style: 'text' })),
    ...svg ? [{ svg, fit: [495, 495], style: 'chart' }] : [],
    ...image ? [{ image, fit: [495, 495], style: 'chart' }] : [],
    ...map(split(text_after, '\n'), (text) => ({ text, style: 'text' }))
  ];
};

/* eslint-disable complexity */
/* eslint-disable max-statements */
const downloadPDF = ({ trial, trial_metrics, projectName, projectFile, experiment, experimentSession, onSuccess, onError }) => {
  // `trial` and `trial_metrics` are required
  if (!trial || !trial_metrics) {
    onError();
    return;
  }

  // successfully generated `pdf_data` is required
  const { pdf_data, pdf_data_status } = trial;
  if (!pdf_data || !hasClusterTaskSucceeded(pdf_data_status)) {
    onError();
    return;
  }

  // successfully calculated basic `metrics` are required
  const trial_metric = trial_metrics || {};
  const { metrics, metrics_status } = trial_metric;
  if (!hasClusterTaskSucceeded(metrics_status)) {
    onError();
    return;
  }

  const {
    class_balance, classification_report, confusion_matrix, feature_importance, feature_importance_classification,
    learning_curves, roc_curve, prediction_error, prediction_curve, residuals_plot, tree_graph
  } = pdf_data;

  const {
    feature_importance_data, prediction_errors_data, learning_curve_data,
    roc_curve_data, roc_labels, confusion_matrix_data, class_balance_data,
    signed_path_to_tree_graph, signed_path_to_tree_graph_png
  } = metrics || {};

  const { raw_data } = trial;
  const fileName = (projectFile && projectFile.name) || '';
  const expName = (experiment && experiment.name) || '';
  const sessionNum = (experimentSession && experimentSession.number) || '';
  const algorithm_name = (raw_data && startCase(normalizeAlgoritmName(raw_data.algorithm_name))) || '';

  const savePDF = ([
    pdfMake,
    vfsFonts,
    feature_importance_classification_svg, // 1
    feature_importance_svg, // 2
    tree_graph_svg, // 3
    tree_graph_png, // 4
    prediction_curve_svg, // 5
    learning_curves_svg, // 6
    roc_curve_svg, // 7
    confusion_matrix_svg, // 8
    prediction_error_svg, // 9
    residuals_plot_svg, // 10
    classification_svg, // 11
    class_balance_svg // 12
  ]) => {
    cachedPdfMake = pdfMake;
    cachedVfsFonts = vfsFonts;

    const metricsPDF = {
      pageSize: 'A4',
      pageMargins: 50,

      pageBreakBefore: (currentNode, followingNodesOnPage /*, nodesOnNextPage, previousNodesOnPage */) =>
        (currentNode.headlineLevel == 1 || currentNode.headlineLevel == 2) && followingNodesOnPage.length <= 3,

      styles: {
        head: {
          fontSize: 14,
          margin: [30, 20]
        },
        header: {
          fontSize: 20,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 15]
        },
        algorithm: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        title: {
          fontSize: 16,
          bold: true,
          margin: [0, 10]
        },
        text: {
          fontSize: 12,
          // textIndent: 20,
          margin: [0, 0, 0, 10]
        },
        chart: {
          fontSize: 14,
          alignment: 'center',
          margin: [0, 5, 0, 15]
        }
      },

      info: {
        title: 'project.metrics.pdf.title',
        creator: 'project.metrics.pdf.creator'
      },

      header: (page, total) => ({
        columns: [
          {
            text: `${projectName.name}${
              fileName ? ' • ' : ''}${fileName}${
              expName ? ' • ' : ''}${expName}${
              sessionNum ? ' #' : ''}${sessionNum}`,
            style: 'head',
            width: '*'
          },
          {
            text: `${page} of ${total}`,
            alignment: 'right',
            style: 'head',
            width: 'auto'
          }
        ]
      }),

      content: [
        { text: 'Trovo AI Explainers', style: 'header', headlineLevel: 1 },
        ...algorithm_name
          ? [{ text: `Algorithm: ${algorithm_name}`, style: 'algorithm' }]
          : [],
        { text: 'Understanding and trusting AI models is a crucial part of any business process. People in general have the need to understand where they go and which results are more appropriate for them. Moreover explainability could give an additional layer of how to tune and improve your model from the side point of view, especially if we talk about black-box models.', style: 'text' },
        { text: 'Companies and organization use AI predictive models to enhance their productivity, but in contrast to that they also want to bring more interpretability to things that they get from their prediction services to improve the quality of the models and provide more fruitful feedback to the customers.', style: 'text' },
        { text: 'Trovo AI service provide the wide set of explainers for different AI models:', style: 'text' },

        ...feature_importance_classification_svg
          ? pdfSection({
              id: 'Feature Importance',
              texts: feature_importance_classification,
              svg: feature_importance_classification_svg
            })
          : [],

        ...feature_importance_svg
          ? pdfSection({id: 'Feature Importance', texts: feature_importance, svg: feature_importance_svg }) : [],

        ...tree_graph_svg
          ? pdfSection({ id: 'Tree Explainers', texts: tree_graph, svg: tree_graph_svg })
          : pdfSection({ id: 'Tree Explainers', texts: tree_graph, image: tree_graph_png }),

        ...prediction_curve_svg
          ? pdfSection({ id: 'Prediction Curve', texts: prediction_curve, svg: prediction_curve_svg }) : [],

        ...learning_curves_svg
          ? pdfSection({ id: 'Learning Curves', texts: learning_curves, svg: learning_curves_svg }) : [],

        ...roc_curve_svg
          ? pdfSection({ id: 'Roc Curve', texts: roc_curve, svg: roc_curve_svg }) : [],

        ...confusion_matrix_svg
          ? pdfSection({ id: 'Confusion Matrix', texts: confusion_matrix, svg: confusion_matrix_svg }) : [],

        ...prediction_error_svg
          ? pdfSection({ id: 'Prediction Error', texts: prediction_error, svg: prediction_error_svg }) : [],

        ...residuals_plot_svg
          ? pdfSection({ id: 'Residuals Plot', texts: residuals_plot, svg: residuals_plot_svg }) : [],

        ...classification_svg
          ? pdfSection({ id: 'Classification', texts: classification_report, svg: classification_svg }) : [],

        ...class_balance_svg
          ? pdfSection({ id: 'Class Balance', texts: class_balance, svg: class_balance_svg }) : []
      ]
    };

    pdfMake
      .createPdf(metricsPDF, null, null, vfsFonts.pdfMake.vfs)
      .download(`metrics${
        expName ? '-' : ''}${snakeCase(expName)}${
        sessionNum ? '-' : ''}${snakeCase(sessionNum)
      }.pdf`, onSuccess);
  };

  Promise.all([
    cachedPdfMake || import('pdfmake/build/pdfmake'),
    cachedVfsFonts || import('pdfmake/build/vfs_fonts'),

    // 1: -> feature_importance_classification_svg
    feature_importance_classification && size(feature_importance_data) > 0
      ? getSvgChartPromise(createFeatureImportanceData(feature_importance_data)) : null,

    // 2: Feature Importance -> feature_importance_svg
    feature_importance && size(feature_importance_data) > 0
      ? getSvgChartPromise(createFeatureImportanceData(feature_importance_data)) : null,

    // 3: Tree Graph -> tree_graph_svg
    cachedSvgPath === signed_path_to_tree_graph ? cachedSvg
    : (tree_graph && hasSignedTreeGraphUrl(trial_metric, false) &&
        axios.get(signed_path_to_tree_graph, {
          cancelToken: (svgSource = CancelToken.source()).token,
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total > MAX_SVG_PDF_SIZE) cancelSvgDownload();
          }
        })
        .then(({ status, data: svg }) => {
          if (status == 200 && svg) {
            cachedSvgPath = signed_path_to_tree_graph;
            if (size(svg) <= MAX_SVG_PDF_SIZE) {
              cachedSvg = replace(svg, /"Times,\s*serif"/g, '"Roboto"');
            } else {
              cachedSvg = null;
            }
            return cachedSvg;
          }
          throw new Error();
        })
        .catch(() => null)
      ) || null,

    // 4: Tree Graph PNG -> tree_graph_png
    cachedPngPath === signed_path_to_tree_graph_png ? cachedPng
    : (tree_graph && hasSignedTreeGraphUrl(trial_metric, true) &&
      axios.get(signed_path_to_tree_graph_png, { responseType: 'arraybuffer' })
        .then(({ status, data: png }) => {
          if (status == 200 && png) {
            cachedPngPath = signed_path_to_tree_graph_png;
            cachedPng = `data:image/png;base64,${Buffer.from(png, 'binary').toString('base64')}`;
            return cachedPng;
          }
          throw new Error();
        })
      ) || null,

    // 5: prediction_curve_svg
    prediction_curve && size(prediction_errors_data) > 0
    ? getSvgChartPromise(createPredCurveData(prediction_errors_data)) : null,

    // 6: learning_curves_svg
    learning_curves && size(learning_curve_data) > 0
    ? getSvgChartPromise(createLearningCurveData(learning_curve_data)) : null,

    // 7: roc_curve_svg
    roc_curve && size(roc_curve_data) > 0 && size(roc_labels) > 0
    ? getSvgChartPromise(createRocData(roc_curve_data, roc_labels)) : null,

    // 8: confusion_matrix_svg
    confusion_matrix && size(confusion_matrix_data) > 0
    ? getSvgChartPromise(createConfusionMatrixData(confusion_matrix_data)) : null,

    // 9: prediction_error_svg
    prediction_error && size(prediction_errors_data) > 0
    ? getSvgChartPromise(createPredErrorData(prediction_errors_data)) : null,

    // 10: residuals_plot_svg
    residuals_plot && size(prediction_errors_data) > 0
    ? getSvgChartPromise(createResidualsData(prediction_errors_data)) : null,

    // 11: classification_svg
    classification_report && size(classification_report) > 0
    ? getSvgChartPromise(createClassifReportData(classification_report)) : null,

    // 12: class_balance_svg
    class_balance && size(class_balance_data) > 0
    ? getSvgChartPromise(createClassBalanceData(class_balance_data)) : null
  ])
  .then(savePDF)
  .catch((error) => onError(error));
};
/* eslint-enable max-statements */
/* eslint-enable complexity */

export default downloadPDF;
