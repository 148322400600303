/* eslint-disable prettier/prettier */
import {
	FETCH_START,
	FORM_START,
	FORM_COMPLETE,
	FETCH_FAIL,
	RW_GET_PROJECT,
	RW_UPLOAD_FILES,
	RW_GET_FILES,
	RW_GET_REGEX_RULES,
	RW_GET_EXPERIMENT,
	RW_ADD_EXPERIMENT,
	RW_GET_ON_PREMISE_EXPERIMENT,
	RW_ADD_ON_PREMISE_EXPERIMENT
} from '../actions/RWActions';
import { updateObject } from '../utility';

const initialState = {
	files: [],
	projects: [],
	regex_rules: [],
	experiments: [],
	onPremiseExpData: [],
	errorMsg: null,
	message: null,
	loading: true,
	submit: false,
};

const fetchStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true, submit: false });
const fromStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: true });
const fromComplete = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: false });

const fetchFail = (state, action) =>
	updateObject(state, {
		errorMsg: action.errorMsg,
		loading: false,
		submit: false
	});

	const getProjects = (state, action) =>
	updateObject(state, {
		projects: action.projects,
		errorMsg: null,
		loading: false
	});

	const uploadFiles = (state, action) =>
	updateObject(state, {
		errorMsg: null,
		loading: false
	});

	const getFiles = (state, action) =>
	updateObject(state, {
		files: action.files,
		errorMsg: null,
		loading: false
	});

	const getRegexRules = (state, action) =>
	updateObject(state, {
		regex_rules: action.regex_rules,
		errorMsg: null,
		loading: false
	});

	const getExperiments = (state, action) =>
	updateObject(state, {
		experiments: action.experiments,
		errorMsg: null,
		loading: false
	});

	const addExperiment = (state, action) =>
	updateObject(state, {
		errorMsg: null,
		loading: false
	});


	const getOnPremiseExperiments = (state, action) =>
	updateObject(state, {
		onPremiseExpData: action.onPremiseExpData,
		errorMsg: null,
		loading: false
	});

	const addOnPremiseExperiment = (state, action) =>
	updateObject(state, {
		errorMsg: null,
		loading: false
	});

	

export const RWReducer = (state = initialState, action) => {
	switch (action.type) {
		case FORM_START:
			return fromStart(state, action);
		case FORM_COMPLETE:
			return fromComplete(state, action);
		case FETCH_START:
			return fetchStart(state, action);
		case FETCH_FAIL:
			return fetchFail(state, action);
		case RW_GET_PROJECT:
			return getProjects(state, action);
		case RW_UPLOAD_FILES:
			return uploadFiles(state, action);
		case RW_GET_FILES:
			return getFiles(state, action);
		case RW_GET_REGEX_RULES:
			return getRegexRules(state, action);
		case RW_GET_EXPERIMENT:
			return getExperiments(state, action);
		case RW_ADD_EXPERIMENT:
			return addExperiment(state, action);
		case RW_GET_ON_PREMISE_EXPERIMENT:
			return getOnPremiseExperiments(state, action);
		case RW_ADD_ON_PREMISE_EXPERIMENT:
			return addOnPremiseExperiment(state, action);
		default:
			return state;
	}
};
