import React, { Component } from 'react';
import Table from '../../../components/Admin/Table';
import Alert from '../../../components/Admin/Alert';
import Switch from '../../../components/Admin/Switch';
import DropDown from '../../../components/Admin/DropDown';
import './alerts.scss';

const tableData = {
	columns: [
		{ title: 'Reseller', key: 'reseller', width: 20 },
		{ title: 'Company', key: 'company', width: 20 },
		{ title: 'Monthly Revenue', key: 'monthlyRevenue', width: 20 },
		{ title: 'Description', key: 'description', width: 40 }
	],
	rows: [
		{ reseller: 'Armanino', company: '3', monthlyRevenue: '?', description: '' },
		{ reseller: 'Reegis', company: '5', monthlyRevenue: '?', description: '' },
		{ reseller: 'Trovo', company: '10', monthlyRevenue: '?', description: '' },
		{ reseller: 'Provider 1', company: '1', monthlyRevenue: '?', description: '' },
		{ reseller: 'Provider 2', company: '6', monthlyRevenue: '?', description: '' },
		{ reseller: 'Provider 3', company: '4', monthlyRevenue: '?', description: '' }
	]
};

const sortOptions = [
	{ label: 'reseller', value: 'reseller' },
	{ label: 'company', value: 'company' },
	{ label: 'revenue', value: 'revenue' }
];
export default class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false
		};
	}
	handleChange = (value) => {
		this.setState({ isChecked: value });
	};

	render() {
		const { isChecked } = this.state;
		return (
			<div className="container-fluid mt-4">
				<div className="row">
					<div className="col-md-4">
						<section className="alert-section">
							<span>
								<h5>Alerts</h5>
								<DropDown options={sortOptions} placeholder="sort by" />
							</span>
							<div className="alert-box-wrapper">
								<Alert />
								<Alert />
								<Alert />
								<Alert />
								<Alert />
								<Alert />
								<Alert />
								<Alert />
								<Alert />
								<Alert />
							</div>
						</section>
					</div>
					<div className="col-md-8">
						<section className="account-table">
							<div className="status">
								<span>
									<strong>View:</strong>Provider
								</span>
								<span>
									<Switch isChecked={isChecked} handleChange={this.handleChange} />
									<strong>Account</strong>
								</span>
							</div>
							<div className="table-wrapper">
								<Table columns={tableData.columns} rows={tableData.rows} />
							</div>
						</section>
					</div>
				</div>
			</div>
		);
	}
}
