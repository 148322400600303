/* eslint-disable prettier/prettier */
import {
  FETCH_START,
  FETCH_RESELLERS,
  ADD_RESELLER_START,
  ADD_RESELLER_FAIL,
  ADD_RESELLER_SUCCESS,
  EDIT_RESELLER_START,
  EDIT_RESELLER_FAIL,
  EDIT_RESELLER_SUCCESS
} from '../actions/ResellerActions';
import { updateObject } from '../utility';

const initialState = {
  resellers: [],
  errorMsg: null,
  loading: false,
  isResellerLoad: false
};

const fetchStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true });
const resellerAddStart = (state) => updateObject(state, { isResellerLoad: true });
const resellerAddFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isResellerLoad: false });
const resellerAddComplete = (state, action) => updateObject(state, { resellers: [...state.resellers, action.reseller] });
const resellerEditStart = (state) => updateObject(state, { isResellerLoad: true });
const resellerEditFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isResellerLoad: false });
const resellerEditComplete = (state, action) => {
  const index = state.resellers.findIndex(reseller => reseller.id === action.reseller.id);
  const resellers = state.resellers;
  resellers[index] = action.reseller;
  return updateObject(state, { resellers: [...resellers] });
}

const fetchResellers = (state, action) => updateObject(state, {
  resellers: action.resellers,
  errorMsg: null,
  loading: false,
});

export const ResellerReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_START:
    return fetchStart(state, action);
  case FETCH_RESELLERS:
    return fetchResellers(state, action);
  case ADD_RESELLER_START:
    return resellerAddStart(state, action);
  case ADD_RESELLER_FAIL:
    return resellerAddFail(state, action);
  case ADD_RESELLER_SUCCESS:
    return resellerAddComplete(state, action);
  case EDIT_RESELLER_START:
    return resellerEditStart(state, action);
  case EDIT_RESELLER_FAIL:
    return resellerEditFail(state, action);
  case EDIT_RESELLER_SUCCESS:
    return resellerEditComplete(state, action);
  default:
    return state;
  }
};
