// IMPORT PACKAGE REFERENCES
import { createStore, applyMiddleware, compose } from 'redux';
// IMPORT MIDDLEWARE

import ReduxThunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

// IMPORT REDUCERS

import { AppReducer } from '../reducers/AppReducer';

const composeEnhancers =
	process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				shouldHotReload: false
			})
		: compose;

// CONFIGURE STORE

export const createAppStore = () => createStore(AppReducer, composeEnhancers(applyMiddleware(ReduxThunk, promise)));
