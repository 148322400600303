import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
	Col,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
} from 'reactstrap';
import { uploadReportsToPG, initScan } from '../../../utilities/s2APIHelpers';

const TopModal = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px;
	border-bottom: 1px solid #bbb;
`;

const BottomModal = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	padding: 20px 10px;
`;

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(20, 25, 35, .5)',
	},

	content: {
		padding: '0',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: 'white',
		minWidth: '33.3%',
		color: '#777',
	},
};

const buttonStyle = {
	backgroundColor: '#0069d9',
	color: 'white',
	margin: '0 auto',
	marginRight: '5px',
};

const startScanData = (id, name, connectionString) => {
	return {
		id: id,
		name: name,
		host: '',
		location: '',
		connectionString: connectionString,
		dataStoreType: 'CSV',
		isActive: true,
		tableCount: 0,
		fieldCount: 0,
		redundancyPercentage: 0,
		sizeOfData: 0,
		sizeUoM: '',
		sizeRedundancyPercentage: 0,
		uniqueDataElements: 0,
		sensitivePercentage: 0,
	};
};

// Modal.setAppElement('#root');

class CustomModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: this.props.open[0],
			modalMode: this.props.open[1],
			modalData: this.props.open,
			redundancyFile: null,
			inventoryFile: null,
			submitMessage: null,
			scanDate: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
			dataStoreName: 'DataStore1',
			dataStoreId: 1,
			connectionString: '/app/csv',
		};

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
		// references are now sync'd and can be accessed.
		this.subtitle.style.color = '#000';
		this.subtitle.style.fontSize = '14pt';
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
		this.props.modalFunction([false, 1, '']);
	}

	loadInventoryFile(e) {
		this.setState({
			inventoryFile: e.target.files[0],
		});
		console.log(e.target);
	}

	scanDate(e) {
		this.setState({
			scanDate: e.target.value,
		});
	}

	loadRedundancyFile(e) {
		this.setState({
			redundancyFile: e.target.files[0],
		});
		console.log(e.target);
	}

	changeDataStoreId(e) {
		this.setState({
			dataStoreId: +e.target.value,
		});
	}

	changeDataStoreName(e) {
		this.setState({
			dataStoreName: e.target.value,
		});
	}

	changeConnectionString(e) {
		this.setState({
			connectionString: e.target.value,
		});
	}

	disableSubmit(e) {
		e.target.setAttribute('disabled', 'disabled');
	}

	async formSubmit(e) {
		e.preventDefault();
		// console.log(this.state.inventoryFile, 'this.state.inventoryFile');
		const res = await uploadReportsToPG(
			this.state.inventoryFile,
			this.state.redundancyFile,
			this.state.modalData[5],
			this.state.modalData[4],
			this.state.modalData[6],
			this.state.modalData[7],
			this.state.scanDate
		);

		this.setState({
			modalMode: 1,
			submitMessage: res,
		});
	}

	async startScanSubmit(e) {
		e.preventDefault();
		console.log(this.state.modalData[3]);
		const url = this.state.modalData[3];
		const data = startScanData(
			this.state.dataStoreId,
			this.state.dataStoreName,
			this.state.connectionString
		);
		await initScan(url, data, () => {
			this.setState({
				modalMode: 1,
				submitMessage: 'Start auto scan processing!',
			});
		});
	}

	renderComponents(int) {
		switch (int) {
			case 0:
				return (
					<Form>
						<FormGroup>
							<Label for="dataStoreId">DataStore Id:</Label>
							<Input
								type="input"
								defaultValue={this.state.dataStoreId}
								id="dataStoreId"
								onChange={e => this.changeDataStoreId(e)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="dataStoreName">DataStore Name: </Label>
							<Input
								type="input"
								defaultValue={this.state.dataStoreName}
								id="dataStoreName"
								onChange={e => this.changeDataStoreName(e)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="connectionString">Connection String: </Label>
							<Input
								type="input"
								defaultValue={this.state.connectionString}
								id="connectionString"
								onChange={e => this.changeConnectionString(e)}
							/>
						</FormGroup>
						<FormGroup
							style={{
								textAlign: 'right',
								paddingLeft: '0px',
								paddingRight: '10px',
							}}
							check
							row
						>
							<Button
								style={buttonStyle}
								onClick={e => {
									this.startScanSubmit(e);
									this.disableSubmit(e);
								}}
							>
								Start Scan
							</Button>

							<Button
								style={buttonStyle}
								onClick={e => {
									this.closeModal();
									this.disableSubmit(e);
								}}
							>
								Cancel
							</Button>
						</FormGroup>
					</Form>
				);
				break;
			case 1:
				return <p> {this.state.submitMessage}</p>;
				break;
			case 2:
				return (
					<Form>
						<FormGroup>
							<Label for="scanDate">Scan Date Time</Label>
							<Input
								type="input"
								defaultValue={this.state.scanDate}
								onChange={e => this.scanDate(e)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="dataInventoryFile">Data Inventory File</Label>
							<Input
								type="file"
								name="file-inventory"
								id="dataInventoryFile"
								onChange={e => this.loadInventoryFile(e)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="dataRedundancyFile">Redundancy File</Label>
							<Input
								type="file"
								name="file-redundancy"
								id="dataRedundancyFile"
								onChange={e => this.loadRedundancyFile(e)}
							/>
						</FormGroup>
						<FormGroup check row>
							<Col sm={{ size: 10, offset: 2 }}>
								<Button
									disabled={
										this.state.redundancyFile == null ||
										this.state.inventoryFile == null
											? true
											: false
									}
									onClick={e => {
										this.formSubmit(e);
										this.disableSubmit(e);
									}}
								>
									Submit
								</Button>
							</Col>
						</FormGroup>
					</Form>
				);
				break;
			default:
				return <p> hi! </p>;
				break;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps != this.props) {
			this.setState({
				modalIsOpen: this.props.open[0],
				modalMode: this.props.open[1],
				modalData: this.props.open,
			});
		}
	}

	render() {
		let labelPopup = null;
		if (this.state.modalMode == 2) {
			labelPopup = 'Upload Report Data';
		} else if (this.state.modalMode == 1) {
			labelPopup = 'Import Report Data';
		} else {
			labelPopup = 'Start Auto Scan';
		}
		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					style={customStyles}
					//contentLabel="Example Modal"
				>
					<TopModal>
						<h3
							style={{ marginBottom: '0px' }}
							ref={subtitle => (this.subtitle = subtitle)}
						>
							{this.state.modalMode == 1 ? 'Importing Data...' : labelPopup}
						</h3>
						<button
							style={{
								color: '#000',
								backgroundColor: 'transparent',
								float: 'right',
								border: 'none',
								fontSize: '11pt',
								fontWeight: 'bold',
							}}
							onClick={this.closeModal}
						>
							X
						</button>
					</TopModal>
					<BottomModal>
						{this.renderComponents(this.state.modalMode)}
					</BottomModal>
				</Modal>
			</div>
		);
	}
}

export default CustomModal;
