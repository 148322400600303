import {
	FETCH_START,
	CHECK_AUGER_API_STATUS,
	FETCH_AUGER_PROJECTS,
	FETCH_AUGER_ORGANIZATIONS,
	ADD_AUGER_PROJECT,
	FETCH_AUGER_PROJECT_DATASETS,
	ADD_AUGER_EXPERIMENTS,
	ADD_AUGER_PROJECT_FILE,
	FETCH_AUGER_EXPERIMENTS,
	LOG_AUGER_API,
	ADD_TRANSACTION,
	ADD_NEW_AUGER_PROJECT,
	GET_AUGER_EXPERIMENTS_SESSION,
	GET_TRIAL_METRICS,
	GET_AUGER_TRIALS,
	GET_JITTERBIT_DATASOURCE,
	DEPLOY_TRIALS,
	DEPLOY_EXPERIMENTS_SESSION_TRIALS,
} from '../actions/AugerActions';
import { updateObject } from '../utility';

const initialState = {
	augerData: {
		projects: {},
		projectDataSets: {},
		organizations: {},
		experiments: {},
		expSessions: [],
		predictions: {},
		trials: {},
		trial_metrics: {},
		deployTrialData: {},
		deployModelData: {},
	},
	project: {},
	augerlogs: [],
	transaction: {},
	jitterBitOptions: [],
	errorMsg: null,
	loading: false,
};

const fetchStart = (state, action) =>
	updateObject(state, { errorMsg: action.errorMsg, loading: true });

const fetchOrganizations = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			organizations: action.organizations,
		},
		errorMsg: null,
		loading: false,
	});

const fetchAugerProjects = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			projects: action.projects,
		},
		errorMsg: null,
		loading: false,
	});

const addAugerProject = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			projects: [...state.augerData.projects, action.project],
		},
		sigleAugerData: {
			...state.sigleAugerData,
			project: action.project,
		},
		errorMsg: null,
		loading: false,
	});

const fetchAugerProjectDataSets = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			projectDataSets: action.datasets,
		},
		errorMsg: null,
		loading: false,
	});

const fetchAugerExperiments = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			experiments: action.experiments,
		},
		errorMsg: null,
		loading: false,
	});

const addProjectFile = (state, action) =>
	updateObject(state, {
		transaction: action.transaction,
		errorMsg: null,
		loading: false,
	});

const addTransaction = (state, action) =>
	updateObject(state, {
		transaction: action.transaction,
		errorMsg: null,
		loading: false,
	});

const addExperiments = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			experiments: action.experiments,
		},
		sigleAugerData: {
			...state.sigleAugerData,
			experiment: action.experiments,
		},
		errorMsg: null,
		loading: false,
	});

const augerLogs = (state, action) =>
	updateObject(state, {
		augerlogs: [...state.augerlogs, action.logs],
		errorMsg: null,
		loading: false,
	});

const addNewProject = (state, action) =>
	updateObject(state, {
		project: action.project,
		errorMsg: null,
		loading: false,
	});

const getExpSessions = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			expSessions: [action.expSessions],
		},
		errorMsg: null,
		loading: false,
	});

const getTrialMetrics = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			trial_metrics: action.trialMetrics,
		},
		errorMsg: null,
		loading: false,
	});

const getTrials = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			trials: action.trials,
		},
		errorMsg: null,
		loading: false,
	});

const deployTrials = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			deployTrialData: action.deployTrialData,
		},
		errorMsg: null,
		loading: false,
	});

const deployModelData = (state, action) =>
	updateObject(state, {
		augerData: {
			...state.augerData,
			deployModelData: action.deployModelData,
		},
		errorMsg: null,
		loading: false,
	});

const getJitterBitDataOptions = (state, action) =>
	updateObject(state, {
		jitterBitOptions: action.jitterBitOptions,
		errorMsg: null,
		loading: false,
	});

export const AugerReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_START:
			return fetchStart(state, action);
		case LOG_AUGER_API:
			return augerLogs(state, action);
		case FETCH_AUGER_ORGANIZATIONS:
			return fetchOrganizations(state, action);
		case FETCH_AUGER_PROJECTS:
			return fetchAugerProjects(state, action);
		case ADD_AUGER_PROJECT:
			return addAugerProject(state, action);
		case FETCH_AUGER_PROJECT_DATASETS:
			return fetchAugerProjectDataSets(state, action);
		case ADD_AUGER_PROJECT_FILE:
			return addProjectFile(state, action);
		case ADD_AUGER_EXPERIMENTS:
			return addExperiments(state, action);
		case FETCH_AUGER_EXPERIMENTS:
			return fetchAugerExperiments(state, action);
		case ADD_TRANSACTION:
			return addTransaction(state, action);
		case ADD_NEW_AUGER_PROJECT:
			return addNewProject(state, action);
		case GET_AUGER_EXPERIMENTS_SESSION:
			return getExpSessions(state, action);
		case GET_TRIAL_METRICS:
			return getTrialMetrics(state, action);
		case GET_AUGER_TRIALS:
			return getTrials(state, action);
		case GET_JITTERBIT_DATASOURCE:
			return getJitterBitDataOptions(state, action);
		case DEPLOY_TRIALS:
			return deployTrials(state, action);
		case DEPLOY_EXPERIMENTS_SESSION_TRIALS:
			return deployModelData(state, action);
		default:
			return state;
	}
};
