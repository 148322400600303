const d3 = require('d3');
const _ = require('lodash');

/* 
 *@author: Chad R Denaux
 */

// Calculate value reference function. This is an abbreviated version of the calculation iterator within dataTransformer.
// since no other values will change. This assumes that an initial transformation has taken place.
function calculateValue(data, unitCost) {
	//initializing from children in data stores starts at datastore level. This is a product of heirarchy.
	var dataStores = data.nodes.children;

	for (let i = 0; i < dataStores.length; i++) {
		var dataStore = dataStores[i];

		for (let t = 0; t < dataStore.tables.length; t++) {
			let table = dataStore.tables[t];
			table.value = table.elements.filter((d) => d.sensitivityScore > 2).length >= 2 ? table.rows * unitCost : 0;
		}

		//calculate overall GRE and Redundancy AVG.
		dataStore.value = parseFloat(
			dataStore.tables.reduce((acc, b) => {
				return (b.value == 0 ? 0 : b.rows * unitCost) + acc;
			}, 0)
		).toFixed(2);
	}

	data.scanStats.gre = calculateTopFive(data.nodes, 'value');

	return data;
}

//Overall GRE and Top Data Stores
function calculateTopFive(nodes, property) {
	let data = {};

	data['top'] = nodes.children
		.sort((a, b) => {
			return b[property] - a[property];
		})
		.slice(0, 5);
	if (property == 'value') {
		data['result'] = Number(
			nodes.children
			.reduce((acc, n) => {
				return parseFloat(n[property]) + acc;
			}, 0)
			.toFixed(2)
		);
	} else {
		data['result'] = Number(
			(nodes.children.reduce((acc, n) => {
				return parseFloat(n[property]) + acc;
			}, 0) / nodes.children.length).toFixed(2)
		);
	}
	return data;
}

//Number of data stores, items and rows for Scan Stats widget
function calculateScans(nodes) {
	let number = 0,
		data = {},
		elements = 0,
		rows = 0;

	nodes.children.forEach(function (d) {
		number += 1;
		elements += d.dataElCount;
		rows += d.rows;
	});

	data['number'] = number;
	data['elements'] = elements;
	data['rows'] = rows;

	return data;
}

const hostIterator = (ports) => {
	for (let j = 0; j < ports.length; j++) {
		if (Array.isArray(ports[j].script)) {
			let vulners = ports[j].script.filter((d) => d.attr['@_id'] == 'vulners' && d.table != undefined);
			if (vulners.length != 0) {
				ports[j].vulners = vulners[0].table;

				ports[j].cvssScore =
					vulners[0].table.table.reduce((acc, d) => {
						if (d.elem) {
							let el = d.elem.filter((d) => d.attr['@_key'] == 'cvss');
							return parseFloat(el[0]['#text']) + acc;
						} else {
							return acc;
						}
					}, 0) / vulners[0].table.table.length;
			}
		} else if (ports[j].script != undefined) {
			var script = ports[j].script;
			if (script.attr['@_id'] == 'vulners' && script.table) {
				ports[j].vulners = script.table;
				ports[j].cvssScore =
					script.table.table.reduce((acc, d) => {
						let el = d.elem.filter((d) => d.attr['@_key'] == 'cvss');
						return parseFloat(el[0]['#text']) + acc;
					}, 0) / script.table.table.length;
			}
		}
	}
};

export function nmapParseTab(data) {
	console.log(data);

	if (data == null) return;

	var parsedObj = [];

	data = data.nmaprun;

	if (data.hasOwnProperty('host') && Array.isArray(data.host)) {
		for (let i = 0; i < data.host.length; i++) {
			let host = data.host[i];
			if (host.hasOwnProperty('ports') && host.hostnames != '') {
				var hostname = host.hostnames.hostname,
					name = Array.isArray(hostname) ? hostname[0].attr['@_name'] : hostname.attr['@_name'],
					ports = host.ports.port;

				hostIterator(ports);

				var divisor = ports.filter((d) => d.cvssScore > 0).length,
					cvssScore =
					divisor > 0 ?
					(ports.reduce((acc, d) => {
						return (d.cvssScore ? d.cvssScore : 0) + acc;
					}, 0) / ports.filter((d) => d.cvssScore > 0).length).toFixed(1) :
					0,
					vulnCount = ports.filter((d) => d.vulners).reduce((acc, port) => {
						return port.vulners.table ? port.vulners.table.length + acc : acc;
					}, 0);

				parsedObj.push({
					name,
					ports,
					os: ports[0].service.attr['@_ostype'] ? ports[0].service.attr['@_ostype'] : 'N/A',
					cvssScore,
					vulnCount
				});
			} else if (host.hostnames != '') {
				let name = host.hostnames.hostname.attr['@_name'];
				parsedObj.push({
					name,
					os: 'undetermined',
					ports: []
				});
			}
		}
	} else if (data.hasOwnProperty('host') && !Array.isArray(data.host)) {
		let host = data.host;

		if (host.hasOwnProperty('ports') && host.hostnames != '') {
			var hostname = host.hostnames.hostname,
				name = Array.isArray(hostname) ? hostname[0].attr['@_name'] : hostname.attr['@_name'],
				ports = host.ports.port;

			hostIterator(host);

			var divisor = ports.filter((d) => d.cvssScore > 0).length,
				cvssScore =
				divisor > 0 ?
				(ports.reduce((acc, d) => {
					return (d.cvssScore ? d.cvssScore : 0) + acc;
				}, 0) / ports.filter((d) => d.cvssScore > 0).length).toFixed(1) :
				0,
				vulnCount = ports.filter((d) => d.vulners).reduce((acc, port) => {
					return port.vulners.table ? port.vulners.table.length + acc : acc;
				}, 0);

			parsedObj.push({
				name,
				ports,
				os: ports[0].service.attr['@_ostype'] ? ports[0].service.attr['@_ostype'] : 'N/A',
				cvssScore,
				vulnCount
			});
		} else if (host.hostnames != '') {
			let name = host.hostnames.hostname.attr['@_name'];
			parsedObj.push({
				name,
				os: 'undetermined',
				ports: []
			});
		}
	}

	return parsedObj;
}

export function dataTransformer(data, inventory, adjustments) {
	console.log(data, 'data');
	console.log(inventory, 'inventory');
	console.log(adjustments, 'adjustments');
	var strengthScale = d3.scaleLinear().range([450, 300]).domain([3, 50000]);

	var dataResult = {
			nodes: {
				name: 'Scan',
				children: []
			},
			links: [],
			scanStats: {
				gre: 0
			}
		},
		unitCost = parseFloat(adjustments);

	// Primary iterator for data inventory report
	console.log(inventory.length);
	for (let i = 0; i < inventory.length; i++) {
		//Set DataStore id
		var itemId = dataResult.nodes.children.filter((d) => {
			if (parseInt(d.id) == parseInt(inventory[i].data_store_id)) return d;
		});

		//if data store is not already in nodes, add datastore
		if (itemId.length === 0) {
			//derives the count for the unumber of times this store appears in the redundancy report
			let count = data.reduce(
				(d, x) =>
				d +
				(parseInt(x.left_data_store_id) === parseInt(inventory[i].data_store_id) ? 1 : 0) +
				(parseInt(x.right_data_store_id) === parseInt(inventory[i].data_store_id) ? 1 : 0),
				0
			);
			dataResult.nodes.children.push({
				id: parseInt(inventory[i].data_store_id),
				tables: [],
				name: inventory[i].data_store_name,
				active: true,
				sensitivityScore: 0,
				numTables: 0,
				dataElCount: 0,
				count: count,
				strength: -strengthScale(data.length),
				currentDepth: false
			});
		}

		var dataStore = dataResult.nodes.children.filter(
			(d) => parseInt(d.id) === parseInt(inventory[i].data_store_id)
		)[0];

		//Generate/set unique table ID
		var tableId = `DS:${inventory[i].data_store_id}T:${inventory[i].table_id}`;
		//if table does not exist in datastore.tables, create a table
		if (dataStore.tables.filter((d) => d.id === tableId).length === 0) {
			let count = data.reduce(
				(d, x) =>
				d +
				(parseInt(x.left_table_id) === parseInt(inventory[i].table_id) ? 1 : 0) +
				(parseInt(x.right_table_id) === parseInt(inventory[i].table_id) ? 1 : 0),
				0
			);
			dataStore.tables.push({
				id: tableId,
				elements: [],
				name: inventory[i].table_name,
				sensitivityScore: 0,
				count,
				strength: -0.0002,
				active: false,
				type: 'table'
			});
		}

		var table = dataStore.tables.filter((d) => d.id === tableId)[0];

		//Generate/set unique Data Element ID
		var elementId = `DS:${inventory[i].data_store_id}T:${tableId}DE:${inventory[i].data_element_id}`;
		//if this data element does not exist in table.elements, create it
		if (table.elements.filter((d) => d.id === elementId).length === 0) {
			table.elements.push({
				id: elementId,
				sensitivityScore: parseInt(inventory[i].sensitivity_score),
				name: inventory[i].data_element_name,
				active: false,
				value: parseInt(inventory[i].sensitivity_score) > 2 ? unitCost : 0,
				rows: parseFloat(inventory[i].row_counts),
				redundancyMultiple: parseInt(inventory[i].redundancy_multiple)
			});
		}
	}

	//Iterator for averaging/summing properties from data element and table levels up to dataStore level
	var dataStores = dataResult.nodes.children;
	for (let i = 0; i < dataStores.length; i++) {
		var dataStore = dataStores[i];

		for (let t = 0; t < dataStore.tables.length; t++) {
			let table = dataStore.tables[t];
			table.sensitivityScore = (table.elements.reduce((acc, b) => {
				return parseInt(b.sensitivityScore) + acc;
			}, 0) / table.elements.length).toFixed(2);
			table.totalValue = table.elements
				.reduce((acc, b) => {
					return parseFloat(b.value) + acc;
				}, 0)
				.toFixed(2);
			table.totalRows = table.elements
				.reduce((acc, b) => {
					return parseInt(b.rows) + acc;
				}, 0)
				.toFixed(2);
			table.rows = Math.max(
				...table.elements.map(function (o) {
					return o.rows;
				})
			);
			table.value = Number(
				table.elements.filter((d) => d.sensitivityScore > 2).length >= 2 ? table.rows * parseFloat(unitCost) : 0
			).toFixed(2);
			table.dataElCount = table.elements.length;
			table.redundancyAvg =
				table.elements.filter((d) => d.redundancyMultiple > 2).length / table.elements.length * 100;
			table.redundancyMultiple = Math.max.apply(null, table.elements.map((d) => d.redundancyMultiple));
			//table.redundancyAvg = table.count === 0 ? 0 : parseFloat( (100 * (  ( table.dataElCount / table.count ) )).toFixed(2));
			//console.log(table.count, "count", table.dataElCount, "element" )
		}

		dataStore.dataElCount = dataStore.tables.reduce((a, b) => {
			return b.dataElCount + a;
		}, 0);

		dataStore.sensitivityScore = Number(
			(dataStore.tables.reduce((acc, b) => {
				return parseInt(b.sensitivityScore) + acc;
			}, 0) / dataStore.tables.length).toFixed(2)
		);
		dataStore.numTables = dataStore.tables.length;

		dataStore.redundancyAvg = Number(
			(dataStore.tables.reduce((a, b) => {
				return b.redundancyAvg + a;
			}, 0) / dataStore.tables.length).toFixed(2)
		);
		dataStore.redundancyMultiple = Math.max.apply(null, dataStore.tables.map((d) => d.redundancyMultiple));
		//dataStore.redundancyAvg = dataStore.count === 0 ? 0 : parseFloat( (100 * (  ( dataStore.dataElCount / ( dataStore.count  *  ( dataStore.numTables ) )) )).toFixed(2));
		dataStore.rows = Number(
			dataStore.tables.reduce((a, b) => {
				return parseInt(b.rows) + a;
			}, 0)
		);
		//calculate overall GRE and Redundancy AVG.
		dataStore.value = Number(
			parseFloat(
				dataStore.tables.reduce((acc, b) => {
					return parseFloat(b.value) == 0 ? 0 + acc : parseInt(b.rows) * parseFloat(unitCost) + acc;
				}, 0)
			).toFixed(2)
		); //( d.count > 0 ) ? initialValue * d.count * ((d.redundancyAvg == 0 ? 1 : d.redundancyAvg) * 100 ) : initialValue;
	}

	//Generate links from redundancy report
	for (let i = 0; i < data.length; i++) {
		if (
			dataResult.links.filter((d) => d.id === data[i].left_data_store_id + ' : ' + data[i].right_data_store_id)
			.length > 0
		) {
			let item = dataResult.links.filter(
				(d) => d.id === data[i].left_data_store_id + ' : ' + data[i].right_data_store_id
			);
			item[0].count = item[0].count + 1;
		} else {
			dataResult.links.push({
				source: data[i].left_data_store_id,
				target: data[i].right_data_store_id,
				id: data[i].left_data_store_id + ' : ' + data[i].right_data_store_id,
				count: 1,
				sweep: 0,
				largeArc: 0,
				active: true,
				type: 'non-tree',
				strength: 0.8
			});
		}
	}

	//create stats for overall reports
	const nodesGRE = _.cloneDeep(dataResult.nodes);

	dataResult.scanStats.gre = calculateTopFive(nodesGRE, 'value');
	dataResult.scanStats.redundancy = calculateTopFive(nodesGRE, 'redundancyAvg');
	dataResult.scanStats.sensitivity = calculateTopFive(nodesGRE, 'sensitivityScore');
	dataResult.scanStats.stores = calculateScans(nodesGRE);

	return dataResult;
}