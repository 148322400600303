import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CustomTableWithPagination from '../../../components/Admin/CustomTable';
import DropDown from '../../../components/Admin/DropDown';
import MainContent from '../../../components/Layouts/MainContent';
import * as companyActions from '../../../redux/actions/CompanyActions';
import * as authActions from '../../../redux/actions/AuthActions';
import AddModal from './AddModal';
import moment from 'moment';

const actionOptions = [ { label: 'Edit', value: 1 }/*, { label: 'Delete', value: 2 } */ ];

class CompanyManagement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isModal: false,
			editInfo: '',
			alert: null
		};
		this.hideAlert = this.hideAlert.bind(this);
		this.successDelete = this.successDelete.bind(this);
		this.cancelDetele = this.cancelDetele.bind(this);
		this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
	}

	componentDidMount() {
		this.props.fetchCompanies();
		this.props.fetchUsers();
	}

	handleEdit = (company) => {
		this.setState({ editInfo: company, isModal: true });
	};

	toggleModal = () => {
		this.setState((state) => ({ isModal: !state.isModal, editInfo: '' }));
	};

	handleAddCompany = () => {
		this.setState({ isModal: true });
	};

	actionHandler = (e, company) => {
		let val = e.target.value;
		if (val == 1) {
			this.setState({ editInfo: company, isModal: true });
		} else if (val == 2) {
			this.warningWithConfirmAndCancelMessage(company.id);
		}
	};

	renderActions = (company) => {
		return (
			<React.Fragment>
				<DropDown
					options={actionOptions}
					placeholder={'Select Action'}
					onItemSelected={(e) => this.actionHandler(e, company)}
				/>
			</React.Fragment>
		);
	};

	addCompany = (company) => {
		const { editInfo } = this.state;
		if (editInfo) {
			const companyEdit = { ...editInfo, ...company };
			companyEdit.updated_at = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
			companyEdit.updated_by = this.props.currentUser.id;
			this.props.editCompany(companyEdit, editInfo.id);
		} else {
			// TODO: needs to update with dynamic value later
			company.compliance_id = 1;
			// NOTE: Company added by the admin will be active by default
			company.status = 1;
			company.created_at = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
			company.created_by = this.props.currentUser.id;
			this.props.addCompany(company);
		}
		this.toggleModal();
	};

	renderName = (id, name) => {
		return <Link to={`/admin/company-details/${id}`}>{name}</Link>;
	};

	warningWithConfirmAndCancelMessage(id) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title="Are you sure?"
					onConfirm={() => this.successDelete(id)}
					onCancel={() => this.cancelDetele()}
					confirmBtnBsStyle="info"
					cancelBtnBsStyle="danger"
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this company!
				</SweetAlert>
			)
		});
	}

	successDelete(id) {
		this.props.onDeleteCompany(id, () => {
			this.props.fetchCompanies();
		});
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title="Deleted!"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="info"
				>
					Your company has been deleted.
				</SweetAlert>
			)
		});
	}

	cancelDetele() {
		this.setState({
			alert: (
				<SweetAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title="Cancelled"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="info"
				>
					Your company is safe :)
				</SweetAlert>
			)
		});
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}

	render() {
		const { companies, users } = this.props;
		const { isModal, editInfo } = this.state;
		const columns = [
			{ dataField: 'id', text: 'CompanyID', sort: true },
			{ dataField: 'name', text: 'Name', sort: true },
			{ dataField: 'website', text: 'Website', sort: true },
			{ dataField: 'status', text: 'Status', sort: true },
			{ dataField: 'action', text: 'Action' }
		];
		const data = companies.map((company) => ({
			id: company.id,
			name: this.renderName(company.id, company.name),
			website: company.website || 'Not Available',
			status: company.status ? 'Active' : 'Inactive',
			action: this.renderActions(company)
		}));

		return (
		  <MainContent>
			<div className="container-fluid" style={{paddingTop: "40px"}}>
			{/*
							<Button style={{ float: 'right', margin: 10 }} onClick={this.handleAddCompany}>
								Add Company
							</Button> */}
				<CustomTableWithPagination columns={columns} rows={data} />
				{this.state.alert}
				{isModal && (
					<AddModal
						isOpen={isModal}
						toggleModal={this.toggleModal}
						users={users}
						editInfo={editInfo}
						addCompany={this.addCompany}
					/>
				)}
			</div>
		  </MainContent>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.company.isCompanyLoad,
	companies: state.company.companies,
	users: state.auth.users,
	currentUser: state.auth.user,
	errorMsg: state.auth.errorMsg
});

const mapDispatchToProps = (dispatch) => ({
	fetchCompanies: () => dispatch(companyActions.fetchCompanies()),
	fetchUsers: () => dispatch(authActions.fetchUsers()),
	addCompany: (company) => dispatch(companyActions.addCompany(company)),
	editCompany: (company, id) => dispatch(companyActions.editCompany(company, id)),
	onDeleteCompany: (id, callback) => dispatch(companyActions.onDeleteCompany(id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManagement);
