import forEach from 'lodash/forEach';
import slice from 'lodash/slice';
import size from 'lodash/size';
import map from 'lodash/map';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';

const common = {
  paper_bgcolor: 'white',
  plot_bgcolor: 'white',
  font: {
    color: 'black',
    family: 'Roboto, sans-serif',
    size: 12
  },
  // eslint-disable-next-line id-length
  margin: { t: 30, autoexpand: true }
};

const colors = [
  'rgb(94,248,241)', 'rgb(150, 255, 133)', 'rgb(112, 31, 137)', 'rgb(205, 86, 90)', 'rgb(60, 115, 212)',
  'rgb(210, 127, 61)', 'rgb(221, 221, 219)', 'rgb(160, 145, 97)', 'rgb(52, 206, 111)', 'rgb(106, 156, 245)'
];

export const truncateLabel = (label) => {
  const len = size(label);
  return len <= 20 ? label : `${label.substring(0, 9)}...${label.substring(len - 9, len)}`;
};

export function createRocData(values, lbls) {
  const labels = lbls || {};
  const { tpr, fpr } = values || {};
  const data = map(tpr, (y, key) =>
    ({ x: fpr[key], y, type: 'scatter', name: labels[key] || key })
  );
  data.push({
    x: [0, 1],
    y: [0, 1],
    line: { dash: 'dash' },
    name: 'project.metrics.roc_data.identity_line' //intl.formatMessage({ id: 'project.metrics.roc_data.identity_line' })
  });
  return {
    data,
    layout: {
      ...common,
      xaxis: {
        title: 'project.metrics.roc_data.false_positive_rate' //intl.formatMessage({ id: 'project.metrics.roc_data.false_positive_rate' })
      },
      yaxis: {
        title: 'project.metrics.roc_data.true_positive_rate', //intl.formatMessage({ id: 'project.metrics.roc_data.true_positive_rate' }),
        type: 'linear'
      }
    }
  };
}

export function createClassBalanceData(values) {
  const x = [];
  const y = [];
  const marker_color = [];
  let colorIdx = 0;
  forEach(values, (val, key) => {
    x.push(key);
    y.push(val);
    marker_color.push(colors[colorIdx]);
    if ((colorIdx += 1) >= colors.length) colorIdx = 0;
  });
  return {
    data: [
      { x, y, type: 'bar', marker: { color: marker_color }, hoverinfo: 'y' }
    ],
    layout: { ...common, xaxis: { type: 'category' }}
  };
}

export function createFeatureImportanceData(values) {
  const x = [];
  const y = [];
  const marker_color = [];
  let colorIdx = 0;
  const { features: feature_names, scores: feature_scores } = values || {};
  forEach(feature_names, (val) => {
    x.push(truncateLabel(val));
    marker_color.push(colors[colorIdx]);
    if ((colorIdx += 1) >= colors.length) colorIdx = 0;
  });
  forEach(feature_scores, (val) => y.push(val));
  return {
    data: [
      { x, y, type: 'bar', marker: { color: marker_color }, hoverinfo: 'y' }
    ],
    layout: { ...common,
      xaxis: {
        type: 'category',
        automargin: true
      },
      yaxis: {
        range: [-1, 1],
        automargin: true
      }
    }
  };
}

export function createConfusionMatrixData(rawData) {
  const annotations = [];
  const { vals: values, display } = rawData || {};
  const x = range(size(values));
  const fontColor = 'rgb(5, 20, 48)';
  forEach(values, (element, index) => {
    forEach(element, (val, jndex) => {
      const text = display && display[index] ? display[index][jndex] : '';
      annotations.push({ x: jndex, y: index, text, showarrow: false, font: { color: fontColor } });
    });
  });
  return {
    data: [
      {
        x,
        y: x,
        z: slice(values),
        type: 'heatmap',
        autocolorscale: false,
        colorscale: [
          ['0', 'rgb(94,248,241)'], ['0.2', 'rgb(88,220,246)'], ['0.4', 'rgb(78,191,244)'], ['1', 'rgb(60,103,236)']
        ]
      }
    ],
    layout: {
      annotations,
      ...common,
      yaxis: {
        title: 'project.metrics.confusion.actual_class', //intl.formatMessage({ id: 'project.metrics.confusion.actual_class' }),
        anchor: 'x',
        type: 'category',
        autorange: true
      },
      xaxis: {
        title: 'project.metrics.confusion.predicted_class', //intl.formatMessage({ id: 'project.metrics.confusion.predicted_class' }),
        anchor: 'y',
        type: 'category',
        autorange: true
      }
    }
  };
}

export function createClassifReportData({ x, y, vals }) {
  if (size(x) <= 0 || size(y) <= 0 || size(vals) <= 0) return null;
  const z = map(vals, (value) => slice(value, 0, -1));
  const annotations = reduce(z, (result, value, index) => {
    const fontColor = 'rgb(5, 20, 48)';
    const currentMapped = map(value, (text, idx) => ({
      x: idx, y: index, text, showarrow: false, font: { color: fontColor }
    }));
    return concat(result, currentMapped);
  }, []);
  return {
    data: [
      {
        x,
        y,
        z,
        type: 'heatmap',
        autocolorscale: false,
        colorscale: [
          ['0', 'rgb(94,248,241)'], ['0.2', 'rgb(88,220,246)'], ['0.4', 'rgb(78,191,244)'], ['1', 'rgb(60,103,236)']
        ]
      }
    ],
    layout: {
      annotations,
      ...common,
      yaxis: {
        title: 'project.metrics.classification.classes', //intl.formatMessage({ id: 'project.metrics.classification.classes' }),
        anchor: 'x',
        type: 'category',
        autorange: true
      },
      xaxis: {
        anchor: 'y',
        type: 'category',
        autorange: true
      }
    }
  };
}

export function createLearningCurveData(values) {
  const {
    train_sizes: x,
    train_scores_mean,
    test_scores_mean,
    train_scores_std,
    test_scores_std
  } = values || {};
  const train_scores = train_scores_std || [];
  const test_scores = test_scores_std || [];
  const trace1 = {
    x,
    y: train_scores_mean,
    type: 'scatter',
    name: 'project.metrics.learning.training_score', //intl.formatMessage({ id: 'project.metrics.learning.training_score' }),
    line: { color: 'rgb(236, 95, 95)' }
  };
  const trace2 = {
    x, // train sizes
    y: test_scores_mean, // Test scores mean
    type: 'scatter', // Cross-validation score
    name: 'project.metrics.learning.cross_validation_score', //intl.formatMessage({ id: 'project.metrics.learning.cross_validation_score' }),
    line: {color: 'rgb(150, 255, 133)'}
  };
  const y1train = map(train_scores_mean, (val, index) => val - train_scores[index]);
  const y2train = map(train_scores_mean, (val, index) => val + train_scores[index]);
  const y1test = map(test_scores_mean, (val, index) => val - test_scores[index]);
  const y2test = map(test_scores_mean, (val, index) => val + test_scores[index]);
  const fill_11 = {
    x,
    y: y1train,
    type: 'scatter',
    mode: 'lines',
    line: { color: 'rgba(0,0,0,0)' },
    showlegend: false
  };
  const fill_12 = {
    x,
    y: y2train,
    fill: 'tonexty',
    type: 'scatter',
    mode: 'lines',
    line: { color: 'rgba(236, 95, 95, 0.01)' },
    showlegend: false
  };
  const fill_21 = {
    x,
    y: y1test,
    type: 'scatter',
    mode: 'lines',
    line: { color: 'rgba(0,0,0,0)' },
    showlegend: false
  };
  const fill_22 = {
    x,
    y: y2test,
    fill: 'tonexty',
    type: 'scatter',
    mode: 'lines',
    line: { color: 'rgba(94,248,241,0.01)' },
    showlegend: false
  };
  return {
    data: [trace1, trace2, fill_11, fill_12, fill_21, fill_22],
    layout: {
      ...common,
      yaxis: {
        title: 'project.metrics.learning.score', //intl.formatMessage({ id: 'project.metrics.learning.score' }),
        anchor: 'x',
        autorange: true
      },
      xaxis: {
        title: 'project.metrics.learning.training_examples', //intl.formatMessage({ id: 'project.metrics.learning.training_examples' }),
        anchor: 'y',
        autorange: true
      }
    }
  };
}
