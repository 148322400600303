import React, {useEffect, useState} from 'react';
import size from 'lodash/size';
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import { Link } from 'react-router-dom';
import MainContent from '../../components/Layouts/MainContent';
import RWProjectDetails from './Cloud/projectDetails'
import RWONPremise from './OnPremise/index'


const showType = (type, project_id) => {
  if(type === '1') {
    return <RWProjectDetails project_id={project_id} />
  } else if (type === '2') {
    return <RWONPremise project_id={project_id} />
  }
}

const RWType = (props) => {
    const project_id = props.match.params.id;
    const rw_type = props.match.params.type;
  	return(
      <>
      {showType(rw_type, project_id)}
      </>
  	)
  }

export default RWType;