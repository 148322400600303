import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import './customTable.scss';

const CustomTableWithPagination = ({ columns = [], rows = [], expandRow = [] }) => {
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = '#fff';
      activeStyle.color = '#000';
    } else {
      activeStyle.backgroundColor = '#6e6e6e';
      activeStyle.color = '#fff';
    }
    if (typeof page === 'string') {
      activeStyle.backgroundColor = '#6e6e6e';
      activeStyle.color = '#fff';
    }
    activeStyle.padding = '10px 15px';
    return (
      <li className="page-item" key={page}>
        <a href="#" onClick={ handleClick } style={ activeStyle }>{ page }</a>
      </li>
    );
  };

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: false,
    sizePerPageList: [{
      text: '5', value: 10
    }],
    pageButtonRenderer
  };

  return <BootstrapTable
            wrapperClasses='custom-table-wrapper'
            classes='custom-table-pagination'
            keyField='id' 
            data={ rows } 
            columns={ columns } 
            expandRow={ expandRow }
            pagination={ paginationFactory(options) } 
          />
}
export default CustomTableWithPagination;