import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import Sidebar from './Sidebar';
import {authRoutes, adminRoutes} from "../../routes";



const StyledHeader = styled.div`
  min-height: 60px;
  max-height: 60px;
  background-color: #6e6e6e;
  display: ${({isAuth}) => isAuth ? "flex" : "none"};
  align-items: center;
  padding: 10px 0 10px 20px;
  top: 0px;
  width: 100%;
  position: fixed;
  z-index: 100000;
   margin-left: 80px;
   @media (max-width: 768px) { 
     margin-left: 0;
   }
`;
const StyledContainer = styled(Container)`
  width: 100%;
`;
const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
`;

const MainWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #29353b !important;
`;
const NavigationLink = styled.div` 
    font-size: 16pt;
    padding: 10px;
    color: #fff;
    list-style: none;
    font-weight: bold;
    text-transform: capitalize;
`;

const PlaybookLinks =styled.div`

    padding: 10px;
    margin-right: 100px;
    color: #fff;
    list-style: none;
    font-weight: bold;
   
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    text-align: right;

`
const PlaybookLinksItems =styled.a`

    font-size: 16pt;
    min-width: 40px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
    background-color: transparent;
    transition: all .3s ease-in-out;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;  
    text-transform: capitalize;

    span{
      font-size: 12pt;
    }

    &:hover{
      color: #88c5ca !important;
      text-decoration: none;
    }

    &.active {
      
      color: #68a5aa;

    }

`

const getRouteTitle = (path) => {
    const routes = [...adminRoutes, ...authRoutes];
    const currentRoute = routes.find(route => (route.path === path));
    return currentRoute ? currentRoute.name.replace(/-/g, ' '): '';
};

const totalRoutes = (path) => {
  const routes = [...authRoutes];
  const currentRoute = routes.find(route => (route.path === path));
  if(currentRoute == undefined){
    return [];
  }

  const groupLength = routes.filter((d) => d.group == currentRoute.group).length - 1 ;

  return groupLength

}

const getRoutesByGroup = (path) =>{
  const routes = [...authRoutes];
  const currentRoute = routes.find(route => (route.path === path));
  if(currentRoute == undefined){
    return [];
  }
  const routeGroup = routes.filter((d) => d.group == currentRoute.group)
                           .map((d) =>{
                                  if(d.groupOrder == currentRoute.groupOrder && d.groupOrder > 0  ){
                                    d.active = true;
                                    return d;
                                  } else if ( d.groupOrder <= currentRoute.groupOrder + 1 && d.groupOrder > 0 ) {
                                    d.active = false;
                                    return d;
                                  }
                                })
                           .filter((d) => d != undefined)
                           .sort((a,b) => a.groupOrder - b.groupOrder );
  
  return routeGroup;

}

const Header = ({isAuth, loginUser, location, handler}) => {

  console.log(getRoutesByGroup(location.pathname))
  const navigationItems = getRoutesByGroup(location.pathname);
  const groupLength = totalRoutes(location.pathname);
  console.log(navigationItems)
  return (
      <MainWrapper>
          {
              isAuth && (
                    <React.Fragment>
                        <Sidebar isAuth={isAuth} loginUser={loginUser} />
                        <StyledHeader isAuth={isAuth} >
                            <StyledContainer fluid>
                                <StyledRow>
                                    <NavigationLink>
                                        {getRouteTitle(location.pathname)}
                                    </NavigationLink>
                                    <PlaybookLinks>
                                       
                                      {
                                        navigationItems.length != 0 && navigationItems.map((d, i) => {  
                                          return (i == (navigationItems.length - 1) && !d.active )  &&                             
                                               (<PlaybookLinksItems href={d.path}  className={ d.active ? "active" : " "  } >
                                                      
                                                     {d.name.replace(/-/g, ' ')} <span> (step {d.groupOrder} / {groupLength}) </span> >
                                                     
                                                </PlaybookLinksItems>)
                                        })
                                      } 

                                    </PlaybookLinks>
                                </StyledRow>
                            </StyledContainer>
                        </StyledHeader>
                    </React.Fragment>
              )
          }
      </MainWrapper>
  )
};

export default withRouter(Header);
