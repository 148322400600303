import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import './resellerDetails.scss';
import axiosAuth from '../../../redux/axiosInstance';
import Button from "../../../components/Admin/Button";
import DropDown from '../../../components/Admin/DropDown';

const data = [
    {
        "name": "Page A",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "Page B",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "Page C",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "Page D",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "Page E",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    },
    {
        "name": "Page F",
        "uv": 2390,
        "pv": 3800,
        "amt": 2500
    },
    {
        "name": "Page G",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    }
];

const SubjectRender = ({ subject }) => <div className='subject'>{ subject }</div>;
const StatusRender = ({ status }) => <div className='status'><img src="/images/information.svg" alt='information'/> {status}</div>;
const tableData = {
    columns: [
        {title: 'Company', key: 'company'},
        {title: 'Subject', key: 'subject'},
        {title: 'Case ID', key: 'caseId'},
        {title: 'Service', key: 'service'},
        {title: 'Category', key: 'category'},
        {title: 'Created(UTC-7)', key: 'created'},
        {title: 'Status', key: 'status'}
    ],
    rows: [
        {
            company: 'Reegis',
            subject: <SubjectRender subject='Update audit cadence to bi-weekly'/>,
            caseId: '136',
            service: 'BlackSwan',
            category: 'Audit Request',
            created: '2019-06-22T14:04',
            status: <StatusRender status='Needs Attention'/>
        },
    ]
};

const providerOptions = [
    {label: 'Provider 1', value: 'provider1'},
    {label: 'Provider 2', value: 'provider 2'},
    {label: 'Provider 3', value: 'provider3'}
];

const metricOptions = [
    {label: 'Metric1',value: 'Metric1'},
    {label: 'Metric2',value: 'Metric2'},
    {label: 'Metric3',value: 'Metric3'},
];

const periodOptions = [
    {label: 'period1',value: 'period1'},
    {label: 'period2',value: 'period2'},
    {label: 'period3',value: 'period3'},
];

export default class ResellerDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            resellerDetails: {}
        }
    }

    toggle = () => {
        this.setState((state) => ({ dropdownOpen: !state.dropdownOpen }));
    };

    componentDidMount() {
        const resellerId = this.props.match.params.id;
        axiosAuth.get(`/reseller/${resellerId}`)
            .then(res => {
                this.setState({resellerDetails: res.data.data});
            });
    }

    render() {
        const {resellerDetails: {name, website, email, phone, image }} = this.state;
        const resellerId = this.props.match.params.id;
        return (
            <section className="reseller-wrapper">
                <div className="container-fluid">
                    <div className="row m-tablet mt-3">
                        <div className="col-md-4 m-tab">
                            <div className='reseller-details'>
                                <img src={image || "/images/noimage.jpg"} alt='no logo'/>
                                <div className="company-info">
                                    <h5>{name}</h5>
                                    <ul>
                                        <li><h6>ResellerID:</h6> <p>{resellerId}</p></li>
                                        <li><h6>Website:</h6> <p>{website || 'N/A'}</p></li>
                                        <li><h6>Email:</h6> <p>{email || 'N/A'}</p></li>
                                        <li><h6>Phone:</h6> <p>{phone || 'N/A'}</p></li>
                                        <li><span className="services"><strong>13</strong> EB Data serviced</span> <span className="services"><strong>13</strong> Account in Network</span></li>
                                        <li>  <span className="services"><strong>512</strong> EB Data serviced</span>
                                            <span className="services"><strong>122</strong> Stores</span></li>
                                    </ul>
                                </div>
                                <div className='subscriptions'>
                                    <h5>Subscription</h5>
                                    <div className="revenue-data">
                                    <span><p>Revenue:</p> <p>$75,000/mo.</p></span>


                                        </div>
                                    </div>
                                <div className="payment-section">
                                    <h5>Payment Status: </h5>
                                    <div><span className="paid"><p>6</p> paid</span>
                                        <span className="upcoming"><p>4</p> upcoming</span>
                                        <span className="overdue"><p>3</p> Overdue</span>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div className="col-md-8 m-tab">
                            <div className='report'>
                                <div className='charts'>
                                    <div className="row w-100">
                                        <div className="col-md-6 col-xs-12">
                                            <div className="chart-wrapper">
                                                <div className="group-dropdown">
                                                    <h5>Report</h5>
                                                    <DropDown options={metricOptions} placeholder="Select Metric" isBlackTheme={true}/>
                                                    <DropDown options={periodOptions} placeholder="Select Period" isBlackTheme={true}/>
                                                </div>
                                                <LineChart width={400} height={250} data={data}
                                                           margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                                                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                                </LineChart>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12 ">
                                            <div className="chart-wrapper">
                                                <div className="group-dropdown">
                                                    <DropDown options={metricOptions} placeholder="Select Metric" isBlackTheme={true} />
                                                    <DropDown options={periodOptions} placeholder="Select Period" isBlackTheme={true} />
                                                </div>
                                                <LineChart width={400} height={250} data={data}
                                                           margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                                                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                                </LineChart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='support'>
                                    <div>
                                        <ResellerManagement companyId={companyId}/>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
