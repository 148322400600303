"use strict";
import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';

import './d3chart.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faHistory, faEye, faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap'

import { deriveUnitCost } from '../../common/utils/deriveUnitCost';
import { currencyFormatter } from '../../common/utils/currencyFormatter';

const toolTipKeys = {
  name: "Name", value: "Value($)", sensitivity: "Sensitivity", redundancy: "Redundancy (Avg) "
};

const plotAction = (g, width, height, data) => {

  console.log(data, "ready-data")

  var x = d3.scaleLinear()
    .domain(d3.extent(data, (node) => node.sensitivityScore) )
    .range([ 10, width ]);
  g.append("g")
    .attr('class', 'blue-axis')
    .attr("transform", "translate(0," + (height - 10) + ")")
    .call(d3.axisBottom(x));

  g.append('g')
     .append("svg:foreignObject")
     .attr('width', 100)
     .attr('height', 30)
     .attr('x', width / 2 - 50)
     .attr('y', height + 10)
     .append('xhtml:div')
     .attr("class", "blue-axis")
     .html("<p>Sensitivity</p>")

  // Add Y axis
  var y = d3.scaleLinear()
    .domain( d3.extent(data, (node) => node.redundancyAvg) )
    .range([ height, 10]);

  g.append('g')
     .append("svg:foreignObject")
     .attr("transform", "rotate(-90)")
     .attr('width', 100)
     .attr('height', 30)
     .attr('x', 0 - (height - (height / 3 + 20) ))
     .attr('y', 0 - 40 )
     .append('xhtml:div')
     .attr("class", "blue-axis")
     .html("<p>Redundancy (Avg)</p>")
  
  g.append("g")
    .attr('class', 'blue-axis')
    .attr("transform", "translate(10," + (- 10) + ")")
    .call(d3.axisLeft(y));

  // Add a scale for bubble size
  var z = d3.scaleLinear()
    .domain( d3.extent(data, (node) => node.value) )
    .range([ 5, 40]);

  // Add dots
  var circles = g.append('g')
    .attr("transform", "translate(0," + (- 10) + ")")
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
      .attr("cx", function (d) { return x(d.sensitivityScore); } )
      .attr("cy", function (d) { return y(d.redundancyAvg); } )
      .attr("r", function (d) { return z(d.value); } )
      .style("fill", "#a5f5ff")
      .style("opacity", "0.7")
      .attr("stroke", "black");

  circles.on("mouseenter", (d) => callNodeTooltip(d) )
         .on('mouseleave', () => {
            d3.select("#plot-tooltip").style("display", "none"); 
         })
      
}

const drawBubblePlot = (data, unitCost) =>{
  
  var margin = {top: 50, right: 50, bottom: 50, left: 50},
      width = 600 - margin.left - margin.right,
      height = 450 - margin.top - margin.bottom;

  var container = d3.select("#rw-bubble-plot");
  container.selectAll("*").remove();
  var svg = container.append("svg")
                     .attr("preserveAspectRatio", "xMinYMin meet")
                     .attr("viewBox", "0 0 600 450")
                     .classed("svg-content-rw-plot", true);
  
  data = data.nodes.children;

  data.forEach((node) => {
    node.value = node.value * unitCost;
  })

  var g = svg.append("g")
             .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  plotAction(g, width, height, data) 

}

const callNodeTooltip = (d) => {
  let items = ``, data = d, properties= {};
      properties.name = data.name;
      properties.value = currencyFormatter(data.value);
      properties.redundancy = data.redundancyAvg;
      properties.sensitivity = data.sensitivityScore;

  for (let [key, value] of Object.entries(properties)) {
    if(Object.keys(toolTipKeys).indexOf(key) != -1  ){
      items += `<li>${toolTipKeys[key]}: ${value}</li>`;
    } else {
      items += `<li>${key}: ${value}</li>`;
    }
  }

  let htmlStr = `<ul class="tool-tip-list">${items}</ul>`;
  displayTooltip(htmlStr);

}

const displayTooltip = (htmlStr) =>{
        let xPos = d3.event.pageX,
            yPos = d3.event.pageY;

        let toolTip = d3.select("#plot-tooltip")
          .style("display", "block")
          .style("top", (yPos + 20 )+"px")
          .style("left", (xPos + 10 ) +"px")
          .html(htmlStr);

        toolTip.style("opacity", 1)

}

class RWBubblePlot extends React.Component{

  constructor(props){
    super(props);

    this.state ={
      data: this.props.data,
      unitCost: this.props.unitCost,
      loading: true    
    }

  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.data != this.props.data || prevProps.unitCost != this.props.unitCost){
      drawBubblePlot(this.props.data, this.props.unitCost)
    }
  }

  componentDidMount(){
     if( this.props.data && this.props.unitCost){
       drawBubblePlot(this.props.data, this.props.unitCost)
     }
  }

  render(){

    return (
      
      <div>
        <div id="plot-tooltip"></div>
        <div className={"bp-container"} id="rw-bubble-plot"></div>
        <p style={{textAlign: "center", padding:"20px 60px"}}>
           Comparison of files by sensitivity and redundancy,
           where the radius of the bubble correlates to the potential 
           value of the file it represents.
        </p>
      </div>
  
    )

  }
  

}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(
  mapStateToProps
)(RWBubblePlot);

