import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './sidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faHome,
    faDatabase,
    faRobot,
    faProjectDiagram,
    faLightbulb,
    faSun,
    faSeedling,
    faSitemap,
    faTrophy,
    faBookReader,
    faCode,
    faFileCode,
    faComments,
    faUserShield,
    faThermometerThreeQuarters,
    faUsers,
    faPlug,
    faBuilding,
    faTachometerAlt,
    faTruck,
    faUser,
    faPeople,
    faStore,
    faChartLine,
    faIndustry,
    faExchangeAlt
} from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../../services/config';
import menuIconSettings from '../../../../static/images/menu-icon-settings.png';
import menuIconLogout from '../../../../static/images/menu-icon-logout.png';
import trovoIcon from '../../../../static/images/trovo-icon.svg';
import harmburger from '../../../../static/images/harmburger.svg';
// import tapLogo from '../../static/images/taplogo-white.png';

 //added filter to allow navigation from any source to set active in sidebar.
 const findNestedRoute = (n) => {
    var activeItem;
    menuItems.forEach((d,i) => {
            if(d.path == n){
                 activeItem = d.name;
            } else if(d.links){
                d.links.forEach((sub, j) => {
                    if (sub.path === n ){
                         activeItem = d.name;
                    } 
                })
            }
    })
    return activeItem;
}

const menuItems = [
    {
            name: 'ted',
            title: 'TROVO',
            icon: trovoIcon,
            path: '/',
            links: [
                    {
                            name: 'dashboard',
                            title: 'Dashboard',
                            icon: <FontAwesomeIcon icon={faHome} />,
                            path: '/'
                    },
            ]
    },
    {
            name: 'risk-exposure-playbook',
            title: 'RISK EXPOSURE',
            icon: <FontAwesomeIcon icon={faDatabase} />,
            path: '/risk-exposure-playbook',
            links: [
                    {
                            name: 'company-settings',
                            title: 'Company Settings',
                            icon: menuIconSettings,
                            path: '/settings'
                    },
                    {
                            name: 'cyber-discovery',
                            title: 'Cyber Discovery',
                            icon: <FontAwesomeIcon icon={faRobot} />,
                            path: '/detection-scan'
                    },
                    {
                            name: 'data-surface',
                            title: 'Data Surface',
                            icon: <FontAwesomeIcon icon={faProjectDiagram} />,
                            path: '/data-surface'
                    },
                    {
                            name: 'insights',
                            title: 'Insights',
                            icon: <FontAwesomeIcon icon={faLightbulb} />,
                            path: '/risk-exposure-insights'
                    },
            ]
    },
    {
            name: 'growth-playbook',
            title: 'GROWTH',
            icon: <FontAwesomeIcon icon={faSeedling} /> ,
            path: '/growth-playbook',
            links: [
                    {
                            name: 'auger',
                            title: 'Auger',
                            icon: <FontAwesomeIcon icon={faTachometerAlt} /> ,
                            path: '/auger-project'
                    },
                    {
                            name: 'useaible',
                            title: 'UseAIble',
                            icon: <FontAwesomeIcon icon={faTruck} />,
                    },
                    {
                        name: 'reveal-why',
                        title: 'Reveal Why',
                        icon: <FontAwesomeIcon icon={faLightbulb} />,
                        path: '/reveal-why'
                    },
                    {
                        name: 'model-factory',
                        title: 'Model Factory',
                        icon: <FontAwesomeIcon icon={faIndustry} />,
                        external: true,
                        path: 'https://modelfactory.prod.aws.quickspark.com/'
                    },
                    {
                        name: 'data-exchange',
                        title: 'Data Exchange',
                        icon: <FontAwesomeIcon icon={faExchangeAlt} />,
                        external: true,
                        path: 'https://karlsgate.com/'
                    },
                    {
                        name: 'project-sunkisst',
                        title: 'Sunkisst',
                        icon: <FontAwesomeIcon icon={faSun} />,
                        path: '/project-sunkisst'
                    },
                    
            ]
    },
    {
            name: 'people-playbook',
            title: 'PEOPLE',
            icon: <FontAwesomeIcon icon={faUsers} /> ,
            path: '/people-playbook',
            links: [
                    {
                            name: 'assessment',
                            title: 'Assessment',
                            icon: <FontAwesomeIcon icon={faBookReader} />,
                            external: true,
                            path: 'https://tap.trovo.ai'
                    },
                    {
                            name: 'alignment',
                            title: 'Alignment',
                            icon:  <FontAwesomeIcon icon={faUsers} />,
                            external: true,
                            path: 'https://tap.trovo.ai/alignment'
                    },
                    {
                            name: 'marketplace',
                            title: 'Marketplace',
                            icon:  <FontAwesomeIcon icon={faStore} />,
                            external: true,
                            path: 'https://tap.trovo.ai/marketplace'
                    },
                    {
                        name: 'biosense',
                        title: 'Health Access Manager',
                        icon: <FontAwesomeIcon icon={faLightbulb} />,
                        path: '/biosense'
                    },
            ]
    },
    {
            name: 'knowledge_base',
            title: 'KNOWLEDGE\nBASE',
            icon: <FontAwesomeIcon icon={faBookReader} />,
            path: '/playbook',
    },
    {
        name: 'api',
        title: 'API',
        icon: <FontAwesomeIcon icon={faCode} />,
        external: true,
        path: `${API_URL}/docs/`,
        links: [
            {
                name: 'swagger',
                title: 'Swagger',
                icon: <FontAwesomeIcon icon={faFileCode} />,
                external: true,
                path: `${API_URL}/docs/`
            }
        ]
    },
    {
            name: 'feedback',
            title: 'FEEDBACK',
            icon: <FontAwesomeIcon icon={faComments} />,
            external: true,
            path: 'http://bit.ly/trovo-feedback',
    }
];


const Sidebar = ({ loginUser, location, history }) => {
    const [currentToggledItem, setCurrentToggledItem] = useState('');
    const [currentMobileToggledItem, setMobileCurrentToggledItem] = useState('');
    const [isMobileMenu, setMobileMenu] = useState(false);
    const [activePage, setActivePage] = useState(location ? findNestedRoute(location.pathname) : "ted");
    
    if (loginUser.user_role && loginUser.user_role === 1) {
        // console.log(loginUser)
        menuItems.splice(5, menuItems[5].name == "feedback" ? 0 : 1, 
                {
                        name: 'admin',
                        title: 'ADMIN',
                        icon: <FontAwesomeIcon icon={faUserShield} />,
                        links: [
                                {
                                        name: 'admin-reports',
                                        title: 'System Status',
                                        icon: <FontAwesomeIcon icon={faThermometerThreeQuarters} />,
                                        path: '/admin/reports'
                                },
                                {
                                        name: 'admin-companies',
                                        title: 'Company Management',
                                        icon: <FontAwesomeIcon icon={faBuilding} />,
                                        path: '/admin/manage-companies'
                                },
                                {
                                    name: 'admin-monitoring',
                                    title: 'Monitoring',
                                    icon:  <FontAwesomeIcon icon={faTachometerAlt} />,
                                    path: '/admin/monitoring'
                                },
                                {
                                    name: 'admin-monitoring',
                                    title: 'Monitoring',
                                    icon:  <FontAwesomeIcon icon={faTachometerAlt} />,
                                    external: true,
                                    path: process.env.GRAFANA_MONITORING_URL
                                },
                                {
                                    name: 'admin-billing',
                                    title: 'Billing',
                                    icon:  <FontAwesomeIcon icon={faChartLine} />,
                                    external: true,
                                    path: process.env.GRAFANA_MONITORING_URL
                                },
                        ]
                }
        );
} else if( menuItems[5].name == "admin") {
    menuItems.splice(5, 1);
}

const handleToggle = (name, hide) => {
        if (currentToggledItem === name || hide) {
                name = '';
        }
        setCurrentToggledItem(name);
};

const handleMobileToggle = (name, hide) => {
        if (currentMobileToggledItem === name || hide) {
                name = '';
        }
        setMobileCurrentToggledItem(name);
};

const moveToPath = (path, external) => {
        if (path) {
                if (external) {
                        window.open(path, '_blank');
                } else {
                        history.push(path);
                }
        }
        setMobileMenu(false);
};

const changeActivePage = (event, name) => {
        //console.log(name)
        event.stopPropagation();
        handleToggle('', true);
        handleMobileToggle('', true);
        setMobileMenu(false);
        if (name) {
                setActivePage(name);
        }
};

    return (
        <div>
            <div className="mobile-block">
                {isMobileMenu && (
                    <React.Fragment>
                        <ul className="sidebar">
                            {menuItems.map((item, key) => (
                                <li id={'mobile_menu_item_' + key} key={key}>
                                    <div className="sidebar-open">
                                        <span style="" onClick={() => moveToPath(item.path, item.external)}>
                                            <img src={item.icon} />
                                            <p style={{ display: 'block' }}>{item.title}</p>
                                        </span>
                                        {item.links &&
                                        item.links.length && (
                                            <div
                                                className="icon"
                                                onClick={() => {
                                                    handleMobileToggle(item.name);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        currentMobileToggledItem === item.name ? faAngleUp : faAngleDown
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'drop-down ' +
                                            (currentMobileToggledItem === item.name ? 'sidebar-open' : 'sidebar-close')
                                        }
                                    >
                                        <ul>
                                            {item.links &&
                                                item.links.length &&
                                                item.links.map((link, key) => (
                                                    <li key={key}>
                                                        {!link.external && link.path ? (
                                                            <NavLink
                                                                exact
                                                                to={link.path}
                                                                onClick={(e) => changeActivePage(e, item.name)}
                                                            >
                                                                <img src={link.icon} />
                                                                <span>{link.title}</span>
                                                            </NavLink>
                                                        ) : (
                                                            <a
                                                                href={link.path}
                                                                target="_blank"
                                                                onClick={(e) => changeActivePage(e)}
                                                            >
                                                                <img src={link.icon} />
                                                                <span>{link.title}</span>
                                                            </a>
                                                        )}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                )}
                <button className="sidebar-btn" onClick={() => setMobileMenu(!isMobileMenu)}>
                    <img src={harmburger} />
                </button>
            </div>
            <div className="left-panel">
                <div className="hide-mobile">
                    <div
                        className="user-info-container"
                        onMouseEnter={() => handleToggle('hum_burger')}
                        onMouseLeave={() => handleToggle('hum_burger', true)}
                    >
                        <div
                            className={`toggle-button-wrapper item-detail ${currentToggledItem === 'hum_burger'
                                ? 'active-blue'
                                : ''}`}
                        >
                            <div className="toggle-btn">
                                <FontAwesomeIcon icon={faBars} />
                            </div>
                        </div>
                        <div
                            className={`sliding-menu ${currentToggledItem === 'hum_burger'
                                ? 'display-sidebar'
                                : 'hide-sidebar'}`}
                        >
                            <ul>
                                <li style={{ lineHeight: '1em', paddingTop: '10px', display: 'flex' }}>
                                    <div
                                        style={{
                                            color: '#fff',
                                            display: 'flex',
                                            flexFlow: 'column',
                                            flex: 1
                                        }}
                                    >
                                        <h6
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '11pt',
                                                marginBottom: '5px'
                                            }}
                                        >
                                            {loginUser.company.name}
                                        </h6>
                                        <p
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '9pt',
                                                marginBottom: '0px'
                                            }}
                                        >
                                            {loginUser.first_name + ' ' + loginUser.last_name}
                                        </p>
                                    </div>
                                    <div
                                        style={{ color: 'white', fontSize: '14pt' }}
                                        className={'font-awesome-icon-item'}
                                    >
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                </li>
                                <li className={"on-hover"}>
                                    <a onClick={(e) => changeActivePage(e)}>
                                        <span>
                                            {location.pathname === 'user-settings' && (
                                                <FontAwesomeIcon className="mr-2" icon={faAngleRight} />
                                            )}
                                            Settings
                                        </span>
                                        <p>
                                            <img src={menuIconSettings} />
                                        </p>
                                    </a>
                                </li>
                                <li className={"on-hover"}>
                                    <NavLink exact to="/logout" onClick={handleToggle}>
                                        <div className="represent-desc">
                                            <span>Logout</span>
                                        </div>
                                        <img src={menuIconLogout} />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="dashboard-menu">
                        <ul>
                            {menuItems.map((item, key) => (
                                <li
                                    key={`primary_icon_wrapper_${key}`}
                                    id={`primary_icon_wrapper_${key}`}
                                    onMouseEnter={() => handleToggle(item.name)}
                                    onMouseLeave={() => handleToggle(item.name, true)}
                                    className={activePage === item.name ? 'active' : 'active-hover'}
                                    onClick={(e) => {
                                        moveToPath(item.path, item.external);
                                        if (!item.external) {
                                            changeActivePage(e, item.name);
                                        }
                                    }}
                                >
                                    <span className="item-detail" id={`primary_icon_${key}`}>
                                        {typeof item.icon === 'string' ? (
                                            <img
                                                className="image-icon"
                                                id={`primary_icon_image_${key}`}
                                                src={item.icon}
                                            />
                                        ) : (
                                            <div id={`primary_icon_image_${key}`} className={'font-awesome-icon-item'}>
                                                {item.icon}
                                            </div>
                                        )}
                                        <p id={`primary_icon_title_${key}`}>{item.title}</p>
                                    </span>
                                    {item.links &&
                                    item.links.length && (
                                        <div
                                            className={`sliding-menu ${currentToggledItem === item.name
                                                ? 'display-sidebar '
                                                : 'hide-sidebar'}${item.name == 'admin' ? ' bottoms-up' : ' '}`}
                                        >
                                            <ul>
                                                {item.links.map((link, index) => (
                                                    <li key={index}>
                                                        {!link.external && link.path ? (
                                                            <NavLink
                                                                exact
                                                                to={link.path}
                                                                onClick={(e) => changeActivePage(e, item.name)}
                                                            >
                                                                <span>
                                                                    {location.pathname === link.path && (
                                                                        <FontAwesomeIcon
                                                                            className="mr-2"
                                                                            icon={faAngleRight}
                                                                        />
                                                                    )}
                                                                    {link.title}
                                                                </span>
                                                                <span>
                                                                    {typeof link.icon == 'string' ? (
                                                                        <img src={link.icon} />
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                fontSize: '12pt',
                                                                                color: 'white',
                                                                                lineHeight: '3em'
                                                                            }}
                                                                        >
                                                                            {link.icon}
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            </NavLink>
                                                        ) : (
                                                            <a
                                                                href={link.path}
                                                                target="_blank"
                                                                onClick={(e) => changeActivePage(e)}
                                                            >
                                                                <span>
                                                                    {location.pathname === link.path && (
                                                                        <FontAwesomeIcon
                                                                            className="mr-2"
                                                                            icon={faAngleRight}
                                                                        />
                                                                    )}
                                                                    {link.title}
                                                                </span>
                                                                <span>
                                                                    {typeof link.icon === 'string' ? (
                                                                        <img src={link.icon} />
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                fontSize: '12pt',
                                                                                color: 'white',
                                                                                lineHeight: '3em'
                                                                            }}
                                                                        >
                                                                            {link.icon}
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            </a>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Sidebar);