import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Row, Col, Form} from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { faAngleDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import CsvDownload from 'react-json-to-csv'
//helpers
import {
  getCyberScans
} from '../../utilities/s2APIHelpers';
import { colorClassCVSS } from '../../common/utils/deriveUnitCost';
import {
  nmapParseTab
} from '../../utilities/dataMutators';
import MainContent from '../../components/Layouts/MainContent';
//stylesheet
import './cyber-scan.scss';

//column mapping- used in handler for mapping attributes to columns for sorting.
const attrMap = {1: "name", 2:"os", 3: "cvssScore", 4: "ports", 5: "vulnCount" }

//sort column by object property
const columnSort = (attr, h) => {
  if (attr == "os" || attr == "name"){
    console.log(h)
    return h.sort((a, b) =>{ if(a[attr] > b[attr]) return -1; });
  } else if (attr == "cvssScore" || attr == "vulnCount"){
    return h.sort((a, b) =>  parseFloat(b[attr]) - parseFloat(a[attr])  );
  } else if (attr == "ports"){
    return h.sort((a, b) =>  b[attr].length - a[attr].length  );
  } else {
    return h;
  }
}

const tooltipText ={
  cvss: `CVSS version 3 assesses the Common Vulnerabilities and Exposures (CVE) system to provide 
         a reference-method for publicly known information-security vulnerabilities and exposures. 
         The National Cybersecurity FFRDC, operated by the Mitre Corporation, maintains the system, 
         with funding from the National Cyber Security Division of the United States Department of 
         Homeland Security. <a href="https://nvd.nist.gov/vuln-metrics/cvss"
         target="_blank">nvd.nist.gov - Vuln Metrics</a>
`
}

//sub-component for renderering nested data.
const ListItem = ({
  openTab,
  host,
  openAll
 }) =>{
  
  var vulner = host.ports ? host.ports.filter((d) => d.vulners != undefined) : [] ;
  const [item, setItem] = useState(host);
  const [open, setOpen] = useState(openTab); 
  const [currentPort, setCurrentPort] = useState( vulner.length != 0 ? vulner[0].attr["@_portid"] : (host.ports ? host.ports[0].attr["@_portid"] : 0 ) );
  
  //updates vary conditionally based upon what props are updated, as this is an extremely dynamic sub-component.
  useEffect(() => {
    vulner = host.ports ? host.ports.filter((d) => d.vulners != undefined) : [] ;
    setItem(host);
    setCurrentPort( vulner.length != 0 ? vulner[0].attr["@_portid"] : (host.ports ? host.ports[0].attr["@_portid"] : 0 ) )
  }, [host, openTab]);

  useEffect(() => {}, [currentPort, item]);

  useEffect(() => {
    setOpen(openAll)
  }, [openAll]);

  const clickPortHandler = (e) =>{
  	setCurrentPort( item.ports.filter((d) => d.attr["@_portid"] == e)[0].attr["@_portid"] );
  }
  const itemCvss = item.cvssScore ? parseFloat(item.cvssScore) : "N/A"; 

  const cvssColor = colorClassCVSS(itemCvss)

  return (
    <li className={'host-level-list-item'}>
      <Row style={{borderBottom: open ? "0px" : "1px solid rgba(150,150,150, 0.5)"}} >
    	<Col sm={2} style={{overflowX: "scroll"}}> {item.name} </Col>
    	<Col sm={2}> {item.os} </Col>
    	<Col sm={2}> { item.ports ? item.ports.length : 0} </Col>
      <Col sm={2}> { item.vulnCount } </Col>
    	<Col sm={2} className="host-cvss-col"><p style={{color: `${ cvssColor.text }`, backgroundColor: `${ cvssColor.bg }`  }}>{ ` ${itemCvss } ${ cvssColor.level }`  }</p></Col>
    	<Col sm={1} style={{alignText: "right"}} onClick={ () => setOpen(!open) } ><FontAwesomeIcon style={{fontSize: "20pt", lineHeight: "3.5em", transform: (open ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} icon={faAngleDown}></FontAwesomeIcon> </Col>
      </Row>
      
      <div className={'detection-panel'} style={{display: open ? "flex" : "none", flexFlow: "column" }}> 
        <Row style={{ padding: "20px 20px" }}>
        	<Col md={6}>
        	  <p>Port Details: </p>
              {item.ports ? 
              	( 	
                  <ul className={'inner-detection-scan-list'}>
                    <li  className={'host-level-headers'}>
                  	  <Row>
                        <Col md={4}> Name</Col>
                        <Col md={2}> Id </Col>
                        <Col md={4}> State </Col>
                        <Col md={2}> CVSS </Col>
                      </Row>
                    </li>
                      {item.ports.map((d, i) => {
                         var portCvss= colorClassCVSS(d.cvssScore);

                         return (
                           <li onClick={() => clickPortHandler(d.attr["@_portid"])} >
                            <Row className={`port-item ${ currentPort && currentPort == d.attr["@_portid"] ? "selected-port" : ""}` }>
                              <Col md={4}>{d.service.attr["@_name"]}</Col>
                              <Col md={2}>{d.attr["@_portid"]}</Col>
                              <Col md={4}>{d.state.attr["@_state"]}</Col>
                              <Col md={2} className="port-cvss-col" ><p style={{color: `${ portCvss.text }`, backgroundColor: `${ portCvss.bg }`  }}>
                                  {  (portCvss && d.cvssScore) ? `${( d.cvssScore.toFixed(1) )}` : "N/A"  }
                                  </p>
                              </Col>
                            </Row>
                           </li>
                         )
                        })
                      }
              	 </ul>
                ) : "This scan returned no ports for this host..."
              }
        	</Col>
        	<Col md={6}>
              <p>Vulnerabilities: </p>
              {currentPort && item.ports.filter((d) => d.attr["@_portid"] == currentPort && d.vulners ).length != 0 ? 
              	( 	
                  <ul className={'inner-detection-scan-list'}>
                    <li  className={'host-level-headers'}>
                  	  <Row>
                        <Col md={4}> Id </Col>
                        <Col md={2}> Type </Col>
                        <Col md={2}> Exploit </Col>
                        <Col md={2}> CVSS </Col>
                      </Row>
                    </li>
                      {item.ports.filter((d) => d.attr["@_portid"] == currentPort && d.vulners )[0].vulners.table.map((d, i) => {

                         let vulnCvss = d.elem.filter((d) => d.attr["@_key"] == "cvss")[0]["#text"],
                             cvssCode = colorClassCVSS(d.elem.filter((d) => d.attr["@_key"] == "cvss")[0]["#text"]);

                         return (
                           <li>
                            <Row>
                              <Col md={4}><a className={'clear-link'} href={`https://nvd.nist.gov/vuln/detail/${d.elem.filter((d) => d.attr["@_key"] == "id")[0]["#text"]}`} 
                                 target="_cyber_detect" rel="noopener noreferrer">{ d.elem.filter((d) => d.attr["@_key"] == "id")[0]["#text"] }</a></Col>
                              <Col md={2}>{ d.elem.filter((d) => d.attr["@_key"] == "type")[0]["#text"] }</Col>
                              <Col md={2}>{ d.elem.filter((d) => d.attr["@_key"] == "is_exploit")[0]["#text"] ? "true" : "false" }</Col>
                              <Col md={2} className="vuln-cvss-col" ><p style={{color: `${ cvssCode.text }`, backgroundColor: `${ cvssCode.bg }`  }}>
                                  { (vulnCvss && cvssCode) ?  vulnCvss.toFixed(1) : "N/A" }
                                  </p>
                              </Col>
                            </Row>
                           </li>
                         )
                        })
                      }
              	  </ul>
                ) : "This scan returned no vulnerabilities associated with the selected port..."
              }
        	</Col>
        </Row>              
           
      </div>
    </li>
  )
}

//Main page/table component.
class CyberScan extends React.Component {

  constructor(props){
  	super(props);
  	this.state = {
      data: null,
      sortBy: null,
      scans: [],
      scanIndex: null,
      loading: true,
      open: true
  	}
  }


  shouldComponentUpdate(nextProps, nextState){
    
    if(nextState.data != undefined){
      return true;
    } else if (nextState.sortBy != this.state.sortBy){
      return true;
    } else if (nextState.scanIndex != this.state.scanIndex){
      return true;
    } else if (nextState.loading != this.state.loading){
      return true;
    }  else {
      return true;
    }

  }

  async componentDidMount(){

    const scans = await getCyberScans(this.props.user.company.id);
    const len = scans.length;
    
    try{
      const json = JSON.parse(scans[len - 1].nmapdata)
      console.log(json)
      const data = nmapParseTab(json);
      
      if(data != 0){
        this.setState({
           scans,
           data,
           scanIndex: len - 1,
           loading: false
         }, () =>{
          console.log(scans, this.state.data);
         })   
      } else {
        console.log(data)
      }

    } catch (e){
      console.log(e)
      if(scans != 0){
        this.setState({
           scans,
           loading: false
         }, () =>{
          console.log(scans, this.state.data);
         })   
      } else {
        this.setState({
           loading: false
         }, () =>{
          console.log("No available data...");
         })  
      }
    }

  }
  
  //parses and flattens data for hosts/ports with detected vulnerabilities for use in csv format.
  mutateDataForCSV(data){
    
    var flattened = []

    for(var i = 0 ; i < data.length; i++){
      var host = data[i], ports = host.ports;

      for(var j = 0; j < ports.length; j++){
        
        if( ports[j].vulners && ports[j].vulners.table ){
          
          var port = ports[j], tables = port.vulners.table;
          for( var z = 0; z < tables.length; z++ ){

            let vuln = {}, t = tables[z];

            vuln.name = t.elem.filter((d) => d.attr["@_key"] == "id")[0]["#text"];
            vuln.type = t.elem.filter((d) => d.attr["@_key"] == "type")[0]["#text"];
            vuln.is_exploit = t.elem.filter((d) => d.attr["@_key"] == "is_exploit")[0]["#text"] ? "true" : "false";
            vuln.cvss = t.elem.filter((d) => d.attr["@_key"] == "cvss")[0]["#text"];
            vuln.port_name = port.service.attr["@_name"];
            vuln.port_id = port.attr["@_portid"];
            vuln.port_state = port.state.attr["@_state"];
            vuln.host_name = host.name;
            vuln.host_os = host.os;

            flattened.push(vuln);

          }

        }

      }
      
    }

    return flattened.length == 0 ? [{"Congrats!": "No vulnerabilities have been detected..."}] : flattened;
  } 

  handleColumnSort(int){

    var attr = attrMap[int];

    var sortedData = columnSort(attr, this.state.data);

    this.setState({
      data: sortedData,
      sortBy: int
    })

  }

  setOpen(open){
    console.log("hit")
    if(!this.state.loading){ 
      this.setState({
        open
      })
    }
  }

  handleScanChange(e){
    var index = parseInt(e.target.value);

    const json = JSON.parse(this.state.scans[index].nmapdata)
    const data = nmapParseTab(json);
      if(data != 0){
        this.setState({
           data,
           scanIndex: index
         }, () =>{
          console.log(this.state.data);
         })   
      } else {
        console.log(data)
      }

  }

  render(){
    
    const cyberScore = this.state.data ? (this.state.data.reduce((acc, host) => {
          return Number( host.cvssScore) + acc;
        }, 0) / (this.state.data.filter((d) => d.cvssScore > 0 ).length) ).toFixed(2) : "N/A";

    const currentScanDate = this.state.scanIndex != null && this.state.scans.length != 0  ? this.state.scans[this.state.scanIndex].date : "N/A"

  	return(
      <MainContent>
      <div className={"detection-container"}>
        
        <Row className="cs-button-container"> 
        <Col md={5} sm={6}  className={"date-select"}>
        <h6>Select Date: </h6>
        { this.state.scanIndex != null && this.state.scans.length != 0 ? 
               ( <Form.Control as="select" value={this.state.scanIndex} style={{ maxWidth: "65%", float: "right"}} onChange={ (e) => this.handleScanChange(e)  } >
                   {
                     this.state.scans.map( (k, i) => {
                      return <option  key={k.date} value={i}>{ moment(k.date).format('MMM DD, YYYY - h:mm:ss a ') }</option> 
                     })
                   }
                 </Form.Control>) : <div></div>
        }
        </Col>

        <Col md={4} sm={6}  className={"cyber-score"} data-tip={tooltipText.cvss }>

          <h6>Cyber Score: </h6><span style={{color: colorClassCVSS(cyberScore).text, 
                                         backgroundColor: colorClassCVSS(cyberScore).bg }}>{`${cyberScore} ${colorClassCVSS(cyberScore).level}`}</span>
          
        </Col>
        <Col md={3} sm={4} className={"download-button"} >
          <CsvDownload 
                        disabled={this.state.data == undefined }
                        data={ this.state.data != undefined ? this.mutateDataForCSV(this.state.data) : null }
                        filename={`${this.props.user.company.name} - TED Cyber Discovery - ${moment(currentScanDate).format("YYYY-MM-DD")}.csv`}
                        className={"cs-button"}
                        style={{
                          fontSize: "12pt",
                          fontWeight: 600,
                          padding: "10px",
                          outline: 0,

                        }}
            >{this.state.data == undefined ? "No Report Available" : "Download Report" }<FontAwesomeIcon className="dl-icon" icon={faDownload} /></CsvDownload>
            </Col>
        </Row>
        <div className={'detection-table-container'}>
        	
          <ul className={'host-level-ul'}>
            <li className={'host-level-headers'}>
              <Row>
              	<Col sm={2} onClick={() => this.handleColumnSort(1)} > Host <FontAwesomeIcon style={{fontSize: "14pt", lineHeight: "3.5em", transform: ( this.state.sortBy != 1 ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} icon={faCaretUp}></FontAwesomeIcon> </Col>
    	          <Col sm={2} onClick={() => this.handleColumnSort(2)} > OS <FontAwesomeIcon style={{fontSize: "14pt", lineHeight: "3.5em", transform: ( this.state.sortBy != 2 ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} icon={faCaretUp}></FontAwesomeIcon> </Col>
    	          <Col sm={2} onClick={() => this.handleColumnSort(4)} > Ports <FontAwesomeIcon style={{fontSize: "14pt", lineHeight: "3.5em", transform: ( this.state.sortBy != 4 ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} icon={faCaretUp}></FontAwesomeIcon> </Col>
                <Col sm={2} onClick={() => this.handleColumnSort(5)} > Vulnerabilites <FontAwesomeIcon style={{fontSize: "14pt", lineHeight: "3.5em", transform: ( this.state.sortBy != 5 ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} icon={faCaretUp}></FontAwesomeIcon> </Col>
    	          <Col sm={2}
                     data-tip={tooltipText.cvss }
                     style={{textAlign: "center"}} onClick={() => this.handleColumnSort(3)} > CVSS <FontAwesomeIcon style={{fontSize: "14pt", lineHeight: "3.5em", transform: ( this.state.sortBy != 3 ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} icon={faCaretUp}></FontAwesomeIcon> </Col>
    	          <Col sm={1} 
                     style={{textAlign: "right"}} 
                     onClick={ () => this.setOpen(!this.state.open) } ><FontAwesomeIcon 
                     style={{fontSize: "20pt", lineHeight: "3.5em", transform: (this.state.open ? "rotate(180deg)" : "rotate(0deg)"), cursor: "pointer"  }} 
                     icon={faAngleDown}></FontAwesomeIcon> 
                </Col>
              </Row>
            </li>
            
            {
              (this.state.data != null) ?
                this.state.data.map((d, i) => {
                  return <ListItem key={`${d +"-" +i}`} host={d} openTab={ i == 0 ? true : false} openAll={this.state.open} />
                }) : (this.state.loading ? (<li>Loading...</li>) : (<li>There are currently no Cyber-Detect scans available... </li>)    )
             
            }

          </ul>

        </div>	
      </div>
      </MainContent>
  	)
  }

}


const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyberScan)