import React from 'react';
import {size, last} from 'lodash';
import {NavLink, withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import MainContent from '../../components/Layouts/MainContent'
import OverlaySpinner from '../../components/Charts/ChartUI/OverlaySpinner'
import './playbook.scss';
import { deriveUnitCost } from '../../common/utils/deriveUnitCost';
import { valueLegendFormat, currencyFormatter } from '../../common/utils/currencyFormatter';
import {
  getReportsForDSA,
  getMSCompScanList,
  getCyberScans
} from '../../utilities/s2APIHelpers';
import {
  nmapParseTab
} from '../../utilities/dataMutators';
import * as actions from '../../redux/actions/index';

import crosswalk from '../../../static/images/crosswalkPhotoMod.png';
import karlsgateImage from '../../../static/images/karlsgate.jpg';
import ModelFactoryImage from '../../../static/images/model-factory.jpg';
import BiosenseLogoImg from '../../../static/images/biosense-logo.jpg';
import DataSetsContacts from '../../../static/images/datasets.jpg';


//this data will likely be dynamic in the future, sent from the back end based upon microservice subscriptions.
const playbookData = {

    "/risk-exposure-playbook" : { 

    description: `Know the dollar amount of your financial exposure and account for it, insure against 
                  it, or remediate. Take control of data protection with a suite of AI-powered solutions, 
                  featuring always-on data monitoring so you can focus on goals and rest better at night. 
                  Ensure that data sprawl and exposure are within the threshold of acceptance`,    
    cards: [
    
    {
        title: "Company Settings",
        image: "https://www.trovo.ai/wp-content/uploads/2019/09/scale-venture.jpg",
        description: `Align and configure your company’s sector adjustment, 
                      which is based on the top five Cyber Insurance carriers averaged cost per 
                      cyber breach incident, enter your current Cyber Insurance policy information, 
                      and other company settings.`,
        link: "/settings",
        kpi: "Coverage",
        notifications: 0
    },{
        title: "Cyber Discovery",
        image: "https://www.trovo.ai/wp-content/uploads/2019/09/scale-infrastructure.jpg",
        description: `Understand the severity of software vulnerabilities within your data supply 
                      chain by scanning for known weaknesses and exposures. Use to better determine 
                      your cyber breach probability and discover unknown datastores and services operating 
                      within your critical infrastructure.`,
        link: "/detection-scan",
        kpi: "Cyber Score",
        notifications: 3
    }, {
        title: "Data Surface",
        image: "https://www.trovo.ai/wp-content/uploads/2019/09/design-data-product.jpg",
        description: `Know what you have. Find, model, and monitor every piece of your structured 
                      and semi-structured data sets, from null sets to the highly-sensitive.`,
        link: "/data-surface",
        kpi: "Data Sprawl",
        notifications: 3
    },{
      title: "Insights",
      image: "https://www.trovo.ai/wp-content/uploads/2019/09/insights-platform.jpg",
      description: `Your dashboard to learn, monitor, and remediate your risk exposure. View gross 
                    and net adjusted risk exposure metrics across your cyber and data scans. Download 
                    your remediation report and plan remediation actions.`,
      link: "/risk-exposure-insights",
      kpi: "NARE",
      notifications: 0
    },{title: null}, {title: null}
    
    ]},

"/growth-playbook" : {

  description: `We believe the future belongs to the most nimble and forward-thinking businesses. 
                Through the holistic design of business operations focused on data science, we empower 
                ompanies to realize their data’s value and leverage its use for significant returns. 
                Our open ML Platform is built with the latest AI microservices to prepare your business 
                to compete.`,  
  
  cards:  [

  {
    title: "Auger AI",
    image: "https://www.trovo.ai/wp-content/uploads/2019/09/data-risk-reduction.jpg",
    description: `Auger offers the industry's most accurate AutoML. Auger’s patented Bayesian 
                  optimization search of ML algorithm and hyperparameter combinations builds the 
                  best possible predictive models faster to accelerate your growth lifecycle.`,
    link: "/auger-project",
    notifications: 3
  },
  {
    title: "useAIble",
    image: "https://www.trovo.ai/wp-content/uploads/2019/09/design-ecosystem.jpg",
    description: `useAIble uses a non-statistical approach to machine learning designed instead to 
                  be used for business logic. Now you can use machine learning to solve problems where 
                  mans or processes introduce problems that can't be described by statistics.`,
    link: "#",
    notifications: 0
  },
  {
    title: "Reveal Why",
    image: "https://www.trovo.ai/wp-content/uploads/2019/09/energy-optimization.jpg",
    description: `Use AI, natural language processing, and advanced data science techniques to 
                  solve your biggest brand, reputation, and customer experience questions. Giving 
                  you the right solutions to an ever-changing market.`,
    link: "/reveal-why",
    notifications: 0
  },
  {
    title: "Model Factory",
    image: ModelFactoryImage,
    description: `Rapidly prototype and iterate supervised learning models with an intuitive pipeline system allowing for data extraction and transformation in application, reducing data preparation work and decreasing model development time.`,
    link: "https://modelfactory.prod.aws.quickspark.com/",
    notifications: 0
  },
  {
    title: "Data Exchange",
    image: karlsgateImage,
    description: `Share your data securely with any partner without exposing PII. Karlsgate hashes any designated PII fields, comparing them with other datasets to find commonalities, while ensuring your data security by never extracting records from your systems.`,
    link: "https://karlsgate.com/",
    notifications: 0
  },
  {
    title: "Project Sunkisst",
    image: DataSetsContacts,
    description: `The most powerful marketing data asset in the world that fuels unlimited lead growth for any data dependent service provider who is part of the team.`,
    link: "/project-sunkisst",
    notifications: 0
  },{title: null}, {title: null}, {title: null}, {title: null}
]}, 
"/people-playbook": {
    description: `People are an organization's most valuable asset, and ensuring that all 
                  members are in positions where their talents, temperament, and career goals
                  are aligned to contribute most effectively is key to bridging organizational success 
                  with a positive and enriching company culture.`,
    cards: [
      {
        title: "Assessment",
        image: "https://get.pxhere.com/photo/african-american-afro-american-analyzing-black-people-brainstorming-business-business-people-caucasian-coffee-communication-computer-cup-digital-device-discussing-drink-laptop-man-manager-marketing-meeting-mug-notebook-office-partnership-people-planning-presentation-serious-strategy-suit-talking-team-teamwork-technology-togetherness-training-video-conference-video-call-woman-worker-working-workspace-collaboration-businessperson-conversation-management-white-collar-worker-1434697.jpg",
        description: `Showing the degree to which you have WorkStyle alignment or misalignment causing WorkStyle Stress.`,
        link: "https://tap.trovo.ai",
        notifications: 2
      },
      {
        title: "Alignment",
        image: crosswalk,
        description: `Advanced talent modeling tools to gain insights into the organization's overall alignment to know which departments, roles and business processes are most aligned to their work.`,
        link: "https://tap.trovo.ai/alignment",
        notifications: 6
      },
      {
        title: "Marketplace",
        image: "https://images.unsplash.com/photo-1528901166007-3784c7dd3653?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80",
        description: ` Find out which employees within your organization best fit for new roles.`,
        link: "https://tap.trovo.ai/marketplace",
        notifications: 0
      },
      {
        title: "Health Access Manager",
        image: BiosenseLogoImg,
        description: `Biosense Infotech enables access to reliable testing and event access within both private and public sectors to rebuild trust and promote public safety.`,
        link: "/biosense",
        notifications: 0
      },{title: null}, {title: null},

    ]
}

}
 

class Playbook extends React.Component { 

  constructor(props){
    super(props);
    this.state ={
      currentScan: null,
      scanStats:  null,
      currentScanData: null,
      cvss: null,
      loading: true
    }
  }

  async componentDidMount(){

    //will need to extract recent microservice report data for displaying KPI's in the card widgets.
    const user = this.props.user;
    await this.props.onFetchS2Data({company_id: user.company.id});
    if(user != undefined){
      try{
        const cyberReports = await getCyberScans(user.company.id);
        var cvss ="NA"
        if(cyberReports.length >  0){
          const json = JSON.parse(cyberReports[cyberReports.length - 1].nmapdata);
          const data = nmapParseTab(json);
          cvss = Math.max.apply(Math, data.map((host) => parseFloat(host.cvssScore) ) )
        }
      } catch(e){
        console.log(e)
      }
      
      if(this.props.s2data != undefined && size(this.props.s2data) > 0 ){
        const s2data = this.props.s2data;
        const current = s2data[s2data.length - 1];
        const scanStats = s2data.filter((d) => d.id == current.id )[0].data_stores.scanStats;
        this.setState({
          currentScan: current.id,
          scanStats:  scanStats,
          currentScanData: current,
          cvss,
          loading: false
        })
      }
      
    }
  }

  render(){

  console.log(this.props.s2data)
  const pageData = playbookData[this.props.location.pathname]; 
  var KPI = {}
  if (this.props.location.pathname == "/risk-exposure-playbook"){
    KPI["Coverage"] = this.props.user ? currencyFormatter(this.props.user.insurances.reduce((a,v) =>{ return a + parseFloat(v.amount) }, 0)) : "";
    KPI["Cyber Score"] = this.state.cvss ? this.state.cvss : "";
    KPI["NARE"] = this.state.scanStats ? currencyFormatter(( this.state.scanStats.gre.result * this.props.unitCost )- this.props.user.insurances.reduce((a,v) =>{ return a + parseFloat(v.amount) }, 0)) : "";
    KPI["Data Sprawl"] = (this.state.scanStats ? this.state.scanStats.redundancy.result+ "%" : "")
  }


  return ( 
  <MainContent>  
   <div className="container-fluid">
    <div className="sub-header-section">
        <p>{pageData.description}</p>
    </div>
    <div className="main-surface-wrapper">
    {

       pageData.cards.map((d, i) => {
         if(d.title){
           return (                   
             <div className="surface-section" key={i + " : " + d.title }>
                <a href={d.link ? d.link : '/'}  className="surface-link" >
                  <span className={"point " + (d.notifications > 0 ? "red" : "") }>{d.notifications}</span>
                  <div className="image-wrap">
                      <img src={d.image}/>
                  </div>
                  <div className="loader">
                      <span></span>
                  </div>
                  <div className="content-section">
                      <span><h4 id="kpi-title">{d.kpi}</h4> 
                            {
                             KPI[d.kpi] != "" ?
                             <h4 id="kpi-value">{KPI[d.kpi]}</h4>
                               :
                             <OverlaySpinner 
                                  loading={this.state.loading && !this.props.loading ? true : false }
                                  top={"0px"}
                                  left={"5px"}
                                  offsetRight={"10px"}
                                  offsetTop={"35px"}
                                  overlayColor={"rgba(25,30,36, 0.5)"}
                                  position={"absolute"}
                                  text={"Loading KPIs"}
                                  spinnerSize={"10px"}
                                  fontSize={"12pt"}
                             />
                             } 
                      </span>
                      <p></p>
                  </div>
                  <div className="service-content">
                      <h4>{i+1} {d.title}</h4>
                      <p>{d.description}</p>
                  </div>
                </a>
              </div>
               
           )
         
         } else {
          return ( <div className="surface-section" style={{minWidth:"250px"}} key={i} ></div> )
        } 
       })

    }
            
    </div>
</div>
</MainContent>
  )
  }
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  s2data: state.s2data.data,
  s2loading: state.s2data.loading,
  unitCost: deriveUnitCost(state.auth.user.adjustments), 
});

const mapDispatchToProps = (dispatch) => ({
  onFetchS2Data: (fields) => dispatch(actions.fetchS2Data(fields)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Playbook) )