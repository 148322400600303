import axios from 'axios';
import axiosAuth from '../axiosInstance';
import { API_URL } from '../../services/config';
import moment from 'moment';

export const AUTH_START = 'AUTH_START';
export const FORM_START = 'FORM_START';
export const FORM_COMPLETE = 'FORM_COMPLETE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const DELETE_USER = 'DELETE_USER';
export const AUTH_FAIL = 'AUTH_FAIL';
export const CLEAR_ER_MSG = 'CLEAR_ER_MSG';
export const UPDATE_USER = 'UPDATE_USER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
// export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_MESSAGE = 'SET_MESSAGE';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_CONFIRM = 'USER_FORGOT_PASSWORD_CONFIRM';
export const GET_COMPANY_INSURANCE = 'GET_COMPANY_INSURANCE';
export const ADD_COMPANY_INSURANCE = 'ADD_COMPANY_INSURANCE';
export const DELETE_COMPANY_INSURANCE = 'DELETE_COMPANY_INSURANCE';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';

export const authStart = () => ({
	type: AUTH_START
});

export const formStart = () => ({
	type: FORM_START
});

export const fetchStart = () => ({
	type: FETCH_START
});

export const fetchFail = (errorMsg) => ({
	type: FETCH_FAIL,
	errorMsg
});

export const formComplete = () => ({
	type: FORM_COMPLETE
});

export const authSuccess = (token, user) => ({
	type: AUTH_SUCCESS,
	idToken: token,
	user
});

export const getUsersData = (userDetails) => ({
	type: GET_USER_DATA,
	userDetails
});

export const authFail = (errorMsg) => ({
	type: AUTH_FAIL,
	errorMsg
});

export const setMessage = (message) => ({
	type: SET_MESSAGE,
	message
});

export const updateUserData = (user) => ({
	type: UPDATE_USER,
	user
});

export const getCompInsurance = (insurance) => ({
	type: GET_COMPANY_INSURANCE,
	insurance
});

export const addCompInsurance = () => ({
	type: ADD_COMPANY_INSURANCE
});
export const deleteCompInsurance = () => ({
	type: DELETE_COMPANY_INSURANCE
});

export const deleteUser = () => ({
	type: DELETE_USER
});

export const clearErrMsgData = () => ({
	type: CLEAR_ER_MSG,
	errorMsg: '',
	message: ''
});

export const logout = () => {
	localStorage.removeItem('idToken');
	localStorage.clear();
	return {
		type: AUTH_LOGOUT
	};
};

export const checkAuthTimeout = (expirationTime) => (dispatch) => {
	setTimeout(() => {
		axios
			.get(`${API_URL}/auth/logout/`)
			.then(() => {
				dispatch(logout());
			})
			.catch(() => {
				dispatch(logout());
			});
	}, expirationTime * 1000);
};

export const signIn = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axios
		.post(`${API_URL}/auth/login/`, params)
		.then((res) => {
			const token = res.data.token;
			const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
			localStorage.setItem('expirationDate', expirationDate);
			localStorage.setItem('idToken', token);
			dispatch(authSuccess(res.data.token, res.data.user));
			dispatch(checkAuthTimeout(3600));
			callback();
		})
		.catch((err) => {
			console.log(err.response, 'signIn');
			if (err.response.data.non_field_errors) {
				dispatch(authFail(err.response.data.non_field_errors[0]));
			} else {
				dispatch(authFail(err.response.data));
			}
		});
};

export const signUp = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axios
		.post(`${API_URL}/auth/registration/`, params)
		.then((res) => {
			const token = res.data.token;
			const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
			localStorage.setItem('expirationDate', expirationDate);
			localStorage.setItem('idToken', token);
			dispatch(authSuccess(token, res.data.user));
			dispatch(checkAuthTimeout(3600));
			callback();
		})
		.catch((err) => {
			console.log(err.response, 'signUp');
			dispatch(authFail(err.response.data));
		});
};

export const editUser = (userId) => (dispatch) => {
	dispatch(authStart());
	console.log(userId, 'ddddd');
};

export const onUpdateUser = (fields, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post(`/companies/${fields.company_id}/company-setting/`, fields)
		.then(async () => {
			const token = localStorage.getItem('idToken');
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			await axiosAuth
				.get(`/auth/user/`)
				.then((res) => {
					dispatch(authSuccess(token, res.data));
					dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
					callback();
				})
				.catch((err) => {
					dispatch(fetchFail(err.response.data));
				});
		})
		.catch((err) => {
			dispatch(authFail(err.message));
		});
};

export const onDeleteUser = (userId, callback) => async (dispatch) => {
	await axiosAuth
		.delete(`/user/${userId}`)
		.then(() => {
			dispatch(deleteUser());
			callback();
		})
		.catch((err) => {
			dispatch(authFail(err.message));
		});
};

export const saveCompanyInsurance = (fields) => async (dispatch) => {
	dispatch(addCompInsurance());
	await axiosAuth
		.post('/company-insurances/', fields)
		.then(async () => {
			const token = localStorage.getItem('idToken');
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			await axiosAuth
				.get(`/auth/user/`)
				.then((res) => {
					dispatch(authSuccess(token, res.data));
					dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
				})
				.catch((err) => {
					dispatch(fetchFail(err.response.data));
				});
		})
		.catch((err) => {
			dispatch(formComplete(err.message));
		});
};

export const deleteCompanyInsurance = (params) => async (dispatch) => {
	dispatch(deleteCompInsurance());
	await axiosAuth
		.delete(`/company-insurances/${params.id}/`)
		.then(async () => {
			const token = localStorage.getItem('idToken');
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			await axiosAuth
				.get(`/auth/user/`)
				.then((res) => {
					dispatch(authSuccess(token, res.data));
					dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
				})
				.catch((err) => {
					dispatch(fetchFail(err.response.data));
				});
		})
		.catch((err) => {
			dispatch(formComplete(err.message));
		});
};

export const authCheckState = () => {
	return (dispatch) => {
		const token = localStorage.getItem('idToken');
		if (token === undefined) {
			axios
				.get(`${API_URL}/auth/logout/`)
				.then(() => {
					dispatch(logout());
				})
				.catch(() => {
					dispatch(logout());
				});
		} else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			if (expirationDate <= new Date()) {
				axios
					.get(`${API_URL}/auth/logout/`)
					.then(() => {
						dispatch(logout());
					})
					.catch(() => {
						dispatch(logout());
					});
			} else {
				axiosAuth
					.get(`/auth/user/`)
					.then((res) => {
						dispatch(authSuccess(token, res.data));
						dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
					})
					.catch((err) => {
						dispatch(fetchFail(err.response.data));
					});
			}
		}
	};
};

export const clearAllErrMsg = () => {
	return (dispatch) => {
		dispatch(clearErrMsgData());
	};
};

export const userFetchStart = () => ({
	type: FETCH_USER_START
});

export const userFetchFail = (errorMsg) => ({
	type: FETCH_USER_FAIL,
	errorMsg
});

export const fetchComplete = (users) => ({
	type: FETCH_USER_SUCCESS,
	users: users
});

export const addStart = () => ({
	type: ADD_USER_START
});

export const addFail = (message) => ({
	type: ADD_USER_FAIL,
	errMsg: message
});

export const addComplete = (user) => ({
	type: ADD_USER_SUCCESS,
	user: user
});

export const editStart = () => ({
	type: EDIT_USER_START
});

export const editFail = (message) => ({
	type: EDIT_USER_FAIL,
	errMsg: message
});

export const editComplete = (user) => ({
	type: EDIT_USER_SUCCESS,
	user: user
});

export const fetchUsers = () => (dispatch) => {
	dispatch(userFetchStart());
	axiosAuth
		.get(`/user`)
		.then((resData) => {
			dispatch(fetchComplete(resData.data.data));
		})
		.catch((err) => {
			dispatch(userFetchFail(err.message));
		});
};

export const addUser = (user) => (dispatch) => {
	dispatch(addStart());
};

export const editUserDetails = (user, id) => (dispatch) => {
	dispatch(editStart());
	axiosAuth
		.put(`/user/${id}`, user)
		.then((resData) => {
			dispatch(editComplete(resData.data.data));
		})
		.catch((err) => {
			dispatch(editFail(err.message));
		});
};

export const forgetPassword = (params, callback) => (dispatch) => {
	dispatch(formStart());
	axios
		.post(`${API_URL}/auth/password/reset/`, params)
		.then((resData) => {
			console.log(resData, 'resData');
			dispatch(authFail('a verification code is sent your email address.'));
			callback();
		})
		.catch((err) => {
			dispatch(editFail(err.message));
		});
};

export const confirmForgetPassword = (params, callback) => {
	return async (dispatch) => {
		dispatch(formStart());
		Auth.forgotPasswordSubmit(params.email, params.code, params.new_password)
			.then((data) => {
				dispatch(authFail('Your password has been changed successfully!'));
				callback();
			})
			.catch((err) => {
				console.log(err.message, 'confirmForgetPassword');
				dispatch(authFail(err.message));
			});
	};
};
