import React from 'react';
import './auger-setup.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomTableWithPagination from '../../components/Admin/CustomTable';
import DropDown from '../../components/Admin/DropDown';
import MainContent from '../../components/Layouts/MainContent';
import * as actions from '../../redux/actions/index';

const actionOptions = [ { label: 'Start', value: 1 }, { label: 'Delete', value: 2 } ];

//Main exported component
class AugerSetup extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const user = this.props.user;
		if (user.user_role === 1) {
			this.props.fetchAllAugerProjects();
		} else {
			this.props.fetchAugerProjects(user.company.id);
		}
	}

	actionHandler = (e, project) => {
		let val = e.target.value;
		if (val == 1) {
			this.props.startAugerProject(project);
		} else if (val == 2) {
		}
	};

	renderActions = (project) => {
		return (
			<React.Fragment>
				<DropDown
					options={actionOptions}
					placeholder={'Select Action'}
					onItemSelected={(e) => this.actionHandler(e, project)}
				/>
			</React.Fragment>
		);
	};

	renderName = (id, name) => {
		return <Link to={`/project-details/${id}`}>{name}</Link>;
	};

	render() {
		const { augerProjects } = this.props;
		const columns = [
			{ dataField: 'name', text: 'Project Name', sort: true },
			{ dataField: 'workers', text: 'Workers' },
			{ dataField: 'status', text: 'Status' },
			{ dataField: 'startedby', text: 'Started By' },
			{ dataField: 'updated', text: 'Updated' },
			{ dataField: 'action', text: 'Action' }
		];
		let data = [];
		if (augerProjects.length > 0) {
			data = augerProjects.map((project) => ({
				id: project.id,
				name: this.renderName(project.id, project.name),
				workers: project.workers_count,
				status: project.status,
				startedby: project.starter_user_id,
				updated: project.updated_at,
				action: this.renderActions(project)
			}));
		} else {
			data = [];
		}

		return (
			<MainContent>
			<div className="contaner-fluid auger-container">
				<h3>Projects</h3>
				<div className="auger-setup-form">
					<Link
						style={{ float: 'right', margin: 10 }}
						to={'/auger-project/add'}
						className="btn btn-secondary"
					>
						Create Project
					</Link>
					<CustomTableWithPagination columns={columns} rows={data} />
				</div>
			</div>
			</MainContent>
		);
	}
}

const mapStateToProps = (state) => ({
	errorMsg: state.auth.errorMsg,
	user: state.auth.user,
	augerProjects: state.auger.augerData.projects
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllAugerProjects: () => dispatch(actions.fetchAllAugerProjects()),
	fetchAugerProjects: (company_id) => dispatch(actions.fetchAugerProjects(company_id)),
	addAugerProject: (params, callback) => dispatch(actions.addAugerProject(params, callback)),
	startAugerProject: (params, callback) => dispatch(actions.startAugerProject(params, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AugerSetup);
