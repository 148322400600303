import React from 'react';
import './auger-setup.scss';
import { Navbar, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import MainContent from '../../components/Layouts/MainContent';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import size from 'lodash/size';
import moment from 'moment';
import {
	faFilePdf,
	faChartArea,
	faEllipsisV,
	faFlask,
	faTrash,
	faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import CustomTableWithPagination from '../../components/Admin/CustomTable';
import AddExperimentModal from './AddExperimentModal';
import TrialMetricsModal from './Graphs/TrialMetricsModal';
import * as actions from '../../redux/actions/index';
import downloadPDF from './Graphs/download_pdf';


// Main exported component
class AugerProjectDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			isModal: false,
			isMetricsModal: false,
			predErrorData: {},
			featureImportanceData: {},
			modelType: '',
			classificationData: {}
		};
	}

	componentDidMount() {
		const project_id = this.props.match.params.id;
		const { user } = this.props;
		if (user.user_role === 1) {
			this.props.fetchAllAugerProjects();
		} else {
			this.props.fetchAugerProjects(user.company.id);
		}
		this.props.fetchAugerProjectDataSets(project_id);
		this.props.fetchAugerExperiments(project_id);
		this.props.getDeploymentsModelData(project_id);
	}

	toggle = (tab) => {
		this.setState({ activeTab: tab });
	};

	toggleModal = () => {
		this.setState((state) => ({ isModal: !state.isModal }));
	};

	toggleMetricsModal = () => {
		this.setState((state) => ({ isMetricsModal: !state.isMetricsModal }));
	};

	handleExperiment = () => {
		this.setState({ isModal: true });
	};

	notifyPDFSuccess = () => toast.success('Successfully Metrics PDF Download');

	notifyPDFError = () => toast.error('Metrics PDF generation has failed');

	notifyMetricsError = () => toast.error('sorry metrics not available now. can you try again.');

	notifyError = (msg) => toast.error(msg);

	notifySuccess = (msg) => toast.success(msg);

	downloadPDFTrinalMetrocs = (expSession) => {
		try {
			if (expSession.status === 'completed') {
				const { project_id } = expSession;
				const { project_file_id } = expSession;
				const { experiment_id } = expSession;
				const project = this.props.augerProjects.filter((p) => p.id === project_id);
				const projectFile = this.props.datasets.filter((f) => f.id === project_file_id);
				const experiment = this.props.experiments.filter((e) => e.id === experiment_id);
				this.props.getAugerTrialMetrics(expSession.id, () => {
					const trial = this.props.trials;
					const { trial_metrics } = this.props;
					if (size(trial_metrics) > 0) {
						const metircsType = trial_metrics.filter((d) => d.metrics.model_type === 'classification');
						if (size(metircsType) > 0 && metircsType[0].metrics.model_type === 'classification') {
							const roc_curve_data = trial_metrics.filter((d) => size(d.metrics.roc_curve_data) > 0);
							const roc_labels = trial_metrics.filter((d) => size(d.metrics.roc_labels) > 0);
							const classification_report = trial_metrics.filter(
								(d) => size(d.metrics.classification_report) > 0
							);
							const class_balance_data = trial_metrics.filter(
								(d) => size(d.metrics.class_balance_data) > 0
							);
							const confusion_matrix_data = trial_metrics.filter(
								(d) => size(d.metrics.confusion_matrix_data) > 0
							);
							const feature_importance_data = trial_metrics.filter(
								(d) => size(d.metrics.feature_importance_data) > 0
							);
							const trialMetrics = {
								...metircsType[0],
								metrics: {
									...metircsType[0].metrics,
									roc_curve_data:
										size(roc_curve_data) > 0 ? roc_curve_data[0].metrics.roc_curve_data : {},
									roc_labels: size(roc_labels) > 0 ? roc_labels[0].metrics.roc_labels : {},
									classification_report:
										size(classification_report) > 0
											? classification_report[0].metrics.classification_report
											: {},
									class_balance_data:
										size(class_balance_data) > 0
											? class_balance_data[0].metrics.class_balance_data
											: {},
									confusion_matrix_data:
										size(confusion_matrix_data) > 0
											? confusion_matrix_data[0].metrics.confusion_matrix_data
											: {},
									feature_importance_data:
										size(feature_importance_data) > 0
											? feature_importance_data[0].metrics.feature_importance_data
											: {},
									feature_list:
										size(feature_importance_data) > 0
											? feature_importance_data[0].metrics.feature_list
											: []
								}
							};
							const data = {
								trial: trial[0],
								trial_metrics: trialMetrics,
								projectName: project[0],
								projectFile: projectFile[0],
								experiment: experiment[0],
								experimentSession: expSession,
								onSuccess: this.notifyPDFSuccess,
								onError: this.notifyPDFError
							};
							downloadPDF(data);
						} else {
							const predErrorData = trial_metrics.filter(
								(d) => size(d.metrics.prediction_errors_data) > 0
							);
							const featureImportData = trial_metrics.filter(
								(d) => size(d.metrics.feature_importance_data) > 0
							);
							const trialMetrics = {
								...predErrorData[0],
								metrics: {
									...predErrorData[0].metrics,
									feature_importance_data:
										size(featureImportData) > 0
											? featureImportData[0].metrics.feature_importance_data
											: {},
									feature_list:
										size(featureImportData) > 0 ? featureImportData[0].metrics.feature_list : {}
								}
							};
							const data = {
								trial: trial[0],
								trial_metrics: trialMetrics,
								projectName: project[0],
								projectFile: projectFile[0],
								experiment: experiment[0],
								experimentSession: expSession,
								onSuccess: this.notifyPDFSuccess,
								onError: this.notifyPDFError
							};
							downloadPDF(data);
						}
					} else {
						this.notifyMetricsError();
					}
				});
			} else {
				this.notifyMetricsError();
			}
		} catch (error) {
			console.log(error, 'Metrics PDF generation has failed');
		}
	};

	showTrialMetrics = (expSession) => {
		if (expSession.status === 'completed') {
			this.props.getAugerTrialMetrics(expSession.id, () => {
				const { trial_metrics } = this.props;
				if (size(trial_metrics) > 0) {
					this.setState({
						isMetricsModal: true,
						modelType: expSession.model_type
					});
					const metircsType = trial_metrics.filter((d) => d.metrics.model_type === 'classification');
					if (size(metircsType) > 0 && metircsType[0].metrics.model_type === 'classification') {
						console.log(trial_metrics, 'metircsType');
						const roc_curve_data = trial_metrics.filter((d) => size(d.metrics.roc_curve_data) > 0);
						const roc_labels = trial_metrics.filter((d) => size(d.metrics.roc_labels) > 0);
						const classification_report = trial_metrics.filter(
							(d) => size(d.metrics.classification_report) > 0
						);
						const class_balance_data = trial_metrics.filter((d) => size(d.metrics.class_balance_data) > 0);
						const confusion_matrix_data = trial_metrics.filter(
							(d) => size(d.metrics.confusion_matrix_data) > 0
						);
						const feature_importance_data = trial_metrics.filter(
							(d) => size(d.metrics.feature_importance_data) > 0
						);
						this.setState({
							classificationData: {
								roc_curve_data:
									size(roc_curve_data) > 0 ? roc_curve_data[0].metrics.roc_curve_data : {},
								roc_labels: size(roc_labels) > 0 ? roc_labels[0].metrics.roc_labels : {},
								classification_report:
									size(classification_report) > 0
										? classification_report[0].metrics.classification_report
										: {},
								class_balance_data:
									size(class_balance_data) > 0
										? class_balance_data[0].metrics.class_balance_data
										: {},
								confusion_matrix_data:
									size(confusion_matrix_data) > 0
										? confusion_matrix_data[0].metrics.confusion_matrix_data
										: {},
								feature_importance_data:
									size(feature_importance_data) > 0
										? feature_importance_data[0].metrics.feature_importance_data
										: {},
								feature_list:
									size(feature_importance_data) > 0
										? feature_importance_data[0].metrics.feature_list
										: {}
							}
						});
					} else {
						const predErrorData = trial_metrics.filter((d) => size(d.metrics.prediction_errors_data) > 0);
						const featureImportData = trial_metrics.filter(
							(d) => size(d.metrics.feature_importance_data) > 0
						);
						this.setState({
							predErrorData: predErrorData[0].metrics.prediction_errors_data,
							featureImportanceData:
								size(featureImportData) > 0 ? featureImportData[0].metrics.feature_importance_data : {}
						});
					}
				} else {
					this.notifyMetricsError();
				}
			});
		} else {
			this.notifyMetricsError();
		}
	};

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	createExpreiment = async (params) => {
		const { user } = this.props;
		const scanDate = moment(report.scanDate).format('YYYY-MM-DD hh:mm:ss');
		await this.props.startAugerProject(params.project_id, async () => {
			await this.props.addAugerProjectOnTed({
				company_id: user.company.id,
				project_id: params.project_id,
				scan_date: scanDate,
				microservice: 3,
			});
			await this.sleep(30000);
			await this.props.addAugerProjectFile(params, async () => {
				await this.sleep(15000);
				const { transaction } = this.props;
				const data = {
					project_id: transaction.project_id,
					name: `${transaction.name}_${Date.now()}`,
					project_file_id: transaction.id
				};
				this.props.addAugerExperiments(data, async () => {
					// const transaction = this.props.transaction;
					// const model_settings = {
					//     scoring: 'r2',
					//     max_n_trials: 20,
					//     features: [
					//        { column_name: 'SalePrice', isTarget: true }
					//     ]
					//   }
					//   const bodyData = {
					//     experiment_id: transaction.id,
					//     status: "preprocess",
					//     model_type: 'regression',
					//     model_settings: model_settings
					//   }

					// this.props.addAugerExperimentSessions(bodyData, () => {
					//     console.log('sdfdsfsd');
					// })
					this.toggleModal();
				});
			});
		});
	};

	deployTrial = (expSession) => {
		if (expSession.status === 'completed') {
			this.props.deployTrialsAuger(expSession.id, () => {
				this.notifySuccess('Successfully Deploy Model');
			});
		} else {
			this.notifyError('Experiment not complete');
		}
	};

	actionExpreimentHandler = (e, exp) => {
		const val = e.target.value;
		if (val == 1) {
			console.log(exp, 'ssds');
		}
	};

	actionHandler = (e, exp) => {
		const val = e.target.value;
		if (val == 1) {
			console.log(exp, 'ssds');
		}
	};

	renderActions = (exp) => {
		return (
			<React.Fragment>
				<FontAwesomeIcon style={{ fontSize: '18px', width: '1.25em', cursor: 'pointer' }} icon={faTrash} />
			</React.Fragment>
		);
	};

	renderExpSessionActions = (expSession) => {
		return (
			<React.Fragment>
				<FontAwesomeIcon
					style={{ fontSize: '18px', width: '1.25em', cursor: 'pointer' }}
					icon={faFilePdf}
					onClick={() => this.downloadPDFTrinalMetrocs(expSession)}
					title="Download Metrics PDF"
				/>
				<FontAwesomeIcon
					style={{ fontSize: '18px', width: '1.25em', cursor: 'pointer' }}
					icon={faChartArea}
					onClick={() => this.showTrialMetrics(expSession)}
					title="View Metrics"
				/>
				<FontAwesomeIcon
					style={{ fontSize: '18px', width: '1.25em', cursor: 'pointer' }}
					icon={faCheckCircle}
					onClick={() => this.deployTrial(expSession)}
					title="Deploy Model"
				/>
			</React.Fragment>
		);
	};

	getExpSessionData = (row) => {
		this.props.getAugerExperimentSessions(row.last_experiment_session_id, () => {
			console.log('ok');
		});
	};

	render() {
		const {
			activeTab,
			isModal,
			isMetricsModal,
			predErrorData,
			featureImportanceData,
			modelType,
			classificationData
		} = this.state;
		const { datasets, experiments, augerlogs, expSessions, trial_metrics, deployModelData } = this.props;
		const project_id = this.props.match.params.id;
		const columns = [
			{ dataField: 'name', text: 'Name', sort: true },
			{ dataField: 'dataset', text: 'Dataset' },
			{ dataField: 'created', text: 'Created' },
			{ dataField: 'status', text: 'Status' },
			{ dataField: 'action', text: 'Action' }
		];
		const colExpSession = [
			{ dataField: 'modetype', text: 'Model Type' },
			{ dataField: 'target', text: 'Target' },
			{ dataField: 'scoring', text: 'Scoring' },
			{ dataField: 'topscore', text: 'Top Score' },
			{ dataField: 'created', text: 'Created' },
			{ dataField: 'status', text: 'Status' },
			{ dataField: 'action', text: 'Action' }
		];

		const depModelDataCol = [
			{ dataField: 'experiment', text: 'Experiment' },
			{ dataField: 'algorithm', text: 'Algorithm' },
			{ dataField: 'score', text: 'Score' },
			{ dataField: 'created', text: 'Created' },
			{ dataField: 'status', text: 'Status' }
		];

		let data = [];
		if (experiments.length > 0) {
            //temporary filter until better flow available.
			data = experiments.filter((d) => d.last_experiment_session_status == "completed" ).map((exp) => ({
				id: exp.id,
				name: exp.name,
				dataset: exp.project_file_id,
				created: exp.created_at,
				status: exp.last_experiment_session_status,
				action: this.renderActions(exp),
				last_experiment_session_id: exp.last_experiment_session_id
			}));
		} else {
			data = [];
		}

		let dataExpSession = [];
		if (expSessions.length > 0) {
			dataExpSession = expSessions.map((expSession) => ({
				id: expSession.id,
				modetype: expSession.model_type ? expSession.model_type : '-',
				target: expSession.model_settings.evaluation_options
					? expSession.model_settings.evaluation_options.targetFeature
					: '-',
				scoring: expSession.model_settings.evaluation_options
					? expSession.model_settings.evaluation_options.scoring
					: '-',
				topscore: expSession.top_score_value ? parseFloat(expSession.top_score_value).toFixed(4) : '-',
				created: expSession.created_at,
				status: expSession.status ? expSession.status : '-',
				action: this.renderExpSessionActions(expSession)
			}));
		} else {
			dataExpSession = [];
		}

		const dataExpSessionRow = {
			parentClassName: 'parent-expand-active',
			renderer: (row) => <CustomTableWithPagination columns={colExpSession} rows={dataExpSession} />,
			showExpandColumn: true,
			onlyOneExpanding: true,
			onExpand: (row, isExpand, rowIndex, e) => {
				this.getExpSessionData(row);
			},
			expandBodyClass: "expanded-row" 
		};

		let depModelDataRow = [];
		if (deployModelData.length > 0) {
			depModelDataRow = deployModelData.map((deModel) => ({
				id: deModel.id,
				experiment: `${deModel.experiment.name} # ${deModel.experiment_session.number}`,
				algorithm: deModel.hyperparameter.algorithm_name,
				score: `${deModel.score_name} : ${parseFloat(deModel.score_value).toFixed(4)}`,
				created: deModel.created_at,
				status: size(deModel.active_pipelines) > 0 ? deModel.active_pipelines[0].status : '---'
			}));
		} else {
			depModelDataRow = [];
		}
		return (
		  <MainContent>
			<div className="contaner-fluid auger-container">
				<h3>
					<Link to="/auger-project">Projects</Link>
				</h3>
				<div className="auger-setup-form">
					<ToastContainer position="top-center" />
					<Navbar light expand="md">
						<Nav tabs>
							<NavItem>
								<NavLink
									className={activeTab === '1' ? 'active' : ''}
									onClick={() => {
										this.toggle('1');
									}}
								>
									Experiment
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === '2' ? 'active' : ''}
									onClick={() => {
										this.toggle('2');
									}}
								>
									New Experiment
								</NavLink>
							</NavItem>
						</Nav>
						<Nav tabs className="align-self-end flex-grow-1 justify-content-end">
							<NavItem>
								<NavLink
									className={activeTab === '3' ? 'active' : ''}
									onClick={() => {
										this.toggle('3');
									}}
								>
									Deployments
								</NavLink>
							</NavItem>
						</Nav>
					</Navbar>

					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div className="tab-content-wrapper">
								<CustomTableWithPagination
									columns={columns}
									rows={data}
									expandRow={dataExpSessionRow}
								/>
								<TrialMetricsModal
									isOpen={isMetricsModal}
									toggleModal={this.toggleMetricsModal}
									predErrorData={predErrorData}
									featureImportanceData={featureImportanceData}
									modelType={modelType}
									classificationData={classificationData}
								/>
							</div>
						</TabPane>
						<TabPane tabId="2">
							<div className="tab-content-wrapper">
								<div className="container">
									<h5 className="mt-3 mb-0 text-center text-secondary">
										Select a Dataset to create new Experiment
									</h5>
									<div className="row" style={{flexFlow: "row wrap" }}>
										<div className="my-3 col-12 col-md-6 col-xl-4">
											<div className="h-100 text-muted clickable-block card">
												<div className="d-flex align-items-center justify-content-center card-body">
													<p className="card-text" onClick={this.handleExperiment}>
														Add New
													</p>
													{isModal && (
														<AddExperimentModal
															isOpen={isModal}
															toggleModal={this.toggleModal}
															project_id={project_id}
															createExpreiment={this.createExpreiment}
														/>
													)}
												</div>
											</div>
										</div>
										{datasets.length > 0 &&
											datasets.map((data, i) => (
												<div className="my-3 col-12 col-md-6 col-xl-4">
													<div
														className="dataset-card h-100 clickable-block text-muted card"
														key={i + data.id}
													>
														<div className="bg-transparent border-bottom-0 pr-0 card-header">
															<div className="row">
																<div className="align-self-center col">
																	<h5 className="mb-0 card-title">{data.name}</h5>
																</div>
																<div className="col-auto">action</div>
															</div>
														</div>
														{data.status === 'processed_with_error' ? (
															<div className="pb-0 card-body">
																<p className="card-text">
																	<div
																		className="text-center alert alert-danger fade show"
																		role="alert"
																	>
																		There was an error processing your file, please
																		verify it is a valid file.
																	</div>
																</p>
															</div>
														) : (
															<div>
																<div className="pb-0 card-body">
																	<p className="card-text" />
																</div>
																<div className="text-muted small bg-transparent border-top-0 card-footer">
																	<table>
																		<tbody>
																			<tr>
																				<td className="pr-1">Total Columns:</td>
																				<td>
																					{data.statistics ? (
																						data.statistics.columns_count
																					) : (
																						''
																					)}
																				</td>
																			</tr>
																			<tr>
																				<td className="pr-1">Total Records:</td>
																				<td>
																					{data.statistics ? (
																						data.statistics.count
																					) : (
																						''
																					)}
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														)}
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="3">
							<div className="tab-content-wrapper">
								{size(depModelDataRow) > 0 ? (
									<CustomTableWithPagination columns={depModelDataCol} rows={depModelDataRow} />
								) : (
									<div className="lead text-center m-4 alert alert-info fade show" role="alert">
										You have no deployments yet
									</div>
								)}
							</div>
						</TabPane>
					</TabContent>
				</div>
				{/* <div className="auger-logs">
                {augerlogs.length > 0 && augerlogs.map((log, i) => (
                    <div key={`log-${i}`}>{log.message}</div>
                ))}
            </div> */}
			</div>
		  </MainContent>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
	augerProjects: state.auger.augerData.projects,
	datasets: state.auger.augerData.projectDataSets,
	experiments: state.auger.augerData.experiments,
	transaction: state.auger.transaction,
	augerlogs: state.auger.augerlogs,
	expSessions: state.auger.augerData.expSessions,
	trial_metrics: state.auger.augerData.trial_metrics,
	trials: state.auger.augerData.trials,
	deployModelData: state.auger.augerData.deployModelData
});

const mapDispatchToProps = (dispatch) => ({
	addAugerProjectOnTed: (params) => dispatch(actions.addAugerProjectOnTed(params)),
	startAugerProject: (project, callback) => dispatch(actions.startAugerProject(project, callback)),
	fetchAugerProjects: (company_id) => dispatch(actions.fetchAugerProjects(company_id)),
	fetchAllAugerProjects: () => dispatch(actions.fetchAllAugerProjects()),
	fetchAugerExperiments: (project_id) => dispatch(actions.fetchAugerExperiments(project_id)),
	addAugerProject: (params, callback) => dispatch(actions.addAugerProject(params, callback)),
	fetchAugerProjectDataSets: (project_id) => dispatch(actions.fetchAugerProjectDataSets(project_id)),
	addAugerProjectFile: (params, callback) => dispatch(actions.addAugerProjectFile(params, callback)),
	addAugerExperiments: (params, callback) => dispatch(actions.addAugerExperiments(params, callback)),
	addAugerExperimentSessions: (params, callback) => dispatch(actions.addAugerExperimentSessions(params, callback)),
	getAugerExperimentSessions: (exp_session_id, callback) =>
		dispatch(actions.getAugerExperimentSessions(exp_session_id, callback)),
	getAugerTrialMetrics: (exp_session_id, callback) =>
		dispatch(actions.getAugerTrialMetrics(exp_session_id, callback)),
	deployTrialsAuger: (exp_session_id, callback) => dispatch(actions.deployTrialsAuger(exp_session_id, callback)),
	getDeploymentsModelData: (project_id) => dispatch(actions.getDeploymentsModelData(project_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AugerProjectDetails);
