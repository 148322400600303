import styled, {css} from 'styled-components'

export const inputRadioStyles = css`
  input[type="radio"] + .fa,
  input[type="radio"] + label > .fa { cursor: pointer; }

  /* Unchecked */
  input[type="radio"] + .fa-check-circle:before,
  input[type="radio"] + label > .fa-check-circle:before { content: "\f111"; } /* .fa-circle */
  input[type="radio"] + .fa-dot-circle-o:before,
  input[type="radio"] + label > .fa-dot-circle-o:before,
  input[type="radio"] + .fa-check-circle-o:before,
  input[type="radio"] + label > .fa-check-circle-o:before { content: "\f10c"; } /* .fa-circle-o */
  input[type="radio"] + .fa-circle:before,
  input[type="radio"] + label > .fa-circle:before { content: "\f1db"; } /* .fa-circle-thin */

  /* Checked */
  input[type="radio"]:checked + .fa-check-circle:before,
  input[type="radio"]:checked + label > .fa-check-circle:before { content: "\f058"; }
  input[type="radio"]:checked + .fa-check-circle-o:before,
  input[type="radio"]:checked + label > .fa-check-circle-o:before { content: "\f05d"; }
  input[type="radio"]:checked + .fa-circle:before,
  input[type="radio"]:checked + label > .fa-circle:before { content: "\f111"; }
  input[type="radio"]:checked + .fa-dot-circle-o:before,
  input[type="radio"]:checked + label > .fa-dot-circle-o:before { content: "\f192"; }
`

export const StyledSelectList = styled.ul`
  padding: 0;
`

export const StyledListItem = styled.li`
  list-style: none;
  font-weight: 400;
  opacity: 1;
  ${inputRadioStyles}
  :hover, label:hover {
    opacity: 1;
    cursor: pointer;
  }
  ${({active}) => active 
  ? `
    font-weight: 700;
    opacity: 1;
  ` 
  : null};
`

export const OverviewWidget = styled.div`
  margin-bottom: 20px;
  color: #fff;
  background: ${({bgColor}) => bgColor ? bgColor: 'purple'};
  padding: 0px 0px;
  text-align: center;
  flex: 1;
  height: 100%;
  h4 {
    font-weight: 300;
    background: ${({bgColorHeader}) => bgColorHeader ? bgColorHeader: 'purple'};
    line-height: 45px;
    text-align: center;
    padding: 0 20px;
    margin-left: 0px;
  }
  h2 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    margin-left: 0px;
    @media (max-width: 991px) {
        font-size: 30px;    
    }  
  }
  button {
    margin-top: 15px;
  }  

`

