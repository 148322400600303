import {
    GET_GENERAL_SETTINGS_FAIL,
    GET_GENERAL_SETTINGS_SUCCESS,
    SAVE_GENERAL_SETTINGS_SUCCESS,
    SAVE_GENERAL_SETTINGS_FAIL,
    RESET_GENERAL_SETTINGS_FLAGS,
} from "../actions/AdminSettingsActions";

const initialState = {
    generalSettings: {},
    saveSettingsSuccess: null,
    errors: {
        GetGeneralSettings: null,
        SaveGeneralSettings: null,
    },
};

export const AdminSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GENERAL_SETTINGS_SUCCESS:
            return {...state, generalSettings: action.settings};
        case GET_GENERAL_SETTINGS_FAIL:
            return {...state, errors: {GetGeneralSettings: action.error}};
        case SAVE_GENERAL_SETTINGS_SUCCESS:
            return {...state, generalSettings: action.settings, saveSettingsSuccess: 'Settings saved successfully.'};
        case SAVE_GENERAL_SETTINGS_FAIL:
            return {...state, errors: {SaveGeneralSettings: action.error}};
        case RESET_GENERAL_SETTINGS_FLAGS:
            return {...state,
                saveSettingsSuccess: null,
                errors: {
                    GetGeneralSettings: null,
                    SaveGeneralSettings: null,
                }
            };
        default:
            return state;
    }
};
