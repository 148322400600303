import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
// import NetworkChart from '../../components/Charts/NetworkChart';
import MainContent from '../../components/Layouts/MainContent';
import loadable from 'react-loadable';
import loading from '../../components/Loading/Loading';
import * as actions from '../../redux/actions/index';

const NetworkChart = loadable({ loading,
  loader: () => import('../../components/Charts/NetworkChart')
});

const Main = (props) => {
	const { s2data, user, onFetchS2Data, s2loading } = props;
	useEffect(
		() => {
			onFetchS2Data({company_id: user.company.id});
		},
		[ ]
	);

		return (
			<MainContent>
				<div className="chart-area-container">
					<div id="chart-holder">
						{ !s2loading ? <NetworkChart s2data={s2data} /> :  null }
					</div>
				</div>
			</MainContent>
		);
}

const mapStateToProps = (state) => ({
	loading: state.auth.loading,
	user: state.auth.user,
	s2data: state.s2data.data,
	s2loading: state.s2data.loading 
});

const mapDispatchToProps = (dispatch) => ({
	onFetchS2Data: (fields) => dispatch(actions.fetchS2Data(fields)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
