"use strict";
import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import * as _ from 'lodash';

const drawBarChart = (data, tooltip) => {
    
    var newData = [], keys = [];

    data.forEach((d) => {
      if(keys.indexOf(d.catName) == -1){
      	keys.push(d.catName);
      }
      newData.push({key: d.catName, numInstances: d.numInstances, name: d.name })
    })

    data = newData;


    var margin = {top: 40, right: 20, bottom: 30, left: 70};
    
    // set the ranges
    var x = d3.scaleBand()
              .range([0, 400])
              .padding(0.1);

    var y = d3.scaleLinear()
                  .range([250, 0]);

    var color = d3.scaleOrdinal(d3.schemeDark2);
          
    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var container = d3.select("#bar-chart-holder");
    container.selectAll("*").remove();
    var svg = container.append("svg")
                         .attr("preserveAspectRatio", "xMidYMid meet")
                         .attr("viewBox", "0 0 525 350")
                         .classed("svg-content-rw-vertical", true);
    var g = svg.append("g")
       .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


     // Scale the range of the data in the domains
     y.domain([0, d3.max(data, function(d){ return d.numInstances; })]  )
     x.domain(data.map((d, i) => { return i}));
     //y.domain([0, d3.max(data, function(d) { return d.sales; })]);
     var legendSpace = 20;
     keys.forEach((d, i) => {     
        var legend = g.append("g")
             .attr("id", d)
             .attr("class", "legend ")
        legend.append("circle")     
             .attr("fill", color(d))
             .attr("cx", i * 100 )
             .attr("cy", 0)
             .attr("r", 5)
        legend.append("text")
             .attr("stroke", "darkgrey")
             .style("font-size", 9)
             .attr("x", ( i * 100) + 10 )
             .attr("y", 3)
             .text(d)
    });

     // append the rectangles for the bar chart
     var subG = g.append("g")
                 .attr("transform", "translate(10, 50 )")

     subG.selectAll(".bar")
         .data(data)
         .enter().append("rect")
         .attr("class", "bar")
         .attr("fill", (d) => color(d.key) )
         .attr("y",(d) => y(d.numInstances)  )
         .attr("width", function(d) {
         	return x.bandwidth(); 
         } )
         .attr("x", function(d, i) {
         	return x( i ); 
         })
         .attr("height",function(d) {
         	return  250 - y(d.numInstances);
         } )
         .on("mouseenter", (d) =>{
          return tooltip(d);
         } )
         .on('mouseleave',function() {
            d3.select("#plot-tooltip").style("display", "none");
         } );

    // add the y Axis
    subG.append("g")
        .call(d3.axisLeft(y)
                .tickFormat(d3.format(''))
                .ticks(data.length < 2 ? 1 : 3)
        	);

}

class RWVerticalBar extends React.Component {

    constructor(props){
    	super(props);
    	this.state = {
    		data: null
    	}
    }

  componentDidMount(){
    
    const data = _.cloneDeep(this.props.data)

    this.setState({
      data
    }, () => {
      drawBarChart(this.state.data, this.props.tooltip)
    } )

  }

  componentDidUpdate(prevProps, prevState){
  	const data = _.cloneDeep(this.props.data)
  	if(prevProps.data != this.props.data){
  	  this.setState({
        data
       }, () => {
        drawBarChart(this.state.data, this.props.tooltip)
       } )
  	}
  }

    render(){
    	return(
            <div id="bar-chart-holder"></div>
    	)
    }

}


export default RWVerticalBar;