import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';

const renderField = (field) => {
  const {
    input,
    label,
    placeholder,
    type,
    name,
    meta: { touched, error, warning },
  } = field;
  return (
      <Form.Group controlId={name}>
        <Form.Label style={{color: "#6e6e6e"}}>{label}</Form.Label>
        <input
            type={type}
            placeholder={placeholder}
            {...input}
            className="form-control"
        />
        {touched
        && ((error && <p className="help is-danger">{error}</p>)
            || (warning && <p className="help is-danger">{warning}</p>))}
      </Form.Group>
  );
}

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const renderFileField = (field) => {
  const { input: { value: omitValue, onChange, onBlur, ...inputProps }, meta: omitMeta, ...props } = field;
  return (
    <div className="form-group">
      <label htmlFor={inputProps.name}>{field.label}</label>
      <div className="control">
        <input
          onChange={adaptFileEventToValue(onChange)}
          onBlur={adaptFileEventToValue(onBlur)}
          type="file"
          multiple
          {...inputProps}
          {...props.input}
          {...props}
        />
      </div>
      {field.meta.touched &&
        ((field.meta.error && <p className="help is-danger">{field.meta.error}</p>) ||
          (field.meta.warning && <p className="help is-danger">{field.meta.warning}</p>))}
    </div>
  );
};

class AddExperimentModal extends React.Component {

  constructor(props){
    super(props);
  }

  submitHandle = (values) => {
		this.props.addExperimentForm(values);
  };
  
  render() {
    const { isOpen, toggleModal, handleSubmit } = this.props;
    return(
      <div>
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Create New Experiment</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit((values) => this.submitHandle(values))}>
              <Field
                  name="name"
                  type="text"
                  placeholder="Enter Experiment Name"
                  component={renderField}
                  label="Experiment Name"
              />
              <Field
									name="file"
									component={renderFileField}
									type="file"
									label="Upload JSON File"
								/>
              <ModalFooter>
                <Button disabled={this.props.isLoading} type="submit" color="primary">{this.props.isLoading ? 'Uploading..' : 'Create New Experiment'}</Button>{' '}
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </ModalBody>

        </Modal>
      </div>
    )
  }
}
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Field is required';
  }
  if (!values.file) {
    errors.file = 'Field is required';
  }
  return errors;
};

export default reduxForm({
  form: 'AddRWONExperimentForm',
  validate,
})(AddExperimentModal);
