import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Button } from 'react-bootstrap';

class AddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scanDate: ''
		};
	}

	renderField = (field) => {
		const { input, label, placeholder, type, name, meta: { touched, error, warning } } = field;
		return (
			<Form.Group controlId={name}>
				<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
				<input
					type={type}
					defaultValue={moment(new Date()).format('YYYY-MM-DD hh:mm:ss')}
					placeholder={placeholder}
					{...input}
					className="form-control"
				/>
				{touched &&
					((error && <p className="help is-danger">{error}</p>) ||
						(warning && <p className="help is-danger">{warning}</p>))}
			</Form.Group>
		);
	};

	renderSelectField = (field) => {
		const { input, label, placeholder, options, name, meta: { touched, error, warning } } = field;
		return (
			<Form.Group controlId={name}>
				<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
				<Select
					{...input}
					placeholder={placeholder}
					onChange={(value) => input.onChange(value)}
					onBlur={() => input.onBlur(input.value)}
					options={options}
				/>
				{touched &&
					((error && <p className="help is-danger">{error}</p>) ||
						(warning && <p className="help is-danger">{warning}</p>))}
			</Form.Group>
		);
	};

	adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

	renderFileField = (field) => {
		const { input: { value: omitValue, onChange, onBlur, ...inputProps }, meta: omitMeta, ...props } = field;
		return (
			<div className="form-group">
				<label htmlFor={inputProps.name}>{field.label}</label>
				<div className="control">
					<input
						onChange={this.adaptFileEventToValue(onChange)}
						onBlur={this.adaptFileEventToValue(onBlur)}
						type="file"
						multiple
						{...inputProps}
						{...props.input}
						{...props}
					/>
				</div>
				{field.meta.touched &&
					((field.meta.error && <p className="help is-danger">{field.meta.error}</p>) ||
						(field.meta.warning && <p className="help is-danger">{field.meta.warning}</p>))}
			</div>
		);
	};

	handleChangeDate = (date) => {
		this.setState({
			scanDate: date
		});
	};

	renderDatePicker = (field) => {
		const { input, label, placeholder, name, meta: { touched, error, warning } } = field;
		/* {...inputs} was causing error in DatePicker */
		return (
			<Form.Group controlId={name}>
				<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
				<div>
					<DatePicker
						placeholder={placeholder}
						onChange={(date) => input.onChange(date)}
						selected={input.value}
						showTimeSelect
						dateFormat="Pp"
						className="form-control"
					/>
				</div>
				{touched &&
					((error && <p className="help is-danger">{error}</p>) ||
						(warning && <p className="help is-danger">{warning}</p>))}
			</Form.Group>
		);
	};

	renderMSOpts = (names) => {
		const filterData = [];
		names.forEach((c) => {
			filterData.push({ value: c.id, label: c.name });
		});
		return filterData;
	};

	submitHandle = async (values) => {
		this.props.addReport(values);
	};

	render() {
		const { isOpen, toggleModal, handleSubmit, allMS, getValues } = this.props;
		return (
			<div>
				<Modal isOpen={isOpen} toggle={toggleModal}>
					<ModalHeader toggle={toggleModal}>Upload CSV/XML/JSON Report</ModalHeader>
					<ModalBody>
						<Form onSubmit={handleSubmit((values) => this.submitHandle(values))}>
							<Field
								name="reportType"
								type="text"
								placeholder="Please select type"
								component={this.renderSelectField}
								options={this.renderMSOpts(allMS)}
								label="Report Type"
							/>
							<Field
								name="scanDate"
								type="text"
								placeholder="Enter Scan Date Time"
								component={this.renderDatePicker}
								label="Scan Date Time"
							/>
							{getValues && getValues.reportType.label === 'S2 BlackSwan API' ? (
								<Field
									name="dataInventoryFile"
									component={this.renderFileField}
									type="file"
									label="Data Inventory File"
								/>
							) : null}
							{getValues && getValues.reportType.label === 'S2 BlackSwan API' ? (
								<Field
									name="dataRedundancyFile"
									component={this.renderFileField}
									type="file"
									label="Redundancy File"
								/>
							) : null}
							{getValues && getValues.reportType.label === 'Nmap' ? (
								<Field
									name="nmapFile"
									component={this.renderFileField}
									type="file"
									label="Upload XML File"
								/>
							) : null}
							{getValues && getValues.reportType.label === 'revealwhy' ? (
								<Field
									name="revealwhyFile"
									component={this.renderFileField}
									type="file"
									label="Upload JSON File"
								/>
							) : null}

							<ModalFooter>
								<Button type="submit" color="primary">
									{this.props.isLoading ? 'LOADING...' : 'Upload Report'}
								</Button>{' '}
								<Button color="secondary" onClick={toggleModal}>
									Cancel
								</Button>
							</ModalFooter>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}
const validate = (values) => {
	const errors = {};
	if (!values.reportType) {
		errors.reportType = 'Field is required';
	}
	if (!values.scanDate) {
		errors.scanDate = 'Field is required';
	}
	if (!values.dataInventoryFile) {
		errors.dataInventoryFile = 'Field is required';
	}
	if (!values.dataRedundancyFile) {
		errors.dataRedundancyFile = 'Field is required';
	}
	if (!values.nmapFile) {
		errors.nmapFile = 'Field is required';
	}
	if (!values.revealwhyFile) {
		errors.revealwhyFile = 'Field is required';
	}
	return errors;
};

const mapStateToProps = (state) => {
	return {
		getValues: getFormValues('AddReportsForm')(state)
	};
};

export default connect(mapStateToProps)(
	reduxForm({
		form: 'AddReportsForm',
		validate
	})(AddModal)
);
