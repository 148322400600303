import React, { Component } from 'react';
import './style.scss';
//configuration file that has the values stored for users
// import config from './config'

class SampleDashboard extends React.Component {
    render() {
        //suppose user is received from props
        const { user } = this.props
        return (
          <section className="grafana-wrapper col-md-12">
                  <div className="row">
                      <div className="col-md-6">
                          <div className='grafana-details'>
                              <div className='subscriptions'>
                                  <h5>Loan Application Service</h5>
                                    <div className="revenue-data">
                                    <iframe 
                                        src="https://ravi:cJ45Gq!Wx7jnPc@monitoring.trovoai.com/d/NXZhyRHGk/7-cost-reporting?orgId=1&from=now-14d&to=now&refresh=10s" 
                                        width="100%" height="300" frameborder="0">
                                    </iframe>
                                     </div>
                                  </div>
                           </div>
                      </div>
                      <div className="col-md-6">
                          <div className='grafana-details'>
                              <div className='subscriptions'>
                                  <h5>Credit Scoring Models</h5>
                                    <div className="revenue-data">
                                    <iframe 
                                        src="https://snapshot.raintank.io/dashboard-solo/snapshot/y7zwi2bZ7FcoTlB93WN7yWO4aMiz3pZb?from=1493369923321&to=1493377123321&panelId=4" 
                                        width="100%" height="300" frameborder="0">
                                    </iframe>
                                     </div>
                                  </div>
                           </div>
                      </div>
                      <div className="col-md-6">
                          <div className='grafana-details'>
                              <div className='subscriptions'>
                                  <h5>Fraud Detection Service</h5>
                                    <div className="revenue-data">
                                    <iframe 
                                        src="https://snapshot.raintank.io/dashboard-solo/snapshot/y7zwi2bZ7FcoTlB93WN7yWO4aMiz3pZb?from=1493369923321&to=1493377123321&panelId=4" 
                                        width="100%" height="300" frameborder="0">
                                    </iframe>
                                     </div>
                                  </div>
                           </div>
                      </div>
                  </div>
          </section>
      )
        // return (
        //     <>
        //         {/** different dashboards for different organizations
        //          * depending upon the parameters their panels value changes
        //          */}
        //         <iframe src="https://ravi:cJ45Gq!Wx7jnPc@monitoring.trovoai.com/d-solo/NXZhyRHGk/ted-cost-reporting-features?orgId=1&from=1596624644984&to=1597834244984&panelId=2" width="450" height="200" frameborder="0"></iframe>
        //         {/* {(config.roles.user1_org1 === user.role.id) &&
        //             <iframe
        //                 src={`https://${config.sampleDash.organization1.username}:${config.sampleDash.organization1.password}@authproxy.sampleDomain/z/aaaaaaaaa/organization_1?orgId=1&from=now-90d&to=now&var-org_user=${type}&var-state=${state}&var-top=5&kiosk=tv`} />
        //         }
        //         {(config.roles.user2_org3 === user.role.id) &&
        //             <iframe
        //                 src={`https://${config.sampleDash.organization3.username}:${config.sampleDash.organization3.password}@authproxy.sampleDomain/z/ccccccccc/organization_3?orgId=3&from=now-90d&to=now&var-org_user=${user.username}&var-city=${city}&kiosk=tv`} />
        //         }
        //         {(config.roles.user3_org2 === user.role.id) &&
        //             <iframe
        //                 src={`https://${config.sampleDash.organization2.username}:${config.sampleDash.organization2.password}@authproxy.sampleDomain/z/bbbbbbbbb/organization_2?orgId=2&from=now-90d&to=now&var-org_user=${user.username}&kiosk=tv`} />
        //         } */}
        //     </>
        // )
    }
}
export default SampleDashboard