import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import DropDown from '../DropDown';
import './navbar.scss';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';

import harmburger from '../../../../static/images/harmburger.svg';
import logowhite from '../../../../static/images/logowhite.png';

const metricOptions = [
	{ label: 'Metric1', value: 'Metric1' },
	{ label: 'Metric2', value: 'Metric2' },
	{ label: 'Metric3', value: 'Metric3' }
];
const NavbarComponent = () => {
	const [ isOpen, setToggle ] = useState(false);
	const logout = () => {
		localStorage.removeItem('idToken');
		window.location = '/login';
	};
	return (
		<div className="main-wrapper">
			{/*<header>*/}
			{/*<span><img src="/assets/images/logo.png" alt='logo'/></span>*/}
			{/*</header>*/}
			<Navbar expand="md">
				<Link to="/" className="title">
					<img src={logowhite} alt="logo" />
					<span>TROVO</span>
				</Link>
				<NavbarToggler onClick={() => setToggle(!isOpen)}>
					<img src={harmburger} alt="nav-icon" />
				</NavbarToggler>
				<Collapse isOpen={isOpen} navbar>
					<Nav className="navbar-wrapper" navbar>
						<NavItem>
							<Link to="/admin/dashboard">Users</Link>
						</NavItem>
						<NavItem>
							<Link to="/admin/manage-companies">Companies</Link>
						</NavItem>
						{/*<NavItem>*/}
						{/*    <Link to='/admin/manage-resellers'>Resellers</Link>*/}
						{/*</NavItem>*/}
						<NavItem>
							<Link to="/admin/services">Services</Link>
						</NavItem>
						{/* <NavItem>
                            <Link to='/admin/calendar'>Calendar</Link>
                        </NavItem>
                        <NavItem>
                            <Link to='/admin/support-cases'>Billing</Link>
                        </NavItem> */}
						<NavItem>
							<Link to="/admin">Reports</Link>
						</NavItem>
						<NavItem>
							<Link to="/admin/admin-settings">Settings</Link>
						</NavItem>
						<NavItem>
							<a onClick={logout}>Logout</a>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
			<div className="header-panel">
				<h5>TED ADMINISTRATOR DASHBOARD</h5>
				{/* <span>
                      <Button text='Edit Info' />
                    <div className="edit-drop"> <label>PROVIDER</label> <DropDown options={metricOptions} placeholder="Select" /></div>
                </span> */}
			</div>
		</div>
	);
};

export default NavbarComponent;
