import axios from 'axios';
import size from 'lodash/size';
import axiosAuth from '../axiosInstance';

export const RW_GET_PROJECT = 'RW_GET_PROJECT';
export const RW_ADD_PROJECT = 'RW_ADD_PROJECT';
export const RW_GET_FILES = 'RW_GET_FILES';
export const RW_GET_REGEX_RULES = 'RW_GET_REGEX_RULES';
export const RW_UPLOAD_FILES = 'RW_UPLOAD_FILES';
export const RW_GET_EXPERIMENT = 'RW_GET_EXPERIMENT';
export const RW_ADD_EXPERIMENT = 'RW_ADD_EXPERIMENT';
export const RW_ADD_ON_PREMISE_EXPERIMENT = 'RW_ADD_ON_PREMISE_EXPERIMENT';
export const RW_GET_ON_PREMISE_EXPERIMENT = 'RW_GET_ON_PREMISE_EXPERIMENT';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';
export const FORM_START = 'FORM_START';
export const FORM_COMPLETE = 'FORM_COMPLETE';


export const formStart = () => ({
	type: FORM_START
});

export const formComplete = () => ({
	type: FORM_COMPLETE
});

export const fetchStart = () => ({
	type: FETCH_START
});

export const fetchFail = (errorMsg) => ({
	type: FETCH_FAIL,
	errorMsg
});

export const projects = (projects) => ({
	type: RW_GET_PROJECT,
	projects
});

export const addproject = (project) => ({
	type: RW_ADD_PROJECT,
	project
});

export const rwUploadFiles = (files) => ({
	type: RW_UPLOAD_FILES,
	files
});

export const rwGetFiles = (files) => ({
	type: RW_GET_FILES,
	files
});

export const rwRegexRules = (regex_rules) => ({
	type: RW_GET_REGEX_RULES,
	regex_rules
});

export const rwGetExperiments = (experiments) => ({
	type: RW_GET_EXPERIMENT,
	experiments
});

export const rwAddExperiment = (experiment) => ({
	type: RW_ADD_EXPERIMENT,
	experiment
});

export const rwGetOnPremiseExperiments = (onPremiseExpData) => ({
	type: RW_GET_ON_PREMISE_EXPERIMENT,
	onPremiseExpData
});

export const rwOnPremiseAddExperiment = () => ({
	type: RW_ADD_ON_PREMISE_EXPERIMENT
});

export const addWRProject = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post('/reveal/project', params)
		.then((res) => {
			dispatch(addproject(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const getProjectList = (company_id, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.get(`reveal/project?company__id=${company_id}`)
		.then((res) => {
			dispatch(projects(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const uploadFiles = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post(`/rw-file-upload/`, params)
		.then((res) => {
			dispatch(rwUploadFiles(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const getFiles = (params) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.get(`rw-file-upload/?company__id=${params.company_id}&project__id=${params.project_id}`)
		.then((res) => {
			dispatch(rwGetFiles(res.data))
			// callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};


export const getRegexRules = () => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.get('reveal/regex-rules')
		.then((res) => {
			dispatch(rwRegexRules(res.data))
			// callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const getExperiment = (project_id) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.get(`reveal/experiment?project__id=${project_id}`)
		.then((res) => {
			dispatch(rwGetExperiments(res.data))
			// callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const addExperiment = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post('reveal/experiment', params)
		.then((res) => {
			dispatch(rwAddExperiment(res.data))
			callback()
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};


export const getOnPremiseExperiment = (project_id, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.get(`reveal/on-premise-experiment?project__id=${project_id}`)
		.then((res) => {
			dispatch(rwGetOnPremiseExperiments(res.data))
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};

export const addOnPremiseExperiment = (params, callback) => async (dispatch) => {
	dispatch(formStart());
	await axiosAuth
		.post('reveal/on-premise-experiment', params)
		.then((res) => {
			dispatch(rwOnPremiseAddExperiment(res.data))
			callback()
		})
		.catch((err) => {
			dispatch(fetchFail(err.response));
		});
};
