import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import CustomTableWithPagination from '../../../components/Admin/CustomTable';
import DropDown from '../../../components/Admin/DropDown';
import * as actions from '../../../redux/actions/AuthActions';
import AddModal from './AddModal';

const actionOptions = [ { label: 'Edit', value: 1 }, { label: 'Delete', value: 2 } ];

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isModal: false,
			editInfo: '',
			alert: null
		};
		this.hideAlert = this.hideAlert.bind(this);
		this.successDelete = this.successDelete.bind(this);
		this.cancelDetele = this.cancelDetele.bind(this);
		this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
	}

	componentDidMount() {
		this.props.fetchUsers();
	}

	handleEdit = (user) => {
		this.setState({ editInfo: user, isModal: true });
	};

	toggleModal = () => {
		this.setState((state) => ({ isModal: !state.isModal, editInfo: '' }));
	};

	handleAddUser = () => {
		this.setState({ isModal: true });
	};

	actionHandler = (e, user) => {
		let val = e.target.value;
		if (val == 1) {
			this.setState({ editInfo: user, isModal: true });
		} else if (val == 2) {
			this.warningWithConfirmAndCancelMessage(user.id);
		}
	};

	renderActions = (user) => {
		return (
			<React.Fragment>
				<DropDown
					options={actionOptions}
					placeholder={'Select Action'}
					onItemSelected={(e) => this.actionHandler(e, user)}
				/>
				{/* <Button onClick={() => this.handleEdit(user)}>Edit</Button> */}
			</React.Fragment>
		);
	};

	addUser = (user) => {
		const { editInfo } = this.state;
		if (editInfo) {
			if (!user.password) delete user.password;
			const userEdit = { ...editInfo, ...user };
			this.props.editUser(userEdit, editInfo.id);
		} else {
			this.props.addUser(user);
		}
		this.toggleModal();
	};

	warningWithConfirmAndCancelMessage(userId) {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title="Are you sure?"
					onConfirm={() => this.successDelete(userId)}
					onCancel={() => this.cancelDetele()}
					confirmBtnBsStyle="info"
					cancelBtnBsStyle="danger"
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this user!
				</SweetAlert>
			)
		});
	}

	successDelete(userId) {
		this.props.onUserDelete(userId, () => {
			this.props.fetchUsers();
		});
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title="Deleted!"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="info"
				>
					Your user has been deleted.
				</SweetAlert>
			)
		});
	}

	cancelDetele() {
		this.setState({
			alert: (
				<SweetAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title="Cancelled"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="info"
				>
					Your user is safe :)
				</SweetAlert>
			)
		});
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}

	render() {
		const { users } = this.props;
		const { isModal, editInfo } = this.state;
		const columns = [
			{ dataField: 'id', text: 'UserID', sort: true },
			{ dataField: 'email', text: 'Email', sort: true },
			{ dataField: 'firstName', text: 'First Name', sort: true },
			{ dataField: 'lastName', text: 'Last Name', sort: true },
			{ dataField: 'action', text: 'Action' }
		];
		const data = users.map((user) => ({
			id: user.id,
			firstName: user.first_name,
			lastName: user.last_name,
			email: user.email,
			action: this.renderActions(user)
		}));

		return (
			<div className="container-fluid">
				<Button style={{ float: 'right', margin: 10 }} onClick={this.handleAddUser}>
					Add User
				</Button>
				<CustomTableWithPagination columns={columns} rows={data} />
				{this.state.alert}
				{isModal && (
					<AddModal
						isOpen={isModal}
						toggleModal={this.toggleModal}
						editInfo={editInfo}
						addUser={this.addUser}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.auth.isUsersLoad,
	users: state.auth.users,
	errorMsg: state.auth.errorMsg
});

const mapDispatchToProps = (dispatch) => ({
	fetchUsers: () => dispatch(actions.fetchUsers()),
	addUser: (user) => dispatch(actions.addUser(user)),
	editUser: (user, id) => dispatch(actions.editUserDetails(user, id)),
	onUserDelete: (userId, callback) => dispatch(actions.onDeleteUser(userId, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
