import React from 'react';
import { Table } from 'reactstrap';
import './table.scss';

const CustomTable = ({ columns = [], rows = [], isBordered = false }) => (
    <Table className={isBordered ? 'custom-table bordered-table' : 'custom-table'} responsive>
        <thead>
        <tr>
            {
                columns.map((column, index) => (
                    <th key={`column-${index}`} style={{width:column.width?`${column.width}%`:'unset'}}>{column.title}</th>
                ))
            }
        </tr>
        </thead>
        <tbody>
        {
            rows.map((row, index) => (
                <tr key={`row${index}`}>
                    {columns.map((data, index) => (
                        <td className={isBordered ? 'bordered' : ''} key={`data-${index}`} style={{width:data.width?`${data.width}%`:'unset'}}>
                            {row[data.key]}
                        </td>
                    ))}
                </tr>
            ))
        }
        </tbody>
    </Table>
)

export default CustomTable;
