export function currencyFormatter(num) {
	if (isNaN(num)) {
		return num;
	}

	num = parseFloat(num);

	if (Math.abs(num) >= 1000000000) {
		return '$' + (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B';
	} else if (Math.abs(num) >= 1000000) {
		return '$' + (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
	} else if (Math.abs(num) >= 1000) {
		return '$' + (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
	} else if (Math.abs(num) < 1000) {
		return '$' + num.toFixed(2).replace(/\.0$/, '');
	}

	return num;
}

export function valueLegendFormat(num) {
	if (isNaN(num)) {
		return num;
	}

	num = parseFloat(num);

	if (Math.abs(num) >= 1000000000) {
		return (num / 1000000000).toFixed(0).replace(/\.0$/, '') + 'B ';
	} else if (Math.abs(num) >= 1000000) {
		return (num / 1000000).toFixed(0).replace(/\.0$/, '') + 'M ';
	} else if (Math.abs(num) >= 1000) {
		return (num / 1000).toFixed(0).replace(/\.0$/, '') + 'K ';
	} else if (Math.abs(num) < 1000) {
		return num.toFixed(0).replace(/\.0$/, '');
	}

	return num;
}
