import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import CustomTableWithPagination from '../../../components/Admin/CustomTable';
import DropDown from '../../../components/Admin/DropDown';
import AddModal from './AddModal';
import CustomModal from '../Modal/msModal';
import moment from 'moment';
import * as actions from '../../../redux/actions/index';
import { getReportsS2, s2AuthToken, s2URLStruct } from '../../../utilities/s2APIHelpers';

const actionOptions = [
	{ label: 'Start Auto Scan', value: 0 },
	{ label: 'Import Data Reports', value: 1 },
	{ label: 'Delete Microservice', value: 2 }
];

const columns = [
	{ dataField: 'companyName', text: 'Company Name', sort: true },
	{ dataField: 'resellerName', text: 'Reseller Name', sort: true },
	{ dataField: 'name', text: 'Services Name', sort: true },
	{ dataField: 'api', text: 'API URL' },
	{ dataField: 'scanType', text: 'Scan Type' },
	{ dataField: 'action', text: 'Action' }
];

const MicroServices = (props) => {
	const { allMS } = props;
	const [ modalOpen, setModalOpen ] = useState([ false, 1, '' ]);
	const [ completed, setCompleted ] = useState(true);
	const [ scanId, setScanId ] = useState(0);
	const [ isModal, setIsModal ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ alert, setAlert ] = useState(null);

	useEffect(() => {
		props.onFetchMicroService();
	}, []);

	useEffect(() => {
		props.onCompanyNames();
	}, []);

	const modalForManualScanImport = (title, message, ms) => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title="Are you sure?"
				onConfirm={() => importConfirmed('This process may take a few minutes to complete.', ms)}
				onCancel={() => cancelModal('You have canceled this action')}
				confirmBtnBsStyle="info"
				cancelBtnBsStyle="danger"
				confirmBtnText="Import Data"
				cancelBtnText="Cancel"
				showCancel
			>
				{message}
			</SweetAlert>
		);
	};

	const importConfirmed = (message, ms) => {
		msScan(ms);
		setAlert(
			<SweetAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title="Import started!"
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				{message}
			</SweetAlert>
		);
	};

	const warningWithConfirmAndCancelMessage = (id, message) => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title="Are you sure?"
				onConfirm={() => successModal(id, 'Your microservice has been deleted.')}
				onCancel={() => cancelModal('Your microservice is safe :)')}
				confirmBtnBsStyle="info"
				cancelBtnBsStyle="danger"
				confirmBtnText="Yes, delete it!"
				cancelBtnText="Cancel"
				showCancel
			>
				{message}
			</SweetAlert>
		);
	};
	// const notifyDeleteSuccess = () => toast.success('Your Microservice has been deleted successfully')
	const successModal = (id, message) => {
		props.onDeleteMicroservice(id, () => {
			props.onFetchMicroService();
		});
		setAlert(
			<SweetAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title="Deleted!"
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				{message}
			</SweetAlert>
		);
	};

	const cancelModal = (message) => {
		setAlert(
			<SweetAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title="Cancelled"
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				{message}
			</SweetAlert>
		);
	};

	const hideAlert = () => {
		setAlert(null);
	};

	const actionHandler = (e, ms) => {
		let val = e.target.value;
		if (val == 0) {
			setModalOpen([ true, 0, 'Please enter Scan Information: ', ms.api_url ]);
			e.target.setAttribute('disabled', 'disabled');
		} else if (val == 1) {
			modalForManualScanImport(
				'Scan Intialized',
				'This action will import data reports from an existing microservice instance.',
				ms
			);
			e.target.setAttribute('disabled', 'disabled');
		} else if (val == 2) {
			warningWithConfirmAndCancelMessage(ms.id, 'You will not be able to recover this microservice!');
			e.target.setAttribute('disabled', 'disabled');
		}
	};

	const notifySuccess = () => toast.success('Scan completed successfully!');

	const renderActions = (ms) => {
		return (
			<React.Fragment>
				<DropDown
					options={actionOptions}
					placeholder={'Select Action'}
					onItemSelected={(e) => actionHandler(e, ms)}
				/>
			</React.Fragment>
		);
	};

	const scanType = (type) => {
		let sTypeName = null;
		if (type === 1) {
			sTypeName = 'CSV Extract';
		} else if (type === 2) {
			sTypeName = 'On-Premise';
		}
		return sTypeName;
	};

	const renderDataMS = (allMS) => {
		const data = allMS.map((ms) => ({
			id: ms.id,
			companyName: ms.company_name,
			resellerName: ms.reseller_name,
			name: ms.name,
			api: ms.api_url,
			scanType: scanType(ms.scan_type),
			action: renderActions(ms)
		}));
		return data;
	};

	const msScan = async (ms, e) => {
		setScanId(ms.id);
		const token = await s2AuthToken(ms.api_url, 'admin', 'ChangeMe2017!');
		const struct = s2URLStruct(ms.api_url);
		if (token) {
			await getReportsS2(token, ms.company_id, ms.id, struct, 30.5, () => {
				notifySuccess();
				setScanId(0);
			});
		}
	};

	const toggleModal = () => {
		setIsModal(!isModal);
	};

	const handleAddMS = () => {
		setIsModal(true);
	};

	const notifyAddMicroservice = () => toast.success('Microservice has been added successfully');

	const addMicroService = (values) => {
		try {
			setIsLoading(true);
			const data = {
				name: values.name,
				company_id: values.company_id.value,
				reseller_id: values.reseller_id ? values.reseller_id.value : null,
				api_url: values.api_url,
				scan_type: values.scan_type.value,
				status: 1,
				created_at: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
				updated_at: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
				created_by: props.user.id,
				updated_by: props.user.id
			};
			props.onAddMicroService(data, () => {
				setIsLoading(false);
				setIsModal(false);
				props.onFetchMicroService();
				notifyAddMicroservice();
			});
		} catch (e) {
			console.log(e.message, 'e.message');
		}
	};

	return (
		<div className="container-fluid">
			<Button style={{ float: 'right', margin: 10 }} onClick={handleAddMS}>
				Add Microservice
			</Button>
			<CustomModal open={modalOpen} modalFunction={setModalOpen} />
			<ToastContainer position="bottom-right" />
			<CustomTableWithPagination columns={columns} rows={renderDataMS(props.allMS)} />
			{alert}
			{isModal && (
				<AddModal
					isOpen={isModal}
					toggleModal={toggleModal}
					companiesNames={props.companiesNames}
					compResellerNames={props.compResellerNames}
					onCompResellerNames={props.onCompResellerNames}
					isLoading={isLoading}
					addMicroService={addMicroService}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	loading: state.auth.isUsersLoad,
	allMS: state.ms.allMS,
	errorMsg: state.auth.errorMsg,
	user: state.auth.user,
	companiesNames: state.company.companiesNames,
	compResellerNames: state.company.compResellerNames
});

const mapDispatchToProps = (dispatch) => ({
	onFetchMicroService: () => dispatch(actions.fetchMicroService()),
	onCompanyNames: () => dispatch(actions.fetchCompanyNames()),
	onCompResellerNames: (id) => dispatch(actions.fetchCompResellerNames(id)),
	onAddMicroService: (params, callbak) => dispatch(actions.addMicroService(params, callbak)),
	onDeleteMicroservice: (id, callbak) => dispatch(actions.deleteMicroservice(id, callbak))
});

export default connect(mapStateToProps, mapDispatchToProps)(MicroServices);
