"use strict";
import React, {Component, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, 
	     faFolder, 
	     faFile
	    } from '@fortawesome/free-solid-svg-icons'



//recursive component used to display file-hierarchy
const ListItem = ({levelOpen, data, level, name, changeDirectory, directory, id, idPath, isLeaf }) => {

const [open, setOpen] = useState(levelOpen);
  useEffect(() => {
    setOpen(levelOpen)
  }, [levelOpen])

  return(
    <div className={"rw-file-list-container"} >
      <div className={"rw-top-level-container"}
           onClick={() => changeDirectory({id, name, level, idPath, isLeaf }) }
           style={{ cursor: data ? "pointer" : "auto" }}  >
        <div className={"list-item-icon"} >
           <FontAwesomeIcon icon={data ? open ? faFolderOpen : faFolder : faFile}
                            style={{color: data ? "#ff9922" : "white", fontSize: "24pt", 
                                    cursor: data ? "pointer" : "auto" }}
                             />
        </div>
        <h6 className={"list-item-text"} 
            onClick={() => changeDirectory({id, name, level, idPath, isLeaf }) } 
            >{name}</h6>
      </div>
      <div className={"rw-file-list-item-container"}>
            	{
            		open && data && data.map((c) => {
            			return (<ListItem 
                                  data={ c.children ? c.children : null } 
                                  level={level + 1} 
                                  name={c.name}
                                  changeDirectory={changeDirectory}
                                  directory={directory}
                                  levelOpen={ directory.idPath[level + 1]  == c.id  ? true : false}
                                  id={c.id}
                                  idPath={c.idPath}
                                  isLeaf={c.isLeaf}
            			        />)
            		})
            	}
      </div>
    </div>

  )


}


class RWFileListView extends React.Component {

  constructor(props){
  	super(props);
    this.state ={
      directory: {}, 
      loading: true 
    }
    
  }

  componentDidMount(){
    this.setState({
      loading: false,
      directory: this.props.directory
    })
  }

  componentDidUpdate(props, state) {
    if ( props.directory.id !== this.props.directory.id || props.directory.idPath.length !== this.props.directory.idPath.length  ) {
      this.setState({
        directory: this.props.directory
      });
    } 
  }

  render(){
    var level = 0;
    var idPath = this.state.directory ? this.state.directory.idPath : [];
    console.log(this.props.directory, "leaf")
  	return (
      <div id="rw-file-list-view"> 
            { !this.state.loading
              && this.props.data && !Array.isArray(this.props.data) ? <ListItem data={ this.props.data.children ? this.props.data.children : null } 
                      level={level} 
                      name={this.props.data.name} 
                      changeDirectory={this.props.changeDirectory}
                      directory={this.state.directory}
                      levelOpen={idPath[0] == this.props.data.id ? true : false}
                      id={this.props.data.id }
                      idPath={this.props.data.idPath} 
                      isLeaf={this.props.data.isLeaf}
                      /> : <p> no directories currently available to view. </p>
            }
      </div>

    )
  }


}

export default RWFileListView;