/* eslint-disable prettier/prettier */
import {
	FETCH_START,
	FETCH_FAIL,
	GET_DSA_DATA
} from '../actions/S2reportActions';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	errorMsg: null,
	loading: true,
};

const fetchStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true})

const fetchFail = (state, action) =>
	updateObject(state, {
		errorMsg: action.errorMsg,
		loading: false,
	});

const getS2Data = (state, action) =>
	updateObject(state, {
		data: action.s2data,
		errorMsg: null,
		loading: false
	});

export const S2reportReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_START:
			return fetchStart(state, action);
		case FETCH_FAIL:
			return fetchFail(state, action);
		case GET_DSA_DATA:
			return getS2Data(state, action);
		default:
			return state;
	}
};
