import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import './companyDetails.scss';
import axiosAuth from '../../../redux/axiosInstance';
import DropDown from '../../../components/Admin/DropDown';
import AddModal from './../CompanyManagement/AddModal';
import ReportLists from './Reports';
import MainContent from '../../../components/Layouts/MainContent';
import * as actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import moment from 'moment';
import noimage from '../../../../static/images/noimage.jpg';

const data = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400,
		amt: 2400
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398,
		amt: 2210
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100
	}
];

const metricOptions = [
	{ label: 'Metric1', value: 'Metric1' },
	{ label: 'Metric2', value: 'Metric2' },
	{ label: 'Metric3', value: 'Metric3' }
];

const periodOptions = [
	{ label: 'period1', value: 'period1' },
	{ label: 'period2', value: 'period2' },
	{ label: 'period3', value: 'period3' }
];

class CompanyDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			companyDetails: {},
			isModal: false,
			editInfo: ''
		};
	}

	toggle = () => {
		this.setState((state) => ({ dropdownOpen: !state.dropdownOpen }));
	};

	toggleModal = () => {
		this.setState((state) => ({ isModal: !state.isModal, editInfo: '' }));
	};

	handleEdit = (company) => {
		this.setState({ editInfo: company, isModal: true });
	};

	componentDidMount() {
		const companyId = this.props.match.params.id;
		this.props.fetchMicroService();
		this.props.fetchReportsByCompany(companyId, '');
		axiosAuth.get(`/companies/${companyId}/`).then((res) => {
			this.setState({ companyDetails: res.data });
		});
	}

	addCompany = (company) => {
		const { editInfo, companyDetails } = this.state;
		companyDetails.name = company.name;
		companyDetails.website = company.website;
		companyDetails.email = company.email;
		companyDetails.phone = company.phone;
		companyDetails.image = company.image;
		this.setState({ companyDetails });
		const companyEdit = { ...editInfo, ...company };
		companyEdit.updated_at = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
		companyEdit.updated_by = this.props.currentUser.id;
		this.props.editCompany(companyEdit, editInfo.id);
		this.toggleModal();
	};

	render() {
		const {
			isModal,
			editInfo,
			companyDetails,
			companyDetails: { name, website, email, phone, image }
		} = this.state;
		const { companies, users, allMS } = this.props;
		const companyId = this.props.match.params.id;
		return (
		  <MainContent>
			<section className="reseller-wrapper">
				<div className="container-fluid">
					<div className="row m-tablet mt-3">
						<div className="col-md-4 m-tab">
							<div className="reseller-details">
								<img src={image || noimage } alt="no logo" />

								<div className="company-info">
									<h5>
										{name}
										<a onClick={() => this.handleEdit(companyDetails)}>Edit</a>
									</h5>
									<ul>
										<li>
											<h6>CompanyID:</h6> <p>{companyId}</p>
										</li>
										<li>
											<h6>Website:</h6> <p>{website || 'N/A'}</p>
										</li>
										<li>
											<h6>Email:</h6> <p>{email || 'N/A'}</p>
										</li>
										<li>
											<h6>Phone:</h6> <p>{phone || 'N/A'}</p>
										</li>
										<li>
											<h6>Total Scans (across all history): </h6> <p>X</p>
										</li>
										<li>
											<h6>Scans This Month: </h6> <p>Y</p>
										</li>
									</ul>
								</div>
								<div className="subscriptions">
									<h5>Subscription</h5>
									<div className="revenue-data">
										<span>
											<p>Revenue:</p> <p>$75,000/mo.</p>
										</span>
									</div>
								</div>
								<div className="payment-section">
									<h5>Payment Status: </h5>
									<div>
										<span className="paid">
											<p>6</p> paid
										</span>
										<span className="upcoming">
											<p>4</p> upcoming
										</span>
										<span className="overdue">
											<p>3</p> Overdue
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-8 m-tab">
							<div className="report">
								<div className="charts">
									<div className="row w-100">
										<div className="col-md-6 col-xs-12">
											<div className="chart-wrapper">
												<div className="group-dropdown">
													<h5>Report</h5>
													<DropDown
														options={metricOptions}
														placeholder="Select Metric"
														isBlackTheme={true}
													/>
													<DropDown
														options={periodOptions}
														placeholder="Select Period"
														isBlackTheme={true}
													/>
												</div>
												<LineChart
													width={400}
													height={250}
													data={data}
													margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
												>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis dataKey="name" />
													<YAxis />
													<Tooltip />
													<Legend />
													<Line type="monotone" dataKey="pv" stroke="#8884d8" />
													<Line type="monotone" dataKey="uv" stroke="#82ca9d" />
												</LineChart>
											</div>
										</div>
										<div className="col-md-6 col-xs-12 ">
											<div className="chart-wrapper">
												<div className="group-dropdown">
													<DropDown
														options={metricOptions}
														placeholder="Select Metric"
														isBlackTheme={true}
													/>
													<DropDown
														options={periodOptions}
														placeholder="Select Period"
														isBlackTheme={true}
													/>
												</div>
												<LineChart
													width={400}
													height={250}
													data={data}
													margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
												>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis dataKey="name" />
													<YAxis />
													<Tooltip />
													<Legend />
													<Line type="monotone" dataKey="pv" stroke="#8884d8" />
													<Line type="monotone" dataKey="uv" stroke="#82ca9d" />
												</LineChart>
											</div>
										</div>
									</div>
								</div>
								<div className="support">
									<div>
										<ReportLists companyId={companyId} allMS={allMS} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isModal && (
					<AddModal
						isOpen={isModal}
						toggleModal={this.toggleModal}
						users={users}
						editInfo={editInfo}
						addCompany={this.addCompany}
					/>
				)}
			</section>
		  </MainContent>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.company.isCompanyLoad,
	companies: state.company.companies,
	users: state.auth.users,
	currentUser: state.auth.user,
	errorMsg: state.auth.errorMsg,
	allMS: state.ms.allMS
});

const mapDispatchToProps = (dispatch) => ({
	fetchMicroService: () => dispatch(actions.fetchMicroService()),
	fetchCompanies: () => dispatch(actions.fetchCompanies()),
	addCompany: (company) => dispatch(actions.addCompany(company)),
	editCompany: (company, id) => dispatch(actions.editCompany(company, id)),
	onDeleteCompany: (id, callback) => dispatch(actions.onDeleteCompany(id, callback)),
	fetchReportsByCompany: (company_id, type) => dispatch(actions.fetchReportsByCompany(company_id, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
