import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

import './reegis.scss';
import Button from "../../../components/Admin/Button";
import DropDown from '../../../components/Admin/DropDown';

const data = [
    {
        "name": "Page A",
        "uv": 4000,
        "pv": 2400,
        "amt": 2400
    },
    {
        "name": "Page B",
        "uv": 3000,
        "pv": 1398,
        "amt": 2210
    },
    {
        "name": "Page C",
        "uv": 2000,
        "pv": 9800,
        "amt": 2290
    },
    {
        "name": "Page D",
        "uv": 2780,
        "pv": 3908,
        "amt": 2000
    },
    {
        "name": "Page E",
        "uv": 1890,
        "pv": 4800,
        "amt": 2181
    },
    {
        "name": "Page F",
        "uv": 2390,
        "pv": 3800,
        "amt": 2500
    },
    {
        "name": "Page G",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    }
];

const providerOptions = [
    {label: 'Provider 1', value: 'provider1'},
    {label: 'Provider 2', value: 'provider 2'},
    {label: 'Provider 3', value: 'provider3'}
];

const metricOptions = [
    {label: 'Metric1',value: 'Metric1'},
    {label: 'Metric2',value: 'Metric2'},
    {label: 'Metric3',value: 'Metric3'},
];

const periodOptions = [
    {label: 'period1',value: 'period1'},
    {label: 'period2',value: 'period2'},
    {label: 'period3',value: 'period3'},
];

export default class Reegis extends Component {

    render() {
        return (
            <section className="reggis-wrapper">
                <h3>Reegis</h3>
                <div className="container-fluid">
                    <div className="row m-tablet">

                        <div className="col-md-4 m-tab mt-3">
                            <div className="reggis-image">
                                <img src="/images/noimage.jpg" alt='noimage'/>
                            </div>
                        </div>
                        <div className="col-md-8 m-tab">
                           <div className="service-content">
                                <h5>Proof of Value</h5>
                               <Button text="Go to Zuora (or whatever billing platform)" />
                           </div>
                            <div className="service-block">
                                <ul className='left-alignment'>
                                    <li>
                                        <span>
                                            <h6>Service(s)</h6>
                                            <p>NMap</p>
                                            <p>BlackSwan data audit</p>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <h6>Deliverable Proof of Value</h6>
                                            <p>- Identify all security risks,provide financial risk value</p>
                                            <p>- Successful NMap setup and scan</p>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <h6>PoV Delivery Date</h6>
                                            <p>August 14, 2019 12:00:00 PST</p>
                                        </span>
                                    </li>
                                </ul>
                                <ul className='right-alignment'>
                                    <li>
                                        <span>
                                            <h6>Anticipated Subscription</h6>
                                            <p>-$10,550 / month</p>
                                            <p>-Billed second Friday of month</p>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <h6>Anticipated Subscription Start Date</h6>
                                            <p>-August 16, 2019</p>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <h6>Subscribed Services</h6>
                                            <p>-BlackSwan </p>
                                            <p>-NMap </p>
                                            <p>-TED Platform subscription </p>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="subscription-section">
                            <h5>Subscription</h5>
                            <div className="subscriptions-detail">
                                    <ul>
                                        <li>
                                            <span>
                                                <h6>Subscription</h6>
                                                <p>$10,550 / month billed second friday of month</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h6>Account Age</h6>
                                                <p>13 months</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h6>Total Lifetime Value</h6>
                                                <p>$5,600 / month</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h6>Gross Margins</h6>
                                                <p>$7,500 / month</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h6>ROI</h6>
                                                <p>$5,375 / month </p>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <h6>Payment Terms</h6>
                                                <p>net 30</p>
                                            </span>
                                        </li>
                                    </ul>
                                <div className='report'>
                                    <div className='charts'>
                                        <div className="row w-100">
                                            <div className="col-md-6 col-xs-12">
                                                <div className="chart-wrapper">
                                                    <div className="group-dropdown">
                                                        <DropDown options={metricOptions} placeholder="Select Metric" isBlackTheme={true}/>
                                                        &nbsp; <span>from</span>
                                                        <DropDown options={periodOptions} placeholder="Select Period" isBlackTheme={true}/>
                                                        &nbsp; <span>to</span>
                                                        <DropDown options={periodOptions} placeholder="Select Period" isBlackTheme={true}/>
                                                    </div>
                                                    <LineChart width={400} height={250} data={data}
                                                               margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                                                        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                                    </LineChart>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-12 ">
                                                <div className="chart-wrapper">
                                                    <div className="group-dropdown">
                                                        <DropDown options={metricOptions} placeholder="Select Metric" isBlackTheme={true} />
                                                        &nbsp; <span>from</span>
                                                        <DropDown options={periodOptions} placeholder="Select Period" isBlackTheme={true} />
                                                        &nbsp; <span>to</span>
                                                        <DropDown options={periodOptions} placeholder="Select Period" isBlackTheme={true} />
                                                    </div>
                                                    <LineChart width={400} height={250} data={data}
                                                               margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                                                        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                                    </LineChart>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
