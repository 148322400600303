import axiosAuth from "../axiosInstance";

export const GET_GENERAL_SETTINGS_SUCCESS = 'GET_GENERAL_SETTINGS_SUCCESS';
export const GET_GENERAL_SETTINGS_FAIL = 'GET_GENERAL_SETTINGS_FAIL';
export const SAVE_GENERAL_SETTINGS_SUCCESS = 'SAVE_GENERAL_SETTINGS_SUCCESS';
export const SAVE_GENERAL_SETTINGS_FAIL = 'SAVE_GENERAL_SETTINGS_FAIL';
export const RESET_GENERAL_SETTINGS_FLAGS = 'RESET_GENERAL_SETTINGS_FLAGS';

export const getGeneralSettings = () => async (dispatch) => {
    await axiosAuth
        .get('settings/gen-settings')
        .then(resData => {
            dispatch({
                type: GET_GENERAL_SETTINGS_SUCCESS,
                settings: resData.data.data,
            });
        })
        .catch(err => {
            console.log('error in save settings :', err);
            dispatch({
                type: GET_GENERAL_SETTINGS_FAIL,
                error: 'Something went wrong, please try again later.',
            });
        });
};

export const saveGeneralSettings = (payload) => async (dispatch) => {
    await axiosAuth
        .post('settings/gen-settings', payload)
        .then(resData => {
            dispatch({
                type: SAVE_GENERAL_SETTINGS_SUCCESS,
                settings: resData.data.data,
            });
        })
        .catch(err => {
            console.log('error in save settings :', err);
            dispatch({
                type: SAVE_GENERAL_SETTINGS_FAIL,
                error: 'Something went wrong, please try again later.',
            });
        });
};

export const resetSettingFlags = () => (dispatch) => {
    dispatch({
        type: RESET_GENERAL_SETTINGS_FLAGS
    })
};
