import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';
import Select from 'react-select';

const renderField = (field) => {
	const { input, label, placeholder, type, name, meta: { touched, error, warning } } = field;
	return (
		<Form.Group controlId={name}>
			<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
			<input type={type} placeholder={placeholder} {...input} className="form-control" />
			{touched &&
				((error && <p className="help is-danger">{error}</p>) ||
					(warning && <p className="help is-danger">{warning}</p>))}
		</Form.Group>
	);
};

const renderSelectField = (field) => {
	const { input, label, placeholder, options, name, meta: { touched, error, warning } } = field;

	return (
		<Form.Group controlId={name}>
			<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
			<Select
				{...input}
				placeholder={placeholder}
				onChange={(value) => input.onChange(value)}
				onBlur={() => input.onBlur(input.value)}
				options={options}
				className="form-control"
			/>
			{touched &&
				((error && <p className="help is-danger">{error}</p>) ||
					(warning && <p className="help is-danger">{warning}</p>))}
		</Form.Group>
	);
};

class AddModal extends React.Component {
	constructor(props) {
		super(props);
		const editInfo = props.editInfo || {};
		this.state = {
			name: editInfo.name || '',
			website: editInfo.website || '',
			users: props.users || [],
			user_id: editInfo.user_id || '',
			image: '',
			uploading: false
		};
	}

	addCompany = (data) => {
		const { name, website, user, phone, email } = data;
		const companyDetails = {
			name,
			website,
			phone,
			email
		};
		if (!this.props.editInfo) {
			companyDetails.user_id = user.value;
		}
		if (this.state.image) {
			companyDetails.image = this.state.image;
		}
		this.props.addCompany(companyDetails);
	};

	componentDidMount() {
		const { editInfo, users } = this.props;
		if (editInfo) {
			const user = users.find((user) => user.id === editInfo.user_id);
			this.props.dispatch(change('AddCompanyForm', 'email', editInfo.email));
			this.props.dispatch(change('AddCompanyForm', 'name', editInfo.name));
			this.props.dispatch(change('AddCompanyForm', 'phone', editInfo.phone));
			this.props.dispatch(change('AddCompanyForm', 'website', editInfo.website));
			// this.props.dispatch(change('AddCompanyForm', 'user_id', {label: user.first_name,value: user.id}));
		}
	}

	handleChange = async (e) => {
		if (e.target.files.length) {
			const file = e.target.files[0];
			this.setState({ uploading: true });
			// const key = await s3Upload(file);
			const photo = '';// await Storage.get(key, { level: 'private' });
			this.setState({ image: photo, uploading: false });
		}
	};

	render() {
		const { isOpen, toggleModal, editInfo, users, handleSubmit } = this.props;
		const { uploading } = this.state;
		const userOptions = users.map((user) => {
			return {
				label: user.first_name,
				value: user.id
			};
		});
		return (
			<div>
				<Modal isOpen={isOpen} toggle={toggleModal}>
					<ModalHeader toggle={toggleModal}>{editInfo ? 'Edit Company' : 'Add Company'}</ModalHeader>
					<ModalBody>
						<Form onSubmit={handleSubmit(this.addCompany)}>
							<Field
								name="name"
								type="text"
								placeholder="Enter Name"
								component={renderField}
								label="Name"
							/>
							<Field
								name="email"
								type="email"
								placeholder="Enter Email"
								component={renderField}
								label="Email"
							/>
							<Field
								name="website"
								type="text"
								placeholder="Enter Website"
								component={renderField}
								label="Website"
							/>
							<Field
								name="phone"
								type="number"
								placeholder="Enter Phone"
								component={renderField}
								label="Phone Number"
							/>
							{!editInfo && (
								<Field
									name="user"
									placeholder="Enter select User"
									component={renderSelectField}
									options={userOptions}
									label="Select User"
								/>
							)}
							<input className="form-control" type="file" onChange={this.handleChange} />
							<ModalFooter>
								<Button disabled={uploading} type="submit" color="primary">
									{uploading ? 'Uploading..' : editInfo ? 'Edit' : 'Add'}
								</Button>{' '}
								<Button color="secondary" onClick={toggleModal}>
									Cancel
								</Button>
							</ModalFooter>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}
const validate = (values, props) => {
	const errors = {};
	if (!values.name) {
		errors.name = 'Field is required';
	}
	if (!values.website) {
		errors.website = 'Field is required';
	}
	if (!props.editInfo && !values.user) {
		errors.user = 'Field is required';
	}
	if (!values.email) {
		errors.email = 'Field is required';
	}
	if (!values.phone) {
		errors.phone = 'Field is required';
	}
	return errors;
};

export default reduxForm({
	form: 'AddCompanyForm',
	validate
})(AddModal);
