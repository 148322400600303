import React from 'react';
import { connect } from 'react-redux';
import './../../reveal-why.scss';

import RangeSelector from '../../../../components/Charts/ChartUI/RangeSelector';
import RWHorizontalBar  from './RWHorizontalBar';
import RWFileListView  from './RWFileListView';

class RWRightColumn1 extends React.Component{

	constructor(props){
      super(props);
      this.state = {
        directory: this.props.directory
      }
	}

	handleChangeData(id) {
      const selectedId = Number.parseInt(id); 
  }

  componentDidUpdate(props, state) {
    if (props.directory.id !== this.props.directory.id) {
      this.setState({
        directory: this.props.directory
      });
    } 
  }

	render(){

    const { scanId, scanList, changeCurrentScan, data, view, fileData, changeDirectory, directory} = this.props;

		return (

		  <div>
            <div className={"range-container"} >
          	{ !view && <RangeSelector
                  currentScan={scanId}
                  scanList={scanList}
                  changeScan={(val) => changeCurrentScan(val)}
                />}
            </div>
            
            <div className={"directory-sens-container"} >
          	  { (data != null) && view ? (<RWFileListView  
                                  data={fileData}
                                  changeDirectory={changeDirectory}
                                  directory={directory}
          	  	                />) : (
          	            	    <RWHorizontalBar 
          	                      data={data.nodes.children}
          	            	    />
          	                  ) 
          	  } 
            </div>

            <div className={"bottom-text-container"} >
          	  <p style={{textAlign: "center", marginTop: "40px"}}>Comparison of files by sensitivity.</p>
            </div>

          </div>
		)
	}

}

export default RWRightColumn1;