"use strict";
import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { Container } from 'react-bootstrap';
import './../../reveal-why.scss';

import { deriveUnitCost } from '../../../../common/utils/deriveUnitCost';
import { currencyFormatter } from '../../../../common/utils/currencyFormatter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, 
	     faFile
	    } from '@fortawesome/free-solid-svg-icons'

import DropDown from '../../../../components/Admin/DropDown';
import RWVerticalBar  from './RWVerticalBar';

const toolTipKeys = {
  name: "Name", value: "Value($)", sensitivity: "Sensitivity", categories: "Categories", numInstances: "PII Count"
};


const clone = (selector) => {
    var node = d3.select(selector).node();
    return d3.select(node.parentNode.insertBefore(node.cloneNode(true), node.nextSibling));
}

const findDirectory = (data, directory) => {
  const {name, level} = directory;
  var i = 0;
  if(data.name == name && level == 0){
  	return data;
  } else {
    function recursiveSearch(data, name, level, i){
    	if( (data.children == undefined && name != data.name ) || ( i >= level && name != data.name ) ){
    		return false;
    	} else if ( i == level && name == data.name  ){
            return data.children ?  {name: data.name, 
                                     children: data.children,
                                     categories: data.categories
                                     } : {name: data.name,
                                          categories: data.categories
                                          };
    	} else {
    		i += 1;
    		for(let j = 0 ; j < data.children.length; j++){
                let result = recursiveSearch(data.children[j], name, level, i);
                if( result ){
                	return result
                } 
    		}
    	}
    }
    
    return recursiveSearch(data, name, level, i) ? recursiveSearch(data, name, level, i)  : data;
    
  }

}

const flatten = (root, depth = 1) => {
  var nodes = [], i = 0;

  function recurse(node) {
    if (node.children && node.depth < depth ) node.children.forEach(recurse);
    nodes.push(node);
  }

  recurse(root);
  return nodes;
}

const customHLinks = function(root, depth = 1) {
  var links = [];
  root.each(function(node) {
    if (node !== root && node.depth === depth ) { // Don’t include the root’s parent, if any.
      links.push({source: node.parent, target: node, strength: -.02,
         active: (node.parent.currentDepth && node.data.active) ? true : false   });
    }
  });
  return links;
}

const plotAction = (g, width, height, data) => {

    var hierarchy = d3.hierarchy(data),
        links = customHLinks(hierarchy),
        nodes = flatten(hierarchy);

    var shiftKey,
        that = this;
    

    var simulation = d3.forceSimulation(nodes)
                       .force("link", d3.forceLink(links)
                                    .id(function(d) { return d.index; })
                                    .distance(200)
                             )
                       .force("charge", d3.forceManyBody().strength(-20) )
                       .force("center", d3.forceCenter(window.innerWidth / 6, window.innerHeight / 5)  )
                       .force("collision", d3.forceCollide().radius(15))
                       .tick(300);

    var linkContainer = g.append("g").selectAll(".link");
    linkContainer = linkContainer.data(links, function(d) { return d.source.index + "-" + d.target.index; }).lower();
    linkContainer.exit().remove();    

    var nodesContainer = g.append("g").attr("stroke", "#6e6e6e")
                                      .attr("stroke-width", 0.5)
                                      .selectAll("foreignObject")
                                      .classed('nodes', true)
                                      .data(nodes, function(d) { return d.index; });
    nodesContainer.exit().remove();


    
    var node = nodesContainer.enter().append("foreignObject")
                                     .attr("class", "node")
                                     .on("mousedown", mousedowned)
                                     .on('mouseenter', function(d) {
                                        callNodeTooltip(d)
                                     } )
                                     .on('mouseleave',function() {
                                        d3.select("#plot-tooltip").style("display", "none");
                                     } )
                                     .call(d3.drag().on("drag",(d) => dragged(d, 0) ).on('end', (d) => dragged(d, 1)  ));  
    
    //node = linkContainer.merge(node)

    var link = linkContainer.enter().append("path").attr("class", "rw-links").lower();

    //link = linkContainer.merge(link);

    function linkDefinition(){

        link.style("stroke", function(d) {
            return "#85df85"   
          })


        link.attr("d", function(d) {
          
          //below determines curvature of arcs.
          var x1 = d.source.x + 20,
              y1 = d.source.y + 15,
              x2 = d.target.x + 20,
              y2 = d.target.y + 15,
              dx = x2 - x1,
              dy = y2 - y1,
              dr =  Math.sqrt((dx * dx) + (dy * dy)) - 100,
              arc1 = dr / (2 / 5 ),
              arc2 = dr / (2 / 5 );

          var xRotation = 15,
              largeArc = 0,
              sweep = 0;

          if( (x2 > x1 && y2 > y1) || (x1 > x2 && y1 > y2) ){
            sweep = 1;
          }

          if(x1 === x2 && y1 === y2){

              x1 = x1 + 10;
              y1 = y1 - 0;
              x2 = x2 - 10;
              y2 = y2 + 0;
              d.count = Math.ceil(d.count/2);
              arc1 = dr * 5 ;
              arc2 = arc1 / 1.3;
              xRotation = -180;
              largeArc = 1;
            }

          let A2 = "A" + arc1 + "," + arc2 + " " + xRotation + " " + largeArc  + "," + sweep +  " " +x2 + "," + y2;

           return ("M" + x1 + "," + y1 + " " +
                    A2);

        }).attr("fill", 'none').attr("stroke-width", 2 );


      }
    var folder = d3.select("#rw-folder-icon").clone(true);
    var file = d3.select("#rw-file-icon").clone(true);
    
    var ticked = (e) => {
       
        node.attr("x", function(d) { return d.x })
            .attr("y", function(d) { return d.y })
            .attr("width", 30)
            .attr("height", 30)
            .append("xhtml:div")
            .classed("folder-div-node", true)
            .html( (d) => { 
            	if(d.children){
            		return folder.classed("folder-div-node", true)
                                            .attr('x', 15)
                                            .attr('y', -17)
                                            .attr("width", 40)
                                            .attr("height", 30)
                        	                .html()
            	} else {
            		 return file.classed("file-div-node", true)
                                            .attr('x', 15)
                                            .attr('y', -17)
                                            .attr("width", 25)
                                            .attr("height", 35)
                        	                .html()
            	}

              }
            )
            
            .attr('font-size', function (d) { return '20pt' });

            linkDefinition();

    }

    function mousedowned(d) {
        if (shiftKey) {
          d3.select(this).classed("selected", d.selected = !d.selected);
          d3.event.stopImmediatePropagation();
        } else if (!d.selected) {
               
          node.classed("selected", function(p) {
            if(p.selected = d === p ){
              //filter nodes on whether they are children of selected node.
              nodes.forEach(function(n){
              	if(n.parent && d === n.parent ){
              		n.selected = true
              	} else {
              		n.selected = false
              	}     
              })

              return (p.selected = d === p );
            }
          });

        } else {
          node.classed("selected", function(p) {
            if(p.selected = d === p ){
              //filter nodes on whether they are parent of selected node.
              nodes.forEach(function(n){
              	if(d.parent && n === d.parent ){
              		n.selected = false
              	}    
              })
              return (p.selected = d === p );
            }
          });
        }
      }

    function nudge(dx, dy) {
        
        //called when nodes are in process of being dragged.
        node.filter(function(d) { return d.selected; })
            .attr("x", function(d) {
              let x = d.x + dx;
              return d.x += dx;
            })
            .attr("y", function(d) {
              let y = d.y + dy;
              return d.y += dy;
            })

        linkDefinition();

      }

      function dragged(d, dragged = 0) {
        nudge(d3.event.dx, d3.event.dy);
      }

    simulation.on("tick", ticked);



}

const drawNetworkPlot = (data, unitCost, directory) =>{
  
  var margin = {top: 50, right: 50, bottom: 50, left: 50},
      width = 600 - margin.left - margin.right,
      height = 450 - margin.top - margin.bottom;

  var container = d3.select("#rw-network-chart");
  container.selectAll("*").remove();
  var svg = container.append("svg")
                     .attr("preserveAspectRatio", "xMinYMin meet")
                     .attr("viewBox", "0 0 600 450")
                     .classed("svg-content-rw-plot", true);

  var g = svg.append("g")
             .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  data = findDirectory(data, directory);

  //console.log(data, "directoryData")

  plotAction(g, width, height, data) 

}

//tooltip define attributes
const callNodeTooltip = (d) => {
  d = d.data ? d.data : d
  let items = ``, properties= {};
      properties.name = d.name;
      properties.categories = d.categories ? [... new Set(d.categories.map((el) => el[0] ))].join(', ') : null;
      properties.numInstances = d.numInstances ? d.numInstances : null;

  for (let [key, value] of Object.entries(properties)) {
    if(Object.keys(toolTipKeys).indexOf(key) != -1 && value != null ){
      items += `<li>${toolTipKeys[key]}: ${value}</li>`;
    } 
  }

  let htmlStr = `<ul class="tool-tip-list">${items}</ul>`;
  displayTooltip(htmlStr);

}

//tooltip diplay
const displayTooltip = (htmlStr) =>{
        let xPos = d3.event.pageX,
            yPos = d3.event.pageY;

        let toolTip = d3.select("#plot-tooltip")
          .style("display", "block")
          .style("top", (yPos + 20 )+"px")
          .style("left", (xPos + 10 ) +"px")
          .html(htmlStr);

        toolTip.style("opacity", 1)

}

//derives cat options from higher-level category list.
const listofCatOptions = (data) => {
  var options = [{value: 0, label: "All"}],
      object = Object.keys(data);
  
  object.forEach((o, i) => {
    if(o != undefined){
      options.push({value: i + 1 , label: o})
    }
  })

  return options;

}

//groups all instances within a given directory by category name for rendering in bar chart
const catMerge = (catItems, directory) => {
  var tracker = {}, mergedCats = [];

  console.log(catItems, "cats!")
  
  catItems.forEach((d) => {

    if(!tracker.hasOwnProperty(d.catName)){
      tracker[d.catName] = d.numInstances
    } else {
      tracker[d.catName] += d.numInstances
    }

  })

  return Object.keys(tracker).map((d) => { return { name: directory.name, catName: d, numInstances: tracker[d] } })

}

//Generates nested data structure for organizing data in tandem with string filter for document view
const groupCatsByName = (catItems, stringFilter) => {
  var catObject = {};

  
  catItems.filter((cat) =>{ 

    return cat.cats.filter((c) => String(c[1]).indexOf(stringFilter) >= 0 )

  } ).forEach((cat) => {
    if(!catObject.hasOwnProperty(cat.name)){
      catObject[cat.name] = {}
    }

    if(!catObject[cat.name].hasOwnProperty(cat.catName)){
      catObject[cat.name][cat.catName] = []
    }
    
    cat.cats.filter((c) => c[1].indexOf(stringFilter) >= 0 ).forEach((c) => {
      catObject[cat.name][cat.catName].push(c)
    })

  })

  return catObject;

}


//Defines list of pii infractions for document view.
const listByNameAndCat = (catItems, stringFilter) => {

  if (catItems.length < 0) {
    return (<p>There is no data to list in the current directory</p>)
  } else {
    return (
      <div className={"info-list-container"}>
        {
          Object.keys(catItems).map((d) => {
            return (
              <div className={"info-list-file"}>
                <h6>Filename: <strong>{d}</strong></h6>
                  {
                    Object.keys(catItems[d]).map((c) => {
                      return (
                        <div className={"info-list-cat"} >
                          <p>Category: <strong>{c}</strong></p>
                          { 
                            catItems[d][c].map((a, i) =>{
                              if(i < 100){
                                return (
                                  <div className={"info-list-item"}>
                                    <p>{i+1}. Position: x:<span style={{color: "lightblue"}}> {a[0][0]}</span> y:<span style={{color: "lightblue"}}> {a[0][1]}</span></p>
                                    <p>&nbsp;&nbsp;&nbsp; Value: <span style={{color: "red"}}>{a[1]}</span> </p>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      )
                    })
                  }
              </div>
            )
          })
        }
      </div> 
    );
  }

}


const boxViews = [
  {label: "Instance Analysis", value: 0},
  {label: "Vulnerable Data", value: 1}, 
]


class RWFolderNetworkPlot extends React.Component{

  constructor(props){
    super(props);

    this.state ={
      data: this.props.data,
      unitCost: this.props.unitCost,
      directory: this.props.directory,
      loading: true, 
      view: boxViews[0],
      searchStr: ''    
    }

  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.data != this.props.data || prevProps.unitCost != this.props.unitCost || prevProps.directory != this.props.directory){
      //drawNetworkPlot(this.props.data, this.props.unitCost, this.props.directory)
    }
  }

  componentDidMount(){
     if( this.props.data && this.props.unitCost){
       //drawNetworkPlot(this.props.data, this.props.unitCost, this.props.directory)
     }
  }

  changeView(e){
    
    var view = boxViews[e.target.value];
    
    this.setState({
      view,
      searchStr: view.value != 1 && '' 
    })

  }

  enterSearchStr(e){
    this.setState({
      searchStr: e.target.value
    })
  }

  changeString(e){
    this.setState({
      searchStr: e.target.value
    })
  }

  renderView(view, catItems, catsGroupedByName, stringFilter, directory){
    var data = this.props.data ? findDirectory(this.props.data, this.props.directory) : null;
    switch (view.value){
      case 0:
        return( catItems.length > 0 ? <RWVerticalBar 
                                  data={catMerge(catItems, directory)} 
                                  tooltip={callNodeTooltip}
                                /> : <p>No data available using current filters.</p>
              );
        break;
      case 1:
        return (
          <div style={{ padding:"20px 60px"}}>
            {
              listByNameAndCat(catsGroupedByName, stringFilter)
            }
          </div> 
        );
        break;
      default: 
        return ( <RWVerticalBar data={this.props.categories} /> );
    }
  
  }

  render(){ 
    const stringFilter = this.state.searchStr ? this.state.searchStr.toLowerCase().trim() : ''  ;
    const { categories, category, directory } = this.props;
    const cats = category == "All" ? Object.values(categories).flat() : categories[category];
    const filterDirectory = cats.filter( (d) => d.idPath.indexOf(directory.id) != -1 ); 
    const catDirectory = this.state.searchStr == '' ? filterDirectory : filterDirectory.filter((d) => {
      if( d.name.toLowerCase().indexOf(stringFilter) >= 0 || d.cats.find( (el) => {
          return String(el[1]).toLowerCase().indexOf(stringFilter) >= 0
        } ) ){

        return d;
      } 
    });
    const catsGroupedByName = groupCatsByName(catDirectory, stringFilter); 
    const catItems = catDirectory  //directory.isLeaf ? catDirectory : catMerge( catDirectory );
    console.log(catItems, "trim")

    return (
      
      <div>
        <div className={"file-filter-section"}>
          <div className={"file-filter-section-1"} >
          <DropDown 
            options={ listofCatOptions(categories) }
            selected={0}
            style={{ float: 'left', margin: 10, display: 'inline-block' }}
            placeholder={category}
            onItemSelected={(e) => this.props.changeCategory(e)}
          />
          <DropDown 
            options={ boxViews }
            selected={0}
            style={{ float: 'left', margin: 10, display: 'inline-block' }}
            placeholder={this.state.view.label}
            onItemSelected={(e) => this.changeView(e)}
          />
            <div className={"search-filter-container"}>
              <input type="text" 
                 onChange={this.changeString.bind(this)} 
                 placeholder={"Search"}
                 value={ stringFilter }
                 />
            </div>
          </div>
          <div className={"file-list-box"}>
            {
              category && catItems.length > 0 ? catItems.filter((value, index, self) =>{ 
                                    return self.map((d) => d.id).indexOf(value.id) == index
                                  })
                                  .map((item) => {
                return <div className={"file-list-item"}
                            onClick={() => this.props.changeDirectory({name: item.name, level: item.level, id: item.id, idPath: item.idPath, isLeaf: item.isLeaf })}
                            >{item.name}</div>
              }) : <p>Sorry, but there are no files relevant to the selected filters...</p>
            }
          </div>
        </div>
        <div className={"file-filter-section"}>

        </div>
        <div id="plot-tooltip"></div>
        <div id="rw-folder-icon" style={{display: "none"}}><FontAwesomeIcon icon={faFolder} /></div>
        <div id="rw-file-icon"  style={{display: "none"}}><FontAwesomeIcon icon={faFile} /></div>
        <div className={"file-doc-box"}>
          { this.renderView(this.state.view, catItems, catsGroupedByName, stringFilter, directory) }       
        </div>
        {/*<div className={"bp-container"} id="rw-network-chart"></div>*/}
        <p style={{textAlign: "center", padding:"20px 60px"}}>
          
        </p>
      </div>
  
    )

  }
  

}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(
  mapStateToProps
)(RWFolderNetworkPlot);


