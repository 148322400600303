import React from 'react'
import styled from 'styled-components'
import {Container} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import './layout.scss';
// import {colors} from '../../global/colors'

const StyledContainer = styled.div`
  max-width: 100%;
  background: #2b343a;
  color: white;
  h2 {
    font-size: 1.7rem;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 1vw;
  }
  padding-bottom: 40px;
  overflow: hidden;
  min-height: calc(100vh - 80px);
  padding-top: 59px;
`

const StyledText = styled.h4`
    margin-bottom: 40px;
    margin-top: 20px;
`

const TooltipContainer = styled(ReactTooltip)`
  max-width: 320px;
  background-color: rgba(0,0,0, .8);
  color: #68a5a9 !important;
  padding: 12px !important;
  font-size: 12pt;
  font-weight: bold;
  border-radius: 5px;
  pointer-events: auto;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 100005;

  a {
    color: #66a4a7 ;
    border-bottom: 1px dashed #66a4a7;
    &:hover {
     text-decoration: none;
     color: #86b4b7
    }
  }
`

const tooltipPosition = ({ left, top }, currentEvent) => {
    // This function is called 3 times (once with target node & twice with event)
    // so we need to compute the targetNode
    const targetNode = currentEvent.getBoundingClientRect ? currentEvent : currentEvent.target;
    var doc = document.documentElement, body = document.body,
        scrollLeft = (doc && doc.scrollLeft || body && body.scrollLeft || 0),
        scrollTop = (doc && doc.scrollTop  || body && body.scrollTop  || 0);

    var boundedBox = targetNode?.getBoundingClientRect?.();
    boundedBox.y += boundedBox.y < 300 ? ( scrollTop == 0 ? 0 : 40 ) : -140 ;  

    const { top: targetNodeTop } = boundedBox || {};
    const { left: targetNodeLeft } = targetNode?.getBoundingClientRect?.() || {};



    return { left: targetNodeLeft || left , top: targetNodeTop || top };
}


const MainContent = ({
  children,
  companyName,
  title,
}) => {
  return (
    <StyledContainer>
      <TooltipContainer delayShow={750} html={true} overridePosition={tooltipPosition} />
      <Container>
        {companyName && (
          <StyledText className="company-name">{companyName} {title && (`- ${title}`)}</StyledText>
        ) }
      </Container>
      {children}
    </StyledContainer>
  )
}

export default MainContent
