import React, { useState } from 'react';
import styled from 'styled-components'

const DotRender = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${props => props.active ? '#fff' : 'transparent'};
    cursor: pointer;
    border: ${props => props.active ? 'none' : '2px solid #fff'};
`

const RangeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;   
    margin: 10px 0;
    padding: 0 15px;
    width: 100%;
`

const RangeSelector = (props) => {
    const handleClick = (i) => {
        props.changeScan(props.scanList[i].id);
    };
    const renderRange = () => {
        let items = [];
        for(let i=0 ; i<props.scanList.length ; i++) {
            items.push(<DotRender className={`range-dot ` + (props.scanList[i].id === props.currentScan ? "active" : "") } key={i} active={props.scanList[i].id === props.currentScan} onClick={() => handleClick(i)}/>)
        }
        return items
    }

    return(
        <RangeWrapper className={"range-wrapper"}>
            {renderRange()}
        </RangeWrapper>
    )
}

export default RangeSelector;
