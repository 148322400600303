// Pulls unitCost from sector adjustment table
export function deriveUnitCost(adjustments) {

	let sub = adjustments,
		accumulator = 0;

	for (let i = 0; i < sub.length; i++) {
		if ( parseFloat(sub[i].percentage) && parseFloat(sub[i].cost) ) {
			accumulator = accumulator + sub[i].percentage * sub[i].cost;
		}
	}

    console.log(accumulator);
	return (accumulator / 100).toFixed(2);
}

export function colorClassCVSS(score){
	score = parseFloat(score);
	if (score > 8 && score <= 10) {
	  return { text: 'grey', bg: 'black', level: 'CRITICAL', factor: 1 };
	} else if (score >= 7 && score <= 8) {
	  return { text: 'black', bg: 'red', level: 'HIGH', factor: 0.875 };
	} else if (score < 7 && score > 3) {
	  return { text: 'black', bg: 'orange', level: 'MEDIUM', factor: 0.75 };
	} else if (score <= 3 && score >= 0) {
	  return { text: 'black', bg: 'yellow', level: 'LOW', factor: 0.675 };
	} else {
	  return { text: 'black', bg: 'grey', level: '', factor: 0.5 };
	}
};
