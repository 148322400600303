import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {connect} from "react-redux";
// import Container from 'react-bootstrap/Container'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {
//   faInstagram,
//   faTwitter,
//   faLinkedin
// } from '@fortawesome/free-brands-svg-icons'

const CustomContainer = styled.div`
  max-width: 100%;
  padding: 10px 20px;
  background-color: transparent;
  min-height: 60px;
  color: #808e99;
  margin-left: 90px;
  display: ${({isAuth}) => isAuth ? "auto" : "none"};
  @media (max-width: 768px) { 
        margin-left: 0;
   }
`

const StyledFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  ul {
    padding: none;
    li {
      display: inline;
      margin-right: 20px;
      list-style: none;
    }
  }
`

const Footer = ({
  title,
  action,
  isAuth,
  generalSettings
}) => {
  return (
    <CustomContainer  isAuth={isAuth} >
    <StyledFooter >
        <div className="footer-left">
            <p>TED {generalSettings && generalSettings.site_version || ''} © Trovo LLC, 2021</p>
        </div>       
    </StyledFooter>
    </CustomContainer>
  )
}

Footer.propTypes = {
  title: PropTypes.string,
  action: PropTypes.any,
  isAuth: PropTypes.any
}

const mapStateToProps = state => ({
    generalSettings: state.adminSettings.generalSettings,
});

export default connect(mapStateToProps)(Footer);
