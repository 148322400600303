import axios from 'axios';
import axiosAuth from '../redux/axiosInstance';
import { dataTransformer } from './dataMutators';
import * as parse from 'csv-parse/lib/sync';

const s2URLStruct = (baseURL) => {
  return {
    inventory: {
      url : `${baseURL}/api/Report/DataInventoryReport`,
      table: "s2_di_report",
      cs: [{name: 'data_store_id', column: 0},{name: 'data_store_name', column: 1},{name: 'table_id', column: 2},
           {name: 'table_name', column: 3},{name: 'data_element_id', column: 4 },{name: 'data_element_name', column: 5},
           {name: 'sensitivity_score', column: 6},{name: 'redundancy_multiple', column: 7},
           {name: 'masked', column: 8}, {name: 'row_counts', column: 9}, {name: 'data_type', column: 10},
           {name: 'last_redundancy_scan', column: 11},{name: 'last_sensitivity_scan', column: 12},{name: 'is_active', column: 13}]
    }, redundancy: { 
      url: `${baseURL}/api/Report/RedundancyReport`,
      table: "s2_r_report",
      cs: [{name: 'redundancy_check_id', column: 0},{name:  'left_data_store_id', column: 1},{name:  'left_table_id', column: 3}, 
           {name: 'left_data_element_id', column: 5}, {name: 'right_data_store_id', column: 7}, {name: 'right_table_id', column: 9}, 
           {name: 'right_data_element_id', column: 11}]
    }, sensitivity: {
      url: `${baseURL}/api/Report/SensitivityReport`,
      table: "s2_s_report",
      cs: [{name: "sensitivity_tag_id", column: 0},{name: 'data_store_id', column: 1}, {name: 'table_id', column: 3}, 
           {name: 'data_element_id', column: 5}]
    },
  }
}


const csvStruct = () => {
  return {
    inventory: {
      table: "s2_di_report",
      cs: [{name: 'data_store_id', column: 0},{name: 'data_store_name', column: 1},{name: 'table_id', column: 2},
           {name: 'table_name', column: 3},{name: 'data_element_id', column: 4 },{name: 'data_element_name', column: 5},
           {name: 'sensitivity_score', column: 6},{name: 'redundancy_multiple', column: 7},
           {name: 'masked', column: 8}, {name: 'row_counts', column: 9}, {name: 'data_type', column: 10},
           {name: 'last_redundancy_scan', column: 11},{name: 'last_sensitivity_scan', column: 12},{name: 'is_active', column: 13}]
    }, redundancy: { 
      table: "s2_r_report",
      cs: [{name: 'redundancy_check_id', column: 0},{name:  'left_data_store_id', column: 1},{name:  'left_table_id', column: 3}, 
           {name: 'left_data_element_id', column: 5}, {name: 'right_data_store_id', column: 7}, {name: 'right_table_id', column: 9}, 
           {name: 'right_data_element_id', column: 11}]
    }, sensitivity: {
      table: "s2_s_report",
      cs: [{name: "sensitivity_tag_id", column: 0},{name: 'data_store_id', column: 1}, {name: 'table_id', column: 3}, 
           {name: 'data_element_id', column: 5}]
    },
  }
}

const getCompanyById = async (companyId) => {
  const res = await axiosAuth
            .get(`company/${companyId}`)
            .then(resData => {
              return resData.data.data
            })
            .catch(err => {
              console.log(err.message, 'getCompanyById')
          });
  return res;
}

const s2AuthToken = async (baseURL, username, password) => {
  const data = {
      username,
      password
    }
  const res = await axios
      .post(`${baseURL}/api/Users/authenticate`, data)
      .then(resData => {
        return resData.data.token;
      })
      .catch(err => {
        console.log(err.message, 's2AuthToken')
    });
  return res;
}

// const getInventory = async (company_id, report_id, LIMIT, OFFSET) => {
//   const data = {
//     company_id,
//     report_id,
//     LIMIT,
//     OFFSET
//   }
//   const res = await axiosAuth
//     .post('reports/get-inventory-data', data)
//     .then(resData => {
//       return resData.data.data
//     })
//     .catch(err => {
//       console.log(err.message, 'getInventory')
//   });
//   return res;
// }

// const getRedundancy = async (company_id, report_id, LIMIT, OFFSET) => {
//   const data = {
//     company_id,
//     report_id,
//     LIMIT,
//     OFFSET
//   }
//   const res = await axiosAuth
//     .post('reports/get-redundancy-data', data)
//     .then(resData => {
//       return resData.data.data
//     })
//     .catch(err => {
//       console.log(err.message, 'getRedundancy')
//   });
//   return res;
// }

// const getSensitivity = async (company_id, report_id, LIMIT, OFFSET) => {
//   const data = {
//     company_id,
//     report_id,
//     LIMIT,
//     OFFSET
//   }
//   const res = await axiosAuth
//     .post('reports/get-sensitivity-data', data)
//     .then(resData => {
//       return resData.data.data
//     })
//     .catch(err => {
//       console.log(err.message, 'getRedundancy')
//   });
//   return res;
// }

const getInventory = async (company_id, report_id, LIMIT, OFFSET) => {
  const data = {
    company_id,
    report_id,
    LIMIT,
    OFFSET
  }
  const res = await axiosAuth
    .get(`/reports/get-inventory-data?company__id=${company_id}&report__id=${report_id}&limit=${LIMIT}&offset=${OFFSET}`)
    .then(resData => {
      return resData.data.results
    })
    .catch(err => {
      console.log(err.message, 'getInventory')
  });
  return res;
}

const getRedundancy = async (company_id, report_id, LIMIT, OFFSET) => {
  const data = {
    company_id,
    report_id,
    LIMIT,
    OFFSET
  }
  const res = await axiosAuth
    .get(`reports/get-redundancy-data?company__id=${company_id}&report__id=${report_id}&limit=${LIMIT}&offset=${OFFSET}`)
    .then(resData => {
      return resData.data.results
    })
    .catch(err => {
      console.log(err.message, 'getRedundancy')
  });
  return res;
}

const getSensitivity = async (company_id, report_id, LIMIT, OFFSET) => {
  const data = {
    company_id,
    report_id,
    LIMIT,
    OFFSET
  }
  const res = await axiosAuth
    .get(`reports/get-sensitivity-data?company__id=${company_id}&report__id=${report_id}&limit=${LIMIT}&offset=${OFFSET}`)
    .then(resData => {
      return resData.data.results
    })
    .catch(err => {
      console.log(err.message, 'getRedundancy')
  });
  return res;
}

// const getStats = async (id) =>{
//   const res = await axiosAuth
//       .get(`reports/get-scan-stats/${id}`)
//       .then(resData => {
//         return resData.data.data;
//       })
//       .catch(err => {
//         console.log(err.message, 'getStats')
//     });
//   return res;
// }

const createMSReport = async (data) => {
  const res = await axiosAuth
      .post('/create-ms-report/', data)
      .then(resData => {
        console.log(resData, 'createNewMicroserviceReport');
        return resData.data.data;
      })
      .catch(err => {
        console.log(err.message, 'createNewMicroserviceReport')
    });
  return res;
}

// const createNmapReport = async (company_id, report_id, nmapdata, date) => {
//   const data = {
//     company_id,
//     nmapdata,
//     report_id,
//     date
//   }
//   const res = await axiosAuth
//       .post('nmap', data)
//       .then(resData => {
//         return resData.data.data;
//       })
//       .catch(err => {
//         console.log(err.message, 'createNmapReport')
//     });
//   return res;
// }

const importToPG = async (data, struct, reportId, companyId) => {
  const params = {
    csvData: data,
    struct,
    reportId,
    companyId
  }
  const res = await axiosAuth
      .post('import-csv-data', params)
      .then(resData => {
        return resData.data.data
      })
      .catch(err => {
        console.log(err.message, 'importToPG')
    });
  return res;
}

const getReportsFromS2 = (struct, token) => {
  const url = struct.url;
  // const table = struct.table;
  var cs = struct.cs;
  return axios.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      }
    })
    .then(response => { 
      const res = parse(response.data, {
          columns: header =>
             header.map( (column, i) => {
              return cs.filter((d) => {
                return i == d.column
              })[0]
          } ),
          skip_empty_lines: true,
          skip_lines_with_error: true
      })
      return res;
    })
    .catch(response => {
      console.log(response)
    })
}

const fileReader = new FileReader();

const handleFileRead = (e) => {
  console.log(fileReader.result)
  return fileReader.result;
}

const getReportsFromCSV = async (file, struct) => {
  // const url = struct.url;
  // const table = struct.table;
  var cs = struct.cs;

  return new Promise(function(resolve) {
        fileReader.onload = function() {
          resolve(fileReader.result);
        };
        fileReader.readAsText(file)
      }).then(function(elem) {
        const res = parse(elem, {
          columns: header =>
             header.map( (column, i) => {
              return cs.filter((d) => {
                return i == d.column
              })[0]
          } ),
          skip_empty_lines: true,
          skip_lines_with_error: true,
          trim: true,
          skip_lines_with_empty_values: true
        })

        return res;
      })

}

const createDataStore = (url, fields, token) => {
  return axios.post(`${url}/api/DataStore?startAutoScan=true`, fields, { 
        headers: { 
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        }
      }).then((res) => {
        return res.data;
      }).catch((e)=> {
        return e
      });
}
/*
const startJob = (url, data_store_id, token) => {
  return axios.get(`${url}/api/Job/StartAutoScan?dataStoreId=${data_store_id}`, { 
        headers: { 
          Accept: 'application/json',
          Authorization: 'Bearer ' + token 
        }
      }).then((res)=> {
        return res.data
      }).catch((e)=> {
        return e
      })
}
*/
const initScan = async(url, data, callback) => {
  const token = await s2AuthToken(url, "admin", "ChangeMe2017!");
  try {
    if(token) {
      await createDataStore(url, data, token);
      //await startJob(url, data.id, token)
      callback();
    }    
  } catch (error) {
    console.log('error initScan ', error);
  }  
 
}

const getReportsForDSA = async(company_id, report_id, unitcost, LIMIT = 20000, OFFSET = 0) => {
  const res1 = await getInventory(company_id, report_id, LIMIT, OFFSET);
  const res2 = await getRedundancy(company_id, report_id, LIMIT, OFFSET);
  const data = await dataTransformer(res2, res1, unitcost);
  console.log(data)
  return data;
}

// const getMSCompScanStats = async (companyId) => {
//   const res = await axiosAuth
//       .get(`reports/get-company-scan-stats/${companyId}`)
//       .then(resData => {
//         return resData.data.data;
//       })
//       .catch(err => {
//         console.log(err.message, 'getMSCompScanStats')
//     });
//   return res;
// } 

const getMSCompScanList = async (companyId, type) => {
  const params = {
    company_id: companyId,
    type: type,
  }
  const res = await axiosAuth
      .get(`/reports/?company__id=${companyId}&microservice__id=${type}`)
      .then(resData => {
        return resData.data.results;
      })
      .catch(err => {
        console.log(err.message, 'getMSCompScanList')
    });
  return res;
}

const uploadCSVToPG = async (inv, red, companyId, scanDate, reportType, callback) => {
    const struct = csvStruct();
    const inventoryData = await getReportsFromCSV(inv, struct.inventory);
    const redundancyData = await getReportsFromCSV(red, struct.redundancy);  
    const data = {
      companyId: companyId,
      scanDate: scanDate,
      type: reportType,
      inventoryData: inventoryData,
      redundancyData: redundancyData
    } 
    await createMSReport(data);
    callback();  
}

const uploadReportsToPG = async (inv, red, companyId, struct, unitcost, ms_id, scanDate) => {
  //try {

    const inventoryData = await getReportsFromCSV(inv, struct.inventory);
    const redundancyData = await getReportsFromCSV(red, struct.redundancy);
   
    const stats = {}; //JSON.stringify(dataTransformer(redundancyData , inventoryData, unitcost).scanStats);
    const reportId = await createMSReport(companyId, scanDate, stats, ms_id, '1');  

    // await importToPG(inventoryData, struct.inventory, reportId["id"], companyId);
    // await importToPG(redundancyData, struct.redundancy, reportId["id"], companyId);
    //const sensitivity = await importToPG(res3, struct.sensitivity, report.id, companyId);

    const check = `Microservice Report Id: ${reportId["id"]} \n Inventory Rows Inserted: ${inventoryData.length} \n Redundancy Rows Inserted: ${redundancyData.length}`;
    return check;
  //} catch{
  //  const check = "Something went wrong, please try again.";
  //  return check;
  //}
  
}

const uploadNmapXMLToPG = async (data, callback) => {
  await createMSReport(data);
  callback();  
}

const uploadRWJSONToPG = async (data, callback) => {
  await createMSReport(data);
  callback();  
}

const getReportsS2 = async(token, companyId, ms_id, struct, unitcost, callback) => {  
  try {
    const res1 = await getReportsFromS2(struct.inventory, token);
    const res2 = await getReportsFromS2(struct.redundancy, token);
    const report = await createMSReport(companyId, new Date(), stats, ms_id, '1');    
    await importToPG(res1, struct.inventory, report.id, companyId);
    await importToPG(res2, struct.redundancy, report.id, companyId);
    callback();
  } catch {
    return false;
  }
}

const getReportsS2WithListener = async (company, token) => {
  const baseURL = `https://${company.name}.trovoai.com`;
  // return name and auth varaibles(not yet implementable)
  // const token = await postForAuthToken(baseUrl, "admin", "ChangeMe2017!" );
  const struct = s2URLStruct(baseURL);
  const res1 = await getReportsFromS2(struct.inventory, token);
  const res2 = await getReportsFromS2(struct.redundancy, token);
  
  const stats = {}; //JSON.stringify(dataTransformer(res2, res1, 30.5).scanStats);
  const reportId = await createMSReport(company.id, new Date(), stats, '1');

  //const res3 = await getReportsFromS2(struct.sensitivity, token);

  const inventory = await importToPG(res1, struct.inventory, reportId, company.id);
  const redundancy = await importToPG(res2, struct.redundancy, reportId, company.id);
  //const sensitivity = await importToPG(res3, struct.sensitivity, reportId, company.id);
  //Sensitivity Rows Inserted: ${sensitivity.length} 

  try{
    const check = `Microservice Report Id: ${reportId} \nInventory Rows Inserted: ${inventory.length} \nRedundancy Rows Inserted: ${redundancy.length}`;
    return check;
  } catch{
    const check = "Something went wrong, please try again.";
    return check;
  }
}

// returns pre-defined remediation report for dashboard download
const getRemediationReport = async (company_id, LIMIT= 5000) => {
  const res = await axiosAuth
              .get(`/reports/get-insights?company__id=${company_id}&limit=${LIMIT}`)
              .then((res) => {
                var remData = res.data.results.sort((a, b) => parseInt(b.sensitivity_score) - parseInt(a.sensitivity_score) );
                return remData;
              }).catch((e) => {
                console.log(e);
                return [];
              })

  return res;
}

const getCyberScans = async (company_id) => {
  const res = await axiosAuth
          .get(`/reports/nmap?company__id=${company_id}` )
          .then(res => { 
            // console.log(res.data.results, 'results')
            return res.data.results;
          })
          .catch(err => {
            console.log(err.message, 'nmap scan error')
            return 0;
          });

  return res;
    
}

const getRWScans = async (project_id) => {
  const res = await axiosAuth
              .get(`reveal/on-premise-experiment?project__id=${project_id}`)
              .then(res => {
                console.log(res.data.results, "data")
                return res.data.results;
              }).catch(err => {
                console.log(err.message, 'nmap scan error')
                return 0;
              });

  return res;
}

//fetches table data in DS
const fetchDSTableData = async (params) => {

  const res = await axiosAuth
    .get(`/reports/get-dsa-table-data?data_store_id=${params.data_store_id}&company__id=${params.company_id}&report__id=${params.report_id}`)
    .then((resData) => {
      //console.log(resData.data)
      return resData.data.data;
    })
    .catch((err) => {
      console.log(err.message)
      return err.message;
    });

  return res;
};


export {
  s2URLStruct,
  getReportsS2WithListener,
  getReportsS2,
  getReportsForDSA,
  getRemediationReport,
  createMSReport,
  getInventory,
  getRedundancy,
  getSensitivity,
  s2AuthToken,
  getCompanyById,
  getMSCompScanList,
  uploadReportsToPG,
  initScan,
  uploadCSVToPG,
  uploadNmapXMLToPG,
  uploadRWJSONToPG,
  getCyberScans,
  getRWScans, 
  fetchDSTableData 
}