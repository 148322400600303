/* eslint-disable prettier/prettier */
import {
	FETCH_START,
	FORM_START,
	FORM_COMPLETE,
	FETCH_FAIL,
	PW_GET_USER_HEALTH_STATUS,
	PW_ADD_USER_HEALTH_STATUS,
	UPLOAD_CSV_HEALTH_DATA,
} from '../actions/PWActions';
import { updateObject } from '../utility';

const initialState = {
	healthStatusData: [],
	errorMsg: null,
	message: null,
	loading: true,
	submit: false,
};

const fetchStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true, submit: false });
const fromStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: true });
const fromComplete = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: false });

const fetchFail = (state, action) =>
	updateObject(state, {
		errorMsg: action.errorMsg,
		loading: false,
		submit: false
	});

	const getHealthStatusData = (state, action) =>
	updateObject(state, {
		healthStatusData: action.status,
		errorMsg: null,
		loading: false
	});

	const addHealthStatusData = (state, action) =>
	updateObject(state, {
		errorMsg: null,
		loading: false
	});

	const uploadCsvFile = (state, action) =>
	updateObject(state, {
		errorMsg: null,
		loading: false
	});

export const PWReducer = (state = initialState, action) => {
	switch (action.type) {
		case FORM_START:
			return fromStart(state, action);
		case FORM_COMPLETE:
			return fromComplete(state, action);
		case FETCH_START:
			return fetchStart(state, action);
		case FETCH_FAIL:
			return fetchFail(state, action);
		case PW_GET_USER_HEALTH_STATUS:
			return getHealthStatusData(state, action);
		case PW_ADD_USER_HEALTH_STATUS:
			return addHealthStatusData(state, action);
		case UPLOAD_CSV_HEALTH_DATA:
			return uploadCsvFile(state, action);
		default:
			return state;
	}
};
