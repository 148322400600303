import React from 'react';
import './alert.scss';

const Alert = ({message, date}) => (

        <div className="alert-content">
            {message ?
                <div className="alert-details">
                    <div className="alert-section">
                    <span className='alert-icon'>
                        <img src="/images/information.svg" alt='information'/>
                    </span>
                    <span className='alert-message'>
                        <p> {message}</p>
                    </span>
                    </div>
                    <span className='date'>
                        {date}
                    </span>
                </div>:
                <h6>Alert</h6>
            }

        </div>
)

export default Alert;
