/* eslint-disable prettier/prettier */
import {
	FETCH_START,
	FORM_START,
	FORM_COMPLETE,
	FETCH_FAIL,
	ADD_MS_SUCCESS,
	FETCH_MS_SUCCESS,
	GET_COMPANY_REPORTS,
	GET_MS_LIST
} from '../actions/MSActions';
import { updateObject } from '../utility';

const initialState = {
	ms: [],
	reports: [],
	errorMsg: null,
	message: null,
	loading: true,
	submit: false,
	allMS: []
};

const fetchStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true, submit: false });
const fromStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: true });
const fromComplete = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: false });

const fetchCompleteAllMS = (state, action) =>
	updateObject(state, {
		allMS: action.allMS,
		errorMsg: null,
		loading: false
	});

const addMS = (state, action) =>
	updateObject(state, {
		errorMsg: null,
		loading: false
	});

const fetchFail = (state, action) =>
	updateObject(state, {
		errorMsg: action.errorMsg,
		loading: false,
		submit: false
	});

const getCompanyReports = (state, action) =>
	updateObject(state, {
		reports: action.reports,
		errorMsg: null,
		loading: false
	});

export const MSReducer = (state = initialState, action) => {
	switch (action.type) {
		case FORM_START:
			return fromStart(state, action);
		case FORM_COMPLETE:
			return fromComplete(state, action);
		case FETCH_START:
			return fetchStart(state, action);
		case GET_MS_LIST:
			return fetchCompleteAllMS(state, action);
		case ADD_MS_SUCCESS:
			return addMS(state, action);
		case FETCH_FAIL:
			return fetchFail(state, action);
		case GET_COMPANY_REPORTS:
			return getCompanyReports(state, action);
		default:
			return state;
	}
};
