import axiosAuth from '../axiosInstance';

export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';
export const FETCH_RESELLERS = 'FETCH_RESELLERS';
export const ADD_RESELLER_START = 'ADD_RESELLER_START';
export const ADD_RESELLER_FAIL = 'ADD_RESELLER_FAIL';
export const ADD_RESELLER_SUCCESS = 'ADD_RESELLER_SUCCESS';
export const EDIT_RESELLER_START = 'EDIT_RESELLER_START';
export const EDIT_RESELLER_FAIL = 'EDIT_RESELLER_FAIL';
export const EDIT_RESELLER_SUCCESS = 'EDIT_RESELLER_SUCCESS';


export const fetchStart = () => ({
  type: FETCH_START,
});

export const fetchFail = errorMsg => ({
  type: FETCH_FAIL,
  errorMsg,
});

export const addStart = () => ({
  type: ADD_RESELLER_START,
});

export const addFail = (message) => ({
  type: ADD_RESELLER_FAIL,
  errMsg: message
});

export const addComplete = (reseller) => ({
  type: ADD_RESELLER_SUCCESS,
  reseller: reseller
});

export const editStart = () => ({
  type: EDIT_RESELLER_START,
});

export const editFail = (message) => ({
  type: EDIT_RESELLER_FAIL,
  errMsg: message
});

export const editComplete = (reseller) => ({
  type: EDIT_RESELLER_SUCCESS,
  reseller: reseller
});

export const getResellers = resellers => {
  return {
    type: FETCH_RESELLERS,
    resellers
  }
}

export const fetchResellers = () => async (dispatch) => {
  dispatch(fetchStart());
    await axiosAuth
      .get('/reseller')
      .then(resData => {
        dispatch(getResellers(resData.data.data));
      })
      .catch(err => {
        dispatch(fetchFail(err.message));
    });
};

export const addReseller = (reseller) => async (dispatch) => {
  dispatch(addStart());
  axiosAuth
  .post(`/reseller`, reseller)
  .then(resData => {
    dispatch(addComplete(resData.data.data));
  })
  .catch(err => {
    dispatch(addFail(err.message));
  });
}

export const editReseller = (reseller, id) => async (dispatch) => {
  dispatch(editStart());
  axiosAuth
  .put(`/reseller/${id}`, reseller)
  .then(resData => {
    dispatch(editComplete(resData.data.data));
  })
  .catch(err => {
    dispatch(editFail(err.message));
  });
}