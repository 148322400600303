import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import NavBar from '../NavBar';
import Footer from '../Footer';
import Sidebar from '../../Layouts/Sidebar';
import './routes.scss';

export const PublicRoute = ({ component: Component, isAuth, loginUser, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<div>
					<div className="main-content">
						<Component {...props} />
					</div>
				</div>
			)}
		/>
	);
};

export const NotFound = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<div>
				<NavBar />
				<div className="main-content">Page Not Found</div>
			</div>
		)}
	/>
);
