import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { authRoutes, withoutAuthRoutes, adminRoutes } from './routes';

import PageHeader from './components/Layouts/PageHeader';
import Spinner from './components/Spinner/Spinner';
import Footer from './components/Layouts/Footer';
import { PublicRoute } from './components/Admin/Routes';
import * as actions from './redux/actions/index';

const StyledBody = styled.div`
	background: #2b343a;
	min-height: 100vh;
	max-width: 100%;
`;

const RouteWrapper = styled.div`
  padding-left: ${(props) => (props.isAuth ? '90px' : '0px')}  
  overflow: auto;
  @media (max-width: 768px) { 
    padding-left: 0;
  }
`;

class Handler extends React.Component {
  render () {
    var navData = this.constructor.items;
    return navData;
  }
}

const Container = (props) => {
	const { onTryAutoSignup, loading, user, isAuthenticated } = props;
	let defaultRoutes = '';
	useEffect(
		() => {
			onTryAutoSignup();
		},
		[ isAuthenticated ]
	);
	const userType = user.user_role;
	if (loading) {
		return <Spinner />;
	} else {
		if (isAuthenticated) {
			defaultRoutes = (
				<Switch>
					{authRoutes && authRoutes.map( (route )=>{  
						Handler.items= {
							routes: authRoutes.filter((d) => d.group == route.group)  
						} 
						return <Route exact key={route.name} {...route} 
													handler={Handler} />
						})}
					{userType === 1 &&
						adminRoutes &&
						adminRoutes.map((route) => (
							<PublicRoute
								isAuth={isAuthenticated}
								loginUser={props.user}
								exact
								key={route.name}
								{...route}
							/>
						))}
					<Redirect from="*" to={userType === 1 ? '/admin' : '/'} />
				</Switch>
			);
		} else {
			defaultRoutes = (
				<Switch>
					{withoutAuthRoutes && withoutAuthRoutes.map((route) => <Route exact key={route.name} {...route} />)}
					<Redirect from="*" to="/" />
				</Switch>
			);
		}
	}

	return (
		<StyledBody>
			<Router>
				<Suspense fallback={<Spinner />}>
					<PageHeader isAuth={isAuthenticated} loginUser={user} />
					<RouteWrapper isAuth={isAuthenticated}>{defaultRoutes}</RouteWrapper>
					<Footer isAuth={isAuthenticated} />
				</Suspense>
			</Router>
		</StyledBody>
	);
};

const mapStateToProps = (state) => ({
	loading: state.auth.loading,
	user: state.auth.user,
	isAuthenticated: state.auth.idToken !== null
});

const mapDispatchToProps = (dispatch) => ({
	onTryAutoSignup: () => dispatch(actions.authCheckState())
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
