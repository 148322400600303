import React from 'react';
import { connect } from 'react-redux';
import './reveal-why.scss';
import size from 'lodash/size';
import{ Container, Row, Column }from 'react-bootstrap';
import Switch from 'react-switch'
import { ToastContainer, toast } from 'react-toastify';

import MainContent from '../../../components/Layouts/MainContent';
import RWBubblePlot  from '../../../components/Charts/RWBubblePlot';
import RWRightColumn1  from './RWComponents/RWRightColumn1';
import RWFolderNetwork  from './RWComponents/RWFolderNetwork';

import AddExperimentModal from './AddExperimentModal';

import { deriveUnitCost } from '../../../common/utils/deriveUnitCost';
import * as actions from '../../../redux/actions/index';

import { data2, data3 } from './RWComponents/tmp-data.js'
import {
  getRWScans
} from '../../../utilities/s2APIHelpers';

import DropDown from '../../../components/Admin/DropDown';

const viewList = [ "Bubble Plot", "Directory View" ]

// const dJSON = require('dirty-json');

//Recursive function that extracts categories from leaf nodesand bubbles them up 
//for better search results.
const extractChildCategories = (data) => {

  var level = 0;

  function bubbleUpCats(children, flag = true, labyrinthine, idPath){

    children.forEach((d) => {
      if(!d.children && d.categories != "" ) {
        let cats = d.categories;
        d.idPath = [...idPath]; 
        d.idPath.push(d.id);
        cats.forEach((cat) => {
          cat[1] = {
            id: d.id, 
            level: labyrinthine, 
            catName: cat[0],
            name: d.name, 
            cats: cat[1], 
            idPath: d.idPath,
            numInstances: ( cat[1] != "" && cat[1].length > 0 ) ? cat[1].length : 0,
            isLeaf: true
          };
        })
        d.isLeaf = true;
        d.level = labyrinthine;
        d.categories = cats;
        return d.categories;
      } else if(d.children && !Array.isArray(d.categories)){ 
        d.idPath = [...idPath];
        d.idPath.push(d.id);
        d.isLeaf = false;
        bubbleUpCats(d.children, false, labyrinthine + 1, d.idPath)
        d.level = labyrinthine;
        d.children.forEach((c, i)=>{
          if(i ==0 && Array.isArray(c.categories)){
            d.categories = c.categories
          } else if(Array.isArray(d.categories) && Array.isArray(c.categories)){
            c.categories.forEach((cat)=> {
              d.categories.push(cat)
            })
          } 
        })      
      }
    })
    
    if(flag){
      var rootCategories = []

      children.forEach((d) => {
        if(d.categories){
          d.categories.forEach((cat) => {
            rootCategories.push(cat)
          })
        }
      })
    
      return rootCategories;
    }
  }
  
  if(data.children && data.categories == ""){  
    var idPath = [];
    idPath.push(data.id); 
    data.idPath = idPath; 
    data.level = level;
    data.isLeaf = false;
    data.categories = bubbleUpCats(data.children, true, level+1, data.idPath)
  }
  
  return data;

}

//creates categorical objects for filtering at the top level
const createCatObject = (array) => {

  var catObject = {};

  if(array == undefined || array.length == 0){
    return catObject;
  }

  for(let i = 0; i < array.length; i++){

    if(!catObject.hasOwnProperty(array[i][0])){
      catObject[array[i][0]] = [];
    }
    
    catObject[array[i][0]].push(array[i][1])

  }

  return catObject;

}

const createRWDataOptions = (rwData) => {
  return rwData.map((d, i) => {
    return {label: d.name, value: i};
  })
}

class RWONPremise extends React.Component {

  constructor(props){
  	super(props);

    this.state ={
      data2: null,
      unitCost: null,
      scanId: null,
      scanList: null,
      view: false,
      loading: false,
      directory: null,
      isModal: false,
			isLoading: false
    } 

  }

  async componentDidMount(){
    //temporary fetch for test json
    const {user, unitCost, project_id} = await this.props;
    await this.props.onFetchS2Data({company_id: user.company.id});
    const s2data = this.props.s2data;
    const rwData = await getRWScans(project_id);
    // const rwData = await this.props.getOnPremiseExperiment(project_id);
    await this.props.getProjectList(user.company.id);
    console.log(rwData, "rwdata");

    var data2 = rwData.length > 0 ? extractChildCategories( JSON.parse(rwData[rwData.length - 1].scan_data) ) : null,
        currentName = rwData.length > 0 ? rwData[rwData.length - 1].name : "No Data Available",
        dataOptions = rwData.length > 0 ? createRWDataOptions(rwData) : [{name: "No Data Available", value: 0}];

    if (size(rwData) > 0 && size(s2data) > 0 && Array.isArray(s2data) ){        
      var report_id = s2data[s2data.length - 1].id,
          data = s2data.filter((d) => d.id == report_id && d.microservice == 1 )[0].data_stores,
          categories = data2 ? createCatObject(data2.categories) : null,
          directory = data2 ? {id: data2.id,name: data2.name, level: 0, idPath: data2.idPath, isLeaf: data2.isLeaf} : null;
      this.setState({
        data,
        data2,
        categories,
        currentCategory: "All",
        unitCost,
        scanId: report_id,
        scanList: s2data,
        loading: false,
        directory,
        currentName,
        dataOptions
      })       
    } else {
      this.setState({
        data2,
        currentCategory: "All",
        loading: false,
        currentName,
        dataOptions
      }) 
    }

  }

  async changeData(e){
     var val = e.target.value;
     const {project_id} = await this.props;
     const rwData = await getRWScans(project_id);

     if (rwData && rwData.length > 0 && Array.isArray(rwData) ){        
      var data2 = rwData.length > 0 ? extractChildCategories( JSON.parse(rwData[val].scan_data) ) : null,
          currentName = rwData.length > 0 ? rwData[val].name : "No Data Available",
          dataOptions = rwData.length > 0 ? createRWDataOptions(rwData) : [{name: "No Data Available", value: 0}],
          categories = data2 ? createCatObject(data2.categories) : null,
          directory = data2 ? {id: data2.id,name: data2.name, level: 0, idPath: data2.idPath, isLeaf: data2.isLeaf} : null;
      this.setState({
        data2,
        categories,
        directory,
        currentName,
        dataOptions
      })       
    } 

  }

  changeCurrentScan(val){
        
    var data = this.props.s2data.filter((d) => d.id == val)[0].data_stores

    this.setState({
    	scanId: val,
    	data
    })

  }

  changeView(){
    this.setState({
    	view: !this.state.view
    })
  }

  changeDirectory(value){
     this.setState({
     	directory: value
     })
  }

  changeCategory(e){
    var val = e.target.value,
        cat = val == 0 ? "All" : Object.keys(this.state.categories)[val - 1] 
        //All is val = 0, while this would be redundant in parsed categories

    this.setState({
      currentCategory: cat
    })
  }

  renderProjectInfo = (projects, project_id) => { 
    const project = projects.filter((p) => p.id === +project_id);
    return (
      <div>{project[0] ? project[0].project_name: ''}</div>
    );
  };

  toggleModal = () => {
		this.setState((state) => ({ isModal: !state.isModal }));
  };
  
  handleForm = () => {
		this.setState({ isModal: true });
  };

  notifySuccess = (msg) => toast.success(msg);

  notifyError = (msg) => toast.error(msg);
  
  addExperimentForm = (values) => {
    console.log(values, 'values - addExperimentForm');
    this.setState({ isLoading: true });
    const nData = values.file;
    const reader = new FileReader();
    reader.readAsText(nData);
    reader.onload = async () => {
      const jsonObj = JSON.parse(reader.result)
      const data = {
        name: values.name,
        scan_data: JSON.stringify(jsonObj),
        project: this.props.project_id,
      };
      await this.props.addOnPremiseExperiment(data, () => {
        this.setState({ isLoading: false });
        this.notifySuccess('Experiment has been successfully created');
        this.toggleModal();
      });
    };
  }

  render(){
  	const { user, projects, project_id } = this.props;
    const { isModal, isLoading, categories, data2, view, directory, currentName, dataOptions } = this.state;
  	return(
      <MainContent>
        <ToastContainer position="bottom-center" />
        <Container className="container-class">
          <div>
            <h3 className="title-h">{ size(projects.results) > 0 ? this.renderProjectInfo(projects.results, project_id): null }</h3>
          </div>
        </Container>
        <Container className="container-class">
        <div className="switch">
        <div className="react-switch-label">
          <DropDown 
            options={ dataOptions }
            selected={0}
            style={{ float: 'left', margin: 10, display: 'inline-block' }}
            placeholder={currentName}
            onItemSelected={(e) => this.changeData(e)}
          />
        </div>
        </div>
        <div className="switch">
        <div className="react-switch-label">
          <p onClick={this.handleForm} className="btn-link-cursor">Add New Experiment</p>
          {isModal && (
            <AddExperimentModal
              isOpen={isModal}
              isLoading={isLoading}
              toggleModal={this.toggleModal}
              addExperimentForm={this.addExperimentForm}
            />
          )}
        </div>
        </div>
        <div className="switch">
        <div className="react-switch-label">
          <label style={{right: 0, fontSize: "13pt", marginBottom: "0px"}} >
            <Switch onChange={this.changeView.bind(this)}
                checked={view}
                onColor="#68a5a9"
                offColor="#68a5a9"
                onHandleColor="#fff"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={36}
                className="rw-react-switch"
                id="material-switch"
            />
          </label>
          <p> { view ? viewList[0] : viewList[1] }</p>
          </div>
        </div>         
        </Container>
        <Container className="container-class">
      	  <div className="col-style-left"> 
      	  {
      	  categories && data2 && (!view ? 
                             <RWFolderNetwork data={data2} 
                                              unitCost={this.props.unitCost} 
                                              directory={directory}
                                              categories={categories}
                                              category={this.state.currentCategory}
                                              changeCategory={this.changeCategory.bind(this)}
                                              changeDirectory={this.changeDirectory.bind(this)} />
                               :
                             <RWBubblePlot data={this.state.data} unitCost={this.props.unitCost} />) 
      	  }
      	  </div>
          <div className="col-style-right">
            { 
                this.state.categories && this.state.data2 && this.state.scanList && <RWRightColumn1 
                   scanId={this.state.scanId}
                   scanList={this.state.scanList}
                   changeCurrentScan={this.changeCurrentScan.bind(this)}
                   data={this.state.data}
                   fileData={data2}
                   view={!view}
                   changeDirectory={this.changeDirectory.bind(this)}
                   directory={directory}
                 />
            }
          </div>
      	</Container>
      </MainContent>
  	)
  }

}

const mapStateToProps = state => ({
  user: state.auth.user,
  loading: state.company.loading,
  unitCost: deriveUnitCost(state.auth.user.adjustments),
  s2data: state.s2data.data,
  projects: state.rw.projects,
  onPremiseExpData: state.rw.onPremiseExpData
})

const mapDispatchToProps = (dispatch) => ({
  onFetchS2Data: (fields) => dispatch(actions.fetchS2Data(fields)),
  getProjectList: (company_id) => dispatch(actions.getProjectList(company_id)),
  addOnPremiseExperiment: (params, callback) => dispatch(actions.addOnPremiseExperiment(params, callback)),
  getOnPremiseExperiment: (project_id) => dispatch(actions.getOnPremiseExperiment(project_id)),
});


export default connect(
	mapStateToProps,
    mapDispatchToProps
)(RWONPremise);