import React, {Component} from 'react';
import { isEqual } from 'lodash';
import {
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import {connect} from "react-redux";
import './adminSettings.scss';
import * as adminSettingsActions from "../../../redux/actions/AdminSettingsActions";

class AdminSettings extends Component {
    constructor(props) {
        super(props);
        this.state ={
            activeTab: '1',
            generalSettings: props.generalSettings,
            loading: false,
            alert: null
        };
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.generalSettings, this.props.generalSettings)) {
            this.setState({ generalSettings: this.props.generalSettings});
        }
        if (prevProps.saveSettingsSuccess !== this.props.saveSettingsSuccess) {
            let alert = null;
            if (this.props.saveSettingsSuccess) {
                alert = {
                    type: 'success',
                    message: this.props.saveSettingsSuccess
                };
            }
            this.setState({ alert, loading: false });
        }
        if (prevProps.saveSettingsError !== this.props.saveSettingsError) {
            let alert = null;
            if (this.props.saveSettingsError) {
                alert = {
                    type: 'danger',
                    message: this.props.saveSettingsError
                };
            }
            this.setState({ alert, loading: false });
        }
    }

    handleInputChange = (e) => {
        const { generalSettings } = this.state;
        generalSettings[e.target.name] = e.target.value;
        this.setState({ generalSettings });
    };

    handleFileChange = async (e) => {
        if(e.target.files.length) {
            const file = e.target.files[0];
            this.setState({ loading: true });
            // const key = await s3Upload(file);
            const photo = ''; //await Storage.get(key, {level: "private"});
            const { generalSettings } = this.state;
            generalSettings.site_logo = photo;
            this.setState({ generalSettings, loading: false });
        }
    };

    toggle = tab => {
        this.setState({ activeTab: tab });
    };

    handleSubmit = () => {
        const { generalSettings } = this.state;
        delete generalSettings.id;
        this.setState({loading: true});
        this.props.saveGeneralSettings(generalSettings);
    };

    hideAlert = () => {
      this.setState({ alert: null});
      this.props.resetSettingFlags();
    };

    renderAlert = () => {
        const { alert } = this.state;
        return (
            <SweetAlert
                type={alert.type}
                style={{ display: 'block', marginTop: '-100px' }}
                title={alert.type === 'success' ? 'Success' : 'Failed'}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="info">
                {alert.message}
            </SweetAlert>
        );
    };

    render() {
        const { activeTab } = this.state;
        const { generalSettings, loading, alert } = this.state;
        return (
            <div className="container-fluid">
                <div className="admin-form-wrapper">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? 'active' : ''}
                                onClick={() => { this.toggle('1'); }}
                            >
                                General
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '2' ? 'active' : ''}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Roles
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '3' ? 'active' : ''}
                                onClick={() => { this.toggle('3'); }}
                            >
                                Micro Services
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '4' ? 'active' : ''}
                                onClick={() => { this.toggle('4'); }}
                            >
                                Role access
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="tab-content-wrapper">
                                <Form>
                                    <FormGroup row>
                                        <Label sm={3}>Title</Label>
                                        <Col sm={9}>
                                            <Input type="text"
                                                   name="site_title"
                                                   placeholder="Title"
                                                   value={generalSettings.site_title}
                                                   onChange={this.handleInputChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={3}>Description</Label>
                                        <Col sm={9}>
                                            <Input type="text"
                                                   name="site_description"
                                                   placeholder="Description"
                                                   value={generalSettings.site_description}
                                                   onChange={this.handleInputChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={3}>Email</Label>
                                        <Col sm={9}>
                                            <Input type="email"
                                                   name="email_address"
                                                   placeholder="Email"
                                                   value={generalSettings.email_address}
                                                   onChange={this.handleInputChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={3}>Version</Label>
                                        <Col sm={9}>
                                            <Input type="text"
                                                   name="site_version"
                                                   placeholder="Version"
                                                   value={generalSettings.site_version}
                                                   onChange={this.handleInputChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={3}>Primary Logo File</Label>
                                        <Col sm={9}>
                                            <Input type="file" name="file" onChange={this.handleFileChange}/>
                                        </Col>
                                    </FormGroup>
                                    <Button color="primary" disabled={loading} onClick={this.handleSubmit}>{loading ? 'loading..' : 'Save'}</Button>
                                </Form>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="tab-content-wrapper">
                                <h4>Under Development</h4>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="tab-content-wrapper">
                                <h4>Under Development</h4>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="tab-content-wrapper">
                                <h4>Under Development</h4>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
                {alert && this.renderAlert()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    generalSettings: state.adminSettings.generalSettings,
    saveSettingsSuccess: state.adminSettings.saveSettingsSuccess,
    saveSettingsError: state.adminSettings.errors.SaveGeneralSettings,
});

const mapDispatchToProps = dispatch => ({
    saveGeneralSettings: (payload) => dispatch(adminSettingsActions.saveGeneralSettings(payload)),
    resetSettingFlags: () => dispatch(adminSettingsActions.resetSettingFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
