export const colors = {
  orange: '#f16924',
  darkTeal: '#3c9198',
  teal: '#a6cfd1',
  darkBlue: '#343b4a',
  //cream: '#f5f6ed',
  //offWhite: '#fffef9',
  gray: '#afb4b4',
  lightGreen: '#97B5B7',
  lightPurple: '#8387B2'
}