import React, { Component } from 'react';
import './account-details.scss';
import CustomTable from '../../../components/Admin/Table';
import Button from '../../../components/Admin/Button';
import MainContent from '../../../components/Layouts/MainContent';
import noimage from '../../../../static/images/noimage.jpg';
// import DropDown from '../../../components/Admin/DropDown';

const SubjectRender = ({ subject }) => <div className="subject">{subject}</div>;
const StatusRender = ({ status }) => {
	return (
		<div className="status-render">
			{status === 'ready' && (
				<div className="ready">
					<span />Ready
				</div>
			)}
			{status === 'scheduled' && (
				<div className="scheduled">
					<span />Scheduled
				</div>
			)}
			{status === 'offline' && (
				<div className="offline">
					<span />Offline
				</div>
			)}
			{status === 'upgrade' && (
				<div className="upgrade">
					<span />Upgrade To Use
				</div>
			)}
		</div>
	);
};

const SupportStatusRender = ({ status }) => (
	<div className="status">
		<img src="/images/information.svg" alt="information" /> {status}
	</div>
);

const tableData = {
	columns: [
		{ title: 'Subject', key: 'subject' },
		{ title: 'Case ID', key: 'caseId' },
		{ title: 'Service', key: 'service' },
		{ title: 'Category', key: 'category' },
		{ title: 'Created(UTC-7)', key: 'created' },
		{ title: 'Status', key: 'status' }
	],
	rows: [
		{
			subject: <SubjectRender subject="Update audit cadence to bi-weekly" />,
			caseId: '136',
			service: 'Risk Exposure',
			category: 'Audit Request',
			created: '2019-10-02T14:04',
			status: <SupportStatusRender status="Needs Attention!" />
		}
	]
};

const service = {
	columns: [
		{ title: 'Service', key: 'service' },
		{ title: 'Status', key: 'status' },
		{ title: 'Last Scan(UTC-7)', key: 'lastScan' }
	],
	rows: [
		{
			service: 'Risk Exposure',
			status: <StatusRender status="ready" />,
			lastScan: '2019-10-03T10:10'
		},
		{
			service: 'Cyber Detection',
			status: <StatusRender status="ready" />,
			lastScan: '2019-09-29T12:00'
		},
		{
			service: 'SecurityScorecard',
			status: <StatusRender status="scheduled" />,
			lastScan: '2019-10-01T10:10'
		},
		{
			service: 'Auto ML',
			status: <StatusRender status="upgrade" />,
			lastScan: '2019-07-23T10:10'
		},
		{
			service: 'Predictive CX',
			status: <StatusRender status="upgrade" />,
			lastScan: '---'
		},
		{
			service: 'Microservice API',
			status: <StatusRender status="offline" />,
			lastScan: '---'
		},
		{
			service: 'Microservice API',
			status: <StatusRender status="offline" />,
			lastScan: '---'
		}
	]
};

const providerOptions = [
	{ label: 'Provider 1', value: 'provider1' },
	{ label: 'Provider 2', value: 'provider2' },
	{ label: 'Provider 3', value: 'provider3' }
];

export default class AccountDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle = () => {
		this.setState((state) => ({ dropdownOpen: !state.dropdownOpen }));
	};

	render() {
		return (
		  <MainContent>
			<section className="reseller-wrapper">
				<div className="container-fluid">
					<div className="row m-tablet ">
						<div className="col-md-4 m-tab mt-3">
							<div className="reseller-details">
								<img src={noimage}alt="noimage" />
								<div className="account-info">
									<h5>Company Name</h5>
									<span>
										<p>Reseller:</p> <p>Trovo</p>
									</span>
									<span>
										<p>Website:</p> <p>www.trovo.ai</p>
									</span>
									<h3>Primary Contact:</h3>
									<span>
										<h5>Name:</h5> <p>Patrick Reilly</p>
									</span>
									<span>
										<h5>Email:</h5> <p>patrick@trovo.ai</p>
									</span>
									<span>
										<h5>Phone:</h5> <p>408-667-8302</p>
									</span>
									<h2>
										<b>1,381</b> scans performed since May. 1, 2019
									</h2>
									<h2>
										<b>41.2PB</b> data across <b>21,389</b> stores
									</h2>
								</div>
								<div className="subscriptions">
									<h5>Subscription</h5>
									<div className="revenue-data">
										<span>
											<p>Payment:</p> <p>$15,000/mo</p>
										</span>
										<span>
											<p>Payment Type:</p> <p>ACH</p>
										</span>
										<span>
											<p>Status: </p> <h5>17 days remaining</h5>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-8 m-tab">
							<div className="service-account mt-3">
								<div className="account">
									<div className="account-header">
										<h5>Support</h5>
									</div>
									<div>
										<CustomTable columns={service.columns} rows={service.rows} isBordered={true} />
									</div>
								</div>
								<div className="account">
									<div className="account-header">
										<h5>Support</h5>
										<Button text="Create New Ticket" />
									</div>
									<div>
										<CustomTable
											columns={tableData.columns}
											rows={tableData.rows}
											isBordered={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		  </MainContent>
		);
	}
}
