import axiosAuth from '../axiosInstance';

export const GET_MS_NAME = 'GET_MS_NAME';
export const ADD_MS_NAME = 'ADD_MS_NAME';
export const UPDATE_MS_NAME = 'UPDATE_MS_NAME';
export const FETCH_ALL_MS = 'FETCH_ALL_MS';
export const FETCH_MS_SUCCESS = 'FETCH_MS_SUCCESS';
export const ADD_MS_SUCCESS = 'ADD_MS_SUCCESS';
export const EDIT_MS_SUCCESS = 'EDIT_MS_SUCCESS';
export const GET_COMPANY_REPORTS = 'GET_COMPANY_REPORTS';
export const GET_MS_LIST = 'GET_MS_LIST';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';
export const FORM_START = 'FORM_START';
export const FORM_COMPLETE = 'FORM_COMPLETE';
export const DELETE_REPORT_START = 'DELETE_REPORT_START';
export const DELETE_REPORT_FAIL = 'DELETE_REPORT_FAIL';

export const formStart = () => ({
	type: FORM_START
});

export const formComplete = () => ({
	type: FORM_COMPLETE
});

export const fetchStart = () => ({
	type: FETCH_START
});

export const fetchFail = (errorMsg) => ({
	type: FETCH_FAIL,
	errorMsg
});

export const fetchComplete = (microService) => ({
	type: FETCH_MS_SUCCESS,
	allMS: microService
});

export const getMSList = (microService) => ({
	type: GET_MS_LIST,
	allMS: microService
});

export const addComplete = (microService) => ({
	type: ADD_MS_SUCCESS,
	ms: microService
});

export const editComplete = (microService) => ({
	type: EDIT_MS_SUCCESS,
	ms: microService
});

export const getCompanyReports = (reports) => ({
	type: GET_COMPANY_REPORTS,
	reports
});

export const deleteReportStart = () => ({
	type: DELETE_REPORT_START
});

export const deleteReportFail = (message) => ({
	type: DELETE_REPORT_FAIL,
	errMsg: message
});

export const fetchMicroService = () => async (dispatch) => {
	dispatch(fetchStart());
	await axiosAuth
		.get('/microservice/')
		.then((resData) => {
			dispatch(getMSList(resData.data.results));
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};

export const addMicroService = (msData, callback) => async (dispatch) => {
	dispatch(formStart());
	console.log(msData, 'msData');
	await axiosAuth
		.post('/microservice', msData)
		.then((res) => {
			dispatch(addComplete(res.data.data));
			callback();
		})
		.catch((err) => {
			console.log(err, 'err on addMicroService');
			dispatch(fetchFail(err.message));
		});
};

export const editMicroService = (msData, id) => async (dispatch) => {
	dispatch(fetchStart());
	await axiosAuth
		.put(`/microservice/${id}`, msData)
		.then((resData) => {
			dispatch(editComplete(resData.data.data));
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};

export const fetchReportsByCompany = (company_id, type) => (dispatch) => {
	dispatch(fetchStart());
	axiosAuth
		.get(`/reports/?company__id=${company_id}&microservice__id=${type}`)
		.then((res) => {
			dispatch(getCompanyReports(res.data.results));
		})
		.catch((err) => {
			console.log(err, 'err on fetchReportsByCompany');
			dispatch(fetchFail(err.message));
		});
};

export const deleteReport = (id, callback) => async (dispatch) => {
	dispatch(deleteReportStart());
	axiosAuth
		.delete(`/reports/${id}`)
		.then(() => {
			callback();
		})
		.catch((err) => {
			dispatch(deleteReportFail(err.message));
		});
};

export const deleteMicroservice = (id, callback) => async (dispatch) => {
	axiosAuth
		.delete(`/microservice/${id}`)
		.then(() => {
			callback();
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};
