import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import { Form, Button } from 'react-bootstrap';

const scanTypeOpt = [ { value: 1, label: 'CSV Extract' }, { value: 2, label: 'On-Premise' } ];

class AddModal extends React.Component {
	constructor(props) {
		super(props);
	}

	renderField = (field) => {
		const { input, label, placeholder, type, name, meta: { touched, error, warning } } = field;
		return (
			<Form.Group controlId={name}>
				<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
				<input type={type} placeholder={placeholder} {...input} className="form-control" />
				{touched &&
					((error && <p className="help is-danger">{error}</p>) ||
						(warning && <p className="help is-danger">{warning}</p>))}
			</Form.Group>
		);
	};

	renderSelectField = (field) => {
		const { input, label, placeholder, options, name, meta: { touched, error, warning } } = field;
		return (
			<Form.Group controlId={name}>
				<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
				<Select
					{...input}
					placeholder={placeholder}
					onChange={(value) => input.onChange(value)}
					onBlur={() => input.onBlur(input.value)}
					options={options}
					className="form-control"
				/>
				{touched &&
					((error && <p className="help is-danger">{error}</p>) ||
						(warning && <p className="help is-danger">{warning}</p>))}
			</Form.Group>
		);
	};

	renderCompOpts = (names) => {
		const filterData = [];
		names.forEach((c) => {
			filterData.push({ value: c.id, label: c.name });
		});
		return filterData;
	};

	renderCompResellerOpts = (names) => {
		const filterData = [];
		names.forEach((cr) => {
			filterData.push({ value: cr.id, label: cr.name });
		});
		return filterData;
	};

	handleChange = (newValue) => {
		try {
			this.props.dispatch(change('AddMSForm', 'reseller_id', ''));
			this.props.onCompResellerNames(newValue.value);
		} catch (e) {
			alert(e.message);
		}
	};

	submitHandle = (values) => {
		console.log(values, 'values');
		this.props.addMicroService(values);
	};

	render() {
		const { isOpen, toggleModal, handleSubmit } = this.props;
		return (
			<div>
				<Modal isOpen={isOpen} toggle={toggleModal}>
					<ModalHeader toggle={toggleModal}>Add Microservice</ModalHeader>
					<ModalBody>
						<Form onSubmit={handleSubmit((values) => this.submitHandle(values))}>
							<Field
								name="company_id"
								placeholder="Please select company"
								component={this.renderSelectField}
								options={this.renderCompOpts(this.props.companiesNames)}
								onChange={this.handleChange}
								label="Company"
							/>
							<Field
								name="reseller_id"
								placeholder="Please select reseller"
								component={this.renderSelectField}
								options={this.renderCompResellerOpts(this.props.compResellerNames)}
								label="Reseller"
							/>
							<Field
								name="name"
								type="text"
								placeholder="Enter micro service name"
								component={this.renderField}
								label="Micro Service Name"
							/>
							<Field
								name="api_url"
								type="text"
								placeholder="Enter micro service api url"
								component={this.renderField}
								label="Micro Service api url"
							/>
							<Field
								name="scan_type"
								placeholder="Enter select scan type"
								component={this.renderSelectField}
								options={scanTypeOpt}
								label="Scan Type"
							/>
							<ModalFooter>
								<Button type="submit" color="primary">
									{this.props.isLoading ? 'LOADING...' : 'Add Microservice'}
								</Button>{' '}
								<Button color="secondary" onClick={toggleModal}>
									Cancel
								</Button>
							</ModalFooter>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

const validate = (values) => {
	const errors = {};
	if (!values.company_id) {
		errors.company_id = 'Field is required';
	}
	// if (!values.reseller_id) {
	//   errors.reseller_id = 'Field is required';
	// }
	if (!values.name) {
		errors.name = 'Field is required';
	}
	if (!values.api_url) {
		errors.api_url = 'Field is required';
	}
	if (!values.scan_type) {
		errors.scan_type = 'Field is required';
	}

	return errors;
};

export default reduxForm({
	form: 'AddMSForm',
	validate
})(AddModal);
