import axios from 'axios';
import {API_URL} from '../services/config';

const defaultOptions = {
  baseURL: API_URL,
};
// Create instance
const instance = axios.create(defaultOptions);
// Set the AUTH token for any request
instance.interceptors.request.use(async config => {
  const token = localStorage.getItem('idToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default instance;
