import axiosAuth from '../axiosInstance';

export const GET_DSA_DATA = 'GET_DSA_DATA';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';

export const fetchStart = () => ({
	type: FETCH_START
});

export const fetchFail = (errorMsg) => ({
	type: FETCH_FAIL,
	errorMsg
});

export const getS2Data = (s2data) => ({
	type: GET_DSA_DATA,
	s2data: s2data
});


export const fetchS2Data = (params) => async (dispatch) => {
	dispatch(fetchStart());
	await axiosAuth
		.get(`/reports/get-dsa-data?company__id=${params.company_id}&microservice__id=1`)
		.then((resData) => {
			console.log(resData.data.results, 'resData.data.results');
			dispatch(getS2Data(resData.data.results));
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};

