import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
// TODO: Create custom styled bootstrap modules
import { Col, Container, Row, Form } from 'react-bootstrap';
import styled from 'styled-components'
import Button from '../../components/Forms/Button';
import FormBg from '../../components/Layouts/FormBg';
import MainContent from '../../components/Layouts/MainContent';
import * as actions from '../../redux/actions/index';

const StyledField = styled.input`
  &:-internal-autofill-selected, &:required, &:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #3e3e3e inset;
    -webkit-text-fill-color: #cdcdcd;
  }
  
  &::placeholder{
    color: #cdcdcd;
  }

  background-color: #3e3e3e;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  color: #cdcdcd;
  width: 100%;
           
`

const renderField = (field) => {
  const {
    input,
    label,
    placeholder,
    type,
    name,
    meta: { touched, error, warning },
  } = field;
  return (
    <Form.Group controlId={name}>
      <Form.Label style={{color: "#6e6e6e"}} >{label}</Form.Label>
      <StyledField
        type={type} 
        placeholder={placeholder}
        style={{background: "#6e6e6e"}}
        className="field-item"
        {...input}
      />
      {touched
          && ((error && <p className="help is-danger">{error}</p>)
              || (warning && <p className="help is-danger">{warning}</p>))}
    </Form.Group>
  );
}

const ForgotPasswordForm = (props) => {
  const {
    handleSubmit,
    loadingSubmit,
    errorMsg,
  } = props;
  let errorMessage = null;
  let authRedirect = null;

  const [confirmation, setConfirmation] = useState(false);
  const [completed, setCompleted] = useState(true);

  useEffect(() => {
    props.onClearErrMsg()
  }, [])  
  
  if (errorMsg) {
    errorMessage = (
        <p className="is-danger">{errorMsg}</p>
    );
  }
  
  if (props.isAuthenticated) {
      authRedirect = <Redirect to='/' />;
  }
  

  const submitHandle = (values) => {
    setCompleted(false);
    try {
      props.onForgetPassword(values, () => {
        setConfirmation(true)
      });
    } catch (e) {
        alert(e.message);
    }
    setCompleted(true);
  }
        
  const handleConfirmationSubmit = (values) => {
    try {
        const data = {
          email: values.email,
          code: values.code,
          new_password: values.new_password
        }
        props.onConfirmForgetPassword(data, () => {
          setCompleted(true);
          // props.history.push('/');
        });
    } catch (e) {
        alert(e.message);
        setCompleted(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Container >
      <h2 style={{marginLeft: "0px"}}>Change password</h2> 
      <Row>
        <Col>
          {authRedirect}
          {errorMessage}
          <Form onSubmit={handleSubmit(values => handleConfirmationSubmit(values))}>
          <Field
            name="email"
            type="email"
            placeholder="email@example.com"
            component={renderField}
            label="Email address"
          />
          <Field
            name="code"
            type="text"
            placeholder="Enter Confirmation Code"
            component={renderField}
            label="Confirmation Code" 
          />
          <Field
          name="new_password"
          type="password"
          placeholder="Enter new password"
          component={renderField}
          label="New Password" 
        />
          <Row>
            <Col>
            <Button style={{width: "80%", margin: "auto", borderRadius: "10px" }} type="submit" block>
                { loadingSubmit ? ' LOADING...' : 'Changed Password' }
            </Button>
            </Col>
          </Row>
          </Form>
        </Col>
        </Row>
      </Container>
    );
  }
  
  
  function forgotForm() {
    return (
      <Container >
      <h2 style={{marginLeft: "0px"}}>Forgot Password?</h2> 
      <Row className="">
          <Col >
            {errorMessage}
            <Form onSubmit={handleSubmit(values => submitHandle(values))}>
              <Field
                name="email"
                type="email"
                placeholder="email@example.com"
                component={renderField}
                label="Email address"
              />
                <Button style={{width: "50%", margin: "auto", borderRadius: "10px"}} type="submit" block>
                  { loadingSubmit ? ' LOADING...' : 'Reset Password' }
                </Button>
            </Form>              
          </Col>
          </Row>
          <Row style={{marginTop: "20%"}} >
          <Col>
              <p style={{fontSize: "11pt"}}>
                  Return to{' '}
                  <Link to="/" style={{color: "#68afa9", fontWeight: "bold"}}>LOGIN</Link>
                  {' '}page.
              </p>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
      <FormBg>
        {completed && (confirmation ? renderConfirmationForm() : forgotForm())}
      </FormBg>
  );

}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.code) {
    errors.code = 'Field is required';
  }
  if (!values.new_password) {
    errors.new_password = 'Field is required';
  } else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/i.test(values.new_password)) {
    errors.new_password = 'You must include at least one lowercase and uppercase character, one number, and one special character.';
  }
  return errors;
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  loadingSubmit: state.auth.submit,
  errorMsg: state.auth.errorMsg
});

const mapDispatchToProps = dispatch => ({
  onForgetPassword: (params, callbak) => dispatch(actions.forgetPassword(params, callbak)),
  onConfirmForgetPassword: (params, callbak) => dispatch(actions.confirmForgetPassword(params, callbak)),
  onClearErrMsg: () => dispatch(actions.clearAllErrMsg()),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'forgotPasswordForm',
  validate,
})(ForgotPasswordForm));
