import React, { Component } from "react";
import Dropzone from "../../../../components/dropzone/Dropzone";
import "./FileUpload.css";
import Progress from "../../../../components/progress/Progress";
import axiosAuth from '../../../../redux/axiosInstance';
import baselineCheck from '../../../../../static/images/baseline-check_circle_outline-24px.svg';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    this.state.files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.sendRequest(file)
      };
      reader.readAsDataURL(file);
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
      const {user, project_id} = this.props;
      console.log(file, 'ss')
      const options = {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent, 'progressEvent');
          const { loaded, total} = progressEvent;
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = {
              state: "pending",
              percentage: parseInt( Math.round((loaded / total) * 100))
            };
            this.setState({ uploadProgress: copy });
        },
      };

      const formData = new FormData();
      formData.append("rw_file_path", file, file.name);
      formData.append("rw_file_name", file.name);
      formData.append("is_scan", false);
      formData.append("company", user.company.id);
      formData.append("user", user.id);
      formData.append("project", project_id);
       //
      // console.log(formData, 'formData');
      
      axiosAuth.post('/rw-file-upload/', formData, options)
        .then(() => {
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = { state: "done", percentage: 100 };
            this.setState({ uploadProgress: copy });
        })
        .catch((err) => {
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = { state: "error", percentage: 0 };
            this.setState({ uploadProgress: copy });
        });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    console.log(uploadProgress, 'uploadProgress')
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={baselineCheck}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  render() {
    return (
      <div className="Upload">
        <span className="Title">Upload Files</span>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

export default FileUpload;