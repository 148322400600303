import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './dropdown.scss';

const data = [
    {label: 'Foo Action', value: 'Foo Action'},
    {label: 'Bar Action', value: 'Bar Action'},
    {label: 'Quo Action', value: 'Quo Action'}];
const DropDown = ({
    placeholder,
    selected,
    options = [], 
    isBlackTheme, 
    fullWidth,
    onItemSelected = () => {}
}) => {
    if (!placeholder) {
        placeholder = 'Select';
    }

    if (selected) {
        
        const selectedOption = options.find(option => {
            return parseInt(option.value) === parseInt(selected) ;    
        });
        placeholder = selectedOption ? selectedOption.label : placeholder;
    }
    let classes = '';
    if (isBlackTheme) {
        classes += 'blank-theme ';
    }
    if (fullWidth) {
        classes += 'full-width'
    }
    const [dropdownOpen, toogleDropdown] = useState(false);
        return(
        <Dropdown 
            isOpen={dropdownOpen} 
            toggle={() => toogleDropdown(!dropdownOpen)}
            className={classes}
        >
            <DropdownToggle caret>
                {placeholder || 'select'}
            </DropdownToggle>
            <DropdownMenu>
                {options.map((option) =>{ 
                    if(option != undefined){ 
                      return (
                      <DropdownItem
                          value={option.value}
                          key={option.value}
                          onClick={onItemSelected}>
                          {option.label}
                      </DropdownItem>
                      )
                    }
                   }
                )}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropDown;
