import React, { Component } from 'react';
import './account-settings.scss';
import CustomTable from "../../../components/Admin/Table";
import MainContent from '../../../components/Layouts/MainContent';

const SubjectRender = ({ subject }) => <div className='subject'>{ subject }</div>;
const StatusRender = ({ status }) => {
    return(
        <div className="status-render">
            {status==='ready' && <div className="ready"><span/>Ready
            </div>}
            {status==='scheduled' && <div className="scheduled"><span/>Scheduled
            </div>}
            {status==='offline' && <div className="offline"><span/>Offline
            </div>}
            {status==='upgrade' && <div className="upgrade"><span/>Upgrade to use
            </div>}
        </div>
    )
}


const SupportStatusRender = ({ status }) => <div className='status'><img src="/images/information.svg" alt='information'/> {status}</div>;

const tableData = {
    columns: [
        {title: 'Subject', key: 'subject'},
        {title: 'Case ID', key: 'caseId'},
        {title: 'Service', key: 'service'},
        {title: 'Category', key: 'category'},
        {title: 'Created(UTC-7)', key: 'created'},
        {title: 'Status', key: 'status'}
    ],
    rows: [
        {
            subject: <SubjectRender subject='Update audit cadence to bi-weekly'/>,
            caseId: '136',
            service: 'BlackSwan',
            category: 'Audit Request',
            created: '2019-06-22T14:04',
            status: <SupportStatusRender status='Needs Attention'/>
        },
    ]
};

    const service = {
        columns: [
            {title: 'Service', key: 'service'},
            {title: 'Status', key: 'status'},
            {title: 'Last Scan(UTC-7)', key: 'lastScan'}
        ],
        rows: [
            {
                service: 'Nmap',
                status: <StatusRender status='ready'/>,
                lastScan: '2019-07-23T10:10'
            },
            {
                service: 'SecuritySccorecard',
                status: <StatusRender status='ready'/>,
                lastScan: '2019-07-23T10:10'
            },
            {
                service: 'Blackshawn',
                status: <StatusRender status='scheduled'/>,
                lastScan: '2019-07-23T10:10'
            },
            {
                service: 'Microservice #4',
                status: <StatusRender status='offline'/>,
                lastScan: '2019-07-23T10:10'
            },
            {
                service: 'Microservice #5',
                status: <StatusRender status='upgrade'/>,
                lastScan: '---'
            },
            {
                service: 'Microservice #6',
                status: <StatusRender status='upgrade'/>,
                lastScan: '---'
            },
            {
                service: 'Microservice #7',
                status: <StatusRender status='upgrade'/>,
                lastScan: '---'
            },
        ]
    }

const external = {
    columns: [
        {title: 'Notification', key: 'notification'},
        {title: 'Email', key: 'email'},
        {title: 'SMS', key: 'sms'}
    ],
    rows: [
        {
            notification: 'Service Interrupted',
            email: <StatusRender status='ready'/>,
            sms: '2019-07-23T10:10'
        },
        {
            notification: 'Payment due',
            email: <StatusRender status='ready'/>,
            sms: '2019-07-23T10:10'
        },

    ]
}

const providerOptions = [
    {label: 'Provider 1', value: 'provider1'},
    {label: 'Provider 2', value: 'provider 2'},
    {label: 'Provider 3', value: 'provider3'}
];

export default class AccountSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        }
    }

    toggle = () => {
        this.setState((state) => ({ dropdownOpen: !state.dropdownOpen }));
    };

    render() {
        return (
          <MainContent>
            <section className="reseller-wrapper">
                <div className="container-fluid">

                    <div className="row m-tablet">
                        <div className="col-md-4 m-tab">
                            <div className='reseller-details'>
                                <img src="/images/noimage.jpg" alt='noimage'/>
                                <div className="account-setting-info">
                                    <h5>Company Name</h5>
                                    <span><p>Website:</p> <p>www.website.com</p></span>
                                    <h3>
                                        Primary Contact:
                                    </h3>
                                    <span><h4>Name:</h4> <p>James Jameson</p></span>
                                    <span><h4>Email:</h4> <p>jjameson @ website.com</p></span>
                                    <span><h4>Phone:</h4> <p>43 8 - 8 42 8 -7272</p></span>
                                    <h6>Add contact+</h6>
                                    <h2><b>00</b> Employees</h2>
                                    <span className="store"> <p><b>0TB</b> Data across</p>
                                       <p><b>0</b> Stores</p></span>
                                </div>
                                <div className='subscriptions'>
                                    <h5>Subscription</h5>
                                    <div className="payment">
                                        <p>*Payment Setup via 3rd party (e.g. Zuora),
                                            high level summary displayed here</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8 m-tab">
                            <div className="col-md-12">
                                <div className='service-account'>
                                    <div className='account'>
                                        <div className="account-header">
                                            <h3>Support</h3>
                                        </div>
                                        <div>
                                            <CustomTable columns={service.columns} rows={service.rows} isBordered={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-config">
                                        <div className="header-title">
                                            <h3>Service Configuration</h3>
                                        </div>
                                        <h6>Industry Distribution</h6>
                                        <div className="distribution">
                                            <ul className="list-item">
                                                <li>Financial Services</li>
                                                <li>Healthcare</li>
                                                <li>Technology</li>
                                                <li>Zulu</li>
                                            </ul>
                                            <ul className="icon">
                                                <li><img src="/images/right.png" alt="right"/> </li>
                                                <li><img src="/images/right.png" alt="right"/> </li>
                                                <li><img src="/images/right.png" alt="right"/> </li>
                                                <li><img src="/images/right.png" alt="right"/> </li>
                                            </ul>
                                            <ul className="health">
                                                <li>Healthcare<span>30%</span></li>
                                                <li>Technology<span>40%</span></li>
                                            </ul>
                                        </div>
                                        <h5 className="total-desc">total &nbsp;<span>70</span>%</h5>
                                        <div className="industry">
                                            <div className="compilance">
                                                <h5>Compliance Scope</h5>
                                                <ul>
                                                    <li>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                                <label>CCPA</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                            <label>GDPR</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                            <label>HIPPA</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="compilance">
                                                <h5>Cyber Insurance Policy</h5>
                                                <span>
                                                    <h1>$24 M</h1>
                                                    <p>Total Coverage</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-config">
                                        <div className="header-title">
                                            <h3>Risk Exposure</h3>
                                        </div>
                                        <div className="notification-exposure">
                                            <span>
                                                <h1>$360 M</h1>
                                                <p>Gross Risk <br/> Exposure</p>
                                            </span>
                                            <span>
                                                <h1 className="risk">$336 M</h1>
                                                <p>Net Adjusted <br/>Risk Exposure</p>
                                            </span>
                                        </div>
                                    </div>
                                        <div className="service-config">
                                            <div className="header-title">
                                                <h3>External Notifications</h3>
                                            </div>
                                            <div>
                                                <CustomTable columns={external.columns} rows={external.rows} isBordered={true}/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </MainContent>
        )
    }
}
