/* eslint-disable prettier/prettier */
import {
	FETCH_START,
	GET_COMPANY_NAME,
	GET_COMP_RESELLER_NAME,
	FETCH_COMPANIES,
	ADD_COMPANY_START,
	ADD_COMPANY_FAIL,
	ADD_COMPANY_SUCCESS,
	EDIT_COMPANY_START,
	EDIT_COMPANY_FAIL,
	EDIT_COMPANY_SUCCESS
} from '../actions/CompanyActions';
import { updateObject } from '../utility';

const initialState = {
	companiesNames: [],
	compResellerNames: [],
	companies: [],
	errorMsg: null,
	loading: false,
	isCompanyLoad: false
};

const fetchStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true });
const companyAddStart = (state) => updateObject(state, { isCompanyLoad: true });
const companyAddFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isCompanyLoad: false });
const companyAddComplete = (state, action) =>
	updateObject(state, { companies: [ ...state.companies, action.company ] });
const companyEditStart = (state) => updateObject(state, { isCompanyLoad: true });
const companyEditFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isCompanyLoad: false });
const companyEditComplete = (state, action) => {
	const index = state.companies.findIndex((company) => company.id === action.company.id);
	const companies = state.companies;
	companies[index] = action.company;
	return updateObject(state, { companies: [ ...companies ] });
};

const getCompanyNames = (state, action) =>
	updateObject(state, {
		companiesNames: action.companiesNames,
		errorMsg: null,
		loading: false
	});

const getCompResellerNames = (state, action) =>
	updateObject(state, {
		compResellerNames: action.compResellerNames,
		errorMsg: null,
		loading: false
	});

const fetchCompanies = (state, action) =>
	updateObject(state, {
		companies: action.companies,
		errorMsg: null,
		loading: false
	});

export const CompanyReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_START:
			return fetchStart(state, action);
		case GET_COMPANY_NAME:
			return getCompanyNames(state, action);
		case GET_COMP_RESELLER_NAME:
			return getCompResellerNames(state, action);
		case FETCH_COMPANIES:
			return fetchCompanies(state, action);
		case ADD_COMPANY_START:
			return companyAddStart(state, action);
		case ADD_COMPANY_FAIL:
			return companyAddFail(state, action);
		case ADD_COMPANY_SUCCESS:
			return companyAddComplete(state, action);
		case EDIT_COMPANY_START:
			return companyEditStart(state, action);
		case EDIT_COMPANY_FAIL:
			return companyEditFail(state, action);
		case EDIT_COMPANY_SUCCESS:
			return companyEditComplete(state, action);
		default:
			return state;
	}
};
