import React from 'react';
import { connect } from 'react-redux';
import size from 'lodash/size';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CustomTableWithPagination from '../../../../components/Admin/CustomTable';
import * as actions from '../../../../redux/actions/index';
import DropDown from '../../../../components/Admin/DropDown';
import AddModal from './AddModal';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { uploadCSVToPG, uploadNmapXMLToPG, uploadRWJSONToPG } from '../../../../utilities/s2APIHelpers';
import './reports.scss';

var parser = require('fast-xml-parser');
var he = require('he');

var xmloptions = {
	attributeNamePrefix: '@_',
	attrNodeName: 'attr', //default is 'false'
	textNodeName: '#text',
	ignoreAttributes: false,
	ignoreNameSpace: false,
	allowBooleanAttributes: false,
	parseNodeValue: true,
	parseAttributeValue: false,
	trimValues: true,
	cdataTagName: '__cdata', //default is 'false'
	cdataPositionChar: '\\c',
	localeRange: '', //To support non english character in tag/attribute values.
	parseTrueNumberOnly: false,
	arrayMode: false, //"strict"
	attrValueProcessor: (val, attrName) => he.decode(val, { isAttributeValue: true }), //default is a=>a
	tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
	stopNodes: [ 'parse-me-as-string' ]
};

class ReportLists extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isModal: false,
			isLoading: false,
			alert: null,
			reportType: null
		};
	}

	onReportChange = (e) => {
		let reportType = e.target.value;
		this.props.fetchReportsByCompany(this.props.companyId, reportType);
		this.setState({ reportType: reportType });
	};

	warningWithConfirmAndCancelMessage(id) {
		console.log('actionHandler', id);
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title="Are you sure?"
					onConfirm={() => this.successDelete(id)}
					onCancel={() => this.cancelDelete()}
					confirmBtnBsStyle="info"
					cancelBtnBsStyle="danger"
					confirmBtnText="Yes, delete it!"
					cancelBtnText="Cancel"
					showCancel
				>
					You will not be able to recover this report!
				</SweetAlert>
			)
		});
	}

	successDelete(id) {
		this.props.onDeleteReport(id, () => {
			this.props.fetchReportsByCompany(this.props.companyId, this.state.reportType);
		});

		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title="Deleted!"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="info"
				>
					Your report has been deleted.
				</SweetAlert>
			)
		});
	}

	cancelDelete() {
		this.setState({
			alert: (
				<SweetAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title="Cancelled"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="info"
				>
					Your report is safe :)
				</SweetAlert>
			)
		});
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}

	actionHandler(id) {
		this.warningWithConfirmAndCancelMessage(id);
	}

	renderAction(report) {
		return (
			<React.Fragment>
				<Button onClick={() => this.actionHandler(report.id)}>Delete</Button>
			</React.Fragment>
		);
	}

	toggleModal = () => {
		this.setState((state) => ({ isModal: !state.isModal }));
	};

	handleAddReport = () => {
		this.setState({ isModal: true });
	};

	notifySuccess = () => toast.success('Update csv completed successfully!');
	notifyXMLSuccess = () => toast.success('Update XML completed successfully!');

	addReport = async (report) => {
		if (report.reportType.label === 'S2 BlackSwan API') {
			this.setState({ isLoading: true });
			const inv = report.dataInventoryFile;
			const red = report.dataRedundancyFile;
			const scanDate = moment(report.scanDate).format('YYYY-MM-DD hh:mm:ss');
			await uploadCSVToPG(inv, red, this.props.companyId, scanDate, report.reportType.value, () => {
				this.props.fetchReportsByCompany(this.props.companyId, report.reportType.value);
				this.setState({ isLoading: false });
				this.notifySuccess();
				this.toggleModal();
			});
		} else if (report.reportType.label === 'Nmap') {
			this.setState({ isLoading: true });
			const nData = report.nmapFile;
			const reader = new FileReader();
			reader.readAsText(nData);
			reader.onload = async () => {
				const result = parser.validate(reader.result);
				if (result !== true) console.log(result.err);
				const jsonObj = parser.parse(reader.result, xmloptions);
				const data = {
					companyId: this.props.companyId,
					nmapdata: JSON.stringify(jsonObj),
					scanDate: moment(report.scanDate).format('YYYY-MM-DD hh:mm:ss'),
					type: report.reportType.value
				};
				await uploadNmapXMLToPG(data, () => {
					this.setState({ isLoading: false });
					this.props.fetchReportsByCompany(this.props.companyId, report.reportType.value);
					this.notifyXMLSuccess();
					this.toggleModal();
				});
			};
		} else if (report.reportType.label === 'revealwhy') { 
			this.setState({ isLoading: true });
			const nData = report.revealwhyFile;
			const reader = new FileReader();
			reader.readAsText(nData);
			reader.onload = async () => {
				const jsonObj = JSON.parse(reader.result)
				const data = {
					companyId: this.props.companyId,
					rwdata: JSON.stringify(jsonObj),
					scanDate: moment(report.scanDate).format('YYYY-MM-DD hh:mm:ss'),
					type: report.reportType.value
				};
				await uploadRWJSONToPG(data, () => {
					this.setState({ isLoading: false });
					this.props.fetchReportsByCompany(this.props.companyId, report.reportType.value);
					this.notifyXMLSuccess();
					this.toggleModal();
				});
			};
		}
	};

	reportOptions(allMS) {
		const filterData = [];
		allMS.forEach((ms) => {
			if(ms != undefined){
				filterData.push({ value: ms.id, label: ms.name });
			}		
		});
		return filterData;
	}

	render() {

		const { companyId, reports, allMS } = this.props;	
		const { isModal, isLoading, reportType } = this.state;
		
		const columns = [
			{ dataField: 'id', text: 'ReportID', sort: true },
			{ dataField: 'scandate', text: 'Report Scan Date', sort: true },
			{ dataField: 'type', text: 'Report Type', sort: true },
			{ dataField: 'action', text: 'Action' }
		];
		let data = [];
		if (size(reports) > 0) {
			data = reports.map((report) => ({
				id: report.id,
				scandate: moment(report.scan_date).format('MMM Do YY, h:mm:ss a'),
				type: report.msdetail.name,
				action: this.renderAction(report)
			}));
		} else {
			data = [];
		}

		return (
			<div className="container-fluid">
				<ToastContainer position="bottom-right" />
				{size(allMS) > 0 ? (
					<DropDown
						options={this.reportOptions(allMS)}
						selected={reportType}
						style={{ float: 'left', margin: 10, display: 'inline-block' }}
						isBlankTheme={true}
						onItemSelected={(e) => this.onReportChange(e)}
					/>
				) : null}
				<Button style={{ float: 'right', margin: 10 }} onClick={this.handleAddReport}>
					Upload Reports
				</Button>
				{size(data) > 0 ? (
					<CustomTableWithPagination columns={columns} rows={data} />
				) : (
					<div style={{ color: '#fff', lineHeight: '50px' }}>No reports available</div>
				)}
				{this.state.alert}
				{isModal && (
					<AddModal
						isOpen={isModal}
						toggleModal={this.toggleModal}
						companyId={companyId}
						allMS={allMS}
						isLoading={isLoading}
						addReport={this.addReport}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.reseller.isResellerLoad,
	errorMsg: state.auth.errorMsg,
	reports: state.ms.reports,
	allMS: state.ms.allMS
});

const mapDispatchToProps = (dispatch) => ({
	fetchReportsByCompany: (company_id, type) => dispatch(actions.fetchReportsByCompany(company_id, type)),
	onDeleteReport: (id, callback) => dispatch(actions.deleteReport(id, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportLists);
