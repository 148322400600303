import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import size from 'lodash/size';
import{ Container, Form }from 'react-bootstrap';
import MainContent from '../../../components/Layouts/MainContent';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { Navbar, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import FileUpload from './FileUpload/FileUpload';
import CustomTableWithPagination from '../../../components/Admin/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartArea,
} from '@fortawesome/free-solid-svg-icons';
import ChartDataModal from './Graphs/ChartDataModal';
import * as actions from '../../../redux/actions/index';
import '../reveal-why.scss';


const renderField = (field) => {
  const {
    input,
    label,
    placeholder,
    type,
    name,
    meta: { touched, error, warning },
  } = field;
  return (
      <Form.Group key={`${name}-${type}`}>
        <Form.Label style={{color: "#6e6e6e"}}>{label}</Form.Label>
        <input
            type={type}
            placeholder={placeholder}
            {...input}
            className="form-control"
        />
        {touched
        && ((error && <p className="help is-danger">{error}</p>)
            || (warning && <p className="help is-danger">{warning}</p>))}
      </Form.Group>
  );
}

const renderSelectField = (field) => {
  const { input, label, placeholder, options, isMulti, name, meta: { touched, error, warning } } = field;
  return (
    <Form.Group controlId={name}>
      <Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
      <Select
        {...input}
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
          },
        })}
      />
      {touched &&
        ((error && <p className="help is-danger">{error}</p>) ||
          (warning && <p className="help is-danger">{warning}</p>))}
    </Form.Group>
  );
};

const renderProjectInfo = (projects, project_id) => { 
  const project = projects.filter((p) => p.id === +project_id);
  return (
    <div>{project[0] ? project[0].project_name: ''}</div>
  );
};

const notifySuccess = (msg) => toast.success(msg);

const notifyError = (msg) => toast.error(msg);

const colExp = [
  { dataField: 'name', text: 'Experiment Name', sort: true },
  { dataField: 'action', text: 'Action' }
];

const RWProjectDetails = (props) => {
    const { 
      projects, 
      getProjectList, 
      getRegexRules, 
      getFiles, 
      rw_files, 
      user, 
      regex_rules,
      handleSubmit,
      addExperiment,
      getExperiment,
      onResetForm,
      experiments
    } = props;
    const [isUploadFile, setUploadFile] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isMetricsModal, setIsMetricsModal] = useState(false)
    const [activeTab, setActiveTab] = useState(1);
    const [featureImportanceData, setFeatureImportanceData] = useState({})
    const project_id = props.project_id; 
     
    useEffect(() => {
      getProjectList(user.company.id)
    }, []); 

    useEffect(() => {
      getExperiment(project_id)
    }, []); 
    
    useEffect(() => {
      getRegexRules()
    }, []); 

    useEffect(() => {
      const params = {
        company_id: props.user.company.id, 
        project_id: project_id
      }
      getFiles(params)
    }, []); 
    

    const handleUploadFiles = () => {
      if(isUploadFile) {
        const params = {
          company_id: props.user.company.id, 
          project_id: project_id
        }
        getFiles(params)
      }      
      setUploadFile(!isUploadFile)
    }

    const toggleTab = (tab) => {
      setActiveTab(tab);
    }

    const toggleMetricsModal = () => {
      setIsMetricsModal(!isMetricsModal);
    };

    const fileOptions = (files) => {
      const filterData = [];
      files.forEach((f) => {
        filterData.push({ value: f.id, label: f.rw_file_name });
      });
      return filterData;
    }

    const regexRulesOptions = (rules) => {
      const filterData = [];
      rules.forEach((r) => {
        filterData.push({ value: r.id, label: r.name });
      });
      return filterData;
    }

    const handleExperiment = (values) => {
      setIsLoading(true)
      const filterData = [];
      values.regex_rules.forEach((r) => {
        filterData.push(r.value);
      });     
      const data = {
        project: project_id,
        name: values.name,
        file: values.rw_files.value,
        regex_rules: filterData,
        scan_data: {}
      }
      addExperiment(data, () => {
        notifySuccess('Run the experiment successfully.');
        getExperiment(project_id);
        onResetForm();
        setIsLoading(false);
      });
    }

    const showChartModal = (exp) => {
      setIsMetricsModal(true)
      const expData = JSON.parse(exp);
      const eData = expData.children[0].children[0].categories;
      console.log(expData.children[0].children[0].categories, 'exp');
      setFeatureImportanceData(eData)
    }
    
    const renderActions = (exp) => {
      return (
        <React.Fragment>
            <FontAwesomeIcon
              style={{ fontSize: '18px', width: '1.25em', cursor: 'pointer' }}
              icon={faChartArea}
              onClick={() => showChartModal(exp.scan_data)}
              title="View Metrics"
            />
          </React.Fragment>
      );
    };
    
    const renderExperiment = (experiments) => {
      return experiments.map((exp) => ({
        id: exp.id,
        name: exp.name,
        action: renderActions(exp)
      }));
    }

  	return(
      <MainContent>
        <ToastContainer position="bottom-center" />
        	<div className="contaner-fluid rw-container">    
            <h3 className="title-h">{ size(projects.results) > 0 ? renderProjectInfo(projects.results, project_id): null }</h3>      
            <Container className="container-class">                           
              <div className="col-style-left">  
                <h2>Experiment</h2>   
                <div className="tab-content-wrapper">
                    { size(experiments.results) > 0 ? <CustomTableWithPagination columns={colExp} rows={renderExperiment(experiments.results)} /> : null }
                    <ChartDataModal
                        isOpen={isMetricsModal}
                        toggleModal={() => toggleMetricsModal()}
                        featureImportanceData={featureImportanceData}
                    />
                  </div>         
              </div>
              <div className="col-style-right"> 
                <button className="upload-btn" onClick={() => handleUploadFiles()}>
                  {isUploadFile ? 'Show files' : 'Upload files' }
                </button>
                {isUploadFile ? (<div className="file-upload-div">
                  <FileUpload user={user} project_id={project_id} />
                </div>) : (
                  <div className="experiment-div">
                    <h2>Experiments</h2>
                    { size(rw_files.results) > 0 ? (
                        <Form onSubmit={handleSubmit(handleExperiment)}>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Enter Name"
                            component={renderField}
                            label="Name"
                        />
                        { size(rw_files.results) > 0 ? (
                        <Field
                            name="rw_files"
                            type="text"
                            isMulti={false}
                            placeholder="Please select file"
                            component={renderSelectField}
                            options={fileOptions(rw_files.results)}
                            label="File"
                          />) : null }
                        { size(regex_rules.results) > 0 ? (
                        <Field
                            name="regex_rules"
                            type="text"
                            isMulti={true}
                            placeholder="Please select regex rules"
                            component={renderSelectField}
                            options={regexRulesOptions(regex_rules.results)}
                            label="Regex Rules"
                          />) : null }
                          <button type="submit" className="upload-btn exp-btn">
                          {isLoading ? 'waiting experiemnt running...' : 'Run'}
                          </button>
                      </Form>   
                       ) : null }            
                  </div>
                ) }
              </div>
            </Container>
          </div>        
      </MainContent>
  	)
  }

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Field is required';
    }
    if (!values.rw_files) {
      errors.rw_files = 'Field is required';
    }
    if (!values.regex_rules) {
      errors.regex_rules = 'Field is required';
    }
    return errors;
  };

  const mapStateToProps = (state) => ({
    user: state.auth.user,
    projects: state.rw.projects,
    rw_files: state.rw.files,
    regex_rules: state.rw.regex_rules,
    experiments: state.rw.experiments,
  });
    
  const mapDispatchToProps = (dispatch) => ({
    getProjectList: (company_id) => dispatch(actions.getProjectList(company_id)),
    getRegexRules: () => dispatch(actions.getRegexRules()),
    getFiles: (params) => dispatch(actions.getFiles(params)),
    addExperiment: (params, callback) => dispatch(actions.addExperiment(params, callback)),
    getExperiment: (project_id) => dispatch(actions.getExperiment(project_id)),
    onResetForm: () => dispatch(reset('rwExperimentForm')),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
      form: 'rwExperimentForm',
      validate
    })(RWProjectDetails)
  );