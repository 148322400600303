import React from 'react'
import styled from 'styled-components'

const StyledSpinner = styled.div`
	width: calc(100% - ${props => props.offsetRight ? props.offsetRight : "90px"});
	min-height: 100%;
	padding-right: ${props => props.offsetRight ? props.offsetRight : "90px"};
	display: ${ props => props.loading ? "flex" : "none" }; 
	justify-content: center;
	align-items: center;
	background-color:  ${props => props.overlayColor ? props.overlayColor : "rgba(20,25,35,.8)"};
	z-index: 99998;
	position: ${ props => props.position ? props.position  : "fixed" };
	top: ${ props => props.top ? props.top  : "60px" };
	left: ${ props => props.left ? props.left  : "auto" }; 
	color: #ccc;
	flex-flow: column;
	font-size: ${props => props.spinnerSize ? props.spinnerSize : "20px"};
	.loader {
	  margin: ${props => props.offsetTop ? props.offsetTop : "100px"} auto;
	  font-size: ${props => props.spinnerSize ? props.spinnerSize : "20px"};
	  width: 1em;
	  height: 1em;
	  border-radius: 50%;
	  position: relative;
	  text-indent: -9999em;
	  -webkit-animation: load4 1.3s infinite linear;
	  animation: load4 1.3s infinite linear;
	  -webkit-transform: translateZ(0);
	  -ms-transform: translateZ(0);
	  transform: translateZ(0);
	}
	@-webkit-keyframes load4 {
	  0%,
	  100% {
	    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	  }
	  12.5% {
	    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	  }
	  25% {
	    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	  }
	  37.5% {
	    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	  }
	  50% {
	    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	  }
	  62.5% {
	    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	  }
	  75% {
	    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	  }
	  87.5% {
	    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	  }
	}
	@keyframes load4 {
	  0%,
	  100% {
	    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	  }
	  12.5% {
	    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	  }
	  25% {
	    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	  }
	  37.5% {
	    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	  }
	  50% {
	    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	  }
	  62.5% {
	    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	  }
	  75% {
	    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	  }
	  87.5% {
	    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	  }
	}

`
 
const OverlaySpinner = ({loading, 
	                     overlayColor, 
	                     offsetRight, 
	                     offsetTop,
	                     position,
	                     text,
	                     fontSize,
	                     spinnerSize,
	                     top,
	                     left
	                    }) => (
  <StyledSpinner loading={loading}  
                 overlayColor={overlayColor}   
                 offsetRight={offsetRight}
                 offsetTop={offsetTop}
                 position={position}
                 spinnerSize={spinnerSize}
                 top={top}
                 left={left}

    >
    <div className="loader"></div>
    <div style={{color: "#ccc", position: "relative", fontSize}}>{text ? text :  "Please wait, calculating results..."}</div>
  </StyledSpinner>
);

export default OverlaySpinner;


