/* eslint-disable prettier/prettier */
import {
	AUTH_START,
	FORM_START,
	FORM_COMPLETE,
	AUTH_SUCCESS,
	UPDATE_USER,
	AUTH_FAIL,
	GET_USER_DATA,
	AUTH_LOGOUT,
	CLEAR_ER_MSG,
	FETCH_USER_START,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAIL,
	ADD_USER_START,
	ADD_USER_FAIL,
	ADD_USER_SUCCESS,
	EDIT_USER_START,
	EDIT_USER_FAIL,
	EDIT_USER_SUCCESS,
	ADD_COMPANY_INSURANCE,
	DELETE_COMPANY_INSURANCE,
	FETCH_FAIL,
	FETCH_START
} from '../actions/AuthActions';
import { updateObject } from '../utility';

const initialState = {
	user: {
		company: [],
		compliance: {},
		adjustments: {},
		insurances: {}
	},
	companyInfo: {
		compliance: [],
		adjustments: [],
		insurances: []
	},
	idToken: null,
	errorMsg: null,
	message: null,
	loading: true,
	fatchLoading: false,
	addLoading: false,
	delLoading: false,
	submit: false,
	authRedirectPath: '/',
	isUsersLoad: false,
	users: []
};

const fetchStart = (state) => updateObject(state, { fatchLoading: true });
const fetchFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, fatchLoading: false });

const authStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, loading: true });
const fromStart = (state, action) => updateObject(state, { errorMsg: action.errorMsg, submit: true });
const fromComplete = (state, action) =>
	updateObject(state, {
		errorMsg: action.errorMsg,
		submit: false,
		addLoading: false,
		delLoading: false
	});
const userFetchStart = (state) => updateObject(state, { isUsersLoad: true });
const userFetchFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isUsersLoad: false });
const userFetchComplete = (state, action) => updateObject(state, { users: action.users, isUsersLoad: false });
const userAddStart = (state) => updateObject(state, { isUsersLoad: true });
const userAddFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isUsersLoad: false });
const userAddComplete = (state, action) => updateObject(state, { users: [ ...state.users, action.user ] });
const userEditStart = (state) => updateObject(state, { isUsersLoad: true });
const userEditFail = (state, action) => updateObject(state, { errorMsg: action.errorMsg, isUsersLoad: false });
const userEditComplete = (state, action) => {
	const index = state.users.findIndex((user) => user.id === action.user.id);
	const users = state.users;
	users[index] = action.user;
	return updateObject(state, { users: [ ...users ] });
};
const authSuccess = (state, action) =>
	updateObject(state, {
		idToken: action.idToken,
		user: action.user,
		errorMsg: null,
		loading: false,
		submit: false
	});

const getUserDetails = (state, action) =>
	updateObject(state, {
		userDetails: action.userDetails,
		errorMsg: null,
		loading: false,
		submit: false
	});

const updateUser = (state, action) =>
	updateObject(state, {
		user: action.user,
		errorMsg: null,
		loading: false,
		submit: false
	});

const authFail = (state, action) =>
	updateObject(state, {
		errorMsg: action.errorMsg,
		loading: false,
		submit: false
	});

const lagout = (state) =>
	updateObject(state, {
		user: [],
		idToken: null,
		errorMsg: null,
		message: null,
		loading: false,
		submit: false
	});

const clearErrMsg = (state, action) => {
	return updateObject(state, {
		errorMsg: action.errorMsg,
		mesage: action.mesage
	});
};

const addCompInsurance = (state) => updateObject(state, { addLoading: true });
const deleteCompInsurance = (state) => updateObject(state, { delLoading: true });

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_START:
			return authStart(state, action);
		case FORM_COMPLETE:
			return fromComplete(state, action);
		case FORM_START:
			return fromStart(state, action);
		case AUTH_SUCCESS:
			return authSuccess(state, action);
		case UPDATE_USER:
			return updateUser(state, action);
		case GET_USER_DATA:
			return getUserDetails(state, action);
		case AUTH_FAIL:
			return authFail(state, action);
		case AUTH_LOGOUT:
			return lagout(state, action);
		case CLEAR_ER_MSG:
			return clearErrMsg(state, action);
		case FETCH_USER_START:
			return userFetchStart(state);
		case FETCH_USER_SUCCESS:
			return userFetchComplete(state, action);
		case FETCH_USER_FAIL:
			return userFetchFail(state, action);
		case ADD_USER_START:
			return userAddStart(state);
		case ADD_USER_SUCCESS:
			return userAddComplete(state, action);
		case ADD_USER_FAIL:
			return userAddFail(state, action);
		case EDIT_USER_START:
			return userEditStart(state);
		case EDIT_USER_SUCCESS:
			return userEditComplete(state, action);
		case EDIT_USER_FAIL:
			return userEditFail(state, action);
		case ADD_COMPANY_INSURANCE:
			return addCompInsurance(state, action);
		case DELETE_COMPANY_INSURANCE:
			return deleteCompInsurance(state, action);
		case FETCH_START:
			return fetchStart(state, action);
		case FETCH_FAIL:
			return fetchFail(state, action);
		default:
			return state;
	}
};
