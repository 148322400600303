import React, { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import size from 'lodash/size';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import defaultUser from '../../../static/images/default-user.png';

import MainContent from '../../components/Layouts/MainContent';
import Button from '../../components/Forms/Button';
import * as actions from '../../redux/actions/index';

import { dateFormat } from '../../common/utils/dates';
import { currencyFormatter } from '../../common/utils/currencyFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave
} from "@fortawesome/free-solid-svg-icons";
import './CompanySettings.scss';

const CustomContainer = styled(Container)`
  max-width: 1350px;
`;

const CustomRow = styled(Row)`
  flex-wrap: nowrap;
  @media (max-width: 858px){
    flex-wrap: wrap;
  }
`;

const CSCardHeader = styled.div`
	background-color: #262d32;
	padding: 15px 10px;
	h4 {
		color: fff;
	}
	margin-bottom: 20px;
`;

const StyledVerticalUL = styled.ul`
	display: flex;
	padding-left: 0px;
	justify-content: space-between;
`;

const StyledListItem = styled.li`
	display: flex;

	label input[type="radio"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: inline-block;
		width: 15px;
		height: 15px;
		padding: 3px;
		background-clip: content-box;
		border: 3px solid #424e57;
		background: #424e57 !important;
		border-radius: 50%;
	}
	label input[type="radio"]:checked {
		background: transparent !important;
	}
`;

const FlexList = styled.li`
	display: flex;
	padding-right: 5px;
	input {
		diplay: inline-block;
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const CustomInput = styled.input`
	width: 90%;
	padding: 5px;
`;

const CustomInsInput = styled(NumberFormat)`
  max-width: 90px;
`;

const StyledUl = styled.ul`
	margin: 0;
	padding: 10px;
	background-color: #262d32;
	flex: 1;
	li {
		list-style: none;
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		input[type="text"],
		input[type="date"],
		input[type="radio"] + label:before,
		input[type="string"] {
			border: 0;
			outline: 0;
			border-bottom: 1px solid #333;
			background-color: #424e57;
			color: white;
		}
	}

	li {
		input[type="file"] {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
			padding: 10px 5px;
			background-color: #fff;
			color: #262d32;
		}

		#file-label {
			padding: 5px;
			background-color: #fff;
			color: #262d32;
			height: 35px;
			font-size: 10pt;
			line-height: 2em;
			cursor: pointer;
			border-radius: 3px;
			margin-bottom: 0px;

			&:hover {
				background-color: #98dfd9;
			}
		}
	}
`;

const StyledTableRow = styled.tr`
	th {
		color: #888;
		font-weight: 500;
	}
	td {
		padding: 10px 5%;
		font-size: 10pt;
	}
	&.active {
		td {
			font-weight: 600;
		}
	}
	input[type="text"] {
		border: 0;
		outline: 0;
		width: 60%;
		background-color: #424e57;
		right: 0px;
		margin-left: 40%;
		padding: 5px 3px;
		color: #fff;
	}
	border-bottom: 1px solid #424e57;
	margin-bottom: 20px;
`;

const StyledTable = styled.table`
	border-collapse: collapse;
	border-spacing: 5px;
	width: 100%;
`;

const tooltipText = {
  sector: `Based on “the most comprehensive, representative and objective dataset of cyber claims 
          financial impact in Existence”, each sector’s cost below is derived from cyber-attack claim 
          payouts from top insurance firms globally, including AIG, AXA, Chubb, Liberty Mutual, 
          Nationwide, and Travelers.
          <a href="https://netdiligence.com/wp-content/uploads/2018/11/2018-NetDiligence-Claims-Study_Version-1.0.pdf">
            Netdiligence PDF </a>
`
}

//pure function item
const CompanySettings = (props) => {
	const [ formData, setFormData ] = useState({
		userId: props.user.id,
		companyname: props.user.company.name || '',
		company_id: props.user.company.id,
		insurances: props.user.insurances || [],
		adjustments: props.user.adjustments || [],
		compliance_id: props.user.company.compliance ? props.user.company.compliance : props.user.complianceRules[0].id
	});

	const [ isFormValid, setIsFormValid ] = useState(false);
	const [ isPercentValid, setIsPercentValid ] = useState(true);
	const [ insuranceDate, setInsuranceDate ] = useState();
	const insuranceNameRef = useRef(null);
	const insuranceDateRef = useRef(null);
	const insurancePolicyAmt = useRef(null);
	const sectorPercentagesRef = useRef(null);

	//handle form change for values
	const handleFormChange = (e) => {
		const companyname = e.target.value;
		setFormData({
			...formData,
			companyname
		});
	};

	//toast success (Green box lower right corner)
	const notifySuccess = () => toast.success('Updated settings!');

	//form validation
	const checkFormValid = () => {
		if (formData.name == '') {
			setIsFormValid(false);
			return;
		}
		const percentages = sectorPercentagesRef.current.querySelectorAll('.sector-percentage');
		let counter = 0;
		percentages.forEach((percent) => {
			if (percent.value) counter += Number.parseInt(percent.value);
		});
		if (counter > 100 || counter < 100) {
			setIsFormValid(false);
			setIsPercentValid(false);
			return;
		}
		setIsPercentValid(true);
		setIsFormValid(true);
	};

	//Version of derive unit cost which draws from sector adjustment UI.
	const calculateUnitCost = () => {
		const percentages = document.querySelectorAll('.sector-percentage'),
			costs = document.querySelectorAll('.sector-cost');
		let sum = 0;

		costs.forEach((value, index) => {
			if (percentages[index].value != 0) {
				sum = sum + percentages[index].value / 100 * parseFloat(value.id);
			}
		});
		return sum.toFixed(2);
	};

	useEffect(() => {
		checkFormValid();
	});

	//not used currently, compliance is hard-coded.
	const handleComplianceChange = (e) => {
		const compliance_id = e.target.value;
		setFormData({
			...formData,
			compliance_id
		});
	};

	//handle change of insurance
	const handleInsuranceDateChange = (date) => {
		setInsuranceDate(date);
	};

	//push to insurance array.
	const handlePushInsurance = () => {
		const name = insuranceNameRef.current.value;
		const date = insuranceDate;
		const amount = insurancePolicyAmt.current.state.numAsString;
		console.log(date, 'date');
		if (name && date) {
			const item = {
				company: props.user.company.id,
				name,
				date: new Date(date),
				amount: parseFloat(amount).toFixed(2)
			};
			props.onSaveCompanyInsurance(item);
			insuranceNameRef.current.value = '';
			setInsuranceDate('');
			insurancePolicyAmt.current.state.numAsString = '';
		}
	};

	const handleDeleteInsurance = (item) => {
		props.onDeleteCompanyInsurance(item);
	};

	//calculate overall percentages
	const handlePercentChange = (e) => {
		e.preventDefault();
		const id = e.target.id;
		const items = props.user.adjustments.map((item) => {
			if (item.sector == id) {
				item.percentage = '' + Number.parseInt(e.target.value);
			}
			return item;
		});
		let newData = { ...formData };
		newData.adjustments = [ ...items ];
		setFormData(newData);
	};

	//submission handler for all data/inputs on page to update Company attributes and sector adjustment.
	const submitDetails = () => {
		const { companyname, company_id, compliance_id } = formData;
		const adjustmentsData = props.user.adjustments.map((a) => {
			return {
				company: company_id,
				...a
			};
		});
		const fieldsData = {
			company_id,
			companyname,
			adjustments: adjustmentsData,
			compliance_id
		};
		console.log(fieldsData, 'fieldsData');
		props.onUpdateUser(fieldsData, () => {
			notifySuccess();
		});
	};

	//retrieve appropriate data for driving CS UI.
	const getInsuranceList = () =>
		props.user.insurances.map((insurance, i) => (
			<li key={`inst-list-${insurance.name}-${i}`} style={{ display: 'flex' }}>
				<div>
					<span style={{ fontWeight: 'bold' }} className="mr-2">
						{insurance.name}
					</span>
					<br />
					<span>
						<Moment format={dateFormat} date={insurance.date} />
					</span>
					<br />
					<span className="mr-2">{currencyFormatter(insurance.amount)}</span>
				</div>
				<div>
					<Button
						size="sm"
						className="btn btn-error"
						id={insurance.id}
						onClick={() => handleDeleteInsurance(insurance)}
					>
						Delete
					</Button>
				</div>
			</li>
		));

	const exceedPercentageMessage = 'You are only allowed to set a total of 100% for sector adjustments.';

	const getComplianceList = () =>
		props.user.complianceRules.map((data, i) => (
			<StyledListItem
				key={`com-list-${data.id}-${i}-${Math.random()}`}
				data-id={data.id}
				style={{ backgroundColor: '#262d32' }}
			>
				<label style={{ backgroundColor: 'transparent', display: 'flex' }} htmlFor={data.id}>
					<input
						style={{ backgroundColor: 'transparent' }}
						defaultChecked={data.id == props.user.company.compliance}
						type="radio"
						name="data-source"
						id={data.id}
						value={data.id}
						className="mr-2"
						onClick={handleComplianceChange.bind(this)}
						disabled="disabled"
					/>
					<span style={{ fontSize: '10pt' }}>{data.regulation}</span>
				</label>
			</StyledListItem>
		));

	const imgStyle = {
		height: '100%',
		verticalAlign: 'middle',
		padding: '5px'
	};

    console.log(props.user.adjustments, "adjustments")
	return (
		<MainContent>
			<ToastContainer position="bottom-right" />
			<CustomContainer>
				<div className="button-holder">
					<Button disabled={!isFormValid || !isPercentValid} onClick={submitDetails} className="cs-button">
						{props.loadingSubmit ? 'LOADING...' : 'Save Settings'} <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faSave} />
					</Button>
				</div>
				<CustomRow>
					<Col style={{ flex: 2 }}>
						<CSCardHeader>
							<div style={{ display: 'flex' }}>
								<div style={imgStyle}>
									<img style={imgStyle} src={defaultUser} />
								</div>
								<StyledUl>
									<li style={{ minHeight: '100%', paddingTop: '20px' }}>
										<CustomInput
											style={{ margin: 'auto', verticalAlign: 'middle' }}
											className="ml-3"
											onChange={handleFormChange}
											type="text"
											name="name"
											value={formData.companyname}
										/>
									</li>
								</StyledUl>
							</div>
						</CSCardHeader>
						<CSCardHeader title="compliance rules">
							<h4>Compliance Rules</h4>
							<StyledVerticalUL>
								{size(props.user.complianceRules) > 0 ? getComplianceList() : null}
							</StyledVerticalUL>
						</CSCardHeader>
						<CSCardHeader title="insurance coverage">
							<h4>Insurance Coverage</h4>
							<StyledUl>
								<FlexList>
									<input
										ref={insuranceNameRef}
										className="mr-3"
										type="text"
										name="insuranceName"
										placeholder="Insurance Name"
									/>
									<DatePicker
										className="mr-3"
										ref={insuranceDateRef}
										name="insuranceDate"
										value={insuranceDate}
										placeholderText="Effective Date"
										selected={insuranceDate}
										onChange={handleInsuranceDateChange}
									/>
									{/* <input ref={insuranceDateRef} className="mr-3" type="date" name="insuranceDate" placeholder="Effective Date"/> */}
									<CustomInsInput
										name="insuranceAmt"
										className="mr-2"
										thousandSeparator={true}
										ref={insurancePolicyAmt}
										prefix={'$'}
										placeholder="$1,000.00"
									/>
								</FlexList>
								<li>
									<Button className="btn btn-primary" onClick={handlePushInsurance}>
										{props.addLoading ? 'LOADING...' : 'Add Insurance'}
									</Button>
								</li>
								<hr style={{ borderColor: '#424e57' }} />
								<div
									className="insurance-list"
									style={{ flex: 1, overflow: 'scroll', maxHeight: '60vh' }}
								>
									{size(props.user.insurances) > 0 ? getInsuranceList() : null}
								</div>
							</StyledUl>
						</CSCardHeader>
					</Col>
					<Col style={{ flex: 1 }}>
						<CSCardHeader title="sector adjustment">
						<div className="tooltip-wrapper" data-tip={tooltipText.sector} >
                <h4 style={{pointerEvents: "none"}} onmouseover="title='';">Sector Adjustment</h4>
              </div>
							<StyledTable ref={sectorPercentagesRef}>
								<tbody>
									{size(props.user.adjustments) > 0 &&
										props.user.adjustments.sort((a, b) => { return parseInt(a.order_by) - parseInt(b.order_by) }).map((adjustment) => (
											<StyledTableRow key={adjustment.sector}>
												{/* <td>{adjustment.active ? '»' : null}</td> */}
												<td colSpan={2}>{adjustment.sector}</td>
												<td
													colSpan={1}
													style={{ textAlign: 'right' }}
													className="sector-cost"
													id={adjustment.cost}
												>
													{parseFloat(adjustment.cost).toFixed(2)}
												</td>
												<td colSpan={1}>
													<InputMask
														mask="999"
														maskChar=""
														className="sector-percentage"
														id={adjustment.sector}
														onChange={handlePercentChange}
														type="text"
														name="percentage"
														defaultValue={adjustment.percentage}
														placeholder="%"
													/>
												</td>
											</StyledTableRow>
										))}
									<StyledTableRow>
										<td colSpan={1} />
										<td
											colSpan={2}
											style={{ textAlign: 'right', color: '#449944', fontWeight: 'bold' }}
										>
											Avg. Cost per Record:{' '}
										</td>
										<td
											colSpan={1}
											style={{ color: '#aa3333', textAlign: 'right', paddingRight: '10%' }}
										>
											{calculateUnitCost()}
										</td>
									</StyledTableRow>
								</tbody>
							</StyledTable>
							{!isPercentValid && <small className="error">{exceedPercentageMessage}</small>}
						</CSCardHeader>
					</Col>
				</CustomRow>
			</CustomContainer>
		</MainContent>
	);
};

const mapStateToProps = (state) => ({
	fatchLoading: state.auth.fatchLoading,
	loadingSubmit: state.auth.submit,
	addLoading: state.auth.addLoading,
	user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
	onUpdateUser: (fields, callback) => dispatch(actions.onUpdateUser(fields, callback)),
	onSaveCompanyInsurance: (fields) => dispatch(actions.saveCompanyInsurance(fields)),
	onDeleteCompanyInsurance: (params) => dispatch(actions.deleteCompanyInsurance(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings);
