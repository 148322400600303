import React from 'react';
import size from 'lodash/size';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import Chart from './chart';

import {
  createFeatureImportanceData, createLearningCurveData, createRocData,
  createConfusionMatrixData, createClassifReportData, createClassBalanceData
} from './classification-charts-data';
import { createPredCurveData, createPredErrorData, createResidualsData } from './regression-charts-data';

class TrialMetricsModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      activeTab: '1',
    }
  }

  toggleTabs = tab => {
    this.setState({ activeTab: tab }); 
  };

  componentDidMount() {
  }

  render() {
    const { isOpen, toggleModal, predErrorData, featureImportanceData, modelType, classificationData } = this.props;
    const { activeTab } = this.state;
    return(
      <div>
        <Modal isOpen={isOpen} toggle={toggleModal} size='lg' className="metrics-window">
          <ModalHeader toggle={toggleModal}>
          {modelType === 'regression' ? (
            <Nav tabs>
              <NavItem>
                  <NavLink
                      className={activeTab === '1' ? 'active' : ''}
                      onClick={() => { this.toggleTabs('1'); }}
                  >
                    Prediction Error
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink
                      className={activeTab === '2' ? 'active' : ''}
                      onClick={() => { this.toggleTabs('2'); }}
                  >
                      Prediction Curve
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink
                      className={activeTab === '3' ? 'active' : ''}
                      onClick={() => { this.toggleTabs('3'); }}
                  >
                      Residuals Plot
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink
                      className={activeTab === '4' ? 'active' : ''}
                      onClick={() => { this.toggleTabs('4'); }}
                  >
                      Feature Importance
                  </NavLink>
              </NavItem>
          </Nav>
          ) : (<Nav tabs>
            <NavItem>
                <NavLink
                    className={activeTab === '1' ? 'active' : ''}
                    onClick={() => { this.toggleTabs('1'); }}
                >
                  ROC Curve
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={activeTab === '2' ? 'active' : ''}
                    onClick={() => { this.toggleTabs('2'); }}
                >
                    Classification
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={activeTab === '3' ? 'active' : ''}
                    onClick={() => { this.toggleTabs('3'); }}
                >
                    Class Balance
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={activeTab === '4' ? 'active' : ''}
                    onClick={() => { this.toggleTabs('4'); }}
                >
                    Confusion Matrix
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={activeTab === '5' ? 'active' : ''}
                    onClick={() => { this.toggleTabs('5'); }}
                >
                    Feature Importance
                </NavLink>
            </NavItem>
        </Nav>)}          
          </ModalHeader>
          <ModalBody>
            {
              modelType === 'regression' ? (
                <div>
                  { activeTab === '1' ? 
                    size(predErrorData) > 0 ? (
                    <Chart {...createPredErrorData(predErrorData)}/>
                    ) : (
                    <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                  { activeTab === '2' ? 
                    size(predErrorData) > 0 ? (
                    <Chart {...createPredCurveData(predErrorData)}/>
                    ) : (
                    <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                  { activeTab === '3' ? 
                    size(predErrorData) > 0 ? (
                    <Chart {...createResidualsData(predErrorData)}/>
                    ) : (
                    <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                  { activeTab === '4' ? 
                    size(featureImportanceData) > 0 ? (
                    <Chart {...createFeatureImportanceData(featureImportanceData)}/>
                    ) : (
                    <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                </div>
              ) : (
                <div>
                  { activeTab === '1' ? 
                    size(classificationData.roc_curve_data) > 0 ? (
                      <Chart {...createRocData(classificationData.roc_curve_data, classificationData.roc_labels)} />
                    ) : (
                      <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                 { activeTab === '2' ? 
                    size(classificationData.classification_report) > 0 ? (
                      <Chart {...createClassifReportData({x: classificationData.classification_report.x, y: classificationData.classification_report.y, vals:classificationData.classification_report.vals})} />
                    ) : (
                      <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                 { activeTab === '3' ? 
                    size(classificationData.class_balance_data) > 0 ? (
                      <Chart {...createClassBalanceData(classificationData.class_balance_data)} />
                    ) : (
                      <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                 { activeTab === '4' ? 
                    size(classificationData.confusion_matrix_data) > 0 ? (
                      <Chart {...createConfusionMatrixData(classificationData.confusion_matrix_data)}/>
                    ) : (
                      <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                 { activeTab === '5' ? 
                    size(classificationData.feature_importance_data) > 0 ? (
                      <Chart {...createFeatureImportanceData(classificationData.feature_importance_data)}/>
                    ) : (
                      <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                    )
                 : ''}
                </div>
              )
            }
            
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default TrialMetricsModal;
