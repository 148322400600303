import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Field, reduxForm, getFormValues } from 'redux-form';
// TODO: Create custom styled bootstrap modules
import { Col, Container, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';

import MainContent from '../../components/Layouts/MainContent';
import Button from '../../components/Forms/Button';
import FormBg from '../../components/Layouts/FormBg';

import * as actions from '../../redux/actions/index';

const StyledField = styled.input`
	&:-internal-autofill-selected,
	&:required,
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 50px #3e3e3e inset;
		-webkit-text-fill-color: #cdcdcd;
	}

	&::placeholder {
		color: #cdcdcd;
	}

	background-color: #3e3e3e;
	border: none;
	border-bottom: 1px solid #cdcdcd;
	color: #cdcdcd;
	width: 100%;
`;
const renderField = (field) => {
	const { input, label, placeholder, type, name, meta: { touched, error, warning } } = field;
	return (
		<Form.Group key={`${name}-${type}`}>
			<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
			<div>
				<StyledField type={type} placeholder={placeholder} {...input} />
			</div>
			{touched &&
				((error && <p className="help is-danger">{error}</p>) ||
					(warning && <p className="help is-danger">{warning}</p>))}
		</Form.Group>
	);
};

const Register = (props) => {
	const { handleSubmit, loadingSubmit, errorMsg } = props;
	let errorMessage = null;
	let authRedirect = null;

	useEffect(() => {
		props.onClearErrMsg();
	}, []);

	if (errorMsg) {
		errorMessage = <p className="is-danger">{JSON.stringify(errorMsg)}</p>;
	}

	if (props.isAuthenticated) {
		authRedirect = <Redirect to="/home" />;
	}

	const submitHandle = (values) => {
		try {
			const userData = {
				first_name: values.firstName,
				last_name: values.lastName,
				email: values.email,
				company: values.companyName,
				user_role: 6,
				password1: values.password,
				password2: values.confirmPassword
			};
			props.onSignUp(userData, () => {
				props.history.push('/');
			});
		} catch (e) {
			alert(e.message);
		}
	};

	function registerForm() {
		return (
			<Container>
				<h2 style={{ marginLeft: '0px' }}>Register</h2>
				<Row>
					<Col>
						{authRedirect}
						{errorMessage}
						<Form onSubmit={handleSubmit((values) => submitHandle(values))}>
							<Field
								name="firstName"
								type="text"
								placeholder="Enter first name"
								component={renderField}
								label="First Name"
							/>
							<Field
								name="lastName"
								type="text"
								placeholder="Enter last name"
								component={renderField}
								label="Last Name"
							/>
							<Field
								name="email"
								type="email"
								placeholder="Enter email"
								component={renderField}
								label="Email address"
							/>
							<Field
								name="companyName"
								type="text"
								placeholder="Enter your company name"
								component={renderField}
								label="Company Name"
							/>
							<Field
								name="password"
								type="password"
								placeholder="Enter password"
								component={renderField}
								label="Password"
							/>
							<Field
								name="confirmPassword"
								type="password"
								placeholder="Re-enter password"
								component={renderField}
								label="Password Confirmation"
							/>
							<Row>
								<Col>
									<p style={{ fontSize: '10pt' }}>
										Already have an account?{' '}
										<Link to="/" style={{ color: '#68afa9', fontWeight: 'bold' }}>
											LOGIN
										</Link>
									</p>
								</Col>
								<Col>
									<Button
										style={{ width: '80%', margin: 'auto', borderRadius: '10px' }}
										type="submit"
										block
									>
										{loadingSubmit ? ' LOADING...' : 'Register'}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<MainContent>
			<FormBg>{registerForm()}</FormBg>
		</MainContent>
	);
};

const validate = (values) => {
	const errors = {};
	if (!values.confirmationCode) {
		errors.confirmationCode = 'Field is required';
	}
	if (!values.firstName) {
		errors.firstName = 'Field is required';
	}
	if (!values.lastName) {
		errors.lastName = 'Field is required';
	}
	if (!values.email) {
		errors.email = 'Email address field is required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}
	if (!values.companyName) {
		errors.companyName = 'Field is required';
	}
	if (!values.password) {
		errors.password = 'Field is required';
	} else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/i.test(values.password)) {
		errors.password =
			'You must include at least one lowercase and uppercase character, one number, and one special character.';
	}
	if (!values.confirmPassword) {
		errors.confirmPassword = 'Please re-enter your password.';
	} else if (values.password != values.confirmPassword) {
		errors.confirmPassword = 'Passwords do not match.';
	}
	return errors;
};

const mapStateToProps = (state) => ({
	loadingSubmit: state.auth.submit,
	errorMsg: state.auth.errorMsg,
	isAuthenticated: state.auth.idToken !== null,
	adjustments: state.company.adjustments,
	getValues: getFormValues('RegisterForm')(state)
});

const mapDispatchToProps = (dispatch) => ({
	onSignUp: (params, callback) => dispatch(actions.signUp(params, callback)),
	onClearErrMsg: () => dispatch(actions.clearAllErrMsg())
});

export default connect(mapStateToProps, mapDispatchToProps)(
	reduxForm({
		form: 'RegisterForm',
		validate
	})(Register)
);
