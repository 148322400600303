import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import size from 'lodash/size';
import{ Container, Form }from 'react-bootstrap';
import MainContent from '../../components/Layouts/MainContent';
import CustomTableWithPagination from '../../components/Admin/CustomTable';
import { ToastContainer, toast } from 'react-toastify';
import './styles.scss';


const col = [
  { dataField: 'all_obj', text: 'Unique Records' },
  { dataField: 'phone_count', text: 'Phone Numbers' },
  { dataField: 'email_count', text: 'Email' },
  { dataField: 'phone_email_count', text: 'Email & Phone Combo' },
  { dataField: 'business_count', text: 'Businesses' },
  { dataField: 'sic_code_count', text: 'SIC Codes' },
  { dataField: 'maid_count', text: 'MAIDs' },
  { dataField: 'ip_count', text: 'IP Addresses' }
];

const colHash = [
  { dataField: 'all_obj', text: 'Unique Records' },
  { dataField: 'phone_count', text: 'Phone Numbers' },
  { dataField: 'email_count', text: 'Email' },
  { dataField: 'phone_email_count', text: 'Hashed Email' },
  { dataField: 'business_count', text: 'Businesses' },
  { dataField: 'sic_code_count', text: 'SIC Codes' },
  { dataField: 'maid_count', text: 'MAIDs' },
  { dataField: 'ip_count', text: 'IP Addresses' }
];

const renderNum = (input) => {
  return parseInt(input).toLocaleString()
}

const fe_data = [
  { 
    id: 1,
    all_obj: renderNum(19529432),
    phone_count: renderNum(8495325),
    email_count: renderNum(29843388),
    phone_email_count: renderNum(37678665),
    business_count: renderNum(5907173),
    sic_code_count: renderNum(12856),
    maid_count: 0,
    ip_count: 0,
  }
];

const qs_data = [
  { 
    id: 1,
    all_obj: renderNum(328130),
    phone_count: renderNum(386766),
    email_count: renderNum(378637),
    phone_email_count: renderNum(410517),
    business_count: renderNum(400153),
    sic_code_count: 0,
    maid_count: 0,
    ip_count: renderNum(377283),
  }
];

const prospectr_data = [
  { 
    id: 1,
    all_obj: renderNum(50583075),
    phone_count: renderNum(28224571),
    email_count: renderNum(176182527),
    phone_email_count: renderNum(184241877),
    business_count: renderNum(21654780),
    sic_code_count: renderNum(74486),
    maid_count: 0,
    ip_count: 0,
  }
];

const sla_data = [
  { 
    id: 1,
    all_obj: renderNum(22742689),
    phone_count: renderNum(3438251),
    email_count: renderNum(41508321),
    phone_email_count: renderNum(41520752),
    business_count: renderNum(10005624),
    sic_code_count: renderNum(14916),
    maid_count: 0,
    ip_count: 0,
  }
];

const sd_data = [
  { 
    id: 1,
    all_obj: renderNum(809707),
    phone_count: renderNum(497276),
    email_count: renderNum(1006872),
    phone_email_count: renderNum(1016631),
    business_count: renderNum(68533),
    sic_code_count: 0,
    maid_count: 0,
    ip_count: 0,
  }
];

const xtaas_data = [
  { 
    id: 1,
    all_obj: renderNum(999999),
    phone_count: 0,
    email_count: 0,
    phone_email_count: renderNum(741556),
    business_count: 0,
    sic_code_count: 0,
    maid_count: 0,
    ip_count: 0,
  }
];

const lift_data = [
  { 
    id: 1,
    all_obj: renderNum(59412771),
    phone_count: renderNum(1289982),
    email_count: renderNum(44912901),
    phone_email_count: renderNum(45703787),
    business_count: renderNum(2400491),
    sic_code_count: renderNum(1344),
    maid_count: renderNum(0),
    ip_count: renderNum(0),
  }
];

const MemberScorecards = (props) => {

  const renderColums = (data) => {
    return data.map((d) => ({
      id: d.id,
      all_obj: d.all_obj,
      phone_count: d.phone_count,
      email_count: d.email_count,
      phone_email_count: d.phone_email_count,
      business_count: d.business_count,
      sic_code_count: d.sic_code_count,
      maid_count: d.maid_count,
      ip_count: d.ip_count,
    }));
  }

  
  	return(
      <MainContent>
        <ToastContainer position="bottom-center" />
        	<div className="contaner-fluid rw-container data-master">    
            <h3 className="title-h">Member Scorecards</h3>      
            <Container className="container-classs">                           
              <div className="col-style">  
                <h2>FlowEngine</h2>   
                  <div className="tab-content-wrapper">
                    { size(qs_data) > 0 ? <CustomTableWithPagination columns={col} rows={renderColums(fe_data)} /> : null }
                  </div>         
              </div>
              <div className="col-style">  
                <h2>QuickSpark</h2>   
                  <div className="tab-content-wrapper">
                    { size(qs_data) > 0 ? <CustomTableWithPagination columns={col} rows={renderColums(qs_data)} /> : null }
                  </div>         
              </div>
              <div className="col-style">  
                <h2>Prospectr Marketing</h2>   
                  <div className="tab-content-wrapper">
                    { size(qs_data) > 0 ? <CustomTableWithPagination columns={col} rows={renderColums(prospectr_data)} /> : null }
                  </div>         
              </div>
              <div className="col-style">  
                <h2>Sales Lead Automation</h2>   
                  <div className="tab-content-wrapper">
                    { size(qs_data) > 0 ? <CustomTableWithPagination columns={col} rows={renderColums(sla_data)} /> : null }
                  </div>         
              </div>
              <div className="col-style">  
                <h2>SimplyDIRECT</h2>   
                  <div className="tab-content-wrapper">
                    { size(qs_data) > 0 ? <CustomTableWithPagination columns={col} rows={renderColums(sd_data)} /> : null }
                  </div>         
              </div>
              <div className="col-style">  
                <h2>Lift Certain B2B</h2>   
                  <div className="tab-content-wrapper">
                    { size(lift_data) > 0 ? <CustomTableWithPagination columns={col} rows={renderColums(lift_data)} /> : null }
                  </div>         
              </div>
              <div className="col-style">  
                <h2>XTaaS</h2>   
                  <div className="tab-content-wrapper">
                    { size(qs_data) > 0 ? <CustomTableWithPagination columns={colHash} rows={renderColums(xtaas_data)} /> : null }
                  </div>         
              </div>
            </Container>
          </div>        
      </MainContent>
  	)
  }

  const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
    
  export default connect(mapStateToProps, null)(MemberScorecards);