import React from 'react';
import './switch.scss';

const Switch = ({isChecked, handleChange}) => {
    return(
        <div className="switch-container">
            <label>
                <input checked={isChecked} onChange={() => handleChange(!isChecked)} className="switch" type="checkbox" />
                <div>
                    <div />
                </div>
            </label>
        </div>
    )
}

export default Switch;
