import React, { Component } from 'react';
import * as d3 from 'd3';

//Data for driving the visualization.
var data = {
	nodes: [
		{ id: 1, rad: 20, range: 4 },
		{ id: 2, rad: 10, range: 1 },
		{ id: 3, rad: 9, range: 3 },
		{ id: 4, rad: 11, range: 2 },
		{ id: 5, rad: 10, range: 2 },
		{ id: 6, rad: 5, range: 1 },
		{ id: 7, rad: 15, range: 3 },
		{ id: 8, rad: 3, range: 2 },
		{ id: 9, rad: 6, range: 5 },
		{ id: 10, rad: 9, range: 2 },
		{ id: 11, rad: 5, range: 2 },
		{ id: 12, rad: 7, range: 3 },
		{ id: 13, rad: 3, range: 3 },
		{ id: 14, rad: 12, range: 5 },
		{ id: 15, rad: 5, range: 1 },
		{ id: 16, rad: 9, range: 2 },
		{ id: 17, rad: 5, range: 2 },
		{ id: 18, rad: 7, range: 3 },
		{ id: 19, rad: 3, range: 3 },
		{ id: 20, rad: 12, range: 5 },
		{ id: 21, rad: 5, range: 1 }
	],
	links: [
		{ source: 1, target: 2, count: 5 },
		{ source: 1, target: 3, count: 3 },
		{ source: 1, target: 4, count: 10 },
		{ source: 1, target: 5, count: 2 },
		{ source: 1, target: 9, count: 15 },
		{ source: 1, target: 10, count: 8 },
		{ source: 2, target: 3, count: 3 },
		{ source: 2, target: 5, count: 20 },
		{ source: 2, target: 1, count: 12 },
		{ source: 2, target: 8, count: 5 },
		{ source: 3, target: 6, count: 9 },
		{ source: 3, target: 10, count: 1 },
		{ source: 1, target: 4, count: 17 },
		{ source: 3, target: 11, count: 20 },
		{ source: 2, target: 15, count: 12 },
		{ source: 2, target: 7, count: 5 },
		{ source: 1, target: 12, count: 9 },
		{ source: 2, target: 13, count: 1 },
		{ source: 1, target: 13, count: 17 },
		{ source: 2, target: 14, count: 3 },
		{ source: 6, target: 3, count: 5 },
		{ source: 14, target: 2, count: 9 },
		{ source: 9, target: 1, count: 1 },
		{ source: 13, target: 6, count: 17 },
		{ source: 15, target: 4, count: 3 },
		{ source: 16, target: 3, count: 5 },
		{ source: 2, target: 17, count: 9 },
		{ source: 1, target: 21, count: 1 },
		{ source: 2, target: 20, count: 17 },
		{ source: 2, target: 18, count: 3 },
		{ source: 6, target: 19, count: 5 },
		{ source: 12, target: 1, count: 9 },
		{ source: 19, target: 2, count: 1 },
		{ source: 3, target: 9, count: 17 },
		{ source: 4, target: 13, count: 3 }
	]
};

const colorArrays = [
	[ '#5464fd', 'crimson' ],
	[ 'magenta', 'turquoise' ],
	[ '#32f354', 'saddlebrown' ],
	[ 'white', '#32f355' ],
	[ 'salmon', 'red' ]
];

class AnimatedBGFE extends Component {
	constructor(props) {
		super(props);
	}

	//unique identifier for adding to gradients.
	uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	drawSimulation() {
		//declare global assets
		var nodes = data.nodes;
		var links = data.links;
		const that = this;

		var svg = d3.select('#login-animated-bg').append('svg');
		svg.attr('preserveAspectRatio', 'xMinYMin meet').attr('viewBox', '0 0 1010 900');

		var width = parseInt(svg.style('width'), 10),
			height = parseInt(svg.style('height'), 10),
			margin = {
				top: 40,
				left: 30,
				bottom: 20,
				right: 30
			};

		//define dimensions and basic gradient
		var area = width * height,
			defs = svg.append('defs'),
			gradient1 = defs.append('linearGradient').attr('id', 'gradient1');

		let background = svg.append('g');
		background.append('rect').attr('width', '100%').attr('height', '100%').attr('opacity', 0);

		let view = svg.append('g');

		//define simulation
		const simulation = d3
			.forceSimulation(nodes)
			.force('charge', d3.forceManyBody().strength(-3500))
			.force(
				'link',
				d3
					.forceLink(links)
					.id(function(d) {
						return d.id;
					})
					.distance((d) => 2 * d.count)
			)
			.force('center', d3.forceCenter(width / 4, height / 5))
			.force('collision', d3.forceCollide().radius(25))
			.alpha(0.9)
			.alphaDecay(0.009)
			.velocityDecay(0.9)
			.alphaMin(0.01);
		//.tick(300);

		// append group for svg items, also primary element transformed in animation
		var g = view.append('g'),
			linkContainer = g.append('g').selectAll('.link'),
			nodeContainer = g
				.append('g')
				.attr('stroke', '#6e6e6e')
				.attr('stroke-width', 0.5)
				.selectAll('circle')
				.classed('node', true);
		nodeContainer = nodeContainer.data(nodes, function(d) {
			return d.id;
		});
		linkContainer = linkContainer.data(links, function(d) {
			return d.source.id + '-' + d.target.id;
		});

		// define essential node and link elements
		var node = nodeContainer.enter().append('circle').attr('class', 'nodes'),
			link = linkContainer.enter().append('path').attr('class', 'links'),
			nodeColor = d3.scaleLinear().range(colorArrays[0]).domain(d3.extent(nodes, (d) => d.range)),
			arcScale = d3.scaleLinear().range([ 0.25, 3 ]).domain(d3.extent(links, (d) => d.count));

		//Visualization positioning
		simulation.on('tick', ticked);

		function ticked(e) {
			positioner(e);
		}

		function positioner(e) {
			node
				.attr('cx', function(d) {
					return d.x;
				})
				.attr('cy', function(d) {
					return d.y;
				})
				.attr('r', (d) => {
					return d.rad;
				})
				.transition()
				.attr('fill', (d) => nodeColor(d.range));

			linkDefinition();
		}

		function gradientDef(d) {
			let id = 'S' + d.source.id + 'T' + d.target.id + 'id' + that.uuidv4();
			let gradient1 = defs
				.append('linearGradient')
				.classed('arc-gradient', true)
				.attr('id', id)
				.attr('gradientUnits', 'userSpaceOnUse');
			gradient1.attr('x1', d.source.x).attr('y1', d.source.y).attr('x2', d.target.x).attr('y2', d.target.y);
			gradient1.append('stop').attr('offset', '0%').transition().attr('stop-color', '#5464fd').duration(3000);
			gradient1.append('stop').attr('offset', '70%').transition().attr('stop-color', 'crimson').duration(3000);
			d.colorId = 'url(#' + id + ')';
			return 'url(#' + id + ')';
		}

		function linkDefinition(def = 0) {
			link
				.style('stroke', function(d) {
					let id = 'S' + d.source.id + 'T' + d.target.id + 'id' + that.uuidv4();
					let gradient1 = defs
						.append('linearGradient')
						.classed('arc-gradient', true)
						.attr('id', id)
						.attr('gradientUnits', 'userSpaceOnUse');
					gradient1
						.attr('x1', d.source.x)
						.attr('y1', d.source.y)
						.attr('x2', d.target.x)
						.attr('y2', d.target.y);
					gradient1.append('stop').attr('offset', '0%').attr('stop-color', '#5464fd');
					gradient1.append('stop').attr('offset', '70%').attr('stop-color', 'crimson');
					d.colorId = 'url(#' + id + ')';
					return 'url(#' + id + ')';
				})
				.attr('d', function(d) {
					//below determines curvature of arcs.
					var x1 = d.source.x,
						y1 = d.source.y,
						x2 = d.target.x,
						y2 = d.target.y,
						dx = x2 - x1,
						dy = y2 - y1,
						dr =
							Math.sqrt(dx * dx + dy * dy) != 0
								? Math.sqrt(dx * dx + dy * dy)
								: Math.sqrt(node._groups[0][d.source.index].r.baseVal.value),
						arc1 = dr / (d.count / 5),
						arc2 = dr / (d.count / 5);

					var xRotation = 15,
						largeArc = 0,
						sweep = 0;

					if ((x2 > x1 && y2 > y1) || (x1 > x2 && y1 > y2)) {
						sweep = 0;
					}

					if (x1 === x2 && y1 === y2) {
						x1 = x1 + 10;
						y1 = y1 - 0;
						x2 = x2 - 10;
						y2 = y2 + 0;
						d.count = Math.ceil(d.count / 2);
						arc1 = Math.sqrt(node._groups[0][d.source.index].r.baseVal.value) * 5;
						arc2 = arc1 / 1.3;
						xRotation = -180;
						largeArc = 1;
					}

					let A2 =
						'A' + arc1 + ',' + arc2 + ' ' + xRotation + ' ' + largeArc + ',' + sweep + ' ' + x2 + ',' + y2;

					return 'M' + x1 + ',' + y1 + ' ' + A2;
				})
				.attr('fill', 'none')
				.attr('stroke-width', (d) => arcScale(d.count));
		}

		//define the relevant transtitions/ the animation

		function handleColorTransitions(i) {
			return d3.scaleLinear().range(colorArrays[i]).domain(d3.extent(nodes, (d) => d.range));
		}

		simulation.on('end', () => {
			nodeColor = handleColorTransitions(1);
			d3.selectAll('.nodes').transition().duration(3000).style('fill', (d) => nodeColor(d.range));
		});

		var count = 2;

		var timedInt = setInterval(function() {
			let num = count % colorArrays.length;
			nodeColor = handleColorTransitions(num);
			d3.selectAll('.nodes').transition().duration(3000).style('fill', (d) => nodeColor(d.range));
			count += 1;
		}, 3000);

		setTimeout(() => clearInterval(timedInt), 30000);

		/*
    g.transition().duration(3000)
     .attr("transform", "rotate(-5)") 
     .transition().duration(3000)
     .attr("transform", "rotate(5)") 
     .transition().duration(3000)
     .attr("transform", "translate(5, -5)rotate(-5)") 
     .transition().duration(3000)
     .attr("transform", "translate(-5, -5)rotate(5)") 
    */
	}

	componentDidMount() {
		//one time draw on mounting component
		this.drawSimulation();
	}

	render() {
		return <div id="login-animated-bg" width="1000px" height="700px" />;
	}
}

export default AnimatedBGFE;
