import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import size from 'lodash/size';
import{ Container, Form }from 'react-bootstrap';
import MainContent from '../../components/Layouts/MainContent';
import CustomTableWithPagination from '../../components/Admin/CustomTable';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../../redux/actions/index';
import AddHealthForm from './AddHealthForm'
import CsvUpload from './CsvUpload'
import './styles.scss';


const colHealthStatus = [
  { dataField: 'event_id', text: 'EventID', sort: true },
  { dataField: 'first_name', text: 'First Name' },
  { dataField: 'last_name', text: 'Last Name' },
  { dataField: 'email_address', text: 'Email' },
  { dataField: 'disposition', text: 'Disposition' },
  { dataField: 'mobile_phone', text: 'Mobile' },
  { dataField: 'created_at', text: 'Date' }
];

const HealthAccessManager = (props) => {
  const { 
    healthStatusData, 
    getHealthStatusData, 
  } = props;

  const [isBtn, setIsBtn] = useState(false)

  useEffect(() => {
    getHealthStatusData()
  }, []); 

  const handleBtn = () => {    
    setIsBtn(!isBtn)
  }

  const renderHealthStatus = (healthStatusData) => {
    return healthStatusData.map((hs) => ({
      id: hs.uuid,
      event_id: hs.event_id,
      first_name: hs.first_name,
      last_name: hs.last_name,
      email_address: hs.email_address,
      disposition: "-----",
      mobile_phone: hs.mobile_phone,
      created_at: hs.created_at,
    }));
  }

  
  	return(
      <MainContent>
        <ToastContainer position="bottom-center" />
        	<div className="contaner-fluid rw-container">    
            <h3 className="title-h">Health Access Manager</h3>      
            <Container className="container-class">                           
              <div className="col-style-left">  
                <h2>Health Data</h2>   
                  <div className="tab-content-wrapper">
                    { size(healthStatusData) > 0 ? <CustomTableWithPagination columns={colHealthStatus} rows={renderHealthStatus(healthStatusData)} /> : null }
                  </div>         
              </div>
              <div className="col-style-right"> 
                  <button className="upload-btn" onClick={() => handleBtn()}>
                    {isBtn ? 'Add Health Data' : 'Upload CSV file' }
                  </button>       
                  {isBtn ? (<CsvUpload />) : (<AddHealthForm />) }
              </div>
            </Container>
          </div>        
      </MainContent>
  	)
  }

  const mapStateToProps = (state) => ({
    user: state.auth.user,
    healthStatusData: state.pw.healthStatusData,
  });
    
  const mapDispatchToProps = (dispatch) => ({
    getHealthStatusData: () => dispatch(actions.getHealthStatusData()),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(HealthAccessManager);