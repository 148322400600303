import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { Container, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../../redux/actions/index';
import './styles.scss';


const renderField = (field) => {
  const {
    input,
    label,
    placeholder,
    type,
    name,
    meta: { touched, error, warning },
  } = field;
  return (
    <Form.Group key={`${name}-${type}`}>
      <Form.Label style={{ color: "#6e6e6e" }}>{label}</Form.Label>
      <input
        type={type}
        placeholder={placeholder}
        {...input}
        className="form-control"
      />
      {touched
        && ((error && <p className="help is-danger">{error}</p>)
          || (warning && <p className="help is-danger">{warning}</p>))}
    </Form.Group>
  );
}

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const renderFileField = (field) => {
  const { input: { value: omitValue, onChange, onBlur, ...inputProps }, meta: omitMeta, ...props } = field;
  return (
    <div className="form-group csv-file-div">
      <label htmlFor={inputProps.name}>{field.label}</label>
      <div className="control">
        <input
          onChange={adaptFileEventToValue(onChange)}
          onBlur={adaptFileEventToValue(onBlur)}
          type="file"
          accept=".csv"
          {...inputProps}
          {...props.input}
          {...props}
        />
      </div>
      {field.meta.touched &&
        ((field.meta.error && <p className="help is-danger">{field.meta.error}</p>) ||
          (field.meta.warning && <p className="help is-danger">{field.meta.warning}</p>))}
    </div>
  );
};

const notifySuccess = (msg) => toast.success(msg);

const notifyError = (msg) => toast.error(msg);


const CsvUpload = (props) => {
  const {
    getHealthStatusData,
    uploadCsvFile,
    handleSubmit,
    onResetForm,
  } = props;
  const [isLoading, setIsLoading] = useState(false)

  const uploadCSV = (values) => {
    let data = new FormData();
    data.append('event_id', values.event_id);
    data.append('file', values.csv_file);
    uploadCsvFile(data, () => {
      notifySuccess('Upload Csv File Successfully.');
      getHealthStatusData();
      onResetForm();
      setIsLoading(false);
    });
  }

  return (
    <div className="from-div">
      <h2>Upload CSV File</h2>
      <Form onSubmit={handleSubmit(uploadCSV)}>
        <Field
          name="event_id"
          type="text"
          placeholder="Enter Event ID"
          component={renderField}
          label="EventID"
        />
        <Field
          name="csv_file"
          component={renderFileField}
          type="file"
          label="Upload CSV File"
        />
        <button type="submit" className="upload-btn exp-btn">
          {isLoading ? 'Waiting on Health Access...' : 'Upload'}
        </button>
      </Form>
    </div>
  )
}

const validate = (values) => {
  const errors = {};
  if (!values.event_id) {
    errors.event_id = 'Field is required';
  }
  if (!values.csv_file) {
		errors.csv_file = 'Field is required';
	}
  return errors;
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getHealthStatusData: () => dispatch(actions.getHealthStatusData()),
  uploadCsvFile: (params, callback) => dispatch(actions.uploadCsvFile(params, callback)),
  onResetForm: () => dispatch(reset('CsvUploadForm')),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'CsvUploadForm',
    validate,
    multipartForm : true,
  })(CsvUpload)
);