import React, {useEffect, useState, createRef} from 'react';

import { connect } from 'react-redux';
import axiosAuth from '../../redux/axiosInstance';
import MainContent from '../../components/Layouts/MainContent';
import { ToastContainer } from 'react-toastify';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
// import * as AWS from 'aws-sdk';
// import * as STS from 'aws-sdk/clients/sts';
// import * as QuickSight from 'aws-sdk/clients/quicksight';
import './styles.scss';


const Sunkist = (props) => {
    const [embedUrl, setEmbedUrl] = useState(null)
    const dashboardRef = createRef();
    
    const embed = (embedUrl) => {
        const options = {
          url: embedUrl,
          container: dashboardRef.current,
          scrolling: "no",
          height: "700px",
          width: "100%",
          locale: "en-US",
          footerPaddingEnabled: true
        };
       QuickSightEmbedding.embedDashboard(options)
    };

    useEffect(() => {
      async function fetchData() {
        // You can await here
        const result = await axiosAuth('coop-members/get-embed-url');
        setEmbedUrl(result.data['embedUrl'])
      }
      fetchData();       
    }, []);

    useEffect(() => {
      if (embedUrl) {
        embed(embedUrl)
      }      
    }, [embedUrl]);
  
  	return(
      <MainContent>
        <ToastContainer position="bottom-center" />
        	<div className="contaner-fluid rw-container data-master">   
            <div ref={dashboardRef} />
          </div>        
      </MainContent>
  	)
  }

  const mapStateToProps = (state) => ({
    user: state.auth.user,
  });

  export default connect(mapStateToProps, null)(Sunkist);