import React, { Component, useState } from 'react';
import { Tooltip } from 'reactstrap';

import Table from '../../../components/Admin/Table';
import './system-health.scss';

const ErrorLog = () => {
    return(
        <button className="btn-down">Download Log</button>
    )
}

const RenderHealthStatus = ({status}) => {
    const [isTooltip, setTooltip] = useState(false)
    return(
        <div className={status}>
            <span id={`${status}-Tooltip`}
            >{status}</span>
            <Tooltip className={`${status}-tooltip`} placement="right" isOpen={isTooltip} target={`${status}-Tooltip`} toggle={() => setTooltip(!isTooltip)}>
                <div className="exchange">
                   <span><b>EX1 Status:</b><p className="status">Offline</p></span>
                   <span><b>Duration:</b><h6>5 Min</h6></span>
                   <span><b>Status Message:</b><h6>Server Disconnected</h6></span>
                </div>
            </Tooltip>
        </div>
    )
}
const severity = {
    columns: [
        {title: 'Company', key: 'company'},
        {title: 'Date of last Health Report', key: 'lastHealthReport'},
        {title: 'Service', key: 'service'},
        {title: 'Health Status', key: 'healthStatus'},
        {title: 'Health Score', key: 'healthScore'},
        {title: 'Offline', key: 'offline'},
        {title: 'Alerts', key: 'alerts'},
        {title: 'Cloud Watch', key: 'cloudWatch'},
        {title: 'Error Log', key: 'errorLog'},
    ],
    rows: [
        {
            company: 'Amanino',
            lastHealthReport: 'Amanino',
            service: 'BlackSwan',
            healthStatus: <RenderHealthStatus status='stable'/>,
            healthScore: '2019-06-22T14:04',
            offline: '2019-06-22T14:04',
            alerts:'description',
            cloudWatch: 'All Logs',
            errorLog: <ErrorLog/>
        },
        {
            company: 'Amanino',
            lastHealthReport: 'Amanino',
            service: 'BlackSwan',
            healthStatus: <RenderHealthStatus status='offline'/>,
            healthScore: '2019-06-22T14:04',
            offline: '2019-06-22T14:04',
            alerts:'description',
            cloudWatch: 'All Logs',
            errorLog: <ErrorLog/>
        }
    ]
}


export default class SystemHealth extends Component {
    render() {
        return (
            <section className="health-section-wrapper">
                <div className="health-content">
                    <h3>System Health</h3>
                    <div className="status">
                        <span className="green">14 Online</span>
                        <span className="pink">3 Offline</span>
                    </div>
                </div>
                <div className="health-checkup">
                <Table columns={severity.columns} rows={severity.rows}/>
                </div>
            </section>
        )
    }
}
