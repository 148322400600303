import React from 'react';
import { connect } from 'react-redux';
import{
    Row, Col,
    Form
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import * as actions from '../../../redux/actions/index';
import '../auger-setup.scss';
import MainContent from '../../../components/Layouts/MainContent';
import Button from '../../../components/Forms/Button';

class AddProject extends React.Component {

  constructor(props){
      super(props);
      this.state = {
        isLoading: false,
      }
  }

  componentDidMount = () => {
    this.props.getJitterBitDataOptions();
  } 
  
  renderField = (field) => {
    const {
      input,
      label,
      placeholder,
      type,
      name,
      meta: { touched, error, warning },
    } = field;
    return (
        <Form.Group controlId={name}>
          <Row>
          <Col md={4}>
            <Form.Label>{label}</Form.Label>
          </Col>
          <Col style={{float: "right"}}>
          <input
              type={type}
              placeholder={placeholder}
              {...input}
              className="auger-input"
          />
          </Col>
          </Row>
          {touched
          && ((error && <p className="help is-danger">{error}</p>)
              || (warning && <p className="help is-danger">{warning}</p>))}
        </Form.Group>
    );
  }

  renderSelectField = (field) => {
    const {
      input,
      label,
      placeholder,
      type,
      name,
      options,
      meta: { touched, error, warning },
    } = field;
    return (
        <Form.Group controlId={name}>
          <Row>
          <Col md={4}>
            <Form.Label>{label}</Form.Label>
          </Col>
          <Col style={{float: "right"}}>
          <select
              type={type}
              placeholder={placeholder}
              {...input}
              className="auger-input"
          >
           <option value=''>Select</option>
           {
             options.map((d, i) => (
                <option key={`${i}-${d.id}`} value={d.id} > {d.name} </option>
             ))
           }
          </select>
          </Col>
          </Row>
          {touched
          && ((error && <p className="help is-danger">{error}</p>)
              || (warning && <p className="help is-danger">{warning}</p>))}
        </Form.Group>
    );
  }
  filename(path){
    path = path.substring(path.lastIndexOf("/")+ 1);
    return (path.match(/[^.]+(\.[^?#]+)?/) || [])[0];
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  notifySuccess = () => toast.success('The project has been setup successfully!');
  notifyErrors = () => toast.error('The project has been setup failed!');

  addProjectHandle = async (params) => {
    const user = this.props.user;
    
    this.setState({ isLoading: true });
    try {
        const addPorjectData = {
            name: params.projectName
        }
        const scanDate = moment(Date()).format('YYYY-MM-DD hh:mm:ss');
        this.props.addAugerProject(addPorjectData, async () => {
            const projectData = this.props.project;
            await this.props.addAugerProjectOnTed({
              company_id: user.company.id, 
              project_id: projectData.id,
              scan_date: scanDate,
				      microservice: 'Auger',
            });
            this.props.startAugerProject(projectData.id, async () => {
                await this.sleep(50000)
                const projectFileData = {
                    fileName: this.filename(params.datasetUrl),
                    name: params.datasetName,
                    project_id: projectData.id,
                    filePath: params.datasetUrl,
                }
                this.props.addAugerProjectFile(projectFileData, async () => {
                    await this.sleep(120000)
                    const transaction = this.props.transaction;
                    const data = {
                        project_id: transaction.project_id,
                        name: `${transaction.name}_${Date.now()}`,
                        project_file_id: transaction.id,
            
                    }
                    this.props.addAugerExperiments(data, () => {   
                        window.open(`https://app.auger.ai/Trovo/${params.projectName}`);                
                        this.setState({ isLoading: false });
                        this.notifySuccess();                        
                        this.props.history.push('/auger-project');
                    })     
                }); 
            });
        });
    } catch (error) {
        this.setState({ isLoading: false });
        this.notifyErrors();
    }
    
  }

  render(){
    const { handleSubmit, jitterBitOptions } = this.props;
  	return(
       <MainContent>
        <div className='contaner-fluid auger-container'>
            <h3>Project Setup</h3>
            <div className="auger-setup-form">
            <ToastContainer position="bottom-right" />
            <Form onSubmit={handleSubmit(this.addProjectHandle)}>
                <Row>
                <Col lg={6} style={{borderRight: "1px solid #6e6e6e"}}>
                    <p>Project details.</p>  
                    <Field
                        name="projectName"
                        component={this.renderField}
                        type="text"
                        label="Project Name" 
                        placeholder="Name"
                    />
                    <Field
                        name="dataSource"
                        component={this.renderSelectField}
                        type="text"
                        label="Data Source"
                        options={jitterBitOptions} 
                    />                      	
                </Col>
                <Col lg={6}>
                    <p>Dataset details.</p> 
                    <Field
                        name="datasetName"
                        type="text"
                        placeholder="Enter dataset Name"
                        component={this.renderField}
                        label="Dataset Name"
                    />
                    <Field
                        name="description"
                        type="text"
                        placeholder="Enter description"
                        component={this.renderField}
                        label="Description"
                    />
                    <Field
                        name="datasetUrl"
                        type="text"
                        placeholder="Enter dataset url(S3 or Public HTTP url)"
                        component={this.renderField}
                        label="Dataset URL"
                    />                                  
                </Col>       
                </Row>
                <Row>
                    <Col className="">
                        <Button type="submit">{this.state.isLoading ? 'processing...' : 'Project Setup'}</Button>
                    </Col>
                </Row>
            </Form>
            </div>
        </div> 
      </MainContent>     
  	)
  }

}

const validate = (values) => {
    const errors = {};
    if (!values.projectName) {
      errors.projectName = 'Field is required';
    }
    if (!values.dataSource) {
        errors.dataSource = 'Field is required';
    }
    if (!values.datasetName) {
        errors.datasetName = 'Field is required';
    }
    if (!values.datasetUrl) {
        errors.datasetUrl = 'Field is required';
    }
    return errors;
  };

  const mapStateToProps = state => ({
    errorMsg: state.auth.errorMsg,
    user: state.auth.user,
    project: state.auger.project,
    transaction: state.auger.transaction,
    jitterBitOptions: state.auger.jitterBitOptions
  });
  
  const mapDispatchToProps = dispatch => ({
    addAugerProjectOnTed: (params ) => dispatch(actions.addAugerProjectOnTed(params)),
    startAugerProject: (project, callback) => dispatch(actions.startAugerProject(project, callback)), 
    addAugerProject: (params, callback) => dispatch(actions.addAugerProject(params, callback)),
    addAugerProjectFile: (params, callback) => dispatch(actions.addAugerProjectFile(params, callback)),
    addAugerExperiments: (params, callback) => dispatch(actions.addAugerExperiments(params, callback)),
    getJitterBitDataOptions: () => dispatch(actions.getJitterBitDataOptions()),
  }); 

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'AddProjectForm',
      validate,
    })(AddProject)
  );







