import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';

const renderField = (field) => {
	const { input, label, placeholder, type, name, meta: { touched, error, warning } } = field;
	return (
		<Form.Group controlId={name}>
			<Form.Label style={{ color: '#6e6e6e' }}>{label}</Form.Label>
			<input type={type} placeholder={placeholder} {...input} className="form-control" />
			{touched &&
				((error && <p className="help is-danger">{error}</p>) ||
					(warning && <p className="help is-danger">{warning}</p>))}
		</Form.Group>
	);
};

class AddModal extends React.Component {
	constructor(props) {
		super(props);
		const editInfo = props.editInfo || {};
		this.state = {
			email: editInfo.email || '',
			firstName: editInfo.first_name || '',
			password: '',
			lastName: editInfo.last_name || ''
		};
	}

	componentDidMount() {
		const { editInfo } = this.props;
		if (editInfo) {
			this.props.dispatch(change('AddUserForm', 'email', editInfo.email));
			this.props.dispatch(change('AddUserForm', 'firstName', editInfo.first_name));
			this.props.dispatch(change('AddUserForm', 'lastName', editInfo.last_name));
		}
	}

	addUser = (data) => {
		const { email, firstName, lastName, password } = data;
		this.props.addUser({ first_name: firstName, last_name: lastName, email: email, password: password });
	};

	render() {
		const { isOpen, toggleModal, editInfo, handleSubmit } = this.props;
		return (
			<div>
				<Modal isOpen={isOpen} toggle={toggleModal}>
					<ModalHeader toggle={toggleModal}>{editInfo ? 'Edit User' : 'Add User'}</ModalHeader>
					<ModalBody>
						<Form onSubmit={handleSubmit(this.addUser)}>
							<Field
								name="email"
								type="email"
								placeholder="Enter Email"
								component={renderField}
								label="Email"
							/>
							<Field
								name="password"
								type="password"
								placeholder="Enter Password"
								component={renderField}
								disabled={!!editInfo}
								label="Password"
							/>
							<Field
								name="firstName"
								type="text"
								placeholder="Enter First Name"
								component={renderField}
								label="First Name"
							/>
							<Field
								name="lastName"
								type="text"
								placeholder="Enter Last Name"
								component={renderField}
								label="Last Name"
							/>
							<ModalFooter>
								<Button type="submit" color="primary">
									{editInfo ? 'Edit' : 'Add'}
								</Button>{' '}
								<Button color="secondary" onClick={toggleModal}>
									Cancel
								</Button>
							</ModalFooter>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

const validate = (values, props) => {
	const errors = {};
	if (!values.email) {
		errors.email = 'Field is required';
	}
	if (!values.firstName) {
		errors.firstName = 'Field is required';
	}
	if (!values.lastName) {
		errors.lastName = 'Field is required';
	}
	if (!props.editInfo && !values.password) {
		errors.password = 'Field is required';
	}
	return errors;
};

export default reduxForm({
	form: 'AddUserForm',
	validate
})(AddModal);
