import React, { Component } from 'react';
import Button from "../../../components/Admin/Button";
import './support-case-review.scss';

export default class SupportCaseReview extends Component {

    render() {
        return (
            <section className="review-section">
                <div className="container-fluid">
                        <div className="support-content">
                            <h5>Support Cases</h5>
                        </div>
                            <div className="bg-review">
                                <div className="heading">
                                    <h4>Case ID 1234567</h4>
                                    <Button text='reopen case' />
                                </div>
                                <div className="support-acc-detail">
                                    <div className="data-detail">
                                        <ul className="detail-info">
                                            <li><p>Company</p><span>Armanino</span></li>
                                            <li><p>Subject</p><span>BlackSwan data sync issues</span></li>
                                            <li><p>Date Created</p><span>2019-06-22 T 14:04</span></li>
                                            <li><p>Case Type</p><span>Technical</span></li>
                                        </ul>
                                        <ul className="detail-info">
                                            <li><p>Status</p><span>Resolved</span></li>
                                            <li><p>Severity</p><span>High</span></li>
                                            <li><p>Opened By</p><span>john.doe@aarmanino.com</span></li>
                                            <li><p>Additional Contacts</p><span>john.doe@aarmanino.com</span><span>john.doe@aarmanino.com</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <span className="correspondence-section">
                                    <h5>Correspondence</h5>
                                     <Button text='new message' />
                                </span>
                                <div className="correspondence-acc-detail">
                                    <div className="data-detail">
                                        <ul className="detail-info">
                                            <li><p>john.doe@aarmanino.com</p><span>2019-06-22 T 14:04</span></li>
                                            <li><div className="text-content">
<h6>Hello,</h6>
                                                <p>The data for our latest BlackSwan scan did not sync properly, and therefore did not run. Please help us fix this.</p>
                                                <p>Thank you,</p>
                                                <span>
                                                    <p>John Doe <br/>Armanino</p>
                                                </span>
                                            </div> </li>
                                        </ul>
                                        <ul className="detail-info-content">
                                            <li><p>john.doe@aarmanino.com</p><span>2019-06-22 T 14:04</span></li>
                                            <li><div className="text-content">
                                                <h6>Hello,</h6>
                                                <p>The data for our latest BlackSwan scan did not sync properly, and therefore did not run. Please help us fix this.</p>
                                                <p>Thank you,</p>
                                                <span>
                                                    <p>John Doe <br/>Armanino</p>
                                                </span>
                                            </div> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </div>
            </section>
        )
    }
}
