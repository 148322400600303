import axiosAuth from '../axiosInstance';

export const GET_COMPANY_NAME = 'GET_COMPANY_NAME';
export const GET_COMP_RESELLER_NAME = 'GET_COMP_RESELLER_NAME';
export const UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_START = 'FETCH_START';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const ADD_COMPANY_START = 'ADD_COMPANY_START';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const EDIT_COMPANY_START = 'EDIT_COMPANY_START';
export const EDIT_COMPANY_FAIL = 'EDIT_COMPANY_FAIL';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';

export const fetchStart = () => ({
	type: FETCH_START
});

export const addStart = () => ({
	type: ADD_COMPANY_START
});

export const addFail = (message) => ({
	type: ADD_COMPANY_FAIL,
	errMsg: message
});

export const addComplete = (company) => ({
	type: ADD_COMPANY_SUCCESS,
	company: company
});

export const editStart = () => ({
	type: EDIT_COMPANY_START
});

export const editFail = (message) => ({
	type: EDIT_COMPANY_FAIL,
	errMsg: message
});

export const editComplete = (company) => ({
	type: EDIT_COMPANY_SUCCESS,
	company: company
});

export const getCompanyNames = (names) => ({
	type: GET_COMPANY_NAME,
	companiesNames: names
});

export const getCompResellerNames = (names) => ({
	type: GET_COMP_RESELLER_NAME,
	compResellerNames: names
});

export const fetchFail = (errorMsg) => ({
	type: FETCH_FAIL,
	errorMsg
});

export const updateCompanyName = (name) => {
	localStorage.setItem('companyName', name);
	return {
		type: UPDATE_COMPANY_NAME,
		name
	};
};

export const getCompanies = (companies) => {
	return {
		type: FETCH_COMPANIES,
		companies
	};
};

export const fetchCompanyNames = () => async (dispatch) => {
	dispatch(fetchStart());
	await axiosAuth
		.get('company-names')
		.then((resData) => {
			dispatch(getCompanyNames(resData.data.data));
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};

export const fetchCompResellerNames = (company_id) => async (dispatch) => {
	dispatch(fetchStart());
	await axiosAuth
		.get(`company-reseller-names/${company_id}`)
		.then((resData) => {
			dispatch(getCompResellerNames(resData.data.data));
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};

export const fetchCompanies = () => async (dispatch) => {
	dispatch(fetchStart());
	await axiosAuth
		.get('/companies/')
		.then((resData) => {
			dispatch(getCompanies(resData.data.results));
		})
		.catch((err) => {
			dispatch(fetchFail(err.message));
		});
};

export const addCompany = (company) => async (dispatch) => {
	dispatch(addStart());
	axiosAuth
		.post(`/companies/`, company)
		.then((resData) => {
			dispatch(addComplete(resData.data.data));
		})
		.catch((err) => {
			dispatch(addFail(err.message));
		});
};

export const editCompany = (company, id) => async (dispatch) => {
	dispatch(editStart());
	axiosAuth
		.put(`/companies/${id}/`, company)
		.then((resData) => {
			dispatch(editComplete(resData.data.data));
		})
		.catch((err) => {
			dispatch(editFail(err.message));
		});
};

export const onDeleteCompany = (id, callback) => async (dispatch) => {
	dispatch(editStart());
	axiosAuth
		.delete(`/companies/${id}`)
		.then(() => {
			callback();
		})
		.catch((err) => {
			dispatch(editFail(err.message));
		});
};
