"use strict";
import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import * as _ from 'lodash';


const drawBarChart = (data) => {

    data = data.splice(0, 10).sort(function (a, b) {
        return d3.ascending(a.name, b.name);
    })

    var margin = {top: 20, right: 20, bottom: 30, left: 70};

    // set the ranges
    var y = d3.scaleBand()
              .range([225, 0])
              .padding(0.1);

    var x = d3.scaleLinear()
                  .range([0, 150]);
          
    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var container = d3.select("#bar-chart-holder");
    container.selectAll("*").remove();
    var svg = container.append("svg")
                         .attr("preserveAspectRatio", "xMidYMid meet")
                         .attr("viewBox", "0 0 225 275")
                         .classed("svg-content-rw-plot", true);
    var g = svg.append("g")
       .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


     // Scale the range of the data in the domains
     x.domain([0, d3.max(data, function(d){ return d.sensitivityScore; })])
     y.domain(data.map(function(d) { return d.name; })).padding(0.5);
     //y.domain([0, d3.max(data, function(d) { return d.sales; })]);

     // append the rectangles for the bar chart
     g.selectAll(".bar")
         .data(data)
       .enter().append("rect")
         .attr("class", "bar")
         .attr("fill", "#a5f5ff")
         //.attr("x", function(d) { return x(d.sales); })
         .attr("width", function(d) {return x(d.sensitivityScore); } )
         .attr("y", function(d) { return y(d.name); })
         .attr("height", y.bandwidth());

    // add the y Axis
    g.append("g")
        .call(d3.axisLeft(y));

}

class RWHorizontalBar extends React.Component {

    constructor(props){
    	super(props);
    	this.state = {
    		data: null
    	}
    }

  componentDidMount(){
    
    const data = _.cloneDeep(this.props.data)

    this.setState({
      data
    }, () => {
      drawBarChart(this.state.data)
    } )

  }

  componentDidUpdate(prevProps, prevState){
  	const data = _.cloneDeep(this.props.data)
  	if(prevProps.data != this.props.data){
  	  this.setState({
        data
       }, () => {
        drawBarChart(this.state.data)
       } )
  	}
  }

    render(){
    	return(
            <div id="bar-chart-holder"></div>
    	)
    }

}


export default RWHorizontalBar;







