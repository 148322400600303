import React from 'react';
import size from 'lodash/size';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import Chart from './chart';

import { experimentData } from './charts-data';

class ChartDataModal extends React.Component {

  constructor(props){
    super(props);

  }

  render() {
    const { isOpen, toggleModal, featureImportanceData } = this.props;
    return(
      <div>
        <Modal isOpen={isOpen} toggle={toggleModal} size='lg' className="metrics-window">
          <ModalHeader toggle={toggleModal}>
              Data       
          </ModalHeader>
          <ModalBody>
            <div>
            {  size(featureImportanceData) > 0 ? (
                <Chart {...experimentData(featureImportanceData)}/>
                ) : (
                <div className="text-center alert alert-info fade show" role="alert">This metric is not supported for the selected model.</div>
                ) 
              }
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default ChartDataModal;
