import React, { Component } from 'react';
import Table from '../../../components/Admin/Table';
import DatePicker from "react-datepicker";
import Button from "../../../components/Admin/Button";
import "react-datepicker/dist/react-datepicker.css";

import './service-history.scss';

const StatusRender = ({status}) => {
    return(
        <div className={severity}>{status}</div>
    )
}
const severity = {
    columns: [
        {title: 'Company', key: 'company'},
        {title: 'Reseller', key: 'reseller'},
        {title: 'MicroService', key: 'microService'},
        {title: 'Status', key: 'status'},
        {title: 'Time Initiated', key: 'timeInitiated'},
        {title: 'Time Completed', key: 'timeCompleted'},
        {title: 'Notes', key: 'notes'},
    ],
    rows: [
        {
            company: 'Amanino',
            reseller: 'Amanino',
            microService: 'BlackSwan',
            notes: 'BlackSwan data sync issues',
            timeInitiated: '2019-06-22T14:04',
            timeCompleted: '2019-06-22T14:04',
            status: <StatusRender status="Success"/>,
        }
    ]
}

const UserInteraction = {
    columns: [
        {title: 'Company', key: 'company'},
        {title: 'User', key: 'user'},
        {title: 'User Access', key: 'userAccess'},
        {title: 'Action', key: 'action'},
        {title: 'Page', key: 'page'},
        {title: 'Time of Action', key: 'timeAction'},
        {title: 'Time on Page', key: 'timePage'},
    ],
    rows: [
        {
            company: 'Amanino',
            user: 'Amanino',
            userAccess: 'BlackSwan',
            action: 'BlackSwan data sync issues',
            page: '2019-06-22T14:04',
            timeAction: '2019-06-22T14:04',
            timePage: '00:05:26',
        }
    ]
}

export default class ServiceHistory extends Component {
    constructor(props){
        super(props);
        this.state = {
            startDate: new Date()
        };
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    render() {
        return (
            <section className="service-history-wrapper">
<div className="container-fluid">
    <div className="row">
      <div className="service-main-usage">
        <h3>Service Usage History</h3>
        <div className="service-history-content">
            <div className="history-header">
                    <span>
                       <span className="datepiker">
                        <label>Start Date</label>
                        <DatePicker
                            selected={this.state.date}
                            onChange={this.handleChange}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                           </span>
                        <span className="datepiker">
                        <label>End Date</label>
                <DatePicker
                    selected={this.state.date}
                    onChange={this.handleChange}
                    showTimeSelect
                    dateFormat="Pp"
                />
                            </span>
                <a> +Add filter</a>
                    </span>
                <Button text="filter" />
            </div>
            <Table columns={severity.columns} rows={severity.rows}/>
            <span className="export"><a>export</a></span>
        </div>
      </div>
        <div className="service-main-usage">
            <h3>Service Usage History</h3>
            <div className="service-history-content">
                <div className="history-header">
                    <span>
                       <span className="datepiker">
                        <label>Start Date</label>
                        <DatePicker
                            selected={this.state.date}
                            onChange={this.handleChange}
                            showTimeSelect
                            dateFormat="Pp"
                        />
                           </span>
                        <span className="datepiker">
                        <label>End Date</label>
                <DatePicker
                    selected={this.state.date}
                    onChange={this.handleChange}
                    showTimeSelect
                    dateFormat="Pp"
                />
                            </span>
                <a> +Add filter</a>
                    </span>
                    <Button text="filter" />
                </div>
                <Table columns={UserInteraction.columns} rows={UserInteraction.rows}/>
            </div>
        </div>
    </div>
</div>
            </section>
        )
    }
}
