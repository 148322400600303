import React, {useEffect, useState} from 'react';
import size from 'lodash/size';
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import { Link } from 'react-router-dom';
import MainContent from '../../components/Layouts/MainContent';
import { ToastContainer, toast } from 'react-toastify';
import AddProjectModal from './AddProjectModal';
import * as actions from '../../redux/actions/index';
import './reveal-why.scss';

const renderProject = (projects) => {  
  return projects.map((d, i) => (
    <div className="my-3 col-12 col-md-6 col-xl-4">
      <div
        className="dataset-card 1-200 clickable-block text-muted card"
        key={i + d.id}
      >
        <div className="bg-transparent border-bottom-0 pr-0 card-header h-200">
          <div className="">
            <div className="align-self-center col">
              <h5 className="mb-0 card-title">
                  {d.project_name}
              </h5>              
            </div>
            <div className="btn-link">
                <Link to={`/reveal-why/${d.id}/1`} className="btn">Cloud Version</Link>
                <Link to={`/reveal-why/${d.id}/2`} className="btn">On-premise</Link>
              </div>
          </div>
        </div>														
      </div>
    </div>
  ))
}

const notifySuccess = (msg) => toast.success(msg);

const notifyError = (msg) => toast.error(msg);

const RevealWhy = (props) => {
    const [isModal, setIsModel] = useState(false)
    const dispatch = useDispatch()
    const { projects, user } = useSelector(state => ({
      user: state.auth.user,
      projects: state.rw.projects
    }), shallowEqual);
  
    useEffect(() => {
      dispatch(actions.getProjectList(user.company.id))
    }, []);     
    
    const toggleModal = () => {
      setIsModel(!isModal)
    };

    const handleAddProject = () => {
      setIsModel(true)
    };

    const createProject = (params) => {
      dispatch(actions.addWRProject(params, () => {
        setIsModel(false);
        notifySuccess('project has been successfully created');
        dispatch(actions.getProjectList(user.company.id));
      }))
    }
  	return(
      <MainContent>
        <ToastContainer position="bottom-center" />
          <div className="contaner-fluid rw-container">
            <h3 className="title-h">Reveal Why Projects</h3>
            <div className="rw-setup-form">
              <div className="tab-content-wrapper">
                    <div className="container">
                      <div className="row" style={{flexFlow: "row wrap" }}>
                        <div className="my-3 col-12 col-md-6 col-xl-4">
                          <div className="h-200 text-muted clickable-block card">
                            <div className="d-flex align-items-center justify-content-center card-body">
                              <p id="rw-add-new-card" className="card-text" onClick={handleAddProject}>
                                Add New
                              </p>
                              {isModal && (
                                <AddProjectModal
                                  isOpen={isModal}
                                  toggleModal={toggleModal}
                                  company_id={user.company.id}
                                  createProject={createProject}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {size(projects) > 0 ? renderProject(projects.results) : null}
                      </div>
                    </div>
                  </div>
            </div>
          </div>
			</MainContent>
  	)
  }

export default RevealWhy;