// admin pages
import Dashboard from './Admin/Dashboard';
import Alerts from './Admin/Alerts';
import CompanyDetails from './Admin/CompanyDetails';
import ResellerDetails from './Admin/resellerDetails';
import MicroServices from './Admin/MicroServices';
import AccountDetails from './Admin/AccountDetails';
import AccountSettings from './Admin/AccountSettings';
import SupportCases from './Admin/SupportCases';
import SupportCaseReview from './Admin/SupportCaseReview';
import Calendar from './Admin/Calendar';
import Reegis from './Admin/Reegis';
import SystemHealth from './Admin/SystemHealth';
import ServiceHistory from './Admin/ServiceHistory';
import CompanyManagement from './Admin/CompanyManagement';
import AdminSettings from './Admin/AdminSettings';

// Client Pages
import CompanySettings from './CompanySettings';
import Homepage from './Homepage';
import CyberScan from './CyberScan';
import Main from './Main';
import Login from './Login';
import Register from './Register';
import Logout from './Logout';
import DashboardNew from './DashboardNew';
import ForgotPassword from './ForgotPassword';
import Playbook from './Playbook';
import AugerSetup from './AugerSetup';
import AugerProjectDetails from './AugerSetup/project';
import AddAugerProject from './AugerSetup/From/AddProject';


import RevealWhy from './RevealWhy';
import RWType from './RevealWhy/RWType';

import HealthAccessManager from './HealthAccessManager';
import Sunkist from './Sunkist';
import MemberScorecards from './Sunkist/MemberScorecards'

import GrafanaDashboard from './GrafanaDashboard'

import menuIconSettings from '../../static/images/menu-icon-settings.png';
import menuIconLogout from '../../static/images/menu-icon-logout.png';
import menuIconDashboard from '../../static/images/menu-icon-dashboard.png';
import menuIconDSA from '../../static/images/menu-icon-data-surface.png';

const authRoutes = [
	{
		path: '/',
		layout: '/client',
		name: 'dashboard',
		component: Homepage,
		priority: 1,
		image: menuIconDashboard
	},
	{
		path: '/detection-scan',
		layout: '/client',
		name: 'cyber-discovery',
		component: CyberScan,
		priority: 5,
		image: menuIconDSA,
		group: "risk-exposure-playbook",
    groupOrder: 2
	},
	{
		path: '/data-surface',
		layout: '/client',
		name: 'data-surface',
		component: Main,
		priority: 2,
		image: menuIconDSA,
		group: "risk-exposure-playbook",
    groupOrder: 3 
	},
	{
		path: '/risk-exposure-insights',
		layout: '/client',
		name: 'risk-exposure-insights',
		component: DashboardNew,
		priority: 4,
		image: menuIconDSA,
		group: "risk-exposure-playbook",
    groupOrder: 4 
	},
	{
		path: '/settings',
		layout: '/client',
		name: 'company-settings',
		component: CompanySettings,
		priority: 3,
		image: menuIconSettings,
		group: "risk-exposure-playbook",
    groupOrder: 1
	},
	{
		path: '/growth-playbook',
		layout: '/client',
		name: 'growth-playbook',
		component: Playbook,
		priority: 4,
		image: menuIconSettings
	},{
		path: '/people-playbook',
		layout: '/client',
		name: 'people-playbook',
		component: Playbook,
		priority: 4,
		image: menuIconSettings
	},{
		path: '/risk-exposure-playbook',
		layout: '/client',
		name: 'risk-exposure-playbook',
		component: Playbook,
		priority: 4,
		image: menuIconSettings,
		group: "risk-exposure-playbook",
    groupOrder: 0 
	},
	{
		path: '/auger-project',
		layout: '/client',
		name: 'auger-project',
		component: AugerSetup,
		priority: 4,
		image: menuIconDashboard,		
	},    
	{
		path: '/project-details/:id',
		layout: '/client',
		name: 'auger-project',
		component: AugerProjectDetails,
		priority: 4,
		image: menuIconDashboard
	},
	{
		path: '/auger-project/add',
		layout: '/client',
		name: 'add-auger-project',
		component: AddAugerProject,
		priority: 4,
		image: menuIconDashboard
	},
	{
		path: '/reveal-why',
		layout: '/client',
		name: 'reveal-why',
		component: RevealWhy,
		priority: 4,
		image: menuIconDashboard,		
	},
	{
		path: '/reveal-why/:id/:type',
		layout: '/client',
		name: 'reveal-why',
		component: RWType,
		priority: 4,
		image: menuIconDashboard,		
	},
	{
		path: '/biosense',
		layout: '/client',
		name: 'Biosense Infotech',
		component: HealthAccessManager,
		priority: 4,
		image: menuIconDashboard,		
	},
	{
		path: '/project-sunkisst',
		layout: '/client',
		name: 'PROJECT SUNKISST',
		component: Sunkist,
		priority: 4,
		image: menuIconDashboard,		
	},
	{
		path: '/project-sunkisst/members',
		layout: '/client',
		name: 'Project Sunkisst',
		component: MemberScorecards,
		priority: 4,
		image: menuIconDashboard,		
	},
	// {
	//   path: '/auger-project/:project_id',
	//   layout: '/client',
	//   name: 'add-auger-project-dataset',
	//   component: AddAugerProjectDataset,
	//   priority: 4,
	//   image:menuIconDashboard
	// },
	// {
	//   path: '/auger-project/:project_id/:experiment_id',
	//   layout: '/client',
	//   name: 'run-auger-project-experiment',
	//   component: RunDatasetExperiment,
	//   priority: 4,
	//   image:menuIconDashboard
	// },
	// {
	//   path: '/edit-settings',
	//   layout: '/client',
	//   name: 'edit-settings',
	//   component: EditSettings,
	//   priority: 4,
	//   image:"/images/menu-icon-settings.png"
	// },
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		priority: 5,
		image: menuIconLogout
	}
];

const adminRoutes = [
	{
		path: '/admin/dashboard',
		layout: '/admin',
		name: 'admin-dashboard',
		component: Homepage,
		priority: 1,
		image: menuIconDashboard
	},
	{
		path: '/admin/risk-exposure-insights',
		layout: '/admin',
		name: 'risk-exposure-insights',
		component: DashboardNew,
		priority: 1,
		image: menuIconDashboard
	},
	{
		path: '/admin/alerts',
		layout: '/admin',
		name: 'admin-alerts',
		component: Alerts,
		priority: 1,
		image: menuIconDashboard
	},
	{
		path: '/admin/company-details/:id',
		layout: '/admin',
		name: 'Summary',
		component: CompanyDetails,
		priority: 2,
		image: menuIconDSA
	},
	{
		path: '/admin/reseller-details/:id',
		layout: '/admin',
		name: 'reseller-details',
		component: ResellerDetails,
		priority: 2,
		image: menuIconDSA
	},
	{
		path: '/admin/services',
		layout: '/admin',
		name: 'services',
		component: MicroServices,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin',
		layout: '/admin',
		name: 'services',
		component: AccountDetails,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/account-settings',
		layout: '/admin',
		name: 'services',
		component: AccountSettings,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/support-cases',
		layout: '/admin',
		name: 'services',
		component: SupportCases,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/support-case-review',
		layout: '/admin',
		name: 'services',
		component: SupportCaseReview,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/calendar',
		layout: '/admin',
		name: 'services',
		component: Calendar,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/reegis',
		layout: '/admin',
		name: 'services',
		component: Reegis,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/system-health',
		layout: '/admin',
		name: 'services',
		component: SystemHealth,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/admin/service-history',
		layout: '/admin',
		name: 'services',
		component: ServiceHistory,
		priority: 3,
		image: menuIconSettings
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		priority: 4,
		image: menuIconLogout
	},
	{
		path: '/admin/manage-companies',
		layout: '/admin',
		name: 'company_management',
		component: CompanyManagement,
		priority: 5,
		image: menuIconDashboard
	},
	{
		path: '/admin/monitoring',
		layout: '/admin',
		name: 'monitoring',
		component: GrafanaDashboard,
		priority: 5,
		image: menuIconDashboard
	},
	{
		path: '/admin/manage-users',
		layout: '/admin',
		name: 'company_users',
		component: Dashboard,
		priority: 5,
		image: menuIconDashboard
	},
	{
		path: '/admin/admin-settings',
		layout: '/admin',
		name: 'admin_settings',
		component: AdminSettings,
		priority: 6,
		image: menuIconDashboard
	}
];

const withoutAuthRoutes = [
	{
		path: '/',
		name: 'login',
		component: Login
	},
	{
		path: '/register',
		name: 'register',
		component: Register
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: ForgotPassword
	}
];

export { authRoutes, adminRoutes, withoutAuthRoutes };
