import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import MainContent from '../../components/Layouts/MainContent';
import { API_URL } from '../../services/config';
import './playbook.scss';

//in future may merge with Playbook component, since they are extremely similar.

///this data will likely be static across users. 
const pageData = {
	description: `The Data Innovation Factory to help you compete today and tomorrow.`,
	items: [
		{
			title: 'Risk Exposure',
			image: 'https://www.trovo.ai/wp-content/uploads/2019/09/design-data-product.jpg',
			description: `Take control of data protection with automated surveillance designed to protect 
                 assets, reduce unnecessary exposure to risk, ensure regulatory compliance, and purge 
                redundant data to save on storage and security.`,
			link: '/risk-exposure-playbook',
      notifications: 6
		},
		{
			title: 'Growth',
			image: 'https://www.trovo.ai/wp-content/uploads/2019/09/design-optimization.jpg',
			description: `Our elastic platform capable of consuming data and logic from a range of API endpoints, 
                adding a new layer of AI models on the combined data set, and presenting the data back 
                through an advanced visualization engine.`,
			link: '/growth-playbook',
      notifications: 3
		},{
      title: 'People',
      image: 'https://images.unsplash.com/photo-1528901166007-3784c7dd3653?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
      description: `People are an organization's most valuable asset, and ensuring that all 
                    members are in positions where their talents, temperament, and career goals
                    are aligned to contribute most effectively is key to bridging organizational success 
                    with a positive and enriching company culture.`,
      link: '/people-playbook',
      notifications: 10
    },
		{
			title: 'Knowledge Base',
			image: 'https://www.trovo.ai/wp-content/uploads/2019/09/prototyping.jpg',
			description: `Learn, understand, and share your data insights with enablement tools, video, 
                       and documentation to support your ongoing Trovo TED platform projects with best 
                       practices for data innovation.`,
			link: null,
      notifications: 0
		},
		{
			title: 'API',
			image: 'https://www.trovo.ai/wp-content/uploads/2019/09/design-ecosystem.jpg',
			description: `Integrate into the TED platform with RESTful APIs and convenience methods for 
                       data sources, transformations, reporting. and visualizations to jump-start your 
                       value-added business rules and functionality.`,
			link: `${API_URL}/docs/`,
      notifications: 0
		},
		{
			title: 'Admin',
			image: 'https://www.trovo.ai/wp-content/uploads/2019/09/strategy.jpg',
			description: `As the Trovo super-user, control, administer, and monitor the TED 
               platform for users, companies, microservices, reports, and payments.`,
			link: '/admin/admin-reports',
      notifications: 0
		},
		{
			title: 'Feedback',
			image: 'https://www.trovo.ai/wp-content/uploads/2019/09/manage-portfolio.jpg',
			description: `Submit your bugs, feature requests, wish list, and suggestions for 
               improving TED with our feedback form.`,
  link: "http://bit.ly/trovo-feedback ",
      notifications: 11
}, {title: null}, {title: null}]

}

const Homepage = ({
    user
}) => { 

  console.log(user)

  //to display the admin card, or not to?
  if(user.user_role != 1){
   var items = pageData.items.filter((d) => d.title != "Admin" );
  } else {
   var items = pageData.items;
  }

  //padding out divs for flex box responsiveness
  let remainder = pageData.items.length % 5;
  if(remainder != 0){
    for(let i= 0; i < remainder; i++ ){
      items.push({title: null});
    }
  } 

  return (   
  <MainContent>
<div className="container-fluid">
    <div className="sub-header-section">
        <p>{pageData.description}</p>
    </div>
    <div className="main-surface-wrapper">
    {

       items.map((d, i) => {
         if(d.title){
           return (                   
             <div className="surface-section" key={i + " : " + d.title }>
                <a href={d.link ? d.link : '/'}  className="surface-link" >
                  <span className={"point " + (d.notifications > 0 ? "red" : "" ) } >{d.notifications}</span>
                  <div className="image-wrap">
                      <img src={d.image}/>
                  </div>
                  <div className="loader">
                      <span></span>
                  </div>
                  <div className="content-section">
                      <span>{i+1}<h4>{d.title}</h4> </span>
                      <p></p>
                  </div>
                  <div className="service-content">
                      <h4></h4>
                      <p>{d.description}</p>
                  </div>
                </a>
              </div>
               
           )
         
         } else {
          return ( <div className="surface-section" style={{minWidth:"250px"}} key={i} ></div> )
        } 
       })

    }
            
    </div>
</div>
</MainContent>
  );
};

const mapStateToProps = (state) => ({
	user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
