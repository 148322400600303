import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';

const renderField = (field) => {
  const {
    input,
    label,
    placeholder,
    type,
    name,
    meta: { touched, error, warning },
  } = field;
  return (
      <Form.Group controlId={name}>
        <Form.Label style={{color: "#6e6e6e"}}>{label}</Form.Label>
        <input
            type={type}
            placeholder={placeholder}
            {...input}
            className="form-control"
        />
        {touched
        && ((error && <p className="help is-danger">{error}</p>)
            || (warning && <p className="help is-danger">{warning}</p>))}
      </Form.Group>
  );
}

class AddExperimentModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      uploading: false,
    }
  }

  createExpreiment = (data) => {
    const { name, description, url } = data;
    const params = {
      name,
      description,
      fileName: this.filename(url),
      filePath: url,
      project_id: this.props.project_id
    };
    this.props.createExpreiment(params);
  };

  filename(path){
    path = path.substring(path.lastIndexOf("/")+ 1);
    return (path.match(/[^.]+(\.[^?#]+)?/) || [])[0];
  }
  componentDidMount() {
  }

  render() {
    const { isOpen, toggleModal, handleSubmit } = this.props;
    const { uploading } = this.state;
    return(
      <div>
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Create Project DataSet</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(this.createExpreiment)}>
              <Field
                  name="name"
                  type="text"
                  placeholder="Enter dataset Name"
                  component={renderField}
                  label="Dataset Name"
              />
              <Field
                  name="description"
                  type="text"
                  placeholder="Enter description"
                  component={renderField}
                  label="Description"
              />
              <Field
                  name="url"
                  type="text"
                  placeholder="Enter dataset url(S3 or Public HTTP url)"
                  component={renderField}
                  label="Dataset URL"
              />
              <ModalFooter>
                <Button disabled={uploading} type="submit" color="primary">{uploading ? 'Uploading..' : 'Create New Dataset'}</Button>{' '}
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </ModalBody>

        </Modal>
      </div>
    )
  }
}
const validate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Field is required';
  }
  if (!values.url) {
    errors.url = 'Field is required';
  }
  return errors;
};

export default reduxForm({
  form: 'AddExperimentForm',
  validate,
})(AddExperimentModal);
